export const VOICE_GENDER = [
  {
    key: 'female',
    value: 'Female',
  },
  {
    key: 'male',
    value: 'Male',
  },
];

export const VIDEO_LENGTH_OPTIONS = [
  {
    key: '0',
    value: 'Short',
  },
  {
    key: '1',
    value: 'Average',
  },
  {
    key: '2',
    value: 'Long',
  },
];

export const LANGUAGE = [
  {
    key: 'ar',
    value: 'Arabic',
  },
  {
    key: 'az',
    value: 'Azerbaijani',
  },
  {
    key: 'bn',
    value: 'Bengali',
  },
  {
    key: 'bs',
    value: 'Bosnian',
  },
  {
    key: 'bg',
    value: 'Bulgarian',
  },
  {
    key: 'ca',
    value: 'Catalan',
  },
  {
    key: 'cs',
    value: 'Czech',
  },
  {
    key: 'da',
    value: 'Danish',
  },
  {
    key: 'de',
    value: 'German',
  },
  {
    key: 'el',
    value: 'Greek',
  },
  {
    key: 'en',
    value: 'English',
  },
  {
    key: 'es',
    value: 'Spanish',
  },
  {
    key: 'he',
    value: 'Hebrew',
  },
  {
    key: 'hi',
    value: 'Hindi',
  },
  {
    key: 'cy',
    value: 'Welsh',
  },
  {
    key: 'tr',
    value: 'Turkish',
  },
].sort((a, b) => a.value.localeCompare(b.value));

export const DIALECTS = {
  ar: ['AE'],
  az: ['AZ'],
  bg: ['BG'],
  bn: ['BD', 'IN'],
  bs: ['BA'],
  ca: ['ES'],
  cs: ['CZ'],
  cy: ['GB'],
  da: ['DK'],
  de: ['AT', 'CH', 'DE'],
  el: ['GR'],
  en: ['US', 'AU', 'CA', 'GB', 'HK', 'IE', 'IN', 'ZA'],
  es: ['AR', 'BO', 'CL', 'CO'],
  he: ['IL'],
  hi: ['IN'],
  tr: ['TR'],
};

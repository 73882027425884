import React, {useRef, useState} from 'react';
import ModalContainer from '../../uiToolkit/ModalContainer';
import {MediaFile} from '../../data/models/LibraryFile/MediaFile';
import {isImageFile} from '../../data/models/LibraryFile/VideoSourceShort';
import SimplePlayerControlled from '../../uiToolkit/SimplePlayerControlled';
import './styles.css';

interface Props {
  onClose: () => void;
  file: MediaFile;
}

const MediaPreview = React.memo(({onClose, file}: Props) => {
  const [width, setWidth] = useState(0);
  const containerRef = useRef<any>(null);

  React.useEffect(() => {
    window.addEventListener('resize', setPlayerDimentions);
    return () => {
      window.removeEventListener('resize', setPlayerDimentions);
    };
  }, []);

  React.useLayoutEffect(() => {
    setPlayerDimentions();
  }, []);

  const setPlayerDimentions = () => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  };

  return (
    <ModalContainer onClose={onClose}>
      {isImageFile(file.fileType) ? (
        <div className="PreviewImageContainer">
          <img src={file.fileUrl} />
        </div>
      ) : (
        <div className="PreviewPlayerContainer" ref={containerRef}>
          <SimplePlayerControlled
            source={file.fileUrl}
            width={width}
            dontLoop
          />
        </div>
      )}
    </ModalContainer>
  );
});

export default MediaPreview;

import {useEffect, useState} from 'react';
import PermissionGateStore from '../store/PermissionGateStore';
import {inject, observer} from 'mobx-react';

interface Props {
  children: any;
  permissionGateStore?: PermissionGateStore;
  permission: string;
  organizationId: string;
}

const PermissionGateContanier = inject('permissionGateStore')(
  observer(
    ({children, permission, permissionGateStore, organizationId}: Props) => {
      const [hasPermission, setHasPermission] = useState(false);

      useEffect(() => {
        permissionGateStore?.getUserPermissions(organizationId).then(res => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          if (res.permissions[permission]) {
            console.log('HAS PERMISSION', res.permissions);
            setHasPermission(true);
          }
        });
      }, [permission]);

      return hasPermission ? children : null;
    },
  ),
);

export default PermissionGateContanier;

import {makeAutoObservable} from 'mobx';
import UserRepository from '../../../data/repositories/UserRepository';

class NavigationUserAvatarStore {
  private userRepo = new UserRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getUserInfo() {
    const result = await this.userRepo.getUserInfo();
    if (result.success && result.data) {
      return result.data;
    }
  }
}

export default NavigationUserAvatarStore;

import {formatDistanceToNow, parseISO, format} from 'date-fns';

export const notificationTimeTextConvertor = (dateCreated: Date) => {
  const dateSubtitle = parseISO(dateCreated.toString() + 'Z');
  let formattedDateSubtitle = '';

  if (new Date().getTime() - dateSubtitle.getTime() < 7 * 24 * 60 * 60 * 1000) {
    formattedDateSubtitle = formatDistanceToNow(dateSubtitle, {
      addSuffix: true,
    });
  } else {
    formattedDateSubtitle = `On ${format(dateSubtitle, 'EEEE p')}`;
  }
  return formattedDateSubtitle;
};

export const formatDateTime = (date: string) => {
  const dateParsed = parseISO(date.toString() + 'Z');
  return format(dateParsed, 'dd-MM-yyyy');
};

export const formatDateTimeTextFormat = (date: string) => {
  const dateParsed = parseISO(date.toString() + 'Z');
  return format(dateParsed, 'do MMMM yyyy');
};

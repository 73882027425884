import React, {useEffect, useState} from 'react';
import ErrorBox from '../../../uiToolkit/ErrorBox';
import {internalErrorCodes} from '../../../data/errorCodes';
import AuthHeader from '../../../uiToolkit/AuthHeader';
import {forgotPasswordText} from '../forgotPasswordText';
import './styles.css';
import PasswordInput from '../../../uiToolkit/Inputs/PasswordInput';
import {Link} from 'react-router-dom';
import Button from '../../../uiToolkit/Buttons/Button';

interface Props {
  errors: string[];
  onResetPassword: (password: string) => void;
  isLoading: boolean;
  clearErrors: () => void;
}

const ResetPasswordUI = React.memo(
  ({errors, onResetPassword, isLoading, clearErrors}: Props) => {
    const [password, setPassword] = useState('');
    const [passwordIsSecure, setPasswordIsSecure] = useState(false);
    const [internalErrors, setInternalErrors] = useState<string[]>([]);

    useEffect(() => {
      if (internalErrors.length) {
        setInternalErrors([]);
      }
    }, [password]);

    const onSubmit = () => {
      setInternalErrors([]);
      if (passwordIsSecure) {
        onResetPassword(password);
      } else {
        const newErrors = [];
        if (!passwordIsSecure) {
          newErrors.push(internalErrorCodes.INVALID_PASSWORD);
        }
        setInternalErrors(newErrors);
      }
    };

    const onClearErrors = () => {
      setInternalErrors([]);
      clearErrors();
    };

    const onPasswordChange = (value: string, isSecure: boolean) => {
      setPassword(value);
      setPasswordIsSecure(isSecure);
    };

    return (
      <>
        <div className="ForgotPasswordErrorContainer">
          <ErrorBox
            errorCodes={[...errors, ...internalErrors]}
            clearErrors={onClearErrors}
          />
        </div>
        <div className="ForgotPasswordFormContainer">
          <AuthHeader
            title={forgotPasswordText.title}
            subtitle={forgotPasswordText.resetSubtitle}
          />
          <div className="ForgotPasswordInputContainer">
            <PasswordInput
              value={password}
              onChange={onPasswordChange}
              showValidation
              placeholder={forgotPasswordText.password}
            />
          </div>
          <div className="ForgotPasswordSubmitButtonContainer">
            <Button
              title={forgotPasswordText.submit}
              onClick={onSubmit}
              disabled={password.trim() === ''}
              isLoading={isLoading}
              uiType="auth_action"
              fullWidth
            />
          </div>
          <p className="PasswordLogin">
            <Link to={'/auth/login'}>{forgotPasswordText.login}</Link>
          </p>
        </div>
      </>
    );
  },
);

export default ResetPasswordUI;

export const foldersText = {
  next: 'Next',
  optionsEmpty: (structure: string) =>
    `Get started by selecting the ${structure} to edit!`,
  folderTitle: 'Name',
  replaceIcon: 'Replace Icon',
  browse: 'Browse for images',
  deleteTitle: (structure: string) => 'Delete ' + structure,
  deleteAction: 'Delete',
  deleteConfirmation: (structure: string) =>
    `Are you sure you want to delete this ${structure}?`,
  discardChangesConfirmation:
    'You have unsaved changes do you want to proceed?',
  description: 'Description',
  descriptionPlaceholder: (structure: string) => structure + ' description',
  save: 'Save',
  discardChanges: 'Cancel',
  newFolder: (structure: string) => 'Add New ' + structure,
  newFolderName: (structure: string) => 'New ' + structure,
  changesSaved: 'Changes Saved',
};

import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import AddInstructorWizardUI from '../view/AddInstructorWizardUI';
import AddInstructorWizardStore from '../store/AddInstructorWizardStore';
import {useParams} from 'react-router-dom';
import {CourseInstructor} from '../../../data/models/Course/Course';

interface Props {
  addInstructorWizardStore?: AddInstructorWizardStore;
}

const AddInstructorWizardContainer = inject('addInstructorWizardStore')(
  observer(({addInstructorWizardStore}: Props) => {
    const {organizationId} = useParams();
    const [instructorData, setInstructorData] = React.useState<any[]>([]);
    const [currentInstructor, setCurrentInstructor] =
      React.useState<CourseInstructor>();

    useEffect(() => {
      if (organizationId) {
        addInstructorWizardStore!
          .getInstructorCandidates(organizationId)
          .then(res => {
            setInstructorData(res.data || []);
          });
        addInstructorWizardStore
          ?.getCurrentInstructor(organizationId)
          .then(setCurrentInstructor);
      }
    }, [addInstructorWizardStore?.isVisible()]);

    const onCancel = () => {
      addInstructorWizardStore!.cancelWizard();
    };

    const onSave = (selectedInstructor: string) => {
      if (organizationId) {
        addInstructorWizardStore!.saveInstructors(
          selectedInstructor,
          organizationId,
        );
      }
    };

    return addInstructorWizardStore!.isVisible() ? (
      <AddInstructorWizardUI
        instructorData={instructorData}
        onCancel={onCancel}
        onSave={onSave}
        currentInstructor={currentInstructor}
        isLoading={addInstructorWizardStore!.isLoading}
      />
    ) : null;
  }),
);

export default AddInstructorWizardContainer;

import {makeAutoObservable} from 'mobx';
import {CROP_VIDEO_STEP, PICK_VIDEO_STEP} from '../steps';
import LibraryRepository from '../../../../data/repositories/LibraryRepository';
import {
  MEDIA_FILES_VIDEO,
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {AddLessonContentModel} from '../../../../data/models/LessonContent/AddLessonContentModel';
import {
  LessonContentBase,
  LessonContentVideo,
  LessonContentVimeoVideo,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../../../data/models/LessonContent/LessonContentBase';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';
import LoadingModule from '../../../LoadingModule/LoadingModule';
import {ApiResponse} from '../../../../data/models/ApiResponse';

class AddVideoSourceWizardStore {
  private libraryRepo = new LibraryRepository();
  private libraryContentRepo = new LessonContentRepository();
  private subscriptionRepo = new SubscriptionRepository();

  private activeStep?: string;
  private parentId?: string;
  private selectedFile?: MediaFile;
  private selectedVimeoFile?: VimeoMediaFile;
  private successCallback?: () => void;
  private nodeToReplace?: LessonContentBase;
  private childId?: string;
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public startWizard(
    successCallback: () => void,
    parentId?: string,
    nodeToReplace?: LessonContentBase,
    childId?: string,
  ) {
    this.successCallback = successCallback;
    this.parentId = parentId;
    this.activeStep = PICK_VIDEO_STEP;
    this.nodeToReplace = nodeToReplace;
    this.childId = childId;
  }

  public cancelWizard() {
    this.parentId = undefined;
    this.activeStep = undefined;
    this.selectedFile = undefined;
    this.selectedVimeoFile = undefined;
    this.successCallback = undefined;
    this.nodeToReplace = undefined;
    this.childId = undefined;
  }

  public getActiveStep() {
    return this.activeStep;
  }

  public getStorageLimit = async (organizationId: string) => {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data.storageLimit;
    }
    return 100;
  };

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getVimeoVideos(
      organizationId,
      page,
      pageSize,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async uploadLibraryVideo(
    organizationId: string,
    video: File,
    duration: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.uploadVideo(
      organizationId,
      video,
      duration,
    );
    //Specific server error for file size
    if (result.errors && result.errors[0] && (result.errors[0] as any).errors) {
      toast.error(getErrorFromCode('file_too_large'), toastConfig);
    }
    if (result.errors && result.errors[0]) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    this.loadingModule.endLoading();
    return result.success;
  }

  public selectFile(file: MediaFile) {
    this.selectedFile = file;
    this.activeStep = CROP_VIDEO_STEP;
  }

  public selectVimeoFile(file: VimeoMediaFile) {
    this.selectedVimeoFile = file;
    this.activeStep = CROP_VIDEO_STEP;
  }

  public getSelectedFile() {
    if (this.selectedFile) {
      return this.selectedFile;
    }
  }

  public getSelectedVimeoFile() {
    if (this.selectedVimeoFile) {
      return this.selectedVimeoFile;
    }
  }

  public async addLessonVideoNode(
    organizationId: string,
    lessonId: string,
    video: MediaFile,
    start: number,
    end: number,
  ) {
    this.loadingModule.startLoading();
    const model: AddLessonContentModel = {
      data: {
        ivNodeType: VIDEO_NODE_TYPE,
        startInSeconds: start,
        endInSeconds: end,
        durationInseconds: end - start,
        contentId: video.storageFileName,
        title: video.fileName,
      } as LessonContentVideo,
      parentsIds: this.parentId ? [this.parentId] : undefined,
    };
    if (this.nodeToReplace) {
      const result = await this.libraryContentRepo.editLessonContent(
        organizationId,
        lessonId,
        {...model.data, internalId: this.nodeToReplace.internalId},
      );
      this.processResult(result);
    } else {
      const result = await this.libraryContentRepo.addLessonContent(
        organizationId,
        lessonId,
        model,
        this.childId,
      );
      this.processResult(result);
    }
    this.loadingModule.endLoading();
  }

  public async addLessonVimeoNode(
    organizationId: string,
    lessonId: string,
    video: VimeoMediaFile,
    start: number,
    end: number,
  ) {
    this.loadingModule.startLoading();
    const model: AddLessonContentModel = {
      data: {
        ivNodeType: VIMEO_VIDEO_TYPE,
        startInSeconds: start,
        endInSeconds: end,
        durationInSeconds: end - start,
        contentUrl: video.url,
        placeholderUrl: video.placeholderUrl,
        title: video.title,
      } as LessonContentVimeoVideo,
      parentsIds: this.parentId ? [this.parentId] : undefined,
    };
    if (this.nodeToReplace) {
      const result = await this.libraryContentRepo.editLessonContent(
        organizationId,
        lessonId,
        {...model.data, internalId: this.nodeToReplace.internalId},
      );
      this.processResult(result);
    } else {
      const result = await this.libraryContentRepo.addLessonContent(
        organizationId,
        lessonId,
        model,
      );
      this.processResult(result);
    }
    this.loadingModule.endLoading();
  }

  public async getMediaFiles(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    orderAsc?: boolean,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getMediaFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      MEDIA_FILES_VIDEO,
      '',
      orderAsc,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }

  private processResult = (result: ApiResponse<any>) => {
    if (result.success && this.successCallback) {
      this.successCallback();
      this.cancelWizard();
    }
  };
}

export default AddVideoSourceWizardStore;

import React, {useEffect, useRef, useState} from 'react';
import ChallengeInfoHeader from '../ChallengeInfoHeader/ChallengeInfoHeader';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';
import {ChallengeBranchAIMapped} from '../../../../data/models/LessonContent/LessonConentMapped';
import {tapyblPlayerText} from '../../tapyblPlayerText';

interface Props {
  passingScore?: number;
  challengeBranch?: ChallengeBranchAIMapped;
  onSelectChallengeAI: (
    answer: ChallengeBranchAIMapped,
    selectedAnswer: string,
  ) => void;
}

const ChallengeBranchAIOverlay = React.memo(
  ({passingScore, challengeBranch, onSelectChallengeAI}: Props) => {
    const [showImage, setShowImage] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (showImage && canvasRef?.current && containerRef.current) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          canvas.width = containerRef.current?.clientWidth || image.width;
          canvas.height = containerRef.current?.clientHeight || image.height;
          context?.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
        image.src = `data:image/png;base64, ${challengeBranch?.answerMask}`;
        if (context) {
          context.globalAlpha = 0;
        }
        canvas.addEventListener(
          'click',
          function (this: HTMLCanvasElement, ev: MouseEvent) {
            const rect = canvas.getBoundingClientRect();
            const x = ev.clientX - rect.left;
            const y = ev.clientY - rect.top;
            const imgData = context?.getImageData(x, y, 1, 1);
            const red = imgData?.data[0];
            const green = imgData?.data[1];
            const blue = imgData?.data[2];
            processCanvasClick(red, green, blue);
          },
        );
      }
    }, [showImage]);

    const processCanvasClick = (
      red?: number,
      green?: number,
      blue?: number,
    ) => {
      if (challengeBranch) {
        if (red === 255 && green === 255 && blue === 255) {
          onSelectChallengeAI(
            challengeBranch,
            challengeBranch?.correctAnswerPathId,
          );
        } else {
          onSelectChallengeAI(
            challengeBranch,
            challengeBranch?.incorrectAnswerPathId,
          );
        }
      }
    };

    return (
      <div
        className={`ChallengeBranchAIOverlay ${
          !showImage ? 'ChallengeBranchAIOverlayBackdrop' : ''
        }`}
        ref={containerRef}>
        {!showImage ? (
          <>
            <ChallengeInfoHeader
              passingScore={passingScore}
              typeIcon="icon-typeChallenge-Branch"
              typeTitle={tapyblPlayerText.challengeBranch}
            />
            <p className="Question">{challengeBranch?.question}</p>
            <div className="ShowImageButton">
              <Button
                title={tapyblPlayerText.showImage}
                onClick={() => setShowImage(true)}
              />
            </div>
          </>
        ) : (
          <div className="MaskContainer">
            <Button
              icon="icon-typeClose"
              onClick={() => setShowImage(false)}
              title={tapyblPlayerText.showQuestion}
              className="ShowQuestionButton"
            />
            <canvas ref={canvasRef} width={200} height={200} />
          </div>
        )}
      </div>
    );
  },
);

export default ChallengeBranchAIOverlay;

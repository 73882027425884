export const generateColorPalette = (baseColor: string): string[] => {
  const scaleSize = 9;
  const baseRGB = hexToRgb(baseColor);

  if (baseColor === '#000000') {
    // Generar escala de grises
    const grayScale = Array.from({length: scaleSize}).map((_, index) => {
      const grayValue = Math.min(255, index * 30); // Incrementando el valor para espaciar los grises
      const modifiedRGB = [grayValue, grayValue, grayValue];
      return rgbToHex(modifiedRGB);
    });

    // Agregar el color base original al principio del array
    return [baseColor, ...grayScale];
  } else if (baseColor === '#ffffff') {
    // Generar escala de blancos
    const whiteScale = Array.from({length: scaleSize}).map((_, index) => {
      const whiteValue = Math.min(255, index * 30); // Incrementando el valor para espaciar los blancos
      const modifiedRGB = [
        255 - whiteValue,
        255 - whiteValue,
        255 - whiteValue,
      ];
      return rgbToHex(modifiedRGB);
    });

    // Agregar el color base original al principio del array
    return [baseColor, ...whiteScale];
  } else {
    // Generar escala de colores para otros casos
    const colorScale = Array.from({length: scaleSize}).map((_, index) => {
      const modifiedRGB = [...baseRGB];
      modifiedRGB[0] = Math.min(255, modifiedRGB[0] + (index + 1) * 25);
      return rgbToHex(modifiedRGB);
    });

    // Agregar el color base original al principio del array
    return [baseColor, ...colorScale];
  }
};

const hexToRgb = (hex: string): number[] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    throw new Error(`Invalid hex color: ${hex}`);
  }

  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
};

const rgbToHex = (rgb: number[]): string => {
  return `#${rgb
    .map(value => Math.min(255, value).toString(16).padStart(2, '0'))
    .join('')}`;
};

import React, {useEffect, useState} from 'react';
import {createCourseText} from '../createCourseText';
import {Col, Row} from 'react-bootstrap';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import ImagePickerInput from '../../../../uiToolkit/Inputs/ImagePickerInput';
import {Subject} from '../../../../data/models/Course/LearningStandards';
import {
  GRADABLE_COURSE_TYPE,
  PARTICIPATION_COURSE_TYPE,
  courseStructureOptions,
  structureSizeOptions,
} from '../../../../data/staticValues/courseStructure';
import {CourseViewModel} from '../../../../data/models/Course/Course';
import TextAreaInput from '../../../../uiToolkit/Inputs/TextAreaInput';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {bookActiveIcon} from '../../../../assets/images';
import './styles.css';
import ErrorBox from '../../../../uiToolkit/ErrorBox';
import {
  GRADABLE_LESSONS_FEATURE,
  SubscriptionPermissions,
  organizationHasFeature,
} from '../../../../data/models/Subscription/SubscriptionPermission';
import UpgradeHint from '../../../../uiToolkit/UpgradeHint';
import Button from '../../../../uiToolkit/Buttons/Button';
import YesNoOption from '../../../../uiToolkit/Inputs/YesNoOption';
import NumbersInput from '../../../../uiToolkit/Inputs/NumbersInput';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';

interface Props {
  subjectOptions: Subject[];
  createCourse: (model: CourseViewModel, exit?: boolean) => void;
  onCancel: () => void;
  errors: string[];
  clearErrors: () => void;
  organizationFeatures?: SubscriptionPermissions;
  isLoading: boolean;
}

const CreateCourseUI = React.memo(
  ({
    subjectOptions,
    createCourse,
    onCancel,
    errors,
    clearErrors,
    organizationFeatures,
    isLoading,
  }: Props) => {
    const [course, setCourse] = useState<any>({
      title: '',
      subject: '',
      type: PARTICIPATION_COURSE_TYPE,
      description: '',
      image: undefined,
      structure: undefined,
      structureAmount: undefined,
      isPublic: false,
      passingScore: undefined,
    });
    const [missingFields, setMissingFields] = useState<{
      [key: string]: boolean;
    }>({
      title: false,
      subject: false,
      type: false,
      description: false,
      image: false,
      structure: false,
      structureAmount: false,
      isPublic: false,
      passingScore: false,
    });

    useEffect(() => {
      if (
        organizationFeatures &&
        !organizationHasFeature(organizationFeatures, GRADABLE_LESSONS_FEATURE)
      ) {
        updateField('type')(PARTICIPATION_COURSE_TYPE);
      }
    }, [organizationFeatures]);

    const onSave = (exit?: boolean) => {
      const isValid = validateData();
      if (isValid) {
        createCourse(
          {
            title: course.title,
            subject: course.subject,
            structure: course.structure || '',
            structureAmount: course.structureAmount || '',
            description: course.description,
            isPublic: course.isPublic,
            type: course.type || '',
            image: course.image,
            passingScore: course.passingScore,
          },
          exit,
        );
      }
    };

    const validateData = () => {
      const newMissingFields = {
        ...missingFields,
      };
      let isValid = true;
      if (course.title.trim() === '') {
        newMissingFields.title = true;
        isValid = false;
      }
      if (course.description.trim() === '') {
        newMissingFields.description = true;
        isValid = false;
      }
      if (!course.subject) {
        newMissingFields.subject = true;
        isValid = false;
      }
      if (!course.structure) {
        newMissingFields.structure = true;
        isValid = false;
      }
      if (!course.structureAmount) {
        newMissingFields.structureAmount = true;
        isValid = false;
      }
      if (!course.type) {
        newMissingFields.type = true;
        isValid = false;
      }
      if (
        course.type === GRADABLE_COURSE_TYPE &&
        (course.passingScore === '' || course.passingScore === undefined)
      ) {
        newMissingFields.passingScore = true;
        isValid = false;
      }
      setMissingFields(newMissingFields);
      return isValid;
    };

    const updateField = (field: string) => (value: any) => {
      const newCourse = {
        ...course,
        [field]: value,
      };
      setCourse(newCourse);
      if (missingFields[field]) {
        const newMissingFields = {
          ...missingFields,
          [field]: false,
        };
        setMissingFields(newMissingFields);
      }
    };

    const getSubjectSelectOptions = () => {
      return subjectOptions.map(item => ({
        value: item.title,
        key: item.id,
      }));
    };

    const getStructureSizeHeader = () => {
      let result = '';
      const structure = courseStructureOptions.find(
        x => x.key === course.structure,
      );
      if (structure) {
        result = structure.amountSelectTitle;
      }
      return result;
    };

    const onUpdateGradable = (value: boolean) => {
      if (value) {
        const newCourse = {
          ...course,
          type: GRADABLE_COURSE_TYPE,
          passingScore: 50,
        };
        setCourse(newCourse);
      } else {
        const newCourse = {
          ...course,
          type: PARTICIPATION_COURSE_TYPE,
          passingScore: undefined,
        };
        setCourse(newCourse);
      }
    };

    return (
      <div className="CreateCourseContainer">
        <PageTitle
          title={createCourseText.title}
          icon={bookActiveIcon}
          subtitle={createCourseText.subtitle}
        />
        <Row className="CreateCourseBody">
          <Col xs={12}>
            <ErrorBox
              errorCodes={errors}
              clearErrors={clearErrors}
              className="CreateCourseErrors"
            />
          </Col>
          <Col lg={5} xs={12}>
            <TextInput
              type="text"
              value={course.title}
              onChange={updateField('title')}
              placeholder={createCourseText.titleTextPlaceholder}
              heading={createCourseText.titleTextTitle}
              className="CreateCourseInput"
              fieldError={missingFields.title}
              isMandatory
              maxCharacters={100}
            />
            <Dropdown
              options={getSubjectSelectOptions()}
              selectedOption={course.subject}
              setSelectedOption={updateField('subject')}
              heading={createCourseText.subjectSelectTitle}
              placeholder={createCourseText.subjectSelectPlaceholder}
              className="CreateCourseInput"
              fieldError={missingFields.subject}
              isMandatory
            />
            <Dropdown
              options={courseStructureOptions.sort((x, y) =>
                x.value > y.value ? 1 : -1,
              )}
              selectedOption={course.structure}
              setSelectedOption={updateField('structure')}
              heading={createCourseText.structureSelectTitle}
              placeholder={createCourseText.structureSelectPlaceholder}
              className="CreateCourseInput"
              fieldError={missingFields.structure}
              isMandatory
            />
            {course.structure ? (
              <Dropdown
                options={structureSizeOptions}
                selectedOption={course.structureAmount}
                setSelectedOption={updateField('structureAmount')}
                heading={getStructureSizeHeader()}
                placeholder={createCourseText.sizePlaceholder}
                className="CreateCourseInput"
                fieldError={missingFields.structureAmount}
                isMandatory
              />
            ) : (
              <div />
            )}
            <YesNoOption
              title={createCourseText.type}
              onSelect={onUpdateGradable}
              value={course.type === GRADABLE_COURSE_TYPE}
              className={
                organizationFeatures &&
                organizationHasFeature(
                  organizationFeatures,
                  GRADABLE_LESSONS_FEATURE,
                )
                  ? 'CreateCourseInput'
                  : ''
              }
              disabled={
                organizationFeatures &&
                !organizationHasFeature(
                  organizationFeatures,
                  GRADABLE_LESSONS_FEATURE,
                )
              }
            />
            {organizationFeatures &&
              !organizationHasFeature(
                organizationFeatures,
                GRADABLE_LESSONS_FEATURE,
              ) && (
                <UpgradeHint
                  hint={createCourseText.wantGradable}
                  className="CreateCourseInput"
                />
              )}
            {course.type === GRADABLE_COURSE_TYPE && (
              <NumbersInput
                value={course.passingScore}
                setValue={updateField('passingScore')}
                fieldError={missingFields.passingScore}
                heading={createCourseText.passingScore}
                className="CreateCourseInput"
                isMandatory
                uiType="standart"
              />
            )}
            <Checkbox
              text={createCourseText.publicListing}
              checked={course.isPublic}
              onCheck={() => updateField('isPublic')(!course.isPublic)}
              slim
              color={'#3A3A3A'}
            />
          </Col>
          <Col lg={{span: 5, offset: 1}} xs={12}>
            <ImagePickerInput
              onChange={updateField('image')}
              placeholder={createCourseText.iconPlaceholder}
              heading={createCourseText.iconHeading}
              className="CreateCourseInput"
              image={course.image}
            />
            <TextAreaInput
              value={course.description}
              onChange={updateField('description')}
              placeholder={createCourseText.descriptionTextPlaceholder}
              heading={createCourseText.descriptionTextTitle}
              className="CreateCourseInput"
              fieldError={missingFields.description}
              isMandatory
            />
          </Col>
        </Row>
        <Row>
          <Col xs={11}>
            <div className="CreateCourseFooter">
              <Button
                title={createCourseText.cancel}
                onClick={onCancel}
                uiType="text"
              />
              <Button
                title={createCourseText.saveAndExit}
                onClick={() => onSave(true)}
                uiType="secondary"
                isLoading={isLoading}
              />
              <Button
                title={createCourseText.nextStep}
                onClick={() => onSave(false)}
                uiType="action"
                isLoading={isLoading}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  },
);

export default CreateCourseUI;

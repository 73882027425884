import React, {useState, useRef} from 'react';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import {uploadIcon} from '../../../assets/images';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../../assets/lottie';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../Toast/toastConfig';

interface Props {
  title?: string;
  subtitle?: string;
  onPPTSelected?: (ppt: File) => void;
  isLoading?: boolean;
  maxFileSize?: number;
  isMandatory?: boolean;
}

const UploadPPTButton = React.memo(
  ({
    title,
    subtitle,
    onPPTSelected,
    isLoading,
    maxFileSize,
    isMandatory,
  }: Props) => {
    const [fileName, setFileName] = useState<string | null>(null);
    const inputFile = useRef<any>(null);

    const onUpload = () => {
      inputFile.current.value = '';
      inputFile.current?.click();
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        const size = file.size / 1024 / 1024;
        const maxSize = maxFileSize ? maxFileSize : 100;
        if (size > maxSize) {
          toast.error(getErrorFromCode('file_too_large'), toastConfig);
        } else {
          const ext = file.name.split('.').pop();
          if (ext !== 'pptx' && ext !== 'pdf') {
            toast.error(getErrorFromCode('invalid_file_format'), toastConfig);
            return;
          }
          setFileName(file.name);
          if (onPPTSelected) {
            onPPTSelected(file);
          }
        }
      }
    };

    return (
      <div className="PPTContainer">
        <div className="Heading">
          {title && (
            <p>
              {title}{' '}
              {isMandatory ? <p className="MandatoryIndicator">*</p> : ''}
            </p>
          )}
          {subtitle && <p className="SubtitleText">{subtitle}</p>}
        </div>
        <div
          className={`UploadPPTButtonContainer ${
            isLoading ? 'UploadPPTButtonContainerDisabled' : ''
          }`}>
          <div className="UploadButtonContainer">
            <button onClick={onUpload} disabled={isLoading}>
              {isLoading ? (
                <Lottie
                  animationData={loadingLottie}
                  loop={true}
                  style={{
                    width: '14px',
                    height: '14px',
                    marginRight: '8.5px',
                  }}
                />
              ) : (
                <img src={uploadIcon} alt="upload" />
              )}
              {appText.upload}
              <input
                type="file"
                onChange={onChange}
                accept=".pptx,.pdf"
                style={{display: 'none'}}
                ref={inputFile}
              />
            </button>
          </div>
          <div className="UploadedFileTitle">
            {fileName ? (
              <p>Uploaded file: {fileName}</p>
            ) : (
              <p>No file uploaded</p>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default UploadPPTButton;

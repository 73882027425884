import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../data/repositories/AuthRepository';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class SignupStore {
  private authRepo = new AuthRepository();
  private loadingModule = new LoadingModule();

  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async registerUser(email: string, password: string, userName: string) {
    this.loadingModule.startLoading();
    const result = await this.authRepo.registerUser({
      email,
      password,
      userName,
    });
    this.loadingModule.endLoading();
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.setErrors(result.errors);
    }
    return false;
  }

  public async sendEmailVerification() {
    this.loadingModule.startLoading();
    const result = await this.authRepo.sendEmailVerification();
    this.loadingModule.endLoading();
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.setErrors(result.errors);
    }
  }

  public getUser() {
    return this.authRepo.getUser();
  }

  public clearErrors() {
    this.errors = [];
  }

  private setErrors(errors: string[]) {
    this.errors = errors;
  }
}

export default SignupStore;

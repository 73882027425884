import {makeAutoObservable} from 'mobx';

class ModalProviderStore {
  private activeModal?: string;
  private onCloseCallback?: (payload?: any) => void;

  constructor() {
    makeAutoObservable(this);
  }

  public openModal(modal: string, onCloseCallback?: () => void) {
    this.activeModal = modal;
    if (onCloseCallback) {
      this.onCloseCallback = onCloseCallback;
    }
  }

  public getActiveStore() {
    return this.activeModal;
  }

  public onClose(payload?: any) {
    this.activeModal = undefined;
    if (this.onCloseCallback) {
      this.onCloseCallback(payload);
      this.onCloseCallback = undefined;
    }
  }
}

export default ModalProviderStore;

import React, {useEffect} from 'react';
import CreateOrganizationUI from '../view/CreateOrganizationUI';
import CreateOrganizationStore from '../store/CreateOrganizationStore';
import {inject, observer} from 'mobx-react';

interface Props {
  isOpen: boolean;
  onClose: (payload?: any) => void;
  createOrganizationStore?: CreateOrganizationStore;
}

const CreateOrganizationContainer = inject('createOrganizationStore')(
  observer(({isOpen, onClose, createOrganizationStore}: Props) => {
    useEffect(() => {
      return () => {
        createOrganizationStore!.clearErrors();
      };
    }, []);

    const onCreateOrganization = async (
      name: string,
      description: string,
      primaryColor: string,
      secondaryColor: string,
      type: number,
      image?: File,
    ) => {
      const result = await createOrganizationStore!.createOrganization(
        name,
        description,
        primaryColor,
        secondaryColor,
        type,
        image,
      );
      if (result) {
        onClose(result.id);
      }
    };

    const clearErrors = () => {
      createOrganizationStore!.clearErrors();
    };

    return isOpen ? (
      <CreateOrganizationUI
        onCancel={onClose}
        onCreateOrganization={onCreateOrganization}
        isLoading={createOrganizationStore!.isLoading}
        errors={createOrganizationStore!.errors}
        clearErrors={clearErrors}
      />
    ) : (
      <div />
    );
  }),
);

export default CreateOrganizationContainer;

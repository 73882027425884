import {inject, observer} from 'mobx-react';
import React, {useState} from 'react';
import LessonEditorStore from '../../../store/LessonEditorStore';
import {
  FILE_SOURCE_TAPYBL,
  MEDIA_FILES_VIDEO,
  MediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import {LessonContentVideo} from '../../../../../data/models/LessonContent/LessonContentBase';
import {useParams} from 'react-router-dom';
import VideoSourceOptionsUI from '../view/VideoSourceOptionsUI';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';
import {lessonEditorText} from '../../../lessonEditorText';
import MyLibraryModalStore from '../../../../../modules/MyLibraryModal/store/MyLibraryModalStore';

interface Props {
  lessonEditorStore?: LessonEditorStore;
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentVideo;
  onCancel: () => void;
  onChangesMade: () => void;
  myLibraryModalStore?: MyLibraryModalStore;
  isPublished?: boolean;
  rootId: string;
}

const VideoSourceOptionsContainer = inject(
  'lessonEditorStore',
  'myLibraryModalStore',
)(
  observer(
    ({
      lessonEditorStore,
      entityToEdit,
      onChangesMade,
      onCancel,
      myLibraryModalStore,
      isPublished,
      rootId,
    }: Props) => {
      const [newFile, setNewFile] = useState<MediaFile>();
      const [showDelete, setShowDelete] = useState(false);
      const [showSoftDelete, setShowSoftDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const saveChanges = (
        timeStart: number,
        timeEnd: number,
        title: string,
      ) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              contentId: newFile
                ? newFile.storageFileName
                : entityToEdit.contentId,
              startInSeconds: timeStart,
              endInSeconds: timeEnd,
              durationInseconds: timeEnd - timeStart,
              title,
            } as LessonContentVideo)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const onSoftDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.softDeleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const processDeleteResult = (success: boolean) => {
        if (success) {
          onChangesMade();
        } else {
          toast.error(getErrorFromCode(''), toastConfig);
        }
      };

      const onPickNewFile = () => {
        myLibraryModalStore?.showLibrary(
          MEDIA_FILES_VIDEO,
          setNewFile,
          FILE_SOURCE_TAPYBL,
        );
      };

      return entityToEdit ? (
        <>
          <VideoSourceOptionsUI
            videoContent={entityToEdit}
            newFile={newFile}
            saveChanges={saveChanges}
            onCancel={onCancel}
            onDelete={() => setShowDelete(true)}
            onSoftDelete={
              entityToEdit?.internalId === rootId
                ? undefined
                : () => setShowSoftDelete(true)
            }
            isLoading={lessonEditorStore!.isLoading}
            onPickNewFile={onPickNewFile}
            isPublished={isPublished}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteVideo}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteVideoSoft}
            onAccept={onSoftDelete}
            onCancel={() => setShowDelete(false)}
            shown={showSoftDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      ) : null;
    },
  ),
);

export default VideoSourceOptionsContainer;

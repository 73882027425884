import React from 'react';
import {Col} from 'react-bootstrap';
import {bookIc} from '../../../assets/images';
import {homeText} from '../homeText';

const LearnerEmptyState = React.memo(() => {
  return (
    <Col lg={{span: 8, offset: 2}} xs={12}>
      <div className="HomeEmptyStateContainer">
        <img src={bookIc} alt="book" />
        <p className="Title">{homeText.noLearnerCourses}</p>
      </div>
    </Col>
  );
});

export default LearnerEmptyState;

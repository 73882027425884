import React, {useEffect, useState} from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import {LessonContentChallengeBranchImageOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import {MediaFile} from '../../../../../data/models/LibraryFile/MediaFile';
import BoxImagePreview from '../../../../../uiToolkit/BoxImagePreview';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import YesNoOption from '../../../../../uiToolkit/Inputs/YesNoOption';

interface Props {
  entitiyToEdit: LessonContentChallengeBranchImageOption;
  newImage?: MediaFile;
  openImagePicker: () => void;
  onCancel: () => void;
  onSave: (isCorrect: boolean) => void;
  isLoading: boolean;
  onDelete: () => void;
  isPublished?: boolean;
}

const ChallengeImageOptionOptionsUI = React.memo(
  ({
    entitiyToEdit,
    openImagePicker,
    newImage,
    onSave,
    isLoading,
    onDelete,
    isPublished,
  }: Props) => {
    const [isCorrect, setIsCorrect] = useState(false);

    useEffect(() => {
      setIsCorrect(entitiyToEdit?.isCorrect || false);
    }, [entitiyToEdit]);

    const saveChanges = () => {
      onSave(isCorrect);
    };

    return (
      <div>
        <BoxImagePreview
          heading={lessonEditorText.source}
          source={newImage ? newImage.fileUrl : entitiyToEdit.contentUrl}
          actionTitle={lessonEditorText.editSource}
          action={openImagePicker}
        />
        <YesNoOption
          value={isCorrect}
          onSelect={setIsCorrect}
          title={lessonEditorText.isCorrect}
          uiType="box"
          className="mt-4"
        />
        <EditorOptionsFooter
          onSave={saveChanges}
          saveEnabled={
            newImage !== undefined || entitiyToEdit?.isCorrect !== isCorrect
          }
          onDelete={onDelete}
          deleteTitle={lessonEditorText.delete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChallengeImageOptionOptionsUI;

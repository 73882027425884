export const REGISTER_TO_MANAGE = 'to_manage';
export const REGISTER_TO_TEACH = 'to_teach';
export const REGISTER_TO_LEARN = 'to_learn';

export const onboardingOptions = [
  {
    key: REGISTER_TO_LEARN,
    subtitle:
      "Explore and enroll in any of tapybl's thousands of captivating interactive learning courses!",
    value: 'Learn',
  },
  {
    key: REGISTER_TO_TEACH,
    subtitle:
      'Create highly immersive interactive learning content to provide your audience the ultimate learning experience!',
    value: 'Teach',
  },
  {
    key: REGISTER_TO_MANAGE,
    subtitle:
      'Set up and manage an organizational workspace for seamless course creation, learner management, integrations, and beyond!',
    value: 'Manage a workspace',
  },
];

import React, {useEffect, useState} from 'react';
import {LessonContentChoiceTextOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {lessonEditorText} from '../../../lessonEditorText';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';

interface Props {
  entityToEdit?: LessonContentChoiceTextOption;
  isLoading: boolean;
  onCancel: () => void;
  onEdit: (question: string) => void;
  onDelete: () => void;
  isPublished?: boolean;
}

const ChoiceTextOptionOptionsUI = React.memo(
  ({entityToEdit, isLoading, onEdit, onDelete, isPublished}: Props) => {
    const [option, setOption] = useState('');

    useEffect(() => {
      setOption(entityToEdit?.title || '');
    }, [entityToEdit]);

    const changesWasMade = () => entityToEdit?.title !== option;

    const isValid = () => option.trim() !== '';

    const onSave = () => {
      if (isValid()) {
        onEdit(option);
      }
    };

    return (
      <div>
        <TextInput
          heading={lessonEditorText.option}
          value={option}
          onChange={setOption}
          placeholder={lessonEditorText.option}
          fieldError={option.trim() === ''}
          maxCharacters={150}
          isMandatory
          uiType="box"
        />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          deleteTitle={lessonEditorText.delete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChoiceTextOptionOptionsUI;

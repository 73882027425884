import React, {useRef, useState} from 'react';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {addVideoSourceWizardText} from '../addVideoSourceWizardText';
import {
  getSecondsFromTimeText,
  getTimeTextFromSeconds,
} from '../../../../utils/getTimeTextFromSeconds';
import SimplePlayerControlled from '../../../../uiToolkit/SimplePlayerControlled';
import './styles.css';
import TimeInput from '../../../../uiToolkit/Inputs/TimeInput/indes';
import Button from '../../../../uiToolkit/Buttons/Button';
import ModalContainer from '../../../../uiToolkit/ModalContainer';

interface Props {
  selectedFile?: MediaFile;
  selectedVimeoFile?: VimeoMediaFile;
  onCancel: () => void;
  onSave: (start: number, end: number) => void;
  isLoading: boolean;
}

const CropVideoStepUI = React.memo(
  ({selectedFile, onCancel, onSave, selectedVimeoFile, isLoading}: Props) => {
    const [width, setWidth] = useState(0);
    const [timeStart, setTimeStart] = useState('00:00:00');
    const [timeEnd, setTimeEnd] = useState('00:00:01');
    const [duration, setDuration] = useState(0);

    const containerRef = useRef<any>(null);

    const saveNode = () => {
      if (startTimeIsValid() && endTimeIsValid()) {
        onSave(
          getSecondsFromTimeText(timeStart),
          getSecondsFromTimeText(timeEnd),
        );
      }
    };

    const onSaveFull = () => {
      onSave(0, Math.ceil(duration));
    };

    React.useEffect(() => {
      function handleResize() {
        if (containerRef && containerRef.current) {
          setWidth(containerRef.current.offsetWidth);
        }
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    React.useLayoutEffect(() => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    }, []);

    const startTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(timeStart);
        const endSeconds = getSecondsFromTimeText(timeEnd);
        return seconds <= Math.ceil(duration) && seconds < endSeconds;
      }
      return true;
    };

    const endTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(timeEnd);
        return seconds <= Math.ceil(duration);
      }
      return true;
    };

    const onDuration = (dur: number) => {
      setDuration(dur);
      setTimeEnd(getTimeTextFromSeconds(Math.ceil(dur)));
    };

    return (
      <ModalContainer onClose={onCancel}>
        <div className="AddVideoSourceStepContent CropPlayerContent">
          <p className="CropHeader">{addVideoSourceWizardText.cropTitle}</p>
          <div className="CropPlayerContainer" ref={containerRef}>
            <SimplePlayerControlled
              width={width}
              onDuration={onDuration}
              source={
                selectedFile ? selectedFile?.fileUrl : selectedVimeoFile?.url
              }
              startTime={
                startTimeIsValid()
                  ? getSecondsFromTimeText(timeStart)
                  : undefined
              }
              endTime={
                endTimeIsValid() ? getSecondsFromTimeText(timeEnd) : undefined
              }
            />
          </div>
          <div className="TimePickersContainer">
            <TimeInput
              value={timeStart}
              onChange={setTimeStart}
              label={addVideoSourceWizardText.startTime}
              isValid={startTimeIsValid()}
              labelTop
            />
            <TimeInput
              value={timeEnd}
              onChange={setTimeEnd}
              label={addVideoSourceWizardText.endTime}
              isValid={endTimeIsValid()}
              labelTop
            />
          </div>
          <div className="UseFullVideoContainer">
            <button onClick={onSaveFull}>
              {addVideoSourceWizardText.useFullVideo}
            </button>
          </div>
          <div className="Footer">
            <Button
              title={addVideoSourceWizardText.cancel}
              onClick={onCancel}
              uiType="text"
            />
            <Button
              title={addVideoSourceWizardText.save}
              onClick={saveNode}
              disabled={!startTimeIsValid() || !endTimeIsValid() || isLoading}
              uiType="action"
            />
          </div>
        </div>
      </ModalContainer>
    );
  },
);

export default CropVideoStepUI;

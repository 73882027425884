import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import LoadingModule from '../../LoadingModule/LoadingModule';

class CreateOrganizationStore {
  private orgRepo = new OrganizationRepository();
  private loadingModule = new LoadingModule();

  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getUserOrganization() {
    this.loadingModule.startLoading();
    const result = await this.orgRepo.getMyOrganization();
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async createOrganization(
    name: string,
    description: string,
    primaryColor: string,
    secondaryColor: string,
    type: number,
    image?: File,
  ) {
    this.loadingModule.startLoading();
    const result = await this.orgRepo.createOrganization(
      name,
      description,
      primaryColor,
      secondaryColor,
      type,
      image,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      this.setErrors(result.errors);
    }
    return result.data;
  }

  public clearErrors() {
    this.errors = [];
  }

  private setErrors(errors: string[]) {
    this.errors = errors;
  }
}

export default CreateOrganizationStore;

import {makeAutoObservable} from 'mobx';
import FoldersRepository from '../../../data/repositories/FoldersRepository';
import CoursesRepository from '../../../data/repositories/CoursesRepository';
import {Folder, FolderCreateModel} from '../../../data/models/Course/Folder';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class FoldersStore {
  private readonly foldersRepo = new FoldersRepository();
  private readonly courseRepo = new CoursesRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getFolders(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.foldersRepo.getCourseFolders(
      organizationId,
      courseId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async getCourse(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepo.getCourse(organizationId, courseId);
    this.loadingModule.endLoading();
    return result.data;
  }

  public async editFolder(organizationId: string, folder: Folder) {
    this.loadingModule.startLoading();
    const result = await this.foldersRepo.editFolder(organizationId, folder);
    this.loadingModule.endLoading();
    return result.success;
  }

  public async createFolder(organizationId: string, folder: FolderCreateModel) {
    this.loadingModule.startLoading();
    await this.foldersRepo.createFolder(organizationId, folder);
    this.loadingModule.endLoading();
  }

  public async exportAsScorm(
    organizationId: string,
    courseId: string,
    courseName: string,
  ) {
    this.loadingModule.startLoading();
    await this.courseRepo.exportAsScorm(organizationId, courseId, courseName);
    this.loadingModule.endLoading();
  }

  public async deleteFolder(
    organizationId: string,
    courseId: string,
    folderId: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.foldersRepo.deleteFolder(
      organizationId,
      courseId,
      folderId,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors?.length) {
      return result.errors[0];
    }
  }

  public async reorderFolders(
    organizationId: string,
    courseId: string,
    folders: Folder[],
  ) {
    const result = await this.foldersRepo.reorderFolders(
      organizationId,
      courseId,
      folders,
    );
    return result.success;
  }

  public async getSidebarOptions(organizationId: string, search: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepo.getSidebarOptions(
      organizationId,
      search,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    } else {
      return [];
    }
  }
}

export default FoldersStore;

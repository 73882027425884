import axios from 'axios';
import {BASE_URL} from './config';
import LocalStorage from '../storage/LocalStorage';

axios.defaults.withCredentials = true;

const apiClient = axios.create({
  baseURL: BASE_URL,
});

const anonClient = axios.create({
  baseURL: BASE_URL,
});

function isUnauthorizedError(error: any) {
  if (!error || !error?.response || !error?.response?.status) {
    return false;
  }
  const {
    response: {status},
  } = error;
  return status === 401;
}

async function renewToken() {
  const response = await apiClient.post('/authentication/refresh-token', {});
  return response.status === 200;
}

async function withAuth(headers: any) {
  if (!headers) {
    headers = {};
  }
  return headers;
}

let refreshingFunc: any;

apiClient.interceptors.response.use(
  res => ({
    success: true,
    ...res,
    data: res.data,
  }),
  async error => {
    const originalConfig = error.config;

    if (!isUnauthorizedError(error)) {
      return resolveWithError(error);
    }

    try {
      if (!refreshingFunc) {
        refreshingFunc = renewToken();
      }

      const success = await refreshingFunc;

      if (success) {
        try {
          return await apiClient.request(originalConfig);
        } catch (innerError) {
          return resolveWithError(error);
        }
      } else {
        await logout();
      }
    } catch (err) {
      await logout();
    } finally {
      refreshingFunc = undefined;
    }
  },
);

const logout = async () => {
  const storage = new LocalStorage();
  await anonClient.delete(`/authentication/invalidate_tokens`, {});
  storage.resetThemeColors();
  storage.wipeLocalStorage();
  window.location.reload();
};

anonClient.interceptors.response.use(
  res => ({
    success: true,
    ...res,
    data: res.data,
  }),
  async error => {
    return resolveWithError(error);
  },
);

const resolveWithError = (error: any) => {
  if (!error.response?.data) {
    return Promise.resolve({
      success: false,
      errors: ['unknown'],
    });
  }
  if (
    error.response.data?.Errors &&
    Array.isArray(error.response.data?.Errors)
  ) {
    return Promise.resolve({
      success: false,
      errors: error.response.data.Errors,
    });
  } else if (error.response.data) {
    return Promise.resolve({
      success: false,
      errors: [error.response.data],
    });
  }
};

export {apiClient, withAuth, anonClient};

import React, {useContext} from 'react';
import {Handle, Position} from 'reactflow';
import EditorTooltipButton from '../../../uiToolkit/EditorTooltopButton';
import {lessonBlocked} from '../../../assets/images';
import {EditorContext} from '../container/LessonEditorContainer';
import {
  LessonContentBase,
  getNextStepsForNodeByType,
} from '../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../lessonEditorText';
import Tooltip from '../../../uiToolkit/Tooltip';
import {handleStyle} from '../../../modules/EditorSettings/settings';
import {getIconByType, getTooltipByType} from '../config';

interface Props {
  data: {
    parent?: LessonContentBase;
  };
}

const AddNewSourceNode = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const getActionsByParent = () => {
    const availableActions = getNextStepsForNodeByType(
      data.parent?.ivNodeType,
      context.isGradable,
      data.parent?.internalId !== undefined,
    );
    return availableActions.map(item => ({
      icon: getIconByType(item),
      action: () => context.addNewNode(data.parent?.internalId, item),
      tooltip: getTooltipByType(item),
    }));
  };

  return (
    <div className="EditorNodeContainer">
      <Handle
        type="target"
        position={Position.Top}
        style={handleStyle}
        isConnectable={false}
      />
      {context.isPublished ? (
        <Tooltip tooltipText={lessonEditorText.cantProceedTooltip} greenTooltip>
          <img src={lessonBlocked} />
        </Tooltip>
      ) : (
        <EditorTooltipButton actions={getActionsByParent()} />
      )}
    </div>
  );
});

export default AddNewSourceNode;

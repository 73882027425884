import React, {useEffect, useRef, useState} from 'react';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import {uploadIcon} from '../../../assets/images';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../../assets/lottie';
import {
  MEDIA_FILES_ALL,
  MEDIA_FILES_IMAGE,
  MEDIA_FILES_VIDEO,
} from '../../../data/models/LibraryFile/MediaFile';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../Toast/toastConfig';
import {isDev} from '../../../utils/envHelper';

interface Props {
  onVideoSelected?: (video: File, duration: number) => void;
  isLoading?: boolean;
  type?: number;
  maxFileSize?: number;
}

const UploadVideoButton = React.memo(
  ({onVideoSelected, isLoading, type, maxFileSize}: Props) => {
    const [file, setFile] = useState<File>();
    const [duration, setDuration] = useState(0);
    const inputFile = useRef<any>(null);
    const videoEl = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (file && onVideoSelected && duration) {
        onVideoSelected(file, duration);
        setFile(undefined);
        setDuration(0);
      }
    }, [file, duration]);

    const onUpload = () => {
      inputFile.current.value = '';
      inputFile.current?.click();
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        const size = file.size / 1024 / 1024;
        const maxSize = maxFileSize ? maxFileSize : 100;
        if (size > maxSize) {
          toast.error(getErrorFromCode('file_too_large'), toastConfig);
        } else {
          if (
            (file.type.includes('image') ||
              file.type.includes('pdf') ||
              file.type.includes(
                'openxmlformats-officedocument.wordprocessingml',
              ) ||
              file.type.includes('msword')) &&
            onVideoSelected
          ) {
            onVideoSelected(file, 0);
          } else {
            setFile(file);
          }
        }
      }
    };

    const handleLoadedMetadata = () => {
      const video = videoEl.current;
      if (!video) return;
      if (video.duration && !duration) {
        setDuration(Math.floor(video.duration));
      }
    };

    const getFileTypesByType = () => {
      switch (type) {
        case MEDIA_FILES_ALL:
          return isDev
            ? 'image/*,video/mp4,video/x-m4v,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            : 'image/*,video/mp4,video/x-m4v';
        case MEDIA_FILES_IMAGE:
          return 'image/*';
        case MEDIA_FILES_VIDEO:
          return 'video/mp4,video/x-m4v';
      }
    };

    return (
      <div
        className={`UploadVideoButtonContainer ${
          isLoading ? 'UploadVideoButtonContainerDisabled' : ''
        }`}>
        <button onClick={onUpload} disabled={isLoading}>
          {isLoading ? (
            <Lottie
              animationData={loadingLottie}
              loop={true}
              style={{
                width: '14px',
                height: '14px',
                // marginBottom: '12px',
                marginRight: '8.5px',
              }}
            />
          ) : (
            <img src={uploadIcon} alt="upload" />
          )}
          {file && (
            <div style={{display: 'none'}}>
              <video
                src={URL.createObjectURL(file)}
                ref={videoEl}
                onLoadedMetadata={handleLoadedMetadata}
              />
            </div>
          )}
          {appText.upload}
          <input
            type="file"
            onChange={onChange}
            accept={getFileTypesByType()}
            style={{display: 'none'}}
            ref={inputFile}
          />
        </button>
      </div>
    );
  },
);

export default UploadVideoButton;

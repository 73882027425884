import {
  backgroundAcademySub,
  backgroundClassSub,
  backgroundSchoolSub,
} from '../../assets/images';

export const SUBSCRIPTION_CLASSROOM = 0;
export const SUBSCRIPTION_SCHOOL = 1;
export const SUBSCRIPTION_ACADEMY = 2;

export const GRADABLE_SUBSCRIPTION = 0;
export const NON_GRADABLE_SUBSCRIPTION = 1;

export const subscriptionPlans = [
  {
    id: SUBSCRIPTION_CLASSROOM,
    title: 'Classroom',
    description:
      'Ideal for teachers in a single classroom looking to avail of the power of tapybl.',
    features: ['From 30 to 299 users', 'Stand Alone', '5GB Storage'],
    priceInfo: 'Starting at $6',
    priceSubtitle: 'Non-Assessment Based per seat',
    gradableInfo: 'Assessable courses start at $9 per seat',
    buttonText: 'Get started',
    backgorund: backgroundClassSub,
  },
  {
    id: SUBSCRIPTION_SCHOOL,
    title: 'School',
    description:
      'Ideal for groups of teachers and schools looking to avail of the power of tapybl directly or via an LMS.',
    features: [
      'From 300 users',
      'LTI Integration',
      'Managed Help Desk',
      '25GB Storage',
    ],
    priceInfo: 'Starting at $5',
    priceSubtitle: 'Non-Assessment Based per seat',
    gradableInfo: 'Assessable courses start at $8 per seat',
    buttonText: 'Get started',
    backgorund: backgroundSchoolSub,
  },
  {
    id: SUBSCRIPTION_ACADEMY,
    title: 'Academic',
    description:
      'Ideal for school districts, colleges and universities looking to avail of the power of tapybl directly or via an LMS.',
    features: [
      'Everything in School',
      'Custom Branding',
      'Account & Onboarding Support',
      'Email Support',
    ],
    priceInfo: 'Custom Plan',
    priceSubtitle: '',
    gradableInfo: '',
    buttonText: 'Request Custom Quote',
    backgorund: backgroundAcademySub,
  },
];

export const gradablePlans = [
  {
    id: NON_GRADABLE_SUBSCRIPTION,
    title: 'Non-Assessment Based',
    sections: [
      {
        features: [
          'Course Management',
          'Content Engagement Statistics',
          'Admin Interface & Settings',
          'Image Publishing',
          'Video Publishing',
          'Audio Publishing',
        ],
      },
      {
        title: 'Interactivity Elements',
        features: [
          'Single choice',
          'Choice Branching',
          'Random Branching',
          'Survey',
          'Feedback',
        ],
      },
    ],
  },
  {
    id: GRADABLE_SUBSCRIPTION,
    title: 'Assessment-Based',
    sections: [
      {
        title: 'Everything in Non Non-Assessment Based +',
        green: true,
      },
      {
        title: 'Assessable Lessons',
        features: [
          'Multiple Choice',
          'Supplemental Learning',
          'AI Assistant',
          'Challenge Branching',
        ],
      },
      {
        title: 'Advanced Insights & Analytics',
        features: ['Voice Challenge', 'Video Challenge', 'Text Challenge'],
      },
    ],
  },
];

import {inject, observer} from 'mobx-react';
import React, {useState} from 'react';
import {LessonContentAcknowledgment} from '../../../../../data/models/LessonContent/LessonContentBase';
import LessonEditorStore from '../../../store/LessonEditorStore';
import AcknowledgmentOptionsUI from '../view/AcknowledgmentOptionsUI';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';
import {lessonEditorText} from '../../../lessonEditorText';

interface Props {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentAcknowledgment;
  lessonEditorStore?: LessonEditorStore;
  onChangesMade: () => void;
  isPublished?: boolean;
  rootId: string;
}

const AcknowledgmentOptionsContainer = inject('lessonEditorStore')(
  observer(
    ({
      entityToEdit,
      isPublished,
      lessonEditorStore,
      onChangesMade,
      rootId,
    }: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const [showSoftDelete, setShowSoftDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onEdit = (title: string, buttonText: string) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              title,
              buttonTitle: buttonText,
            } as LessonContentAcknowledgment)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const onSoftDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.softDeleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const processDeleteResult = (success: boolean) => {
        if (success) {
          onChangesMade();
        } else {
          toast.error(getErrorFromCode(''), toastConfig);
        }
      };

      return entityToEdit ? (
        <>
          <AcknowledgmentOptionsUI
            onDelete={() => setShowDelete(true)}
            onSoftDelete={
              entityToEdit.internalId === rootId
                ? undefined
                : () => setShowSoftDelete(true)
            }
            onEdit={onEdit}
            isLoading={lessonEditorStore!.isLoading}
            isPublished={isPublished}
            node={entityToEdit}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteAcknowledgment}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteAcknowledgmentSoft}
            onAccept={onSoftDelete}
            onCancel={() => setShowSoftDelete(false)}
            shown={showSoftDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      ) : null;
    },
  ),
);

export default AcknowledgmentOptionsContainer;

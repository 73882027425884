export const courseEditText = {
  CourseEditRootCourse: 'Course',
  GeneralName: 'Course Name',
  GeneralCategory: 'Category',
  GeneralDescription: 'Course Description',
  GeneralShortDescription: 'Course Short Description',
  GeneralInstructor: 'Instructor',
  GeneralPoster: 'Course Poster',
  GeneralDueDate: 'Due Date',
  GeneralSaveButton: 'Save',
  GeneralCancelButton: 'Cancel',
  ImageChangeFail: 'Course Poster could not be changed.',
  PosterHeading: 'Course Poster',
  DescriptionPlaceholder: 'Enter course description here',
  changesSaved: 'Changes Saved',
  imagePlaceholder: 'Select Image',
  republishTooltip: 'Republish the course to make changes go live.',
};

import React, {useState} from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {Outlet} from 'react-router-dom';
import './styles.css';
import MobileRestriction from '../../../modules/MobileRestriction';

const EditorRoot = React.memo(() => {
  const [navHeight, setNavHeight] = useState(0);
  return (
    <div className="EditorRootContainer">
      <NavBar onHeightCalculated={setNavHeight} />
      <Container fluid>
        <Row>
          <Col
            xs={12}
            style={{height: `calc(100vh - ${navHeight}px)`}}
            className=" d-none d-lg-block">
            <Outlet />
          </Col>
          <Col
            xs={12}
            className="d-lg-none d-block"
            style={{height: `calc(100vh - ${navHeight}px)`}}>
            <MobileRestriction />
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default EditorRoot;

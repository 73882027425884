import React from 'react';
import './styles.css';
import {Notification} from '../../../../data/models/Notifications/Invitation';
import {notificationsText} from '../notificationsText';
import InvitationNotificationUserProfile from '../component/InvitationNotificationUserProfile';
import {notificationTimeTextConvertor} from '../../../../utils/notificationTimeTextConvertor';

interface Props {
  notifications: Notification[];
  seenNotifications: Notification[];
  onDeny: (invitationId: string, notificationId: string) => void;
  onSeenNotification: (notificationId: string) => void;
  onViewCourse: (courseId: string, notificationId: string) => void;
  onViewWorkspace: (workspaceId: string, notificationId: string) => void;
  onViewCoursePreview: (
    courseId: string,
    workspaceId: string,
    notificationId: string,
  ) => void;
}

const NotificationsUserProfileUI = React.memo(
  ({
    notifications,
    onDeny,
    onSeenNotification,
    onViewCourse,
    onViewWorkspace,
    seenNotifications,
    onViewCoursePreview,
  }: Props) => {
    if (notifications.length === 0 && seenNotifications.length === 0) {
      return (
        <div className="UserProfileNotificationEmptyState">
          <h3>{notificationsText.noNotifications}</h3>
        </div>
      );
    }

    return (
      <div className="NotificationsUserProfile">
        <div className="NotificationsSectionHeader">
          <p>{notificationsText.newNotifications}</p>
        </div>
        {notifications.map((notification: Notification, index: number) => (
          <li key={index}>
            <InvitationNotificationUserProfile
              title={notification.description}
              dateSubtitle={notificationTimeTextConvertor(
                notification.dateCreated,
              )}
              onViewCoursePreview={() =>
                onViewCoursePreview(
                  notification.entityId,
                  notification.organizationId!,
                  notification.id,
                )
              }
              icon={notification.organizationAvatarUrl}
              onDeny={() => onDeny(notification.entityId, notification.id)}
              onSeenNotification={() => onSeenNotification(notification.id)}
              type={notification.type}
              onViewCourse={() =>
                onViewCourse(notification.entityId, notification.id)
              }
              onViewWorkspace={() =>
                onViewWorkspace(notification.organizationId!, notification.id)
              }
            />
          </li>
        ))}
        <div className="NotificationsSectionHeader">
          <p>{notificationsText.seenNotifications}</p>
        </div>
        {seenNotifications.map((notification: Notification, index: number) => (
          <li key={index}>
            <InvitationNotificationUserProfile
              title={notification.description}
              dateSubtitle={notificationTimeTextConvertor(
                notification.dateCreated,
              )}
              onViewCoursePreview={() =>
                onViewCoursePreview(
                  notification.entityId,
                  notification.organizationId!,
                  notification.id,
                )
              }
              icon={notification.organizationAvatarUrl}
              onDeny={() => onDeny(notification.entityId, notification.id)}
              onSeenNotification={() => onSeenNotification(notification.id)}
              type={notification.type}
              onViewCourse={() =>
                onViewCourse(notification.entityId, notification.id)
              }
              onViewWorkspace={() =>
                onViewWorkspace(notification.organizationId!, notification.id)
              }
              seen
            />
          </li>
        ))}
      </div>
    );
  },
);

export default NotificationsUserProfileUI;

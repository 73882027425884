import React from 'react';
import {bookIc} from '../../assets/images';
import {appText} from '../../assets/text/appText';
import Button from '../Buttons/Button';
import './styles.css';

interface Props {
  onCreate: () => void;
}

const CoursesEmptyState = ({onCreate}: Props) => {
  return (
    <div className="EmptyStateContainer">
      <img src={bookIc} />
      <p className="Title">{appText.noCoursesTitle}</p>
      <p className="Subtitle">{appText.noCoursesSubtitlte}</p>
      <Button
        onClick={onCreate}
        title={appText.createCourseAction}
        uiType="secondary"
      />
    </div>
  );
};

export default CoursesEmptyState;

import {makeAutoObservable} from 'mobx';
import GradebookRepository from '../../../data/repositories/GradebookRepository';

class GradebookStore {
  private gradebookRepo = new GradebookRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getGradebook(
    organizationId: string,
    courseId: string,
    page: number,
    pageSize: number,
    search: string,
  ) {
    const result = await this.gradebookRepo.getCourseGradebook(
      organizationId,
      courseId,
      page,
      pageSize,
      search,
    );
    if (result.success && result.data) {
      return result.data;
    }
    return {
      learners: [],
      lessons: [],
      grades: {},
      pagesCount: 0,
    };
  }
}

export default GradebookStore;

import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {adminPanelText} from '../adminPanelText';
import NavBar from '../../../uiToolkit/NavBar';
import './styles.css';
import {OrgLastAccessedModel} from '../../../data/models/Oganization/OrgLastAccessedModel';
import WorkspaceVisitsTable from '../components/WorkspaceVisitsTable';

interface Props {
  statistic?: any;
  logout: () => void;
  lastAccess: OrgLastAccessedModel[];
  pages: number;
  onPageChange: (page: number) => void;
}

const AdminPanelUI = React.memo(
  ({statistic, lastAccess, pages, onPageChange}: Props) => {
    return statistic ? (
      <div>
        <NavBar />
        <Container>
          <Row>
            <Col xs={12}>
              <div className="AdminPanelContainer">
                <h3>{adminPanelText.title}</h3>
              </div>
              <ul>
                <li>
                  {adminPanelText.activeUsers} {statistic?.activeUsersAmount}
                </li>
                <li>
                  {adminPanelText.workspacesAmount}{' '}
                  {statistic?.workspacesAmount}
                </li>
                <li>
                  {adminPanelText.coursesAmount} {statistic?.coursesAmount}
                </li>
                <li>
                  {adminPanelText.publicCoursesAmount}{' '}
                  {statistic?.publicCoursesAmount}
                </li>
              </ul>
            </Col>
            <Col xs={6}>
              <div className="AdminPanelContainer">
                <h3>{adminPanelText.workspaceVisits}</h3>
              </div>
              <WorkspaceVisitsTable
                lastAccess={lastAccess}
                pages={pages}
                onPageChange={onPageChange}
              />
            </Col>
          </Row>
        </Container>
      </div>
    ) : null;
  },
);

export default AdminPanelUI;

import React from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {selectSubscriptionText} from '../selectSubscriptionText';
import './styles.css';
import {
  SUBSCRIPTION_CLASSROOM,
  SUBSCRIPTION_SCHOOL,
  subscriptionPlans,
} from '../subscriptionPlans';
import SubscriptionPlan from '../components/SubscriptionPlan';
import {useNavigate, useParams} from 'react-router-dom';

interface Props {
  upgrade?: boolean;
  trial?: boolean;
}

const SelectSubscriptionUI = React.memo(({upgrade, trial}: Props) => {
  const {organizationId} = useParams();
  const navigate = useNavigate();

  const onClick = (id: number) => {
    if (id === SUBSCRIPTION_CLASSROOM || id === SUBSCRIPTION_SCHOOL) {
      if (upgrade) {
        navigate(`/subscription/${organizationId}/tailor_upgrade`, {
          state: {subscriptionType: id},
        });
      } else if (trial) {
        navigate(`/subscription/${organizationId}/tailor_trial`, {
          state: {subscriptionType: id},
        });
      } else {
        navigate(`/subscription/${organizationId}/tailor`, {
          state: {subscriptionType: id},
        });
      }
    } else {
      window.open(
        'https://help.tapybl.com/support/tickets/new?ticket_form=ask_a_question',
        '_blank',
      );
    }
  };

  return (
    <div className="SelectSubscriptionContainer">
      <NavBar />
      <Container>
        <Row>
          <Col xs={12}>
            <p className="SelectTitle">{selectSubscriptionText.selectTitle}</p>
            <div className="SubscriptionPlansContainer">
              {subscriptionPlans.map(item => (
                <SubscriptionPlan
                  {...item}
                  onClick={() => onClick(item.id)}
                  key={item.id}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default SelectSubscriptionUI;

import React, {useEffect, useState} from 'react';
import SettingsProfileUI from '../view/SettingsUserProfileUI';
import {inject, observer} from 'mobx-react';
import {toast} from 'react-toastify';
import SettingsUserProfileStore from '../store/SettingsUserProfileStore';
import {UserInfo} from '../../../../data/models/User/UserInfo';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {userProfileText} from '../../userProfileText';

interface Props {
  settingsUserProfileStore?: SettingsUserProfileStore;
}
const SettingsUserProfileContainer = inject('settingsUserProfileStore')(
  observer(({settingsUserProfileStore}: Props) => {
    const [profile, setProfile] = useState<UserInfo | null>(null);

    const fetchProfile = async () => {
      const userProfile = await settingsUserProfileStore!.getUserProfile();
      setProfile(userProfile);
    };

    const onSave = async (fullName: string) => {
      const success = await settingsUserProfileStore!.editUserProfile(fullName);
      if (!success) {
        fetchProfile();
        toast.error(userProfileText.nameUpdateFailed, toastConfig);
      } else {
        fetchProfile();
        toast.success(userProfileText.nameUpdateSuccessful, toastConfig);
      }
      settingsUserProfileStore!.setProfileUpdated(true);
    };

    const onUploadImage = async (image: File) => {
      const success = await settingsUserProfileStore!.editUserAvatar(image);
      if (!success) {
        fetchProfile();
        toast.error(userProfileText.profilePictureUpdateFailed, toastConfig);
        settingsUserProfileStore!.setProfileUpdated(false);
      } else {
        fetchProfile();
        settingsUserProfileStore!.setProfileUpdated(true);
        toast.success(
          userProfileText.profilePictureUpdateSuccessful,
          toastConfig,
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    };

    const onChangePassword = async (
      password: string,
      newPassword: string,
      confirmNewPassword: string,
    ) => {
      const success = await settingsUserProfileStore!.editUserPassword(
        password,
        newPassword,
        confirmNewPassword,
      );

      if (!success) {
        fetchProfile();
        settingsUserProfileStore!.setProfileUpdated(false);
        toast.error(userProfileText.passwordUpdateFailed, toastConfig);
      } else {
        settingsUserProfileStore!.setProfileUpdated(true);
        toast.success(userProfileText.passwordUpdateSuccessful, toastConfig);
      }
    };

    useEffect(() => {
      fetchProfile();
    }, [settingsUserProfileStore, onSave, onUploadImage, onChangePassword]);

    return profile ? (
      <SettingsProfileUI
        onSave={onSave}
        userProfile={profile}
        onChangePassword={onChangePassword}
        onUploadImage={onUploadImage}
      />
    ) : null;
  }),
);

export default SettingsUserProfileContainer;

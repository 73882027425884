import React from 'react';
import {addFreeformChoiceText} from '../addFreeformChoiceText';
import {LessonContentFreeformTextChoice} from '../../../../data/models/LessonContent/LessonContentBase';
import NumbersInput from '../../../../uiToolkit/Inputs/NumbersInput';
import ColorInput from '../../../../uiToolkit/Inputs/ColorInput';
import TextAreaInput from '../../../../uiToolkit/Inputs/TextAreaInput';

interface Props {
  textNode: LessonContentFreeformTextChoice;
  onChangeTextField: (field: string, value: any) => void;
}

const FreeformTextOptions = React.memo(
  ({textNode, onChangeTextField}: Props) => {
    return (
      <div className="OptionsContainer">
        <p className="SectionHeader">{addFreeformChoiceText.options}</p>
        <div className="OptionsContent">
          <TextAreaInput
            value={textNode.text}
            heading={addFreeformChoiceText.freeformTextInput}
            onChange={(value: string) => onChangeTextField('text', value)}
            placeholder={addFreeformChoiceText.title}
            uiType="box"
            className="OptionsInput"
          />
          <NumbersInput
            value={textNode.fontSize}
            heading={addFreeformChoiceText.fontSize}
            setValue={(value?: number) => onChangeTextField('fontSize', value)}
            uiType="box"
            maxValue={60}
            className="OptionsInput"
          />
          <ColorInput
            value={textNode.color}
            heading={addFreeformChoiceText.textColor}
            onChange={(value: string) => onChangeTextField('color', value)}
            placeholder={addFreeformChoiceText.color}
            uiType="box"
          />
        </div>
      </div>
    );
  },
);

export default FreeformTextOptions;

export const addVideoOverlayWizardText = {
  cropTitle: 'Add a start and end for video',
  startTime: 'Start',
  endTime: 'End',
  seekToTimestamp: 'Seek to time (MM:SS)',
  useFullVideo: 'Use the full video instead',
  cancel: 'Cancel',
  save: 'Save',
  overlayTitle: 'Overlay Settings',
  addOverlay: 'Add Overlay',
  overlayError: 'Invalid Overlay Settings',
  nodeDestination: 'Node Destination',
  lessonDestination: 'Lesson Destination',
  showOverlay: 'Show Overlay For Learners?',
};

import {ApiResponse} from '../models/ApiResponse';
import {InvoiceResponse} from '../models/Subscription/Invoice';
import {OrganizationSubscription} from '../models/Subscription/Subscription';
import {SubscriptionPermissions} from '../models/Subscription/SubscriptionPermission';
import {apiClient, withAuth} from './baseApiClient';

class SubscriptionApi {
  public async getSubscriptionPermissions(
    organizationId: string,
  ): Promise<ApiResponse<SubscriptionPermissions>> {
    const headers = await withAuth({});
    return apiClient.get(`/subscription/permissions/${organizationId}/all`, {
      headers,
    });
  }

  public async getSubscribeSession(
    organizationId: string,
    qty: number,
    tier: number,
  ): Promise<ApiResponse<string>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/create`,
      {qty, tier},
      {
        headers,
      },
    );
  }

  public async upgradeSubscription(
    organizationId: string,
    qty: number,
    tier: number,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/change_plan`,
      {qty, tier},
      {
        headers,
      },
    );
  }

  public async getPaymentMethodSession(
    organizationId: string,
  ): Promise<ApiResponse<string>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/change_payment_method`,
      {},
      {
        headers,
      },
    );
  }

  public async getSubscribeTrialSession(
    organizationId: string,
    tier: number,
  ): Promise<ApiResponse<string>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/create_trial?tier=${tier}`,
      {},
      {
        headers,
      },
    );
  }

  public async getOrganizationSubscription(
    organizationId: string,
  ): Promise<ApiResponse<OrganizationSubscription>> {
    const headers = await withAuth({});
    return apiClient.get(`/subscription/${organizationId}`, {
      headers,
    });
  }

  public async cancelSubscription(
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/subscription/${organizationId}/cancel`,
      {},
      {
        headers,
      },
    );
  }

  public async getInvoicesList(
    organizationId: string,
  ): Promise<ApiResponse<InvoiceResponse>> {
    const headers = await withAuth({});
    return apiClient.get(`Subscription/${organizationId}/invoices`, {headers});
  }
}

export default SubscriptionApi;

import React, {useEffect, useState} from 'react';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {LessonContentAcknowledgment} from '../../../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../../../lessonEditorText';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';

interface Props {
  node: LessonContentAcknowledgment;
  onEdit: (title: string, buttonText: string) => void;
  onDelete: () => void;
  onSoftDelete?: () => void;
  isPublished?: boolean;
  isLoading: boolean;
}

const AcknowledgmentOptionsUI = React.memo(
  ({node, onEdit, onDelete, isPublished, isLoading, onSoftDelete}: Props) => {
    const [title, setTitle] = useState('');
    const [buttonText, setButtonText] = useState('');

    useEffect(() => {
      setTitle(node.title);
      setButtonText(node.buttonTitle);
    }, [node]);

    const changesWasMade = () =>
      node.title !== title || node.buttonTitle !== buttonText;

    const isValid = () => title.trim() !== '';

    const onSave = () => {
      if (isValid()) {
        onEdit(title, buttonText);
      }
    };

    return (
      <div>
        <TextInput
          heading={lessonEditorText.acknowledgmentText}
          value={title}
          onChange={setTitle}
          placeholder={lessonEditorText.acknowledgmentTextPlaceholder}
          fieldError={title.trim() === ''}
          maxCharacters={500}
          isMandatory={true}
          uiType="box"
        />
        <TextInput
          heading={lessonEditorText.acknowledgmentButton}
          value={buttonText}
          onChange={setButtonText}
          placeholder={lessonEditorText.acknowledgmentButtonPlaceholder}
          fieldError={buttonText.trim() === ''}
          maxCharacters={40}
          isMandatory={true}
          uiType="box"
        />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          deleteTitle={lessonEditorText.delete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
          onSoftDelete={onSoftDelete}
        />
      </div>
    );
  },
);

export default AcknowledgmentOptionsUI;

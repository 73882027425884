export const videoSourcesText = {
  title: 'My Library',
  recent: 'Recent files',
  allFiles: 'Uploaded files',
  vimeoFiles: 'Vimeo files',
  search: 'Search for file',
  delete: 'Delete',
  fileIsUsed:
    'This video is used as part of a lesson in a course and cannot be deleted',
  confirmDelete: 'Are you sure you want to delete this file from your library?',
  fileDeleted: 'File deleted',
  generateWithAI: 'Generate with AI Assistant',
  aiFiles: 'AI Generated Files',
};

import OrganizationApi from '../api/OrganizationApi';
import {
  CreateRoleModel,
  EditRoleModel,
} from '../models/Oganization/CreateRoleModel';
import {Organization} from '../models/Oganization/Organization';
import LocalStorage from '../storage/LocalStorage';

class OrganizationRepository {
  private organizationApi = new OrganizationApi();
  private localStorage = new LocalStorage();

  public getMyOrganization() {
    return this.organizationApi.getUserOrganization();
  }

  public getOrganizationDetails(organizationId: string) {
    return this.organizationApi.getOrganizationDetails(organizationId);
  }

  public async applyThemeColors() {
    const user = this.localStorage.getItemFromStorage(
      LocalStorage.Keys.USER_KEY,
    );
    if (!user) {
      return;
    }
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    const storedSecondaryColor = localStorage.getItem('secondaryColor');
    if (storedPrimaryColor && storedSecondaryColor) {
      this.localStorage.setThemeColors(
        storedPrimaryColor,
        storedSecondaryColor,
      );
    }
    const userOrg = await this.organizationApi.getUserOrganization();
    if (
      userOrg.success &&
      userOrg.data &&
      userOrg.data.primaryColor &&
      userOrg.data.secondaryColor
    ) {
      this.localStorage.setThemeColors(
        userOrg.data.primaryColor,
        userOrg.data.secondaryColor,
      );
    }
  }

  public async createOrganization(
    name: string,
    description: string,
    primaryColor: string,
    secondaryColor: string,
    type: number,
    image?: File,
  ) {
    const result = await this.organizationApi.createOrganization({
      name,
      description,
      primaryColor,
      secondaryColor,
      type,
    });
    if (result.success) {
      this.localStorage.setThemeColors(primaryColor, secondaryColor);
    }
    if (result.success && result.data && image) {
      await this.organizationApi.uploadOrganizationAvatar(
        image,
        result.data.id,
      );
    }
    return result;
  }

  public async editOrganization(organization: Organization, image?: File) {
    const result = await this.organizationApi.editOrganization(organization);
    if (result.success) {
      this.localStorage.setThemeColors(
        organization.primaryColor,
        organization.secondaryColor,
      );
    }
    if (result.success && result.data && image) {
      await this.organizationApi.uploadOrganizationAvatar(
        image,
        organization.id,
      );
    }
    return result;
  }

  public getOrganizationRoles(organizationId: string) {
    return this.organizationApi.getOrganizationRoles(organizationId);
  }

  public createRole(model: CreateRoleModel) {
    return this.organizationApi.createRole(model);
  }

  public getRoleById(id: string, organizationId: string) {
    return this.organizationApi.getRoleById(id, organizationId);
  }

  public editRole(model: EditRoleModel) {
    return this.organizationApi.editRole(model);
  }

  public deleteRole(organizationId: string, roleId: string) {
    return this.organizationApi.deleteRole(organizationId, roleId);
  }

  public getOrganizationUsersCount(organizationId: string) {
    return this.organizationApi.getOrganizationUsersCount(organizationId);
  }

  public addVimeoSecret(organizationId: string, secret: string) {
    return this.organizationApi.addVimeoSecret(organizationId, secret);
  }

  public getHaveVimeoIntegration(organizationId: string) {
    return this.organizationApi.getHaveVimeoIntegration(organizationId);
  }
}

export default OrganizationRepository;

import React, {useEffect, useState} from 'react';
import LessonEditorUI from '../view/LessonEditorUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {createContext} from 'react';
import AddVideoSourceWizardStore from '../../../modules/Editor/AddVideoSourceWizard/store/AddVideoSourceWizardStore';
import {AddVideoSourceWizard} from '../../../modules/Editor/AddVideoSourceWizard';
import LessonEditorStore from '../store/LessonEditorStore';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';
import {
  ACKNOWLEDGMENT_NODE,
  BRANCH_BACKGROUND_FREEFORM,
  BRANCH_IMAGE_FREEFORM,
  BRANCH_SHAPE_FREEFORM,
  BRANCH_TEXT_FREEFORM,
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_IMAGE_OPTION,
  CHALLENGE_BRANCH_QUESTION,
  CHALLENGE_BRANCH_TEXT_OPTION,
  CHOICE_HEADER_NODE_TYPE,
  CHOICE_IMAGE_OPTION,
  CHOICE_TEXT_OPTION,
  CHOICE_TEXT_QUESTION,
  JUMP_TO_NODE,
  LessonContentBase,
  LessonContentChallengeBranchImageOption,
  LessonContentChallengeChoiceHeader,
  LessonContentChoiceHeader,
  LessonContentChoiceImageOption,
  LessonContentVideoBackgorund,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';
import {Lesson} from '../../../data/models/Course/Lesson';
import {AddChoiceQuestionWizard} from '../../../modules/Editor/AddChoiceQuestionWizard';
import AddChoiceQuestionStore from '../../../modules/Editor/AddChoiceQuestionWizard/store/AddChoiceQuestionStore';
import {AddChoiceTextOptionWizard} from '../../../modules/Editor/AddChoiceTextOptionWizard';
import AddChoiceTextOptionWizardStore from '../../../modules/Editor/AddChoiceTextOptionWizard/store/AddChoiceTextOptionWizardStore';
import MyLibraryModalStore from '../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import {
  MEDIA_FILES_IMAGE,
  MEDIA_FILES_VIDEO,
} from '../../../data/models/LibraryFile/MediaFile';
import {MyLibraryModal} from '../../../modules/MyLibraryModal';
import AddMChoiceWizardStore from '../../../modules/Editor/AddMChoiceWizard/store/AddMChoiceWizardStore';
import {AddMChoiceWizard} from '../../../modules/Editor/AddMChoiceWizard';
import {Course} from '../../../data/models/Course/Course';
import {Folder} from '../../../data/models/Course/Folder';
import {AddChallengeBranchQuestionWizard} from '../../../modules/Editor/AddChallengeBranchQuestionWizard';
import AddChallengeBranchQuestionStore from '../../../modules/Editor/AddChallengeBranchQuestionWizard/store/AddChallengeBranchQuestionStore';
import {AddChallengeBranchTextOptionWizard} from '../../../modules/Editor/AddChallengeBranchTextOptionWizard';
import AddChallengeBranchTextOptionStore from '../../../modules/Editor/AddChallengeBranchTextOptionWizard/store/AddChallengeBranchTextOptionStore';
import AddChallengeBranchAIStore from '../../../modules/Editor/AddChallengeBranchAIWizard/store/AddChallengeBranchAIStore';
import {AddChallengeBranchAIWizard} from '../../../modules/Editor/AddChallengeBranchAIWizard';
import {ActiveTabs} from '../../../data/models/UI/ActiveTabs';
import {SidebarOptions} from '../../../data/models/UI/SidebarOption';
import AddVideoOverlayStore from '../../../modules/Editor/AddVideoOverlayWizard/store/AddVideoOverlayStore';
import {
  AddVideoOverlay,
  EditVideoOverlay,
} from '../../../modules/Editor/AddVideoOverlayWizard';
import {GRADABLE_COURSE_TYPE} from '../../../data/staticValues/courseStructure';
import AddFreeformChoiceStore from '../../../modules/Editor/AddFreeformChoiceOption/store/AddFreeformChoiceStore';
import {AddFreeformChoice} from '../../../modules/Editor/AddFreeformChoiceOption';
import AddJumpToWizardStore from '../../../modules/Editor/AddJumpToWizard/store/AddJumpToWizardStore';
import AddJumpToWizardContainer from '../../../modules/Editor/AddJumpToWizard/container/AddJumpToWizardContainer';
import AddAcknowledgmentWizardStore from '../../../modules/Editor/AddAcknowledgmentWizard/store/AddAcknowledgmentWizardStore';
import {AddAcknowledgmentWizard} from '../../../modules/Editor/AddAcknowledgmentWizard';

export const EditorContext = createContext({
  selectedNode: undefined,
  selectedNodeType: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setSelectedNode: (node: any, type: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  addNewNode: (parentId: string, type: number) => {},
  isGradable: false,
  isPublished: false,
  lessonContent: undefined,
} as any);

interface Props {
  addVideoSourceWizardStore?: AddVideoSourceWizardStore;
  addChoiceQuestionStore?: AddChoiceQuestionStore;
  lessonEditorStore?: LessonEditorStore;
  addChoiceTextOptionWizardStore?: AddChoiceTextOptionWizardStore;
  myLibraryModalStore?: MyLibraryModalStore;
  addMChoiceWizardStore?: AddMChoiceWizardStore;
  addChallengeBranchQuestionStore?: AddChallengeBranchQuestionStore;
  addChallengeBranchTextOptionStore?: AddChallengeBranchTextOptionStore;
  addChallengeBranchAIStore?: AddChallengeBranchAIStore;
  addVideoOverlayStore?: AddVideoOverlayStore;
  addFreeformChoiceStore?: AddFreeformChoiceStore;
  addJumpToWizardStore?: AddJumpToWizardStore;
  addAcknowledgmentWizardStore?: AddAcknowledgmentWizardStore;
}

const LessonEditorContainer = inject(
  'addVideoSourceWizardStore',
  'addChoiceQuestionStore',
  'addChoiceTextOptionWizardStore',
  'myLibraryModalStore',
  'lessonEditorStore',
  'addMChoiceWizardStore',
  'addChallengeBranchQuestionStore',
  'addChallengeBranchTextOptionStore',
  'addChallengeBranchAIStore',
  'addVideoOverlayStore',
  'addFreeformChoiceStore',
  'addJumpToWizardStore',
  'addAcknowledgmentWizardStore',
)(
  observer(
    ({
      addVideoSourceWizardStore,
      lessonEditorStore,
      addChoiceQuestionStore,
      addChoiceTextOptionWizardStore,
      myLibraryModalStore,
      addMChoiceWizardStore,
      addChallengeBranchQuestionStore,
      addChallengeBranchTextOptionStore,
      addChallengeBranchAIStore,
      addVideoOverlayStore,
      addFreeformChoiceStore,
      addJumpToWizardStore,
      addAcknowledgmentWizardStore,
    }: Props) => {
      const [selectedNode, setSelectedNode] = useState<LessonContentBase>();
      const [selectedNodeType, setSelectedNodeType] = useState(-1);
      const [course, setCourse] = useState<Course>();
      const [publishedId, setPublisehdId] = useState<string>();
      const [lessonContent, setLessonContent] = useState<LessonContentList>();
      const [lesson, setLesson] = useState<Lesson>();
      const [folder, setFolder] = useState<Folder>();

      const navigate = useNavigate();
      const {courseId, organizationId, folderId, lessonId} = useParams();
      const [sidebarOptions, setSidebarOptions] = useState<SidebarOptions>([]);
      const [search, setSearch] = useState('');

      useEffect(() => {
        if (lesson && lesson.id !== lessonId) {
          window.location.reload();
        }
      }, [lessonId]);

      useEffect(() => {
        fetchContent();
        fetchLesson();
        fetchFolder();
        if (organizationId && courseId && lessonId) {
          lessonEditorStore!
            .getLessonIsPublished(organizationId, lessonId)
            .then(res => setPublisehdId(res?.publishedId));
          lessonEditorStore
            ?.getCourse(organizationId, courseId)
            .then(setCourse);
        }
      }, []);

      useEffect(() => {
        getSidebarOptions(search);
      }, [search]);

      const fetchLesson = () => {
        if (organizationId && lessonId) {
          lessonEditorStore!
            .getLesson(organizationId, lessonId)
            .then(setLesson);
        }
      };

      const fetchContent = () => {
        setLessonContent(undefined);
        if (organizationId && lessonId) {
          onClearSelectedNode();
          lessonEditorStore
            ?.getLessonContent(organizationId, lessonId)
            .then(setLessonContent);
        }
      };

      const fetchFolder = () => {
        if (organizationId && folderId) {
          lessonEditorStore
            ?.getLessonFolder(organizationId, folderId)
            .then(setFolder);
        }
      };

      const onBack = () => {
        navigate(
          `/editor/${organizationId}/courses/${courseId}/folders/${folderId}/lessons`,
          {replace: true},
        );
      };

      const onSelectNode = (node: any, type: any) => {
        setSelectedNode(node);
        setSelectedNodeType(type);
      };

      const getNodeById = (id: string) => {
        if (lessonContent && lessonContent.contentList) {
          return lessonContent.contentList.items[id];
        }
      };

      const onPreview = () => {
        navigate(
          `/preview/${organizationId}/course/${courseId}/lesson/${lessonId}`,
        );
      };

      const onPublish = () => {
        navigate(`/preview/${organizationId}/course/${courseId}`);
      };

      const onClearSelectedNode = () => {
        setSelectedNode(undefined);
        setSelectedNodeType(-1);
      };

      const onChangesMade = () => {
        fetchContent();
        fetchLesson();
      };

      const getChildForParent = (parentId: string) => {
        let childId = '';
        if (lessonContent) {
          Object.keys(lessonContent.contentList.items).forEach(key => {
            if (
              lessonContent.contentList.items[key]?.parentsIds?.includes(
                parentId,
              )
            ) {
              childId = key;
            }
          });
        }
        return childId;
      };

      const addNewNode = (
        parentId: string,
        type: number,
        nodeToReplace?: LessonContentBase,
      ) => {
        const childId = getChildForParent(parentId);
        if (organizationId && lessonId) {
          if (type === VIDEO_NODE_TYPE) {
            addVideoSourceWizardStore?.startWizard(
              fetchContent,
              parentId,
              nodeToReplace,
              childId,
            );
          }
          if (type === CHOICE_HEADER_NODE_TYPE) {
            lessonEditorStore
              ?.addLessonNode(organizationId, lessonId, parentId, {
                ivNodeType: type,
                title: '',
              } as unknown as LessonContentChoiceHeader)
              .then(fetchContent);
          }
          if (type === CHOICE_TEXT_QUESTION) {
            addChoiceQuestionStore?.startWizard(parentId, fetchContent);
          }
          if (type === CHOICE_TEXT_OPTION) {
            addChoiceTextOptionWizardStore!.startWizard(parentId, fetchContent);
          }
          if (type === CHOICE_IMAGE_OPTION) {
            myLibraryModalStore!.showLibrary(MEDIA_FILES_IMAGE, file => {
              if (file) {
                lessonEditorStore!
                  .addLessonNode(organizationId, lessonId, parentId, {
                    ivNodeType: CHOICE_IMAGE_OPTION,
                    contentId: file.storageFileName,
                  } as LessonContentChoiceImageOption)
                  .then(fetchContent);
              }
            });
          }
          if (type === MULTIPLE_CHOICE) {
            const parent = getNodeById(parentId);
            if (parent) {
              addMChoiceWizardStore?.startWizard(
                parent.data,
                lessonId,
                fetchContent,
                nodeToReplace,
                childId,
              );
            }
          }
          if (type === CHALLENGE_BRANCH_HEADER) {
            lessonEditorStore
              ?.addLessonNode(organizationId, lessonId, parentId, {
                ivNodeType: type,
              } as unknown as LessonContentChallengeChoiceHeader)
              .then(fetchContent);
          }
          if (type === CHALLENGE_BRANCH_QUESTION) {
            addChallengeBranchQuestionStore!.openWizard(parentId, fetchContent);
          }
          if (type === CHALLENGE_BRANCH_IMAGE_OPTION) {
            myLibraryModalStore!.showLibrary(MEDIA_FILES_IMAGE, file => {
              if (file) {
                lessonEditorStore!
                  .addLessonNode(organizationId, lessonId, parentId, {
                    ivNodeType: CHALLENGE_BRANCH_IMAGE_OPTION,
                    contentId: file.storageFileName,
                    isCorrect: false,
                  } as LessonContentChallengeBranchImageOption)
                  .then(fetchContent);
              }
            });
          }
          if (type === CHALLENGE_BRANCH_TEXT_OPTION) {
            addChallengeBranchTextOptionStore!.openWizard(
              parentId,
              fetchContent,
            );
          }
          if (type === CHALLENGE_BRANCH_AI) {
            addChallengeBranchAIStore!.startWizard(parentId, fetchContent);
          }
          if (type === OVERLAY_VIDEO_TYPE && lessonContent) {
            myLibraryModalStore!.showLibrary(
              MEDIA_FILES_VIDEO,
              (file, vimeoFile) => {
                addVideoOverlayStore!.openWizard(
                  parentId,
                  file ? file : null,
                  vimeoFile ? vimeoFile : null,
                  fetchContent,
                  lessonContent,
                  course?.type.toString() === GRADABLE_COURSE_TYPE,
                  undefined,
                  nodeToReplace,
                  childId,
                );
              },
            );
          }
          if (type === BRANCH_IMAGE_FREEFORM && lessonContent) {
            myLibraryModalStore!.showLibrary(MEDIA_FILES_IMAGE, file => {
              if (file) {
                addFreeformChoiceStore!.showWizard(
                  parentId,
                  type,
                  fetchContent,
                  lessonContent,
                  {
                    id: file.storageFileName,
                    url: file.fileUrl || '',
                  },
                  nodeToReplace,
                );
              }
            });
          }
          if (
            (type === BRANCH_SHAPE_FREEFORM || type === BRANCH_TEXT_FREEFORM) &&
            lessonContent
          ) {
            addFreeformChoiceStore!.showWizard(
              parentId,
              type,
              fetchContent,
              lessonContent,
              undefined,
              nodeToReplace,
            );
          }
          if (type === JUMP_TO_NODE) {
            addJumpToWizardStore!.startWizard(parentId, fetchContent);
          }
          if (type === BRANCH_BACKGROUND_FREEFORM) {
            const parent = getNodeById(parentId);
            lessonEditorStore!
              .addLessonNode(organizationId, lessonId, parentId, {
                ivNodeType: BRANCH_BACKGROUND_FREEFORM,
                isCorrect:
                  parent?.data.ivNodeType === CHALLENGE_BRANCH_QUESTION
                    ? false
                    : undefined,
              } as LessonContentVideoBackgorund)
              .then(fetchContent);
          }
          if (type === ACKNOWLEDGMENT_NODE) {
            addAcknowledgmentWizardStore!.openWizard(
              parentId,
              fetchContent,
              nodeToReplace,
              childId,
            );
          }
        }
      };

      const getBreadcrumbs = () => [
        {
          title: 'Courses',
          link: `/manage/${organizationId}/courses`,
        },
        {
          title: course!.title,
          link: `/editor/${organizationId}/courses/${courseId}/folders`,
        },
        {
          title: folder!.name,
          link: `/editor/${organizationId}/courses/${courseId}/folders/${folderId}/lessons`,
        },
        {
          title: lesson!.title,
          link: `#`,
        },
      ];

      const getSidebarOptions = (search: string) => {
        if (organizationId) {
          lessonEditorStore!
            .getSidebarOptions(organizationId, search)
            .then(res => {
              setSidebarOptions(res);
            });
        }
      };

      const exportAsScorm = () => {
        if (organizationId && course && course.id) {
          lessonEditorStore!.exportAsScorm(
            organizationId,
            course.id,
            course.title,
          );
        }
      };

      return course && folder && lesson ? (
        <EditorContext.Provider
          value={{
            selectedNode,
            selectedNodeType,
            setSelectedNode: onSelectNode,
            addNewNode: addNewNode,
            isGradable: lesson?.isGradable,
            isPublished: publishedId,
            lessonContent: lessonContent,
          }}>
          <LessonEditorUI
            onBack={onBack}
            selectedItem={selectedNode}
            lesson={lesson}
            onExportScorm={exportAsScorm}
            selectedItemType={selectedNodeType}
            lessonContent={lessonContent}
            onClearSelectedNode={onClearSelectedNode}
            onChangesMade={onChangesMade}
            onPreview={onPreview}
            onPublish={onPublish}
            course={course}
            lessonIsPublisehd={
              publishedId !== undefined && publishedId !== null
            }
            breadcrumbs={getBreadcrumbs()}
            sidebarOptions={sidebarOptions}
            activeTabs={{courseId, folderId, lessonId} as ActiveTabs}
            onSearch={setSearch}
          />
          <AddVideoSourceWizard />
          <AddChoiceQuestionWizard />
          <AddChoiceTextOptionWizard />
          <MyLibraryModal />
          <AddMChoiceWizard lessonContent={lessonContent} />
          <AddChallengeBranchQuestionWizard />
          <AddChallengeBranchTextOptionWizard />
          <AddChallengeBranchAIWizard />
          <AddVideoOverlay />
          {lessonContent && <AddFreeformChoice lessonContent={lessonContent} />}
          <AddJumpToWizardContainer lessonContent={lessonContent} />
          <EditVideoOverlay />
          <AddAcknowledgmentWizard />
        </EditorContext.Provider>
      ) : (
        <div />
      );
    },
  ),
);

export default LessonEditorContainer;

export const learnersText = {
  learnerInviteSuccessMsg: 'Invitation(s) Sent Successfully',
  learnerInvitePartialSuccessMsg: 'Some of the invitation(s) Sent Successfully',
  learnerInviteFailMsg: 'Invitation(s) not sent',
  inviteLearnersPageTitle: 'Invite Learners',
  inviteLearnersPageSubtitle:
    'Invite learners to your workspace by adding their email addresses below.',
  HeadingInviteLearnerInputField: 'Invite Learners',
  SubmitButton: 'Submit',
  CancelButton: 'Cancel',
  learnerDeleteSuccessMsg: 'Learner Deleted Successfully',
  learnerActivated: 'Learner Activated Successfully',
  learnerRemoveFromCourseSuccess: 'Learner removed from the course',
  learnerReEnrollSuccess: 'Learner enrollment activated',
  EmailInstructions:
    'Use the Enter or Return Key to add additonal email addresses',
};

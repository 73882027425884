export const listLearnersText = {
  title: 'Learners',
  allLearners: 'All Learners',
  customGroups: 'Custom groups',
  active: 'Active',
  inactive: 'Inactive',
  invite: 'Add Learners',
  learnerNotEnrolled:
    'Selected Learner has not been enrolled in any course yet.',
  remove: 'Remove',
  removeFromCourse: 'Remove learner enrollment',
  removeFromCourseConfirmation:
    'Are you sure you want to remove this learner from this course?',
  removeFromOrgConfirmation:
    'Are you sure you want to remove this learner from this workspace?',
  noLearners:
    'Looks like there are no learners in this workspace yet. Use the "Add Learners" button to get started!',
  noLearnersInCourse:
    'Looks like there are no learners in this course yet. Enroll learners in the course on the courses page',
  deleteLearner: 'Delete this learner',
  deleteInvitation: 'Delete invitation',
  activate: 'Activate this learner',
  activateEnrollment: 'Activate this enrollment',
  activateLearnerToActivateEnrollment:
    'Activate the learner record to activate his enrollments',
  copyEmail: 'Copy Email',
};

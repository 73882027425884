import React from 'react';
import {editorAddItemIc, lessonArrowDown} from '../../assets/images';
import './styles.css';
import Tooltip from '../Tooltip';

interface Props {
  onClick: () => void;
  isDragging: boolean;
  withArrow?: boolean;
  tooltip?: string;
}

const EditorAddBelowButton = React.memo(
  ({onClick, isDragging, withArrow, tooltip}: Props) => {
    return (
      <div
        className="EditorAddBelowAction"
        style={{
          opacity: isDragging ? 0 : 1,
        }}>
        {withArrow && <img src={lessonArrowDown} />}
        {tooltip ? (
          <Tooltip tooltipText={tooltip} greenTooltip>
            <button onClick={onClick} className="EditorAddBelowButton">
              <img src={editorAddItemIc} />
            </button>
          </Tooltip>
        ) : (
          <button onClick={onClick} className="EditorAddBelowButton">
            <img src={editorAddItemIc} />
          </button>
        )}
      </div>
    );
  },
);

export default EditorAddBelowButton;

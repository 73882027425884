import React, {useState} from 'react';
import './styles.css';
import {settingsText} from '../../settingsText';
import Button from '../../../../uiToolkit/Buttons/Button';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';

interface Props {
  onSaveSecret: (secret: string) => void;
  hasIntegration?: boolean;
}

const StorageIntegrationSettingsUI = React.memo(
  ({onSaveSecret, hasIntegration}: Props) => {
    const [secret, setSecret] = useState('');

    return (
      <div className="StorageSettingsContainer">
        <div>
          <p className="StoragePrompt">{settingsText.vimeoPrompt}</p>
          {hasIntegration === undefined ? null : !hasIntegration ? (
            <>
              <TextInput
                heading={settingsText.vimeoClientSecret}
                placeholder={settingsText.vimeoClientPlaceholder}
                value={secret}
                onChange={setSecret}
                type="password"
              />
              <Button
                onClick={() => {
                  onSaveSecret(secret);
                }}
                title={settingsText.setupVimeoStorage}
                isLoading={false}
                className="VimeoSetupButton"
                disabled={!secret}
              />
            </>
          ) : (
            <div>
              <p className="StoragePrompt">
                <span className="icon-typeCheck"></span>{' '}
                {settingsText.vimeoConfigured}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default StorageIntegrationSettingsUI;

import {makeAutoObservable} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {coursePreviewText} from '../coursePreviewText';

class CoursePreviewStore {
  private courseRepository = new CoursesRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getCoursePresentation(organizationId: string, courseId: string) {
    this.isLoading = true;
    const result = await this.courseRepository.getCoursePreview(
      organizationId,
      courseId,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async publishCourse(organizationId: string, courseId: string) {
    this.isLoading = true;
    const result = await this.courseRepository.publishCourse(
      organizationId,
      courseId,
    );
    this.isLoading = false;
    if (result.success) {
      toast.success(coursePreviewText.publishSuccessful, toastConfig);
    }
    return result.success;
  }

  public async republishCourse(organizationId: string, courseId: string) {
    this.isLoading = true;
    const result = await this.courseRepository.republishCourse(
      organizationId,
      courseId,
    );
    this.isLoading = false;
    if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    if (result.success) {
      toast.success(coursePreviewText.republishSuccessful, toastConfig);
    }
    return result.success;
  }
}

export default CoursePreviewStore;

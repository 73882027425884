export const forgotPasswordText = {
  title: 'Forgot your password?',
  subtitle: 'Enter your registered email address',
  email: 'Email',
  code: 'Code',
  submit: 'Reset password',
  password: 'New Password',
  emailSent:
    'A password reset link has been sent if your account is registered. Please check your inbox',
  goBack: 'Go Back',
  login: 'Login',
  resetSubtitle: 'Please enter a new password',
};

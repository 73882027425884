import React, {useEffect, useState} from 'react';
import CreateCourseUI from '../view/CreateCourseUI';
import {inject, observer} from 'mobx-react';
import {Subject} from '../../../../data/models/Course/LearningStandards';
import {CourseViewModel} from '../../../../data/models/Course/Course';
import {useNavigate, useParams} from 'react-router-dom';
import CreateCourseStore from '../store/CreateCourseStore';
import {SubscriptionPermissions} from '../../../../data/models/Subscription/SubscriptionPermission';

interface Props {
  createCourseStore?: CreateCourseStore;
}

const CreateCourseContainer = inject('createCourseStore')(
  observer(({createCourseStore}: Props) => {
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const [orgFeatures, setOrgFeatures] = useState<SubscriptionPermissions>();
    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId) {
        createCourseStore
          ?.getLearningStandarts(organizationId)
          .then(setSubjects);
        createCourseStore!
          .getOrganizationAvailableFeatures(organizationId)
          .then(setOrgFeatures);
      }
      return () => {
        clearErrors();
      };
    }, []);

    const createCourse = async (model: CourseViewModel, exit?: boolean) => {
      if (organizationId) {
        const createdCourse = await createCourseStore?.createCourse(
          {...model, organizationId},
          organizationId,
        );
        if (createdCourse && !exit) {
          navigate(
            `/editor/${organizationId}/courses/${createdCourse.id}/folders`,
          );
        } else if (createdCourse && exit) {
          navigate(`/manage/${organizationId}/courses`, {replace: true});
        }
      }
    };

    const onCancel = () => {
      navigate(`/manage/${organizationId}/courses`, {replace: true});
    };

    const clearErrors = () => {
      createCourseStore!.clearErrors();
    };

    return orgFeatures ? (
      <CreateCourseUI
        subjectOptions={subjects}
        createCourse={createCourse}
        onCancel={onCancel}
        errors={createCourseStore!.errors}
        clearErrors={clearErrors}
        organizationFeatures={orgFeatures}
        isLoading={createCourseStore!.isLoading}
      />
    ) : null;
  }),
);

export default CreateCourseContainer;

import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import CreateOrganizationStore from '../store/CreateOrganizationStore';
import CreateOrganizationOpenerUI from '../view/CreateOrganizationOpenerUI';
import {OrganizationDetails} from '../../../data/models/Oganization/Organization';
import ModalProviderStore from '../../ModalProvider/store/ModalProviderStore';
import {CREATE_ORGANIZATION_MODAL} from '../../ModalProvider/globalModals';
import {useNavigate} from 'react-router-dom';

interface Props {
  createOrganizationStore?: CreateOrganizationStore;
  modalProviderStore?: ModalProviderStore;
}

const CreateOrganizationOpenerContainer = inject(
  'createOrganizationStore',
  'modalProviderStore',
)(
  observer(({createOrganizationStore, modalProviderStore}: Props) => {
    const [userOrganization, setUserOrganization] =
      useState<OrganizationDetails>();

    const navigate = useNavigate();

    useEffect(() => {
      createOrganizationStore!.getUserOrganization().then(setUserOrganization);
    }, []);

    const onOpen = () => {
      modalProviderStore!.openModal(CREATE_ORGANIZATION_MODAL, onFinish);
    };

    const onFinish = (orgId?: string) => {
      if (orgId && typeof orgId === 'string') {
        navigate(`/manage/${orgId}/workspace`);
      }
    };

    if (createOrganizationStore!.isLoading) {
      return <div />;
    }

    return !userOrganization ? (
      <CreateOrganizationOpenerUI onOpen={onOpen} />
    ) : (
      <div />
    );
  }),
);

export default CreateOrganizationOpenerContainer;

import Compress from 'compress.js';

export const resizeImage = async (
  file: File,
  width: number,
  height: number,
) => {
  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 1, // the quality of the image, max is 1,
    maxWidth: width, // the max width of the output image, defaults to 1920px
    maxHeight: height, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
  const fileWithName = new File([resizedFile], file.name, {
    type: resizedFile.type,
  });
  return fileWithName;
};

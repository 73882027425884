import * as Icons from '../../../assets/images';
import {APP_PERMISSIONS} from '../../../data/permissions';
import {isSandbox} from '../../../utils/envHelper';

export const navConfig = (id?: string) => [
  {
    icon: Icons.homeIcon,
    title: 'Workspace',
    route: `/manage/${id}/workspace`,
    activeIcon: 'Home',
    createOption: false,
    reqireSubscription: false,
  },
  {
    icon: Icons.fileIcon,
    title: 'Library',
    route: `/manage/${id}/library`,
    activeIcon: 'Page',
    createOption: false,
    permission: APP_PERMISSIONS.ORG_LIST_FILES,
    reqireSubscription: true,
  },
  {
    icon: Icons.bookIc,
    title: 'Courses',
    route: `/manage/${id}/courses`,
    activeIcon: 'Course',
    createOption: true,
    permission: APP_PERMISSIONS.ORG_LIST_COURSES,
    createPermission: APP_PERMISSIONS.ORG_CREATE_COURSES,
    createOptionTooltip: 'Create Course',
    reqireSubscription: true,
  },
  {
    icon: Icons.usersNavIcon,
    title: 'Learners',
    route: `/manage/${id}/learners/general`,
    overrideCreateLink: `/manage/${id}/learners/create`,
    activeIcon: 'Users',
    createOption: isSandbox ? false : true,
    permission: APP_PERMISSIONS.ORG_LIST_LEARNERS,
    createPermission: APP_PERMISSIONS.ORG_CREATE_LEARNERS,
    createOptionTooltip: 'Add Learners',
    reqireSubscription: true,
  },
  {
    icon: Icons.settingsIcon,
    title: 'Settings',
    route: `/manage/${id}/settings/general`,
    activeIcon: 'Settings',
    createOption: false,
    permission: APP_PERMISSIONS.ORG_MANAGE_SETTINGS,
    reqireSubscription: true,
  },
];

export const helpConfig = {
  icon: Icons.helpIc,
  title: 'Help & Tutorials',
  route: `https://anitechsolutions.atlassian.net/wiki/spaces/THC/overview`,
  activeIcon: 'Help',
  createOption: false,
};

export const contactConfig = {
  icon: Icons.helpIc,
  title: 'Contact us',
  route: `https://help.tapybl.com/support/tickets/new?ticket_form=ask_a_question`,
  activeIcon: 'Mail',
  createOption: false,
};

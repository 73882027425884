export const CLASSROOM_NOT_GRADABLE = 0;
export const CLASSROOM_GRADABLE = 1;
export const SCHOOL_NOT_GRADABLE = 2;
export const SCHOOL_GRADABLE = 3;

export interface OrganizationSubscription {
  organizationSubscriptionStatus: number;
  currentPeriodEndDate: string;
  trialStart?: string;
  trialEnd?: string;
  last4?: string;
  qty: number;
  subscription: {
    subscriptionTier: number;
    name: string;
  };
}

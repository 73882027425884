import React from 'react';
import './styles.css';
import {videoSourcesText} from '../videoSourcesText';
import RecentFileItem from '../components/RecentFileItem';
import AllFilesTable from '../components/AllFilesTable';
import {libraryTitleIcon} from '../../../assets/images';
import UploadVideoButton from '../../../uiToolkit/Inputs/UploadVideoButton';
import {Col, Row} from 'react-bootstrap';
import PageTitle from '../../../uiToolkit/PageTitle';
import {
  MEDIA_FILES_ALL,
  MediaFile,
  VimeoMediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import SearchInput from '../../../uiToolkit/Inputs/SearchInput';
import VimeoFilesTable from '../components/VimeoFilesTable';
import Button from '../../../uiToolkit/Buttons/Button';
import AiGeneratedFilesTable from '../components/AiGeneratedFilesTable';
import PermissionGateContanier from '../../../modules/PermissionGate/container/PermissionGateContainer';
import {AI_ASSISTANT_FEATURE} from '../../../data/models/Subscription/SubscriptionPermission';

interface Props {
  videoSources: MediaFile[];
  recentFiles: MediaFile[];
  onItemClick: (item: MediaFile) => void;
  search: string;
  setSearch: (search: string) => void;
  onUpload: (video: File, duration: number) => void;
  isLoading: boolean;
  pages: number;
  onPageChange: (page: number) => void;
  onDelete: (itemId: string) => void;
  storageLimit: number;
  onSort: (selectedColumn: any, sortDirection: any) => void;
  vimeoVideos: VimeoMediaFile[];
  aiGeneratedVideos: MediaFile[];
  onGenerateAiButtonClicked: () => void;
  onPreview: (file: MediaFile) => void;
  onSortAI: (selectedColumn: any, sortDirection: any) => void;
  onAIPageChange: (page: number) => void;
  aiPages: number;
  organizationId: string;
}

const LibraryUI = React.memo(
  ({
    videoSources,
    onItemClick,
    search,
    setSearch,
    onUpload,
    isLoading,
    onPageChange,
    pages,
    recentFiles,
    onDelete,
    storageLimit,
    onSort,
    vimeoVideos,
    onGenerateAiButtonClicked,
    aiGeneratedVideos,
    onPreview,
    onAIPageChange,
    onSortAI,
    aiPages,
    organizationId,
  }: Props) => {
    return (
      <div className="VideoSourcesContainer">
        <div className="HeaderContainer">
          <PageTitle title={videoSourcesText.title} icon={libraryTitleIcon} />
          <div className="SearchContainer">
            <SearchInput
              value={search}
              onChange={setSearch}
              className="Search"
            />
            <div className="UploadContainer">
              <UploadVideoButton
                isLoading={isLoading}
                onVideoSelected={onUpload}
                type={MEDIA_FILES_ALL}
                maxFileSize={storageLimit}
              />
              <span className="MaxFileSize">
                max file size is {storageLimit}mb
              </span>
            </div>
            <PermissionGateContanier
              organizationId={organizationId}
              permission={AI_ASSISTANT_FEATURE}>
              <div className="GenerateAiButtonContainer">
                <Button
                  title={videoSourcesText.generateWithAI}
                  onClick={() => {
                    onGenerateAiButtonClicked();
                  }}
                  uiType="action"
                />
              </div>
            </PermissionGateContanier>
          </div>
        </div>
        <h5 className="RecentFilesTitle">{videoSourcesText.recent}</h5>
        <div className="RecentFilesContainer">
          {recentFiles.map(item => (
            <RecentFileItem
              item={item}
              onItemClick={onItemClick}
              key={item.id.toString()}
            />
          ))}
        </div>
        <h5 className="AllFilsTitle">{videoSourcesText.allFiles}</h5>
        <Row>
          <Col lg={12} xs={12}>
            <AllFilesTable
              sources={videoSources}
              onItemClick={onItemClick}
              onPageChange={onPageChange}
              pages={pages}
              onDelete={onDelete}
              onFileUpload={onUpload}
              isLoading={isLoading}
              onSort={onSort}
              onPreview={onPreview}
            />
          </Col>
        </Row>
        {aiGeneratedVideos.length ? (
          <>
            <h5 className="AllFilsTitle">{videoSourcesText.aiFiles}</h5>
            <Row>
              <Col lg={12} xs={12}>
                <AiGeneratedFilesTable
                  sources={aiGeneratedVideos}
                  onItemClick={onItemClick}
                  onPageChange={onAIPageChange}
                  pages={aiPages}
                  onDelete={onDelete}
                  onFileUpload={onUpload}
                  isLoading={isLoading}
                  onSort={onSortAI}
                  onPreview={onPreview}
                />
              </Col>
            </Row>
          </>
        ) : null}
        {vimeoVideos.length ? (
          <>
            <h5 className="AllFilsTitle">{videoSourcesText.vimeoFiles}</h5>
            <Row>
              <Col lg={12} xs={12}>
                <VimeoFilesTable
                  sources={vimeoVideos}
                  onPageChange={onPageChange}
                  pages={1}
                />
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    );
  },
);

export default LibraryUI;

import {makeAutoObservable} from 'mobx';
import LibraryRepository from '../../../data/repositories/LibraryRepository';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {generateAIVideosText} from '../generateAIVideosText';
import LoadingModule from '../../LoadingModule/LoadingModule';
import {getErrorFromCode} from '../../../data/errorCodes';

class GenerateAIVideosStore {
  private learnerRepository = new LibraryRepository();
  private showWizard = false;
  private videoGenerationStatus = 'idle';
  private onFinishedCallback?: () => void = undefined;

  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get generationStatus() {
    return this.videoGenerationStatus;
  }

  public startWizard(onFinished: () => void) {
    this.onFinishedCallback = onFinished;
    this.showWizard = true;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public isVisible() {
    return this.showWizard;
  }

  public cancelWizard() {
    this.showWizard = false;
  }

  public async generateAiVideosFromSlides(
    organizationId: string,
    file: File,
    aiImgage: boolean,
    sliderValue: string,
    ignoreSlidesList: number[],
    type: number,
    voiceGender: string,
    language: string,
    selectedDialect: string,
  ) {
    this.loadingModule.startLoading();
    this.videoGenerationStatus = 'pending';
    const result = await this.learnerRepository.generateAiVideosFromSlides(
      organizationId,
      file,
      aiImgage,
      sliderValue,
      ignoreSlidesList,
      type,
      voiceGender,
      language,
      selectedDialect,
    );
    this.loadingModule.endLoading();
    if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
      this.videoGenerationStatus = 'error';
    } else {
      this.videoGenerationStatus = 'idle';
      toast.success(generateAIVideosText.successMessage, toastConfig);
    }
    if (this.onFinishedCallback) {
      this.onFinishedCallback();
    }
    this.onFinishedCallback = undefined;
    this.showWizard = false;
  }
}

export default GenerateAIVideosStore;

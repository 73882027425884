import {makeAutoObservable} from 'mobx';
import OrganizationStaffRepository from '../../../data/repositories/OrganizationStaffRepository';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {addInstructorWizardText} from '../addInstructorWizardText';
import CoursesRepository from '../../../data/repositories/CoursesRepository';

class AddInstructorWizardStore {
  private organizationStaffRepository = new OrganizationStaffRepository();
  private coursesRepository = new CoursesRepository();

  private showWizard = false;
  private courseId?: string;

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public startWizard(courseId?: string) {
    this.courseId = courseId;
    this.showWizard = true;
  }

  public isVisible() {
    return this.showWizard;
  }

  public cancelWizard() {
    this.showWizard = false;
  }

  public async getCurrentInstructor(organizationId: string) {
    if (this.courseId) {
      const result = await this.coursesRepository.getCourseInstructor(
        organizationId,
        this.courseId,
      );
      if (result.success && result.data) {
        return result.data;
      }
    }
  }

  public async saveInstructors(
    selectedInstructor: string,
    organizationId: string,
  ) {
    this.isLoading = true;
    const result = await this.organizationStaffRepository.saveInstructors(
      selectedInstructor,
      organizationId,
      this.courseId!,
    );
    this.isLoading = false;
    if (result.success) {
      toast.success(addInstructorWizardText.successMessage, toastConfig);
      this.showWizard = false;
      return result.data;
    } else {
      toast.error(addInstructorWizardText.errorMessage, toastConfig);
      return result.errors;
    }
  }

  public async getInstructorCandidates(organizationId: string) {
    if (this.courseId) {
      const result =
        await this.organizationStaffRepository.getInstructorCandidates(
          organizationId,
          this.courseId!,
        );
      return result;
    }
    return {data: []};
  }
}

export default AddInstructorWizardStore;

import React, {useEffect} from 'react';
import ImageSvgHandles from './handles';
import {SvgImage} from '../../../../data/models/LessonContent/ContentFreeform';

interface Props {
  image: SvgImage;
  source: string;
  aspect: number;
  updateImageOptions?: (options: SvgImage) => void;
  onClick?: () => void;
}

const Image = React.memo(
  ({image, source, aspect, updateImageOptions, onClick}: Props) => {
    const [imageOptions, setImageOptions] = React.useState<SvgImage>({
      x: 0,
      y: 0,
      width: 0,
    });
    const [position, setPosition] = React.useState({
      active: false,
      offset: {x: 0, y: 0},
    });

    useEffect(() => {
      setImageOptions({...image});
    }, [image]);

    const handlePointerDown = (e: any) => {
      if (onClick) {
        onClick();
        e.stopPropagation();
      }
      if (!updateImageOptions) return;
      const el = e.target;
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      el.setPointerCapture(e.pointerId);
      setPosition({
        ...position,
        active: true,
        offset: {
          x,
          y,
        },
      });
    };

    const handlePointerMove = (e: any) => {
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      if (position.active) {
        setImageOptions({
          ...imageOptions,
          x: imageOptions.x - (position.offset.x - x),
          y: imageOptions.y - (position.offset.y - y),
        });
      }
    };

    const handlePointerUp = () => {
      setPosition({
        ...position,
        active: false,
      });
      if (updateImageOptions) {
        updateImageOptions(imageOptions);
      }
    };

    const onSetWidth = (width: number) => {
      setImageOptions({
        ...imageOptions,
        width,
      });
      if (updateImageOptions) {
        updateImageOptions({
          ...imageOptions,
          width,
        });
      }
    };

    return (
      <g>
        <image
          x={imageOptions.x}
          y={imageOptions.y}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}
          width={imageOptions.width}
          cursor={updateImageOptions ? 'move' : onClick ? 'pointer' : ''}
          href={source}
        />
        {updateImageOptions && (
          <ImageSvgHandles
            width={imageOptions.width}
            aspectRatio={aspect}
            x={imageOptions.x}
            y={imageOptions.y}
            setWidth={onSetWidth}
          />
        )}
      </g>
    );
  },
);

export default Image;

import React, {useEffect, useState} from 'react';
import LibraryUI from '../view/LibraryUI';
import {inject, observer} from 'mobx-react';
import VideoSourcesStore from '../store/VideoSourcesStore';
import {useParams} from 'react-router-dom';
import {
  FILE_ORIGIN_GENERATED,
  FILE_ORIGIN_UPLOADED,
  MediaFile,
  VimeoMediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import ConfirmationModal from '../../../uiToolkit/ConfirmationModal';
import {videoSourcesText} from '../videoSourcesText';
import NavigationStore from '../../../navigation/store/NavigationStore';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {GenerateAIVideos} from '../../../modules/GenerateAIVideos';
import GenerateAIVideosStore from '../../../modules/GenerateAIVideos/store/GenerateAIVideosStore';
import MediaPreview from '../../../modules/MediaPreview';

interface Props {
  videoSourcesStore?: VideoSourcesStore;
  navigationStore?: NavigationStore;
  generateAIVideosStore?: GenerateAIVideosStore;
}

const PAGE_SIZE = 10;

const LibraryContanier = inject(
  'videoSourcesStore',
  'navigationStore',
  'generateAIVideosStore',
)(
  observer(
    ({videoSourcesStore, navigationStore, generateAIVideosStore}: Props) => {
      const [vimeoVideos, setVimeoVideos] = useState<VimeoMediaFile[]>([]);
      const [videoSources, setVideoSources] = useState<MediaFile[]>([]);
      const [recentFiles, setRecentFiles] = useState<MediaFile[]>([]);
      const [storageLimit, setStorageLimit] = useState(0);
      const [search, setSearch] = useState('');
      const [pages, setPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(1);
      const [aiPages, setAiPages] = useState(0);
      const [currentAiPage, setCurrentAiPage] = useState(1);
      const [sortOptions, setSortOptions] = useState({
        column: 'file_name',
        order: 'desc',
      });
      const [aiSortOptions, setAiSortOptions] = useState({
        column: 'file_name',
        order: 'desc',
      });
      const [fileToDeleteId, setFileToDeleteId] = useState<string>();
      const {organizationId} = useParams();
      const [aiGeneratedVideos, setAiGeneratedVideos] = useState<MediaFile[]>(
        [],
      );
      const [fileToPreview, setFileToPreview] = useState<MediaFile>();

      useEffect(() => {
        fetchFiles();
        if (recentFiles.length === 0) {
          fetchRecentFiles();
        }
        if (organizationId) {
          videoSourcesStore!
            .getStorageLimit(organizationId)
            .then(setStorageLimit);
          videoSourcesStore!
            .getVimeoVideos(organizationId, 1, 10)
            .then(setVimeoVideos);
        }
      }, [videoSourcesStore, search]);

      useEffect(() => {
        fetchFiles();
      }, [currentPage, search, sortOptions]);

      useEffect(() => {
        fetchAiFiles();
      }, [
        generateAIVideosStore,
        currentAiPage,
        search,
        aiSortOptions,
        generateAIVideosStore?.generationStatus,
      ]);

      const fetchFiles = () => {
        if (organizationId) {
          videoSourcesStore
            ?.getMyVideoSources(
              currentPage - 1,
              organizationId,
              PAGE_SIZE,
              sortOptions.column,
              search,
              sortOptions.order === 'asc',
              FILE_ORIGIN_UPLOADED,
            )
            .then(res => {
              setVideoSources(res.data || []);
              setPages(res.pagesCount);
            });
        }
      };

      const fetchAiFiles = () => {
        if (organizationId) {
          videoSourcesStore
            ?.getMyVideoSources(
              currentAiPage - 1,
              organizationId,
              PAGE_SIZE,
              aiSortOptions.column,
              search,
              aiSortOptions.order === 'asc',
              FILE_ORIGIN_GENERATED,
            )
            .then(res => {
              setAiGeneratedVideos(res.data || []);
              setAiPages(res.pagesCount);
            });
        }
      };

      const fetchRecentFiles = () => {
        if (organizationId) {
          videoSourcesStore
            ?.getMyVideoSources(
              0,
              organizationId,
              PAGE_SIZE,
              'created_date',
              '',
              false,
            )
            .then(res => {
              setRecentFiles(res.data || []);
            });
        }
      };

      const onUpload = (video: File, duration: number) => {
        if (organizationId) {
          videoSourcesStore
            ?.uploadLibraryVideo(organizationId, video, duration)
            .then(() => {
              fetchFiles();
              fetchRecentFiles();
              navigationStore!.getOrganizationDetails(organizationId);
            });
        }
      };

      const onSearchChange = (search: string) => {
        setSearch(search);
        setCurrentPage(1);
      };

      const onItemClick = () => {
        // navigate('/video_editor');
      };

      const onDelete = () => {
        if (organizationId && fileToDeleteId) {
          videoSourcesStore!
            .deleteMediaFile(organizationId, fileToDeleteId)
            .then(() => {
              toast.success(videoSourcesText.fileDeleted, toastConfig);
              fetchFiles();
              fetchRecentFiles();
              fetchAiFiles();
              navigationStore!.getOrganizationDetails(organizationId);
            });
          setFileToDeleteId(undefined);
        }
      };

      const onSort = (selectedColumn: any, sortDirection: any) => {
        setSortOptions({
          column: selectedColumn.sortField,
          order: sortDirection,
        });
      };

      const onSortAi = (selectedColumn: any, sortDirection: any) => {
        setAiSortOptions({
          column: selectedColumn.sortField,
          order: sortDirection,
        });
      };

      const onGenerateAIButton = () => {
        generateAIVideosStore?.startWizard(() => {
          fetchAiFiles();
        });
      };

      const onPreview = (file: MediaFile) => {
        setFileToPreview(file);
      };

      return (
        <>
          <LibraryUI
            videoSources={videoSources}
            onItemClick={onItemClick}
            search={search}
            setSearch={onSearchChange}
            isLoading={videoSourcesStore!.isLoading}
            onUpload={onUpload}
            pages={pages}
            onPageChange={setCurrentPage}
            recentFiles={recentFiles}
            onDelete={setFileToDeleteId}
            storageLimit={storageLimit}
            onSort={onSort}
            vimeoVideos={vimeoVideos}
            onGenerateAiButtonClicked={onGenerateAIButton}
            aiGeneratedVideos={aiGeneratedVideos}
            onPreview={onPreview}
            aiPages={aiPages}
            onAIPageChange={setCurrentAiPage}
            onSortAI={onSortAi}
            organizationId={organizationId || ''}
          />
          <GenerateAIVideos />
          <ConfirmationModal
            shown={fileToDeleteId !== undefined}
            onAccept={onDelete}
            onCancel={() => setFileToDeleteId(undefined)}
            acceptText={videoSourcesText.delete}
            title={videoSourcesText.confirmDelete}
          />
          {fileToPreview && (
            <MediaPreview
              file={fileToPreview}
              onClose={() => setFileToPreview(undefined)}
            />
          )}
        </>
      );
    },
  ),
);

export default LibraryContanier;

import React, {useEffect, useState} from 'react';
import AdminPanelUI from '../view/AdminPanelUI';
import {inject, observer} from 'mobx-react';
import AdminPanelStore from '../store/AdminPanelStore';
import {useNavigate} from 'react-router-dom';

interface Props {
  adminPanelStore?: AdminPanelStore;
}

const AdminPanelContainer = inject('adminPanelStore')(
  observer(({adminPanelStore}: Props) => {
    const navigate = useNavigate();
    const [statistic, setStatistic] = useState<any>();
    const [lastAccess, setLastAccess] = useState<any>();
    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
      adminPanelStore?.getAnalytics().then(res => {
        if (res.data) {
          setStatistic(res.data);
          adminPanelStore!.getLastAccessData(activePage - 1, 10).then(res => {
            setLastAccess(res.data);
            setPages(res.pagesCount);
          });
        } else {
          navigate('/', {replace: true});
        }
      });
    }, [activePage]);

    const logout = () => {
      adminPanelStore!.logout().then(() => {
        navigate('/', {replace: true});
      });
    };

    return (
      <AdminPanelUI
        statistic={statistic}
        logout={logout}
        lastAccess={lastAccess}
        onPageChange={setActivePage}
        pages={pages}
      />
    );
  }),
);
export default AdminPanelContainer;

import React from 'react';
import {
  CLASSROOM_GRADABLE,
  CLASSROOM_NOT_GRADABLE,
  OrganizationSubscription,
  SCHOOL_GRADABLE,
  SCHOOL_NOT_GRADABLE,
} from '../../../data/models/Subscription/Subscription';
import {
  SUBSCRIPTION_CLASSROOM,
  SUBSCRIPTION_SCHOOL,
} from '../subscriptionPlans';
import Button from '../../../uiToolkit/Buttons/Button';
import {selectSubscriptionText} from '../selectSubscriptionText';

interface Props {
  selectedTier: number;
  currentSubscription: OrganizationSubscription;
  selectedIsGradable: boolean;
  selectedSeats: number;
  isLoading: boolean;
  hasError: boolean;
  onSubscribe: () => void;
  currentPrice: number;
}

const PlanDiffSection = React.memo(
  ({
    selectedTier,
    currentSubscription,
    selectedIsGradable,
    selectedSeats,
    isLoading,
    hasError,
    onSubscribe,
    currentPrice,
  }: Props) => {
    const tierHasChanged = () => {
      if (
        selectedTier == SUBSCRIPTION_CLASSROOM &&
        (currentSubscription.subscription.subscriptionTier ===
          CLASSROOM_GRADABLE ||
          currentSubscription.subscription.subscriptionTier ===
            CLASSROOM_NOT_GRADABLE)
      ) {
        return false;
      }
      if (
        selectedTier == SUBSCRIPTION_SCHOOL &&
        (currentSubscription.subscription.subscriptionTier ===
          SCHOOL_GRADABLE ||
          currentSubscription.subscription.subscriptionTier ===
            SCHOOL_NOT_GRADABLE)
      ) {
        return false;
      }
      return true;
    };

    const currentSubscriptionIsGraded = () => {
      if (
        currentSubscription.subscription.subscriptionTier ===
          CLASSROOM_GRADABLE ||
        currentSubscription.subscription.subscriptionTier === SCHOOL_GRADABLE
      ) {
        return true;
      }
      return false;
    };

    const planHasChanged = () => {
      if (currentSubscription.qty !== selectedSeats) {
        return true;
      }
      if (tierHasChanged()) {
        return true;
      }
      if (currentSubscriptionIsGraded() !== selectedIsGradable) {
        return true;
      }
      return false;
    };

    const currentSubscriptionIsClassroom = () => {
      return (
        currentSubscription.subscription.subscriptionTier ===
          CLASSROOM_GRADABLE ||
        currentSubscription.subscription.subscriptionTier ===
          CLASSROOM_NOT_GRADABLE
      );
    };

    const calculateOldPrice = () => {
      let price = 0;
      switch (currentSubscription.subscription.subscriptionTier) {
        case CLASSROOM_NOT_GRADABLE:
          price = 6;
          break;
        case CLASSROOM_GRADABLE:
          price = 9;
          break;
        case SCHOOL_NOT_GRADABLE:
          price = 5;
          break;
        case SCHOOL_GRADABLE:
          price = 8;
          break;
      }
      return price * currentSubscription.qty;
    };

    return (
      <div>
        <h3>
          {tierHasChanged()
            ? selectSubscriptionText.changingTier
            : selectSubscriptionText.changingPlan}
        </h3>
        {tierHasChanged() ? (
          <div>
            <p>
              {selectSubscriptionText.currentTier(
                currentSubscriptionIsClassroom()
                  ? selectSubscriptionText.classroom
                  : selectSubscriptionText.school,
              )}
            </p>
            <p>
              {selectSubscriptionText.upgradingToTier(
                selectedTier === SUBSCRIPTION_CLASSROOM
                  ? selectSubscriptionText.classroom
                  : selectSubscriptionText.school,
              )}
            </p>
            <p>
              {selectSubscriptionText.priceDifference}
              {calculateOldPrice() - currentPrice < 0 ? '-' : '+'}$
              {Math.abs(calculateOldPrice() - currentPrice)}.00
            </p>
            <p>
              {selectSubscriptionText.upgradeSupport(
                selectedTier === SUBSCRIPTION_CLASSROOM
                  ? selectSubscriptionText.classroom
                  : selectSubscriptionText.school,
                currentSubscription.qty - selectedSeats,
              )}
            </p>
            <p>Price shown excludes taxes</p>
          </div>
        ) : (
          <div>
            {currentSubscriptionIsGraded() !== selectedIsGradable && (
              <>
                <p>
                  {selectSubscriptionText.currectGradedOption(
                    currentSubscriptionIsGraded()
                      ? selectSubscriptionText.gradable
                      : selectSubscriptionText.nonGradable,
                  )}
                </p>
                <p>
                  {selectSubscriptionText.changedToGradedOption(
                    selectedIsGradable
                      ? selectSubscriptionText.gradable
                      : selectSubscriptionText.nonGradable,
                  )}
                </p>
              </>
            )}
            {currentSubscription.qty !== selectedSeats && (
              <>
                <p>
                  {selectSubscriptionText.currentSeats(currentSubscription.qty)}
                </p>
                <p>{selectSubscriptionText.changedSeats(selectedSeats)}</p>
              </>
            )}

            <p>
              {selectSubscriptionText.priceDifference}{' '}
              {calculateOldPrice() - currentPrice < 0 ? '-' : '+'}$
              {Math.abs(calculateOldPrice() - currentPrice)}.00
            </p>
            {currentSubscriptionIsGraded() !== selectedIsGradable && (
              <p>
                {selectSubscriptionText.supportChangeGraded(selectedIsGradable)}
              </p>
            )}
            {currentSubscription.qty !== selectedSeats && (
              <p>
                {currentSubscription.qty < selectedSeats
                  ? selectSubscriptionText.addSeats(
                      selectedSeats - currentSubscription.qty,
                    )
                  : selectSubscriptionText.removeSeats(
                      currentSubscription.qty - selectedSeats,
                    )}
              </p>
            )}
            <p>{selectSubscriptionText.noTaxes}</p>
          </div>
        )}
        <Button
          title={selectSubscriptionText.upgradeNow}
          onClick={onSubscribe}
          disabled={hasError || !planHasChanged()}
          isLoading={isLoading}
        />
      </div>
    );
  },
);

export default PlanDiffSection;

import React, {useEffect, useState} from 'react';
import {settingsText} from '../../settingsText';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import {Col, Row} from 'react-bootstrap';
import {APP_PERMISSIONS, getPermissionName} from '../../../../data/permissions';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';
import ErrorBox from '../../../../uiToolkit/ErrorBox';
import {Role} from '../../../../data/models/Oganization/Role';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  editMode?: boolean;
  onCancel: () => void;
  onSave: (name: string, permissions: string[]) => void;
  isLoading: boolean;
  errors: string[];
  roleToEdit?: Role;
  clearErrors: () => void;
}

const RoleDetailsUI = React.memo(
  ({
    editMode,
    onCancel,
    onSave,
    isLoading,
    errors,
    roleToEdit,
    clearErrors,
  }: Props) => {
    const [name, setName] = useState('');
    const [checkedPermissions, setCheckedPermissions] = useState<{
      [key: string]: boolean;
    }>({});

    useEffect(() => {
      if (editMode && roleToEdit) {
        setName(roleToEdit.roleName);
        const permissions: any = {};
        roleToEdit.rolePermissions?.forEach(permission => {
          permissions[permission.permission] = true;
        });
        setCheckedPermissions(permissions);
      }
    }, [roleToEdit]);

    const onCheck = (permission: string) => {
      const newPermissions = {
        ...checkedPermissions,
        [permission]: checkedPermissions[permission] ? false : true,
      };
      setCheckedPermissions(newPermissions);
    };

    const onSubmit = () => {
      onSave(name, Object.keys(checkedPermissions));
    };

    const buttonEnabled = () => {
      return name.trim() !== '' && Object.keys(checkedPermissions).length !== 0;
    };

    return (
      <div className="RoleCreateContainer">
        <h2>
          {editMode
            ? `${settingsText.edit} ${roleToEdit?.roleName || ''}`
            : settingsText.newRole}
        </h2>
        <h5>
          {editMode
            ? settingsText.editRoleSubtitle
            : settingsText.newRoleSubtitle}
        </h5>
        <ErrorBox
          errorCodes={errors}
          className="ErrorBox"
          clearErrors={clearErrors}
        />
        <TextInput
          value={name}
          onChange={setName}
          heading={settingsText.roleNameTitle}
          placeholder={settingsText.roleNamePlaceholder}
          className="NameInput"
          isMandatory
        />
        <div className="PermissionsContainer">
          <div>
            <p>
              {settingsText.permissions}{' '}
              <span className="TextBoxHeading MandatoryIndicator">*</span>
            </p>
          </div>
          <Row>
            {Object.keys(APP_PERMISSIONS).map(key => (
              <Col lg={6} xs={12} key={key} className="CheckboxColumn">
                <Checkbox
                  text={getPermissionName(APP_PERMISSIONS[key])}
                  onCheck={() => onCheck(APP_PERMISSIONS[key])}
                  checked={checkedPermissions[APP_PERMISSIONS[key]]}
                  slim
                />
              </Col>
            ))}
          </Row>
        </div>
        <div className="Footer">
          <Button
            onClick={onCancel}
            title={settingsText.cancel}
            className="Cancel"
            uiType="text"
          />
          <Button
            onClick={onSubmit}
            title={settingsText.save}
            disabled={!buttonEnabled()}
            isLoading={isLoading}
            uiType="action"
          />
        </div>
      </div>
    );
  },
);

export default RoleDetailsUI;

import {makeAutoObservable} from 'mobx';
import LibraryRepository from '../../../data/repositories/LibraryRepository';
import {MEDIA_FILES_ALL} from '../../../data/models/LibraryFile/MediaFile';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import SubscriptionRepository from '../../../data/repositories/SubscriptionRepository';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class VideoSourcesStore {
  private libraryRepo = new LibraryRepository();
  private subscriptionRepo = new SubscriptionRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public getStorageLimit = async (organizationId: string) => {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data.storageLimit;
    }
    return 100;
  };

  public async getMyVideoSources(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    search?: string,
    orderAsk?: boolean,
    origin?: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getMediaFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      MEDIA_FILES_ALL,
      search,
      orderAsk,
      origin,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.getVimeoVideos(
      organizationId,
      page,
      pageSize,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async uploadLibraryVideo(
    organizationId: string,
    video: File,
    duration: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.uploadVideo(
      organizationId,
      video,
      duration,
    );
    this.loadingModule.endLoading();
    if (result.errors && result.errors[0]) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    //Specific server error for file size
    if (result.errors && result.errors[0] && (result.errors[0] as any).errors) {
      toast.error(getErrorFromCode('file_too_large'), toastConfig);
    }
    return result.success;
  }

  public async deleteMediaFile(organizationId: string, mediaFileId: string) {
    this.loadingModule.startLoading();
    const result = await this.libraryRepo.deleteMediaFile(
      organizationId,
      mediaFileId,
    );
    this.loadingModule.endLoading();
    return result.success;
  }
}

export default VideoSourcesStore;

import React, {useState} from 'react';
import {Container, Row} from 'react-bootstrap';
import './styles.css';
import CoursesCard from '../components/CoursesCard';
import {PublicCourseModel} from '../../../data/models/Course/CourseList';
import FilterButton from '../../../uiToolkit/Buttons/FilterButton';
import FilterExploreCourses from '../components/FilterExploreCourses';
import {SubjectListItem} from '../../../data/models/Course/Subject';
import NavBar from '../../../uiToolkit/NavBar';
import {exploreText} from '../exploreText';
import {closeFilterIc} from '../../../assets/images';

interface Props {
  isLoading: boolean;
  publicCourses: PublicCourseModel[];
  onOpenCourse: (courseId: string, teaching?: boolean) => void;
  subjectsList: SubjectListItem[];
  selectedFilter: string;
  setSelectedFilter: (filter: string) => void;
}

const ExploreUi = React.memo(
  ({
    publicCourses,
    onOpenCourse,
    subjectsList,
    selectedFilter,
    setSelectedFilter,
  }: Props) => {
    const [showFilters, setShowFilters] = useState(false);
    const selectedSubject = subjectsList.find(
      subject => subject.id === selectedFilter,
    );
    const [removeFilter, setRemoveFilter] = useState<boolean>(true);

    return (
      <>
        <NavBar />
        <Container>
          <div className="explore-header">
            <div className="TitleContainer">
              <h1 className="explore-header_title">{exploreText.title}</h1>
              <div className="FilterContainer">
                <FilterButton onClick={() => setShowFilters(!showFilters)} />
                <FilterExploreCourses
                  subjects={subjectsList}
                  applyFiltersButton={setSelectedFilter}
                  onCloseFilters={() => setShowFilters(false)}
                  showFilter={showFilters}
                  removeFilter={removeFilter}
                />
              </div>
            </div>
            {selectedSubject && (
              <div className="ActiveFilters">
                <div key={selectedFilter} className="FilterNames">
                  <h3>{selectedSubject.title}</h3>
                  <img
                    src={closeFilterIc}
                    alt="close"
                    onClick={() => {
                      setRemoveFilter(!removeFilter);
                      setSelectedFilter('');
                    }}
                  />
                </div>
              </div>
            )}
            {publicCourses?.length ? (
              <Row className="ExploreCoursesContent">
                {publicCourses.map((course, index) => (
                  <React.Fragment key={index}>
                    <CoursesCard
                      course={course}
                      onOpenCourse={() => onOpenCourse(course.id, true)}
                    />
                  </React.Fragment>
                ))}
              </Row>
            ) : (
              <div className="ExploreCoursesEmpty">
                <p>{exploreText.noCourses}</p>
              </div>
            )}
          </div>
        </Container>
      </>
    );
  },
);

export default ExploreUi;

export const addFreeformChoiceText = {
  lastVideoFrame: 'Last Video Frame',
  options: 'Options',
  imageChoice: 'Image Choice',
  freeformText: 'Text Choice',
  freeformShape: 'Shape Choice',
  imageChoiceTitle: 'Image Choice Title',
  freeformTextInput: 'Text Choice:',
  title: 'Title',
  cancel: 'Cancel',
  save: 'Save',
  fontSize: 'Font Size:',
  textColor: 'Text Color:',
  color: 'Color',
  addPoint: 'Add Point',
  removePoint: 'Remove Point',
  showOverlay: 'Show Overlay For Learners?',
};

import React, {useEffect, useState} from 'react';
import DashboardRootUI from '../view/DashboardRootUI';
import {inject, observer} from 'mobx-react';
import NavigationStore from '../../../store/NavigationStore';
import {useParams} from 'react-router-dom';
import {Organization} from '../../../../data/models/Oganization/Organization';
import {OrganizationSubscription} from '../../../../data/models/Subscription/Subscription';
import {SubscriptionPermissions} from '../../../../data/models/Subscription/SubscriptionPermission';

interface Props {
  navigationStore?: NavigationStore;
}

const DashboardRootContainer = inject('navigationStore')(
  observer(({navigationStore}: Props) => {
    const [permissions, setPermissions] = useState<string[]>([]);
    const [organization, setOrganization] = useState<Organization>();
    const [subscription, setSubcsription] =
      useState<OrganizationSubscription>();
    const [subscriptionPermissions, setSubscriptionPermissions] =
      useState<SubscriptionPermissions>();
    const {organizationId} = useParams();

    useEffect(() => {
      if (organizationId) {
        navigationStore
          ?.getUserOrgPermissions(organizationId)
          .then(setPermissions);
        navigationStore
          ?.getOrganizationDetails(organizationId)
          .then(setOrganization);
        navigationStore
          ?.getOrganizationSubscription(organizationId)
          .then(setSubcsription);
        navigationStore
          ?.getOrgSubscriptionPermissions(organizationId)
          .then(setSubscriptionPermissions);
        navigationStore?.getOrganizationUsersCount(organizationId);
      }
    }, []);

    return (
      <DashboardRootUI
        permissions={permissions}
        organization={organization}
        storageUsed={navigationStore!.getStorageUsed()}
        organizationId={organizationId}
        hasSubscription={subscription !== undefined}
        subscriptionPermissions={subscriptionPermissions}
        usersCount={navigationStore!.getUsersUsed()}
      />
    );
  }),
);

export default DashboardRootContainer;

export const coursePreviewText = {
  courses: 'Courses',
  inThisCourse: 'In this course',
  lessons: 'Lessons',
  minApprox: 'min. Approx.',
  invalidLesson: 'This lesson is empty and will not be published.',
  invalidFolder:
    'This will not be visible to learners as there is no actual lesson content within',
  coursePreview: 'Course Preview',
  lessonsAmount: (amountOfLessons: number) =>
    `${amountOfLessons === 1 ? 'Lesson' : 'Lessons'}`,
  publishCourse: 'Publish Course',
  republishCourse: 'Republish Course',
  datePublished: 'Date published',
  publishSuccessful: 'Course Published Successfully',
  republishSuccessful: 'Course Republished Successfully',
};

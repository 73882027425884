export const createOrgText = {
  title: 'Create Workspace',
  nameTitle: 'Workspace Name',
  namePlaceholder: 'Name',
  avatarTitle: 'Workspace Logo',
  avatarPlaceholder: 'Browse an image to insert',
  description: 'Description',
  desscriptionPlaceholder: 'Description for your Workspace',
  cancel: 'Cancel',
  create: 'Create Workspace',
};

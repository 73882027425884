import React, {useEffect, useState} from 'react';
import TailorSubscriptionUI from '../view/TailorSubscriptionUI';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {redirect} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import SelectSubscriptionStore from '../store/SelectSubscriptonStore';
import {OrganizationSubscription} from '../../../data/models/Subscription/Subscription';

interface Props {
  selectSubscriptionStore?: SelectSubscriptionStore;
  upgrade?: boolean;
  trial?: boolean;
}

const TailorSubscriptionContainer = inject('selectSubscriptionStore')(
  observer(({selectSubscriptionStore, upgrade, trial}: Props) => {
    const [currentSubscription, setCurrentSubscription] =
      useState<OrganizationSubscription>();
    const {organizationId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (upgrade && organizationId) {
        selectSubscriptionStore!
          .getCurrentSubscription(organizationId)
          .then(setCurrentSubscription);
      }
    }, []);

    useEffect(() => {
      if (location?.state?.subscriptionType === undefined) {
        redirect(`/subscription/${organizationId}/select`);
      }
    }, [location, location.state]);

    const onCreateSubscription = (qty: number, tier: number) => {
      if (organizationId) {
        if (upgrade) {
          selectSubscriptionStore!
            .updateSubscription(organizationId, qty, tier)
            .then(success => {
              if (success) {
                navigate(`/subscription/${organizationId}/success_update`);
              }
            });
        } else if (trial) {
          selectSubscriptionStore!
            .getTrialSubscriptionSession(organizationId, tier)
            .then(res => {
              if (res) {
                const win = window.open(res, '_blank');
                if (win != null) {
                  win.focus();
                }
              }
            });
        } else {
          selectSubscriptionStore!
            .getSubscriptionSession(organizationId, qty, tier)
            .then(res => {
              if (res) {
                const win = window.open(res, '_blank');
                if (win != null) {
                  win.focus();
                }
              }
            });
        }
      }
    };

    return (
      <TailorSubscriptionUI
        selectedPlanId={location?.state?.subscriptionType}
        onCreateSubscription={onCreateSubscription}
        isLoading={selectSubscriptionStore!.isLoading}
        upgrade={upgrade}
        trial={trial}
        currentSubscription={currentSubscription}
      />
    );
  }),
);

export default TailorSubscriptionContainer;

import React, {useEffect, useState} from 'react';
import CoursePreviewUI from '../view/CoursePreviewUI';
import {inject, observer} from 'mobx-react';
import CoursePreviewStore from '../store/CoursePreviewStore';
import {CoursePresentation} from '../../../../data/models/Course/CoursePresentation';
import {useNavigate, useParams} from 'react-router-dom';

interface Props {
  coursePreviewStore?: CoursePreviewStore;
}

const CoursePreviewContainer = inject('coursePreviewStore')(
  observer(({coursePreviewStore}: Props) => {
    const [coursePresentation, setCoursePresentation] =
      useState<CoursePresentation>();

    const {courseId, organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      fetchPresentation();
    }, []);

    const getCourseLink = () => {
      return `/manage/${organizationId}/courses`;
    };

    const onEditFolders = () => {
      navigate(`/editor/${organizationId}/courses/${courseId}/folders`);
    };

    const onLessonClick = (lessonId: string) => {
      navigate(
        `/preview/${organizationId}/course/${courseId}/lesson/${lessonId}`,
      );
    };

    const onPublishCourse = () => {
      if (organizationId && courseId) {
        if (coursePresentation?.course.isPublished) {
          coursePreviewStore
            ?.republishCourse(organizationId, courseId)
            .then(fetchPresentation);
        } else {
          coursePreviewStore
            ?.publishCourse(organizationId, courseId)
            .then(fetchPresentation);
        }
      }
    };

    const fetchPresentation = () => {
      if (courseId && organizationId) {
        coursePreviewStore!
          .getCoursePresentation(organizationId, courseId)
          .then(setCoursePresentation);
      }
    };

    return (
      <CoursePreviewUI
        coursePresentation={coursePresentation}
        courseLink={getCourseLink()}
        onEditFolders={onEditFolders}
        onLessonClick={onLessonClick}
        onPublishCourse={onPublishCourse}
        isLoading={coursePreviewStore!.isLoading}
      />
    );
  }),
);

export default CoursePreviewContainer;

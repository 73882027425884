import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import LessonEditorStore from '../../../store/LessonEditorStore';
import {
  LessonContentOverlayVideo,
  LessonContentVimeoOverlayVideo,
} from '../../../../../data/models/LessonContent/LessonContentBase';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';
import {lessonEditorText} from '../../../lessonEditorText';
import MyLibraryModalStore from '../../../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import AddVideoOverlayStore from '../../../../../modules/Editor/AddVideoOverlayWizard/store/AddVideoOverlayStore';
import VideoOverlayOptionsUI from '../view/VideoOverlayOptionsUI';
import {LessonContentList} from '../../../../../data/models/LessonContent/LessonContentList';
import {
  MEDIA_FILES_VIDEO,
  MediaFile,
  VimeoMediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import EditVideoOverlayStore from '../../../../../modules/Editor/AddVideoOverlayWizard/store/EditVideoOverlayStore';

interface Props {
  lessonEditorStore?: LessonEditorStore;
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentOverlayVideo | LessonContentVimeoOverlayVideo;
  onCancel: () => void;
  onChangesMade: () => void;
  myLibraryModalStore?: MyLibraryModalStore;
  addVideoOverlayStore?: AddVideoOverlayStore;
  isPublished?: boolean;
  lessonContent?: LessonContentList;
  isGradable: boolean;
  editVideoOverlayStore?: EditVideoOverlayStore;
  rootId: string;
}

const VideoOverlayOptionsContainer = inject(
  'lessonEditorStore',
  'myLibraryModalStore',
  'addVideoOverlayStore',
  'editVideoOverlayStore',
)(
  observer(
    ({
      lessonEditorStore,
      entityToEdit,
      onChangesMade,
      onCancel,
      addVideoOverlayStore,
      isPublished,
      lessonContent,
      isGradable,
      myLibraryModalStore,
      editVideoOverlayStore,
      rootId,
    }: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const [showSoftDelete, setShowSoftDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const onSoftDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.softDeleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const processDeleteResult = (success: boolean) => {
        if (success) {
          onChangesMade();
        } else {
          toast.error(getErrorFromCode(''), toastConfig);
        }
      };

      const onPickNewFile = () => {
        myLibraryModalStore?.showLibrary(
          MEDIA_FILES_VIDEO,
          (file?: MediaFile, vimeoFile?: VimeoMediaFile) => {
            if (file && lessonContent && entityToEdit) {
              editVideoOverlayStore!.openWizard(
                file,
                null,
                onChangesMade,
                lessonContent,
                isGradable,
                entityToEdit.internalId,
              );
            } else if (vimeoFile && lessonContent && entityToEdit) {
              editVideoOverlayStore!.openWizard(
                null,
                vimeoFile,
                onChangesMade,
                lessonContent,
                isGradable,
                entityToEdit.internalId,
              );
            }
          },
        );
      };

      const onEdit = () => {
        if (lessonContent) {
          addVideoOverlayStore!.openWizard(
            '',
            null,
            null,
            onChangesMade,
            lessonContent,
            isGradable,
            entityToEdit,
          );
        }
      };

      const onSaveTitle = (title: string) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              title: title,
            } as LessonContentOverlayVideo)
            .then(onChangesMade);
        }
      };

      return entityToEdit ? (
        <>
          <VideoOverlayOptionsUI
            videoContent={entityToEdit}
            onCancel={onCancel}
            onDelete={() => setShowDelete(true)}
            onSoftDelete={
              entityToEdit?.internalId === rootId
                ? undefined
                : () => setShowSoftDelete(true)
            }
            isLoading={lessonEditorStore!.isLoading}
            onPickNewFile={onPickNewFile}
            isPublished={isPublished}
            onEdit={onEdit}
            onSave={onSaveTitle}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteVideo}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteVideoSoft}
            onAccept={onSoftDelete}
            onCancel={() => setShowDelete(false)}
            shown={showSoftDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      ) : null;
    },
  ),
);

export default VideoOverlayOptionsContainer;

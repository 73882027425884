import React, {useEffect} from 'react';
import ConfirmEmailUI from '../view/ConfirmEmailUI';
import ConfirmEmailStore from '../store/ConfirmEmailStore';
import {inject, observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

interface Props {
  confirmEmailStore?: ConfirmEmailStore;
}

const ConfirmEmailContainer = inject('confirmEmailStore')(
  observer(({confirmEmailStore}: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
      return () => {
        confirmEmailStore!.clearErrors();
      };
    }, [confirmEmailStore]);

    const onConfirmEmail = async (code: string) => {
      const result = await confirmEmailStore?.confirmEmail(code);
      if (result) {
        const organization = await confirmEmailStore!.getMyOrganization();
        if (organization) {
          navigate('/home');
        } else {
          navigate('/auth/onboarding');
        }
      }
    };

    const onRetry = () => {
      confirmEmailStore!.sendEmailVerification();
    };

    const onBack = async () => {
      await confirmEmailStore!.clearUser();
      navigate(-1);
    };

    const clearErorrs = () => {
      confirmEmailStore!.clearErrors();
    };

    return (
      <ConfirmEmailUI
        errors={confirmEmailStore!.errors}
        onConfirmEmail={onConfirmEmail}
        onRetry={onRetry}
        isLoading={confirmEmailStore!.isLoading}
        onBack={onBack}
        clearErorrs={clearErorrs}
      />
    );
  }),
);

export default ConfirmEmailContainer;

import React, {useEffect, useState} from 'react';
import OpenWorkspaceUI from '../view/OpenWorkspaceUI';
import OpenWorkspaceStore from '../store/OpenWorkspaceStore';
import {inject, observer} from 'mobx-react';
import {
  ORGANIZATION_USER_LEARNER,
  OrganizationDetails,
} from '../../../data/models/Oganization/Organization';
import {useNavigate} from 'react-router-dom';

interface Props {
  openWorkspaceStore?: OpenWorkspaceStore;
}

const OpenWorkspaceContainer = inject('openWorkspaceStore')(
  observer(({openWorkspaceStore}: Props) => {
    const [userOrganization, setUserOrganization] =
      useState<OrganizationDetails>();

    const navigate = useNavigate();

    useEffect(() => {
      openWorkspaceStore!.getUserOrganization().then(setUserOrganization);
    }, []);

    const onOpenWorkspace = () => {
      if (
        userOrganization &&
        userOrganization.userType !== ORGANIZATION_USER_LEARNER
      ) {
        navigate(`/manage/${userOrganization.id}/workspace`);
      }
    };

    return userOrganization &&
      userOrganization.userType !== ORGANIZATION_USER_LEARNER ? (
      <OpenWorkspaceUI onOpenDashboard={onOpenWorkspace} />
    ) : (
      <div />
    );
  }),
);

export default OpenWorkspaceContainer;

import {makeAutoObservable} from 'mobx';
import LibraryRepository from '../../../data/repositories/LibraryRepository';
import {
  MEDIA_FILES_ALL,
  MEDIA_FILES_VIDEO,
  MediaFile,
  VimeoMediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import SubscriptionRepository from '../../../data/repositories/SubscriptionRepository';

class MyLibraryModalStore {
  private libraryRepo = new LibraryRepository();
  private subscriptionRepo = new SubscriptionRepository();
  private isLibraryShown = false;
  private sourceType?: number;
  private onFileCallback?: (
    file?: MediaFile,
    vimeoFile?: VimeoMediaFile,
  ) => void;
  private modalType?: number;

  public isLoading = false;

  public getStorageLimit = async (organizationId: string) => {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data.storageLimit;
    }
    return 100;
  };

  public showLibrary(
    type: number,
    onFileCallback: (file?: MediaFile, vimeoFile?: VimeoMediaFile) => void,
    sourceType?: number,
  ) {
    this.onFileCallback = onFileCallback;
    this.isLibraryShown = true;
    this.modalType = type;
    this.sourceType = sourceType;
  }

  public getSourceType() {
    return this.sourceType;
  }

  public closeLibrary() {
    this.onFileCallback = undefined;
    this.modalType = undefined;
    this.isLibraryShown = false;
  }

  public onFileSelect(file?: MediaFile, vimeoFile?: VimeoMediaFile) {
    if (this.onFileCallback) {
      this.onFileCallback(file, vimeoFile);
      this.closeLibrary();
    }
  }

  public isVisible() {
    return this.isLibraryShown;
  }

  public getType() {
    if (this.modalType === undefined) {
      return MEDIA_FILES_ALL;
    }
    return this.modalType;
  }

  public async uploadLibraryFile(
    organizationId: string,
    video: File,
    duration: number,
  ) {
    this.isLoading = true;
    const result = await this.libraryRepo.uploadVideo(
      organizationId,
      video,
      duration,
    );
    if (result.errors && result.errors[0]) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    //Specific server error for file size
    if (result.errors && result.errors[0] && (result.errors[0] as any).errors) {
      toast.error(getErrorFromCode('file_too_large'), toastConfig);
    }

    this.isLoading = false;
    return result.success;
  }

  public async getMediaFiles(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    orderAsc?: boolean,
  ) {
    if (this.modalType === undefined) {
      return {
        data: [],
        pagesCount: 0,
      };
    }

    this.isLoading = true;
    const result = await this.libraryRepo.getMediaFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      this.modalType,
      '',
      orderAsc,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }

  public async getVimeoFiles(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    if (this.modalType !== MEDIA_FILES_VIDEO) {
      return [];
    }

    this.isLoading = true;
    const result = await this.libraryRepo.getVimeoVideos(
      organizationId,
      page,
      pageSize,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default MyLibraryModalStore;

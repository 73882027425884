import {makeAutoObservable} from 'mobx';
import UserRepository from '../../../data/repositories/UserRepository';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import NotificationsRepository from '../../../data/repositories/NotificationsRepository';
import CoursesListRepository from '../../../data/repositories/CoursesRepository';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class HomeStore {
  private userRepo = new UserRepository();
  private orgRepo = new OrganizationRepository();
  private notificationsRepository = new NotificationsRepository();
  private coursesListRepository = new CoursesListRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getUserInfo() {
    this.loadingModule.startLoading();
    const result = await this.userRepo.getUserInfo();
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public applyThemeColors() {
    this.orgRepo.applyThemeColors();
  }

  public async getMyOrganization() {
    this.loadingModule.startLoading();
    const result = await this.orgRepo.getMyOrganization();
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getMyInvitations() {
    const result = await this.notificationsRepository.getMyNotifications();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async getCourseList() {
    const result = await this.coursesListRepository.getCourseList();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default HomeStore;

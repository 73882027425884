import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {settingsTitleIcon} from '../../../assets/images';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import {Link, Outlet, useLocation, useParams} from 'react-router-dom';
import PageTitle from '../../../uiToolkit/PageTitle';

const tabs = (organizationId: string) => [
  {
    name: 'General',
    route: `/manage/${organizationId}/settings/general`,
  },
  {
    name: 'Roles',
    route: `/manage/${organizationId}/settings/roles`,
    createRoute: `/manage/${organizationId}/settings/create_role`,
    editRoute: `/manage/${organizationId}/settings/edit_role`,
  },
  {
    name: 'Users',
    route: `/manage/${organizationId}/settings/users`,
    createRoute: `/manage/${organizationId}/settings/invite_users`,
    editRoute: `/manage/${organizationId}/settings/edit_user`,
  },
  {
    name: 'Subscription',
    route: `/manage/${organizationId}/settings/subscription`,
  },
  {
    name: 'LTI',
    route: `/manage/${organizationId}/settings/lti_settings`,
  },
  {
    name: 'Vimeo Integration',
    route: `/manage/${organizationId}/settings/storage_integration`,
  },
];

const DashboardSettingsRoot = React.memo(() => {
  const {organizationId} = useParams();
  const location = useLocation();

  return (
    <div className="SettingsContainer">
      <PageTitle
        title={appText.dashboardSettingsTitle}
        icon={settingsTitleIcon}
      />
      <div className="TabsContainer">
        {tabs(organizationId || '').map(tab => (
          <Link
            key={tab.name}
            className={
              location.pathname === tab.route ||
              location.pathname === tab.createRoute ||
              (tab.editRoute && location.pathname.includes(tab.editRoute))
                ? 'ActiveButton'
                : ''
            }
            to={tab.route}>
            {tab.name}
          </Link>
        ))}
        <div className="FillBorder" />
      </div>
      <Row>
        <Col lg={9} xs={12}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
});

export default DashboardSettingsRoot;

import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {Lesson} from '../../../data/models/Course/Lesson';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';
import {useParams} from 'react-router-dom';
import ScormStore from '../store/ScormStore';
import ScormPlayerUI from '../view/ScormPlayerUI';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';

interface Props {
  scormStore?: ScormStore;
}

const ScormPlayerContainer = inject('scormStore')(
  observer(({scormStore}: Props) => {
    const [lesson, setLesson] = useState<Lesson>();
    const [content, setContent] = useState<LessonContentList>();

    const {lessonId, lessonSecret} = useParams();

    useEffect(() => {
      if (lessonId && lessonSecret) {
        scormStore!.getScormLessonData(lessonSecret, lessonId).then(res => {
          setLesson(res?.lesson);
          setContent(res?.lessonContent);
        });
      }
    }, []);

    const onFinish = () => {
      parent.postMessage({type: 'finish'}, '*');
    };

    const onFinishLesson = async (progress: LearnerProgressModel) => {
      if (!lessonSecret || !lessonId) return;
      const result = await scormStore!.getScormForSCORM(
        lessonSecret,
        lessonId,
        progress,
      );
      parent.postMessage(
        {grade: result?.grade, passed: result?.passed, type: 'grade'},
        '*',
      );
    };

    const navigateToLesson = () => {
      //TODO
      // const publishedId = await ltiPlayerStore!.getPublishedLessonId(
      //   organizationId,
      //   navigateToLessonId,
      // );
      // if (publishedId) {
      //   navigate(`/course/${courseId}/lesson/${publishedId}`);
      // }
    };

    return content && lesson ? (
      <ScormPlayerUI
        isLoading={scormStore!.isLoading}
        lessonContent={content}
        onFinish={onFinish}
        passingScore={lesson.passingScore || 0}
        isGradable={lesson.isGradable || false}
        onFinishLesson={onFinishLesson}
        navigateToLesson={navigateToLesson}
      />
    ) : null;
  }),
);

export default ScormPlayerContainer;

import LibraryApi from '../api/LibraryAPI';
import {MEDIA_FILES_ALL} from '../models/LibraryFile/MediaFile';

class LibraryRepository {
  private libraryApi = new LibraryApi();

  public async uploadVideo(
    organizationId: string,
    video: File,
    duration: number,
  ) {
    const urlResult = await this.libraryApi.generateVideoUploadLink(
      organizationId,
      video.type,
      Math.floor(video.size / 1024 / 1024),
    );
    if (!urlResult.success || !urlResult.data) {
      return urlResult;
    }
    try {
      const uploadResult = await this.libraryApi.uploadFileToStorage(
        video,
        urlResult.data.uploadUrl,
      );
      if (uploadResult.ok) {
        const addFileResult = await this.libraryApi.createMediaLibraryFile(
          organizationId,
          urlResult.data.storageFileName,
          video.type,
          video.name,
          Math.floor(video.size / 1024 / 1024),
          duration,
        );
        return addFileResult;
      }
    } catch {
      return Promise.resolve({
        success: false,
        errors: ['unknown'],
      });
    }

    return urlResult;
  }

  public getMediaFiles(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    typeFilter?: number,
    search?: string,
    orderAsk?: boolean,
    origin?: number,
  ) {
    return this.libraryApi.getLibraryFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      typeFilter !== undefined ? typeFilter : MEDIA_FILES_ALL,
      search,
      orderAsk,
      origin,
    );
  }

  public getByContentId(organizationId: string, contentId: string) {
    return this.libraryApi.getFileByContentId(organizationId, contentId);
  }

  public deleteMediaFile(organizationId: string, mediaFileId: string) {
    return this.libraryApi.deleteMediaFile(organizationId, mediaFileId);
  }

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    return this.libraryApi.getVimeoVideos(organizationId, page, pageSize);
  }

  public async generateAiVideosFromSlides(
    organizationId: string,
    file: File,
    aiImgage: boolean,
    sliderValue: string,
    ignoreSlidesList: number[],
    type: number,
    voiceGender: string,
    language: string,
    selectedDialect: string,
  ) {
    const result = await this.libraryApi.generateAiVideosFromSlides(
      organizationId,
      file,
      aiImgage,
      sliderValue,
      ignoreSlidesList,
      type,
      voiceGender,
      language,
      selectedDialect,
    );
    return result;
  }
}

export default LibraryRepository;

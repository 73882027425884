import React from 'react';
import {backgroundClassSub} from '../../../assets/images';

interface Props {
  title: string;
  sections: {
    title?: string;
    features?: string[];
    green?: boolean;
  }[];
  onSelect: () => void;
  isSelected: boolean;
}

const GradableCard = React.memo(
  ({title, sections, isSelected, onSelect}: Props) => {
    return (
      <div
        className={`GradableCard ${isSelected ? 'GradableSelected' : ''}`}
        onClick={onSelect}
        style={{backgroundImage: `url(${backgroundClassSub})`}}>
        <p>{title}</p>
        {isSelected && (
          <div className="SelectedMarker">
            <span className="icon-typeCheck"></span>
          </div>
        )}
        {sections.map((section, index) => (
          <div key={index}>
            {section.title && (
              <span
                style={
                  section.green
                    ? {
                        color: 'var(--workspace-secondary-color-1)',
                        fontWeight: 'bold',
                      }
                    : {}
                }>
                {section.title}
              </span>
            )}
            {section.features && (
              <ul
                className="Features"
                style={section.title ? {paddingLeft: '20px'} : {}}>
                {section.features.map(feature => (
                  <li key={feature}>
                    {' '}
                    <span className="icon-typeCheck"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    );
  },
);

export default GradableCard;

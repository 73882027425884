import React, {useEffect} from 'react';
import UserMenuPopUpUI from '../view/UserMenuPopUpUI';
import UserMenuPopUpStore from '../store/UserMenuPopUpStore';
import {useNavigate} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import ModalProviderStore from '../../ModalProvider/store/ModalProviderStore';
import {LOGOUT_CONFIRMATION_MODAL} from '../../ModalProvider/globalModals';

interface Props {
  userMenuPopUpStore?: UserMenuPopUpStore;
  modalProviderStore?: ModalProviderStore;
  shown: boolean;
  onHide: () => void;
}

const UserMenuPopUpContainer = inject(
  'userMenuPopUpStore',
  'modalProviderStore',
)(
  observer(({userMenuPopUpStore, shown, modalProviderStore, onHide}: Props) => {
    const navigate = useNavigate();
    const [userName, setUserName] = React.useState('');
    const [email, setUserEmail] = React.useState('');

    useEffect(() => {
      document.body.addEventListener('click', onDocumentClick);
      getUserInfo();
      return () => {
        removeEventListener('click', onDocumentClick);
      };
    }, []);

    const getUserInfo = async () => {
      const userInfo = await userMenuPopUpStore?.getUserName();
      if (userInfo) {
        setUserName(userInfo.name);
        setUserEmail(userInfo.email);
      }
    };

    const onDocumentClick = () => {
      onHide();
    };

    const onLogout = async (confirmation?: boolean) => {
      if (confirmation === true) {
        await userMenuPopUpStore!.logout();
        navigate('/');
      }
    };

    const onStartLogout = () => {
      modalProviderStore?.openModal(LOGOUT_CONFIRMATION_MODAL, onLogout);
    };

    const onProfileClick = () => {
      navigate(`/profile/settings`);
    };

    return shown ? (
      <UserMenuPopUpUI
        userName={userName}
        email={email}
        onLogout={onStartLogout}
        onProfileClick={onProfileClick}
      />
    ) : null;
  }),
);

export default UserMenuPopUpContainer;

import {makeAutoObservable} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import AuthRepository from '../../../../data/repositories/AuthRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';

class ListCoursesStore {
  private coursesRepo = new CoursesRepository();
  private authRepo = new AuthRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getOrganizationCourses(organizationId: string) {
    this.loadingModule.startLoading();
    const result =
      await this.coursesRepo.getOrganizationCourses(organizationId);
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async deleteCourse(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.coursesRepo.deleteCourse(
      organizationId,
      courseId,
    );
    this.loadingModule.endLoading();
    return result.success;
  }

  public async getPermissions(organizationId: string): Promise<string[]> {
    this.loadingModule.startLoading();
    const result = await this.authRepo.getUserOrgPermissions(organizationId);
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default ListCoursesStore;

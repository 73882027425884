import React, {useState} from 'react';
import './styles.css';
import {addVideoSourceWizardText} from '../addVideoSourceWizardText';
import DataTable from 'react-data-table-component';
import UploadVideoButton from '../../../../uiToolkit/Inputs/UploadVideoButton';
import LibraryEmptyState from '../../../../uiToolkit/EmptyStates/LibraryEmptyState';
import {
  MEDIA_FILES_VIDEO,
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import '../../../../uiToolkit/DataTable/styles.css';
import TablePagination from '../../../../uiToolkit/TablePagination';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {logoTextWhite} from '../../../../assets/images';
import Button from '../../../../uiToolkit/Buttons/Button';

const columns = [
  {
    name: 'Filename',
    sortable: true,
    sortField: 'file_name',
    selector: (row: MediaFile) => row.fileName,
    cell: (row: MediaFile) => (
      <div className="DataTableImageNameContainer" data-tag="allowRowEvents">
        <h5 className="DataTableText" data-tag="allowRowEvents">
          {row.placeholderUrl ? (
            <img src={row.placeholderUrl} />
          ) : (
            <img src={logoTextWhite} className="LogoPLaceholder" />
          )}
          {row.fileName}
        </h5>
      </div>
    ),
    grow: 2,
  },
  {
    name: 'Type',
    cell: (row: MediaFile) => (
      <div className="DataTableImageNameContainer" data-tag="allowRowEvents">
        <h5 className="DataTableText" data-tag="allowRowEvents">
          {row.fileType}
        </h5>
      </div>
    ),
  },
  {
    name: 'Upload Date',
    sortable: true,
    sortField: 'created_date',
    selector: (row: MediaFile) => row.dateCreated.toDateString(),
    cell: (row: MediaFile) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {new Date(Date.parse(row.dateCreated.toString())).toLocaleDateString()}
      </h5>
    ),
  },
];

const vimeoColumns = [
  {
    name: 'Filename',
    sortable: true,
    sortField: 'file_name',
    selector: (row: VimeoMediaFile) => row.title,
    cell: (row: VimeoMediaFile) => (
      <div className="DataTableImageNameContainer" data-tag="allowRowEvents">
        <h5 className="DataTableText" data-tag="allowRowEvents">
          <img src={row.placeholderUrl} />
          {row.title}
        </h5>
      </div>
    ),
    grow: 2,
  },
];

interface Props {
  onVideoUpload: (video: File, duration: number) => void;
  isLoading?: boolean;
  files: MediaFile[];
  onFileSelect: (file: MediaFile) => void;
  onVimeoFileSelect: (file: VimeoMediaFile) => void;
  onCancel: () => void;
  pages: number;
  onPageChange: (page: number) => void;
  storageLimit: number;
  onSort: (selectedColumn: any, sortOrder: any) => void;
  vimeoFiles: VimeoMediaFile[];
}

const PickVideoStepUI = React.memo(
  ({
    onVideoUpload,
    isLoading,
    files,
    onFileSelect,
    onCancel,
    pages,
    onPageChange,
    storageLimit,
    onSort,
    vimeoFiles,
    onVimeoFileSelect,
  }: Props) => {
    const [selectedSource, setSelectedSource] = useState(0);

    return (
      <ModalContainer onClose={onCancel}>
        <div className="AddVideoSourceStepContent">
          <div className="Header">
            <h1>{addVideoSourceWizardText.pickVideoTitle}</h1>
            <div className="UploadContainer">
              <UploadVideoButton
                isLoading={isLoading}
                onVideoSelected={onVideoUpload}
                type={MEDIA_FILES_VIDEO}
                maxFileSize={storageLimit}
              />
              <span className="MaxFileSize">
                max file size is {storageLimit}mb
              </span>
            </div>
          </div>
          {vimeoFiles.length !== 0 ? (
            <div className="d-flex flex-row">
              <Button
                title="Tapybl Library"
                onClick={() => setSelectedSource(0)}
                uiType={selectedSource === 0 ? 'tab_active' : 'tab'}
              />
              <Button
                title="Vimeo"
                onClick={() => setSelectedSource(1)}
                uiType={selectedSource === 1 ? 'tab_active' : 'tab'}
              />
            </div>
          ) : null}
          {selectedSource === 0 && (
            <>
              <h3>{addVideoSourceWizardText.allVideos}</h3>
              <DataTable
                data={files}
                columns={columns}
                onRowClicked={(row: MediaFile) => onFileSelect(row)}
                highlightOnHover
                pagination
                paginationServer
                paginationComponent={TablePagination}
                pointerOnHover
                sortServer
                onSort={onSort}
                paginationTotalRows={pages * 10}
                onChangePage={onPageChange}
                noDataComponent={
                  <LibraryEmptyState
                    onVideoUpload={onVideoUpload}
                    isLoading={isLoading}
                    type={MEDIA_FILES_VIDEO}
                  />
                }
              />
            </>
          )}
          {selectedSource === 1 && (
            <>
              <h3>{addVideoSourceWizardText.vimeoVideos}</h3>
              <DataTable
                data={vimeoFiles}
                columns={vimeoColumns}
                onRowClicked={(row: VimeoMediaFile) => onVimeoFileSelect(row)}
                highlightOnHover
                pagination
                paginationServer
                paginationComponent={TablePagination}
                pointerOnHover
                paginationTotalRows={pages * 10}
                // onChangePage={onPageChange}
              />
            </>
          )}
        </div>
      </ModalContainer>
    );
  },
);

export default PickVideoStepUI;

export const loginText = {
  title: 'Login to your tapybl account',
  forgotPassword: 'forgot password?',
  submit: 'Sign In',
  dontHaveAccount: 'Don’t have an account? ',
  signup: 'Sign up',
  email: 'Email',
  password: 'Password',
  infoModalTitle: 'Interactive Video Learning.',
  infoModalText:
    'Catering specifically for education and CPD, tapybl is on a mission to uplift global learning outcomes through visually engaging and personalized interactive video learning.',
};

export const loginTextES = {
  title: 'Login to your tapybl account',
  forgotPassword: 'forgot password?',
  submit: 'Sign In',
  dontHaveAccount: 'Don’t have an account? ',
  signup: 'Sign up',
  email: 'Email',
  password: 'Password',
  infoModalTitle: 'Interactive Video Learning.',
  infoModalText:
    'Catering specifically for education and CPD, tapybl is on a mission to uplift global learning outcomes through visually engaging and personalized interactive video learning.',
};

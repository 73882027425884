import CoursesRepository from '../../../data/repositories/CoursesRepository';
import {makeAutoObservable} from 'mobx';

class CourseAccessGateStore {
  private courseRepository = new CoursesRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async checkCourseAccess(organizationId: string, courseId: string) {
    const result = await this.courseRepository.getCoursePreview(
      organizationId,
      courseId,
    );
    if (
      !result.success &&
      result.errors &&
      result.errors.length &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      result.errors[0]?.PermissionErrorType === 1
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return result.errors[0]?.MissingPermission;
    }
  }

  public async checkPublishedCourseAccess(courseId: string) {
    const result = await this.courseRepository.getCoursePresentation(courseId);
    if (
      !result.success &&
      result.errors &&
      result.errors.length &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      result.errors[0]?.PermissionErrorType === 1
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return result.errors[0]?.MissingPermission;
    }
  }
}

export default CourseAccessGateStore;

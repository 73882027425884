export const courseHomeText = {
  welcome: 'Welcome',
  inThisCourse: 'In this course',
  lessons: 'Lessons',
  minApprox: 'min. Approx.',
  minutes: 'Minutes',
  minute: 'Minute',
  courses: 'Courses',
  readyForIt: 'Get Started Today',
  daysLeft: (amountOfDays: number) =>
    `${amountOfDays === 1 ? 'Day' : 'Days'} left`,
  lessonsAmount: (amountOfLessons: number) =>
    `${amountOfLessons === 1 ? 'Lesson' : 'Lessons'}`,
  continue: 'Start Lesson',
  start: 'Enroll Now',
};

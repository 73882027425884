export interface SubscriptionPermissions {
  permissions: {
    [key: string]: boolean;
  };
  storageLimit: number;
  userQty: number;
}

export const GRADABLE_LESSONS_FEATURE = 'gradable_lessons';
export const AI_ASSISTANT_FEATURE = 'ai_assistant';

export const organizationHasFeature = (
  permissions: SubscriptionPermissions,
  feature: string,
) => {
  return permissions.permissions[feature];
};

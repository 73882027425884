import React from 'react';
import {appText} from '../../assets/text/appText';
import Button from '../Buttons/Button';
import './styles.css';

interface Props {
  title: string;
  onAccept: () => void;
  onCancel: () => void;
  shown: boolean;
  acceptText?: string;
}

const ConfirmationModal = React.memo(
  ({title, onAccept, onCancel, shown, acceptText}: Props) => {
    return shown ? (
      <div className="ConfirmationModal">
        <div className="ConfirmationModalContainer">
          <h2>{title}</h2>
          <div className="ButtonsContainer">
            <Button title={appText.cancel} onClick={onCancel} uiType="text" />
            <Button
              title={acceptText ? acceptText : appText.accept}
              onClick={onAccept}
              className="AcceptButton"
              uiType="action"
            />
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  },
);

export default ConfirmationModal;

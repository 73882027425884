import {makeAutoObservable} from 'mobx';
import UserRepository from '../../../../data/repositories/UserRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';

class SettingsUserProfileStore {
  private userRepository = new UserRepository();
  private loadingModule = new LoadingModule();
  public profileUpdated = false;

  constructor() {
    makeAutoObservable(this);
  }

  public setProfileUpdated(value: boolean) {
    this.profileUpdated = value;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getUserProfile() {
    const result = await this.userRepository.getUserInfo();
    if (result.success && result.data) {
      return result.data;
    }
    return null;
  }

  public async editUserProfile(fullName: string) {
    this.loadingModule.startLoading();
    const result = await this.userRepository.editUserName(fullName);
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      return false;
    }
    return true;
  }

  public async editUserAvatar(image: File) {
    this.loadingModule.startLoading();
    const result = await this.userRepository.uploadUserAvatar(image);
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      return false;
    }
    return true;
  }

  public async editUserPassword(
    password: string,
    newPassword: string,
    confirmNewPassword: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.userRepository.changeUserPassword(
      password,
      newPassword,
      confirmNewPassword,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      return false;
    }
    return true;
  }
}
export default SettingsUserProfileStore;

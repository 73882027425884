import {ApiResponse} from '../models/ApiResponse';
import {
  Lesson,
  LessonCreateModel,
  LessonIsPublishedModel,
  LessonShortModel,
} from '../models/Course/Lesson';
import {LessonPresentation} from '../models/Course/LessonPresentation';
import {
  CreateLearnerLessonModel,
  LearnerProgressModel,
} from '../models/LessonContent/CreateLearnerLessonModel';
import {LTILessonContent} from '../models/LessonContent/LTILessonContent';
import {LearnerProgress} from '../models/LessonContent/LessonLearner';
import {ProgressUpdateResponse} from '../models/LessonContent/ProgressUpdateResponse';
import {ScormGradeResult} from '../models/LessonContent/SCORMGradeResult';
import {apiClient, withAuth} from './baseApiClient';

class LessonsApi {
  public async createLesson(
    organizationId: string,
    lesson: LessonCreateModel,
  ): Promise<ApiResponse<Lesson>> {
    const headers = await withAuth({});
    return apiClient.post(`/lessons/${organizationId}/create`, lesson, {
      headers,
    });
  }

  public async getLessons(
    organizationId: string,
    folderId: string,
  ): Promise<ApiResponse<Lesson[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/lessons/${organizationId}/folder/${folderId}/list`, {
      headers,
    });
  }

  public async getAllCourseLessons(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<LessonShortModel[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/lessons/${organizationId}/${courseId}/all_lessons`, {
      headers,
    });
  }

  public async editLesson(
    organizationId: string,
    lesson: Lesson,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.put(`/lessons/${organizationId}/edit`, lesson, {headers});
  }

  public async deleteLesson(
    organizationId: string,
    lessonId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `/lessons/${organizationId}/delete?lessonId=${lessonId}`,
      {headers},
    );
  }

  public async reorderLessons(
    organizationId: string,
    folderId: string,
    lessons: Lesson[],
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.put(
      `/lessons/${organizationId}/folder/${folderId}/order`,
      lessons,
      {headers},
    );
  }

  public async uploadPoster(
    organizationId: string,
    lessonId: string,
    poster: File,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    const form = new FormData();
    form.append('poster', poster);
    form.append('lessonId', lessonId);
    return apiClient.post(`/lessons/${organizationId}/poster`, form, {headers});
  }

  public async getLessonById(
    organizationId: string,
    lessonId: string,
  ): Promise<ApiResponse<Lesson>> {
    const headers = await withAuth({});
    return apiClient.get(`/lessons/${organizationId}/get/${lessonId}`, {
      headers,
    });
  }

  public async getLessonIsPublishedById(
    organizationId: string,
    lessonId: string,
  ): Promise<ApiResponse<LessonIsPublishedModel>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/lessons/${organizationId}/get/${lessonId}/is_published`,
      {
        headers,
      },
    );
  }

  public async getLessonPresentation(
    courseId: string,
    lessonId: string,
  ): Promise<ApiResponse<LessonPresentation>> {
    const headers = await withAuth({});
    return apiClient.get(`/lessons/${courseId}/get/${lessonId}/presentation`, {
      headers,
    });
  }

  public async getLessonPreview(
    organizationId: string,
    courseId: string,
    lessonId: string,
  ): Promise<ApiResponse<LessonPresentation>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/lessons/${organizationId}/${courseId}/get/${lessonId}/preview`,
      {
        headers,
      },
    );
  }

  public async getLearnerProgress(
    courseId: string,
    lessonId: string,
  ): Promise<ApiResponse<LearnerProgress>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/lessons/course/${courseId}/lesson/${lessonId}/learnerLessonProgress`,
      {
        headers,
      },
    );
  }

  public async addLearnerLesson(
    courseId: string,
    model: CreateLearnerLessonModel,
  ) {
    const headers = await withAuth({});
    return apiClient.post(
      `/lessons/course/${courseId}/learnerLesson/add`,
      model,
      {
        headers,
      },
    );
  }

  public async editLearnerLessonProgress(
    courseId: string,
    lessonId: string,
    model: LearnerProgressModel,
  ): Promise<ApiResponse<ProgressUpdateResponse>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/lessons/course/${courseId}/lesson/${lessonId}/learnerLessonProgress/edit`,
      model,
      {
        headers,
      },
    );
  }

  public async getLessonDataFromSecret(
    lessonSecret: string,
  ): Promise<ApiResponse<LTILessonContent>> {
    return apiClient.get(`/lessons/lti/${lessonSecret}`);
  }

  public async getScormLessonData(
    lessonSecret: string,
    lessonId: string,
  ): Promise<ApiResponse<LTILessonContent>> {
    return apiClient.get(`/lessons/scorm/${lessonSecret}/${lessonId}`);
  }

  public async saveProgressWithSecret(
    lessonSecret: string,
    progress: LearnerProgressModel,
  ): Promise<ApiResponse<any>> {
    return apiClient.post(`/lessons/lti/set_grade/${lessonSecret}`, progress);
  }

  public async getScoreForSCORM(
    lessonSecret: string,
    lessonId: string,
    progress: LearnerProgressModel,
  ): Promise<ApiResponse<ScormGradeResult>> {
    return apiClient.post(
      `/lessons/scorm/${lessonSecret}/${lessonId}/score`,
      progress,
    );
  }
}

export default LessonsApi;

import React, {useEffect, useState} from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import {generateAIVideosText} from '../generateAIVideosText';
import Button from '../../../uiToolkit/Buttons/Button';
import './styles.css';
import Dropdown from '../../../uiToolkit/Inputs/Dropdown';
import RangeSlider from 'react-bootstrap-range-slider';
import YesNoOption from '../../../uiToolkit/Inputs/YesNoOption';
import {Row, Col} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import UploadPPTButton from '../../../uiToolkit/Inputs/UploadPPTButton';
import {parseStringArrayToInt} from '../../../utils/parseStringArrayToInt';
import {
  VOICE_GENDER,
  LANGUAGE,
  DIALECTS,
  VIDEO_LENGTH_OPTIONS,
} from '../../../data/models/QuestionGeneration/AIGenerationDropdownOptions';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../../assets/lottie';

interface Props {
  onSave: (
    file: File,
    aiImgage: boolean,
    sliderValue: string,
    ignoreSlidesList: number[],
    type: number,
    voiceGender: string,
    language: string,
    selectedDialect: string,
  ) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const ignoreSlidesRegex = /^(\d|,\s\d|\s\d-\d|,\d|,\s\d-\d|\d,|\d-\d)*$/gm;

const GenerateAIVideosUI = React.memo(
  ({onSave, onCancel, isLoading}: Props) => {
    const [ignoreSlidesList] = useState('');
    const [type, setType] = useState('');
    const [aiImage, setAiImage] = useState(true);
    const [sliderValue, setSliderValue] = useState('70');
    const [file, setFile] = useState<File>();
    const [isFormValid, setIsFormValid] = useState(false);
    const [voiceGender, setVoiceGender] = useState('female');
    const [language, setLanguage] = useState('en');
    const initialDialects = DIALECTS[language as keyof typeof DIALECTS].map(
      dialect => ({key: dialect, value: dialect}),
    );
    const [dialectOptions, setDialectOptions] =
      useState<{key: string; value: string}[]>(initialDialects);
    const [selectedDialect, setSelectedDialect] = useState<string>(
      initialDialects[0]?.key,
    );

    useEffect(() => {
      if (
        file !== undefined &&
        type.trim() !== '' &&
        ignoreSlidesRegex.test(ignoreSlidesList)
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }, [file, ignoreSlidesList, type]);

    useEffect(() => {
      const newDialects = DIALECTS[language as keyof typeof DIALECTS].map(
        dialect => ({key: dialect, value: dialect}),
      );
      setDialectOptions(newDialects);
      setSelectedDialect(newDialects[0]?.key);
    }, [language]);

    const onPPTSelected = (ppt: File) => {
      setFile(ppt);
    };

    const updateDialectOptions = (language: string) => {
      const dialects = DIALECTS[language as keyof typeof DIALECTS];
      const options = dialects.map(dialect => ({key: dialect, value: dialect}));
      setDialectOptions(options);
    };

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = Number(e.target.value);
      if (value < 0) value = 0;
      if (value > 100) value = 100;
      setSliderValue(value.toString());
    };

    return (
      <div className="AIGenerationContainer">
        <ModalContainer onClose={onCancel}>
          <div className="GenerateAIVideosContainer">
            <div className="Header">
              <h1>{generateAIVideosText.ModalHeader}</h1>
            </div>

            <UploadPPTButton
              onPPTSelected={onPPTSelected}
              title={generateAIVideosText.uploadPPTText}
              // subtitle={generateAIVideosText.uploadPPTSubtitle}
              isMandatory
            />

            {/* <TextInput
              value={ignoreSlidesList}
              onChange={setIgnoreSlidesList}
              heading={generateAIVideosText.slideIgnoreHeadingText}
              placeholder={generateAIVideosText.slideIgnorePlaceholderText}
              maxCharacters={150}
              isMandatory
            /> */}

            <Dropdown
              options={VIDEO_LENGTH_OPTIONS}
              selectedOption={type}
              placeholder={generateAIVideosText.selectType}
              setSelectedOption={setType}
              heading={generateAIVideosText.type}
              isMandatory
              className="DropdownContainers"
              subtitle={generateAIVideosText.durationSubtitle}
            />

            <div className="CheckboxContainer">
              <YesNoOption
                title={generateAIVideosText.checkboxText}
                value={aiImage}
                onSelect={setAiImage}
                className="DropdownContainers"
                subtitle={generateAIVideosText.aiImageSubtitleText}
              />

              {aiImage && (
                <div className="Slider">
                  <Form>
                    <Form.Group as={Row}>
                      <Form.Label>
                        {generateAIVideosText.sliderHeading}
                      </Form.Label>
                      <Form.Text>
                        {generateAIVideosText.sliderSubtitle}
                      </Form.Text>
                      <Col xs="9">
                        <RangeSlider
                          value={sliderValue}
                          onChange={handleSliderChange}
                          tooltipPlacement="top"
                          tooltip="on"
                          disabled={!aiImage}
                          min={0}
                          max={100}
                        />
                      </Col>
                      <Col xs="3">
                        <Form.Control
                          value={sliderValue}
                          onChange={handleSliderChange}
                          onKeyPress={event => {
                            const keyCode = event.keyCode || event.which;
                            const keyValue = String.fromCharCode(keyCode);
                            if (!/^\d+$/.test(keyValue)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              )}
            </div>

            <div className="DropdownContainers">
              <p className="StepHeading">
                {generateAIVideosText.narrationCustomization}
              </p>
              <p className="StepSubHeading">
                {generateAIVideosText.narrationCustomizationSubtitle}
              </p>
            </div>
            <div className="LanguageContainer">
              <Dropdown
                options={LANGUAGE}
                selectedOption={language}
                setSelectedOption={selectedLanguage => {
                  setLanguage(selectedLanguage);
                  updateDialectOptions(selectedLanguage);
                }}
                heading={generateAIVideosText.languageDropdownHeading}
                className="Dropdown me-4"
              />
              <Dropdown
                options={dialectOptions}
                selectedOption={selectedDialect}
                setSelectedOption={setSelectedDialect}
                heading={generateAIVideosText.dialectDropdownHeading}
                className="Dropdown"
              />
            </div>
            <Dropdown
              options={VOICE_GENDER}
              selectedOption={voiceGender}
              setSelectedOption={setVoiceGender}
              heading={generateAIVideosText.voiceGenderHeading}
              className="Dropdown"
            />
            <div className="Footer">
              <Button
                title={generateAIVideosText.cancel}
                onClick={onCancel}
                uiType="text"
              />

              <Button
                title={generateAIVideosText.save}
                onClick={() => {
                  if (isFormValid && file) {
                    onSave(
                      file,
                      aiImage,
                      sliderValue,
                      parseStringArrayToInt(ignoreSlidesList),
                      parseInt(type, 10),
                      voiceGender,
                      language,
                      selectedDialect,
                    );
                  }
                }}
                uiType="action"
                disabled={!isFormValid || isLoading}
              />
            </div>
            {isLoading ? (
              <div className="LoadingContainer">
                <Lottie
                  animationData={loadingLottie}
                  loop={true}
                  style={{width: '25px', height: '25px'}}
                />
                <p>{generateAIVideosText.loadingText}</p>
              </div>
            ) : null}
          </div>
        </ModalContainer>
      </div>
    );
  },
);

export default GenerateAIVideosUI;

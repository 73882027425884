import React from 'react';
import {checkGreenIcon, lessonIc, playCircleIcon} from '../../../assets/images';
import {Lesson} from '../../../data/models/Course/Lesson';

interface Props {
  item: Lesson;
  currentlyPlayingId: string;
  isLast: boolean;
  completed?: boolean;
  onClick: () => void;
}

const PlaylistItem = React.memo(
  ({item, currentlyPlayingId, isLast, completed, onClick}: Props) => {
    return (
      <>
        <div
          className={`ItemContainer ${
            currentlyPlayingId === item.id ? 'PlayingContainer' : ''
          } ${
            currentlyPlayingId !== item.id && !completed
              ? 'GreyContainer'
              : 'ClickableContainer'
          }`}
          onClick={completed ? onClick : undefined}>
          <div className="ItemTitleContainer">
            <h4 className="ItemTitleText">{item.title}</h4>
            {completed && item.id !== currentlyPlayingId && (
              <img src={checkGreenIcon} alt="done" />
            )}
            {item.id === currentlyPlayingId && (
              <img src={playCircleIcon} alt="done" />
            )}
          </div>
          <div className="ItemPlaceholderContainer">
            {item.poster ? (
              <img
                src={item.poster}
                alt="placeholder"
                className="ItemPlaceholderImage"
              />
            ) : (
              <img src={lessonIc} />
            )}
          </div>
        </div>
        {!isLast ? (
          <div className="ItemConnectorContainer">
            <div className="ItemConnector" />
          </div>
        ) : (
          <div />
        )}
      </>
    );
  },
);

export default PlaylistItem;

import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';
import {
  ACKNOWLEDGMENT_NODE,
  BRANCH_BACKGROUND_FREEFORM,
  BRANCH_IMAGE_FREEFORM,
  BRANCH_SHAPE_FREEFORM,
  BRANCH_TEXT_FREEFORM,
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_AI_CORRECT,
  CHALLENGE_BRANCH_AI_INCORRECT,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_IMAGE_OPTION,
  CHALLENGE_BRANCH_QUESTION,
  CHALLENGE_BRANCH_TEXT_OPTION,
  CHOICE_HEADER_NODE_TYPE,
  CHOICE_IMAGE_OPTION,
  CHOICE_TEXT_OPTION,
  CHOICE_TEXT_QUESTION,
  JUMP_TO_NODE,
  LessonContentAcknowledgment,
  LessonContentBase,
  LessonContentChallengeBranchAI,
  LessonContentChallengeBranchImageOption,
  LessonContentChallengeBranchQuestion,
  LessonContentChallengeBranchTextOption,
  LessonContentChoiceImageOption,
  LessonContentChoiceQuestion,
  LessonContentChoiceTextOption,
  LessonContentFreeformImageChoice,
  LessonContentFreeformShapeChoice,
  LessonContentFreeformTextChoice,
  LessonContentMChoice,
  LessonContentVideo,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';

export interface CommonNode {
  internalId: string;
  title?: string;
  imageUrl?: string;
  isCorrect?: boolean;
}

export const mapNode = (
  node: LessonContentBase,
  progress: LearnerProgressModel,
): CommonNode => {
  switch (node.ivNodeType) {
    case VIDEO_NODE_TYPE:
    case VIMEO_VIDEO_TYPE:
    case OVERLAY_VIDEO_TYPE:
    case OVERLAY_VIMEO_VIDEO_TYPE:
      return {
        internalId: node.internalId,
        title: (node as LessonContentVideo).title,
        imageUrl: (node as LessonContentVideo).placeholderUrl,
      };
    case CHOICE_HEADER_NODE_TYPE:
      return {
        internalId: node.internalId,
        title: 'Choice Branch',
      };
    case CHOICE_TEXT_QUESTION:
      return {
        internalId: node.internalId,
        title: (node as LessonContentChoiceQuestion).title,
      };
    case CHOICE_TEXT_OPTION:
      return {
        internalId: node.internalId,
        title: (node as LessonContentChoiceTextOption).title,
      };
    case CHALLENGE_BRANCH_IMAGE_OPTION:
      return {
        internalId: node.internalId,
        imageUrl: (node as LessonContentChallengeBranchImageOption).contentUrl,
        isCorrect: getFreeformIsCorrect(progress, node.internalId),
      };
    case CHOICE_IMAGE_OPTION:
      return {
        internalId: node.internalId,
        imageUrl: (node as LessonContentChoiceImageOption).contentUrl,
      };
    case MULTIPLE_CHOICE:
      return {
        internalId: node.internalId,
        title: (node as LessonContentMChoice).question,
        isCorrect: progress[node.internalId]
          ? progress[node.internalId].isCorrect
          : undefined,
      };
    case CHALLENGE_BRANCH_HEADER:
      return {
        internalId: node.internalId,
        title: 'Challenge',
      };
    case CHALLENGE_BRANCH_QUESTION:
      return {
        internalId: node.internalId,
        title: (node as LessonContentChallengeBranchQuestion).question,
      };
    case CHALLENGE_BRANCH_TEXT_OPTION:
      return {
        internalId: node.internalId,
        title: (node as LessonContentChallengeBranchTextOption).answer,
        isCorrect: getFreeformIsCorrect(progress, node.internalId),
      };
    case CHALLENGE_BRANCH_AI:
      return {
        internalId: node.internalId,
        title: (node as LessonContentChallengeBranchAI).question,
      };
    case CHALLENGE_BRANCH_AI_CORRECT:
      return {
        internalId: node.internalId,
        title: 'Correct',
      };
    case CHALLENGE_BRANCH_AI_INCORRECT:
      return {
        internalId: node.internalId,
        title: 'Incorrect',
      };
    case BRANCH_IMAGE_FREEFORM:
      return {
        internalId: node.internalId,
        imageUrl: (node as LessonContentFreeformImageChoice).contentUrl,
        title: (node as LessonContentFreeformImageChoice).title,
        isCorrect: getFreeformIsCorrect(progress, node.internalId),
      };
    case BRANCH_TEXT_FREEFORM:
      return {
        internalId: node.internalId,
        title: (node as LessonContentFreeformTextChoice).text,
        isCorrect: getFreeformIsCorrect(progress, node.internalId),
      };
    case BRANCH_SHAPE_FREEFORM:
      return {
        internalId: node.internalId,
        title: (node as LessonContentFreeformShapeChoice).title,
        isCorrect: getFreeformIsCorrect(progress, node.internalId),
      };
    case JUMP_TO_NODE:
      return {
        internalId: node.internalId,
        title: 'Jump To',
      };
    case BRANCH_BACKGROUND_FREEFORM:
      return {
        internalId: node.internalId,
        title: 'Background Option',
        isCorrect: getFreeformIsCorrect(progress, node.internalId),
      };
    case ACKNOWLEDGMENT_NODE:
      return {
        internalId: node.internalId,
        title: (node as LessonContentAcknowledgment).title,
      };
    default:
      return {
        internalId: node.internalId,
      };
  }
};

const getFreeformIsCorrect = (
  progress: LearnerProgressModel,
  choiceId: string,
) => {
  let isCorrect = undefined;
  Object.keys(progress).forEach(key => {
    if (
      progress[key].answers &&
      progress[key].answers.length &&
      progress[key].answers.includes(choiceId) &&
      progress[key].type !== 0
    ) {
      isCorrect = progress[key].isCorrect;
    }
  });
  return isCorrect;
};

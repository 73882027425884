import React from 'react';
import PageTitle from '../../../../uiToolkit/PageTitle';
import './styles.css';
import {Row, Col} from 'react-bootstrap';
import {ReactMultiEmail, isEmail} from 'react-multi-email';
import {learnersText} from '../../learnersText';
import Button from '../../../../uiToolkit/Buttons/Button';
import {appText} from '../../../../assets/text/appText';

interface Props {
  onSubmit: (learners: string[]) => void;
  onCancel: () => void;
  learners: string[];
  setLearners: (learners: string[]) => void;
  isLoading: boolean;
}

const CreateLearnersUI = React.memo(
  ({onSubmit, onCancel, learners, setLearners, isLoading}: Props) => {
    const buttonEnabled = () => learners.length > 0;

    const onSave = () => {
      onSubmit(learners);
    };

    return (
      <Row>
        <Col lg={8} xs={12}>
          <div className="AddLearnersContainer">
            <div className="HeaderContainer">
              <PageTitle
                title={learnersText.inviteLearnersPageTitle}
                subtitle={learnersText.inviteLearnersPageSubtitle}
              />
            </div>
            <div className="EmailInput">
              <h3>
                {learnersText.HeadingInviteLearnerInputField}{' '}
                <span className="TextBoxHeading MandatoryIndicator">*</span>
              </h3>
              <ReactMultiEmail
                emails={learners}
                onChange={(_emails: string[]) => {
                  setLearners(_emails);
                }}
                validateEmail={(email: string) => {
                  return isEmail(email);
                }}
                getLabel={(
                  email: string,
                  index: number,
                  removeEmail: (index: number) => void,
                ) => {
                  return (
                    <div className="EmailsSelected" data-tag key={index}>
                      {email}
                      <span
                        className="RemoveSelectedEmail"
                        data-tag-handle
                        onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
              <div className="EmailInstructionsDesign">
                <p>{learnersText.EmailInstructions}</p>
              </div>
            </div>
            <div className="Footer">
              <Button
                onClick={onCancel}
                title={learnersText.CancelButton}
                className={appText.cancel}
                uiType="text"
              />
              <Button
                onClick={onSave}
                title={learnersText.SubmitButton}
                disabled={!buttonEnabled()}
                isLoading={isLoading}
                uiType="action"
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  },
);

export default CreateLearnersUI;

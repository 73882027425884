import React, {useContext} from 'react';
import NodeHandles from './NodeHandles';
import {NodeData} from '../models';
import {LessonContentFreeformTextChoice} from '../../../data/models/LessonContent/LessonContentBase';
import {EditorContext} from '../container/LessonEditorContainer';
import Tooltip from '../../../uiToolkit/Tooltip';
import NodeReplace from './NodeReplace';

interface Props {
  data: NodeData;
}

const ChoiceFreeformText = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  const getNode = () => {
    return data.payload as LessonContentFreeformTextChoice;
  };

  return (
    <NodeHandles>
      <div className="EditorNodeContainer">
        {getNode().isCorrect !== null && (
          <div>
            {getNode().isCorrect ? (
              <span className="icon-typeCheck ChallengeCorrect"></span>
            ) : (
              <span className="icon-typeClose ChallengeIncorrect"></span>
            )}
          </div>
        )}
        <div
          className={getNode().isCorrect !== null ? 'ChoiceNodeReplace' : ''}>
          <NodeReplace currentNode={data} />
        </div>
        <div
          onClick={onClick}
          className={`EditorCard ${
            context.selectedNode?.internalId === data.payload.internalId
              ? 'SelectedEditorCard'
              : ''
          } ForceWidthCard`}>
          <Tooltip tooltipText={getNode().text} greenTooltip>
            <p className="ChoiceQuestionNode">{getNode().text}</p>
          </Tooltip>
        </div>
      </div>
    </NodeHandles>
  );
});

export default ChoiceFreeformText;

export const addAcknowledgmentWizardText = {
  title: 'Add Acknowledgment',
  titleInput: 'Acknowledgment text',
  placeholder:
    'Input your text that describes the terms and conditions of this acknowledgment node',
  buttonInput: 'Accept button title',
  buttonPlaceholder:
    'Set the title of the button i.e. Accept, OK, Acknowledge, Confirm, etc.',
  cancel: 'Cancel',
  save: 'Save',
};

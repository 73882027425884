import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import {LessonContentChoiceHeader} from '../../../../../data/models/LessonContent/LessonContentBase';
import LessonEditorStore from '../../../store/LessonEditorStore';
import {useParams} from 'react-router-dom';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';
import {lessonEditorText} from '../../../lessonEditorText';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import ChoiceHeaderOptionsUI from '../view/ChoiceHeaderOptionsUI';

interface Props {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentChoiceHeader;
  lessonEditorStore?: LessonEditorStore;
  onChangesMade: () => void;
  isPublished?: boolean;
  isChallenge?: boolean;
}

const ChoiceHeaderOptionsContainer = inject('lessonEditorStore')(
  observer(
    ({
      entityToEdit,
      lessonEditorStore,
      onChangesMade,
      isPublished,
      isChallenge,
    }: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(success => {
              if (success) {
                onChangesMade();
              } else {
                toast.error(getErrorFromCode(''), toastConfig);
              }
            });
        }
      };

      return (
        <>
          <ChoiceHeaderOptionsUI
            onDelete={() => setShowDelete(true)}
            isPublished={isPublished}
          />
          <ConfirmationModal
            title={
              isChallenge
                ? lessonEditorText.areYouSureDeleteChallengeHeaderOption
                : lessonEditorText.areYouSureDeleteChoiceHeaderOption
            }
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      );
    },
  ),
);

export default ChoiceHeaderOptionsContainer;

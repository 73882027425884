import React from 'react';
import {
  CREATE_ORGANIZATION_MODAL,
  LOGOUT_CONFIRMATION_MODAL,
} from '../globalModals';
import {CreateOrganization} from '../../CreateOrganization';
import ConfirmationModal from '../../../uiToolkit/ConfirmationModal';
import {appText} from '../../../assets/text/appText';

interface Props {
  activeModal?: string;
  onClose: (payload?: any) => void;
}

const ModalProviderUI = React.memo(({activeModal, onClose}: Props) => {
  if (activeModal === CREATE_ORGANIZATION_MODAL) {
    return <CreateOrganization isOpen={true} onClose={onClose} />;
  }
  if (activeModal === LOGOUT_CONFIRMATION_MODAL) {
    return (
      <ConfirmationModal
        title={appText.logoutConfirm}
        onCancel={onClose}
        acceptText={appText.logout}
        onAccept={() => onClose(true)}
        shown
      />
    );
  }
  return null;
});

export default ModalProviderUI;

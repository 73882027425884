// USE THIS FOR PLAYER and useLayout effect to compute the width. This has been done in Le

import React from 'react';
import ReactPlayer from 'react-player';
import {OnProgressProps} from 'react-player/base';
import VideoPlayerControls from '../VideoPlayerControls';

type PlayerUiTypes = 'standart' | 'box';

interface Props {
  width: number;
  source?: string;
  startTime?: number;
  endTime?: number;
  onDuration?: (duration: number) => void;
  onVideoProgress?: (time: number) => void;
  noLogo?: boolean;
  uiType?: PlayerUiTypes;
  hideControls?: boolean;
  dontLoop?: boolean;
}

const SimplePlayerControlled = React.memo(
  ({
    width,
    source,
    startTime,
    endTime,
    onDuration,
    noLogo,
    uiType,
    onVideoProgress,
    hideControls,
    dontLoop,
  }: Props) => {
    const [playing, setPlaying] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const playerRef = React.useRef<ReactPlayer>(null);

    const getBorderRadius = () => {
      switch (uiType) {
        case 'box':
          return '3px';
        default:
          return '20px';
      }
    };

    const onReady = (player: ReactPlayer) => {
      if (playerRef && playerRef.current) {
        const innerPlayer = player.getInternalPlayer();
        innerPlayer.style.borderRadius = getBorderRadius();
      }
    };

    const togglePlaying = () => {
      setPlaying(!playing);
    };

    const onProgress = (state: OnProgressProps) => {
      if (startTime !== undefined && endTime !== undefined) {
        if (state.playedSeconds < startTime || state.playedSeconds > endTime) {
          playerRef.current?.seekTo(startTime);
          setCurrentTime(startTime);
          if (onVideoProgress) {
            onVideoProgress(startTime);
          }
        } else {
          setCurrentTime(state.playedSeconds);
          if (onVideoProgress) {
            onVideoProgress(state.playedSeconds);
          }
        }
      } else {
        setCurrentTime(state.playedSeconds);
        if (onVideoProgress) {
          onVideoProgress(state.playedSeconds);
        }
      }
    };

    const onDurationCallback = (seconds: number) => {
      setDuration(seconds);
      if (onDuration) {
        onDuration(seconds);
      }
    };

    const seekTo = (time: number) => {
      playerRef.current?.seekTo(time);
      setCurrentTime(time);
    };

    return (
      <div>
        <ReactPlayer
          url={source}
          width={width}
          ref={playerRef}
          height={width / 1.77}
          playing={playing}
          onReady={onReady}
          onProgress={onProgress}
          onDuration={onDurationCallback}
          loop={dontLoop ? false : true}
          onError={e => {
            console.log(e);
          }}
        />
        {!hideControls && (
          <VideoPlayerControls
            playing={playing}
            duration={duration}
            togglePlaying={togglePlaying}
            currentTime={currentTime}
            seekTo={seekTo}
            setPlaying={setPlaying}
            noLogo={noLogo}
            slim={uiType === 'box'}
          />
        )}
      </div>
    );
  },
);

export default SimplePlayerControlled;

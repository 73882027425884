import React, {useState} from 'react';
import {
  gradableIc,
  gradableIconPlayer,
  infoPlayer,
} from '../../../../assets/images';
import './styles.css';
import {tapyblPlayerText} from '../../tapyblPlayerText';

interface Props {
  passingScore?: number;
  typeIcon: string;
  typeTitle: string;
  haveMultipleAnswers?: boolean;
}

const ChallengeInfoHeader = React.memo(
  ({passingScore, typeIcon, typeTitle, haveMultipleAnswers}: Props) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
      <div className="ChallengeInfoHeader">
        <div className="InfoRight">
          <div className="InfoBlock">
            <img src={gradableIconPlayer} />
          </div>
          <div className="InfoBlock">
            <span className="Percent">{Math.floor(passingScore || 0)} %</span>
          </div>
          <div className="InfoBlock InfoBlockText">
            <span className={`${typeIcon} TypeIndicator`}></span>
            {/* <img src={typeIcon} style={{marginRight: '5px'}} /> */}
            <span>{typeTitle}</span>
          </div>
          {haveMultipleAnswers && (
            <div className="InfoBlock InfoBlockText">
              <span>{tapyblPlayerText.selectAll}</span>
            </div>
          )}
        </div>
        <div className="InfoLeft" onClick={() => setShowInfo(!showInfo)}>
          <img src={infoPlayer} />
          {showInfo && (
            <div className="InfoModal">
              <div className="InfoBody">
                <div>
                  <img src={gradableIc} />
                  <span>{tapyblPlayerText.infoGradable}</span>
                </div>
                <div>
                  <span className="Percent">
                    {Math.floor(passingScore || 0)} %{' '}
                  </span>
                  <span>{tapyblPlayerText.infoPercent}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default ChallengeInfoHeader;

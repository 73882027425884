import React, {useEffect, useState} from 'react';
import {LessonContentChoiceQuestion} from '../../../../../data/models/LessonContent/LessonContentBase';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {lessonEditorText} from '../../../lessonEditorText';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';

interface Props {
  entityToEdit?: LessonContentChoiceQuestion;
  isLoading: boolean;
  onCancel: () => void;
  onEdit: (question: string) => void;
  onDelete: () => void;
  isPublished?: boolean;
}

const ChoiceQuestionOptionsUI = React.memo(
  ({entityToEdit, isLoading, onEdit, onDelete, isPublished}: Props) => {
    const [question, setQuestion] = useState('');

    useEffect(() => {
      setQuestion(entityToEdit?.title || '');
    }, [entityToEdit]);

    const changesWasMade = () => entityToEdit?.title !== question;

    const isValid = () => question.trim() !== '';

    const onSave = () => {
      if (isValid()) {
        onEdit(question);
      }
    };

    return (
      <div>
        <TextInput
          heading={lessonEditorText.question}
          value={question}
          onChange={setQuestion}
          placeholder={lessonEditorText.question}
          fieldError={question.trim() === ''}
          maxCharacters={150}
          isMandatory={true}
          uiType="box"
        />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          deleteTitle={lessonEditorText.delete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChoiceQuestionOptionsUI;

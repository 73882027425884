import React, {useEffect} from 'react';
import RoleDetailsUI from '../view/RoleDetailsUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import SettingsStore from '../../store/SettingsStore';

interface Props {
  settingsStore?: SettingsStore;
}

const RoleCreateContainer = inject('settingsStore')(
  observer(({settingsStore}: Props) => {
    const navigate = useNavigate();
    const {organizationId} = useParams();

    const onClose = () => {
      navigate(`/manage/${organizationId}/settings/roles`, {
        replace: true,
      });
    };

    useEffect(() => {
      return () => {
        settingsStore!.clearRoleSettingsErrors();
      };
    }, []);

    const onCreate = async (name: string, permissions: string[]) => {
      if (organizationId) {
        const success = await settingsStore!.createOrganizationRole(
          organizationId,
          name,
          permissions,
        );
        if (success) {
          onClose();
        }
      }
    };

    const clearErrors = () => {
      settingsStore!.clearRoleSettingsErrors();
    };

    return (
      <RoleDetailsUI
        onCancel={onClose}
        isLoading={settingsStore!.isLoading}
        onSave={onCreate}
        errors={settingsStore!.roleSettingsErrors}
        clearErrors={clearErrors}
      />
    );
  }),
);

export default RoleCreateContainer;

const getErrorFromCode = (code: string) => {
  switch (code) {
    case internalErrorCodes.INVALID_EMAIL:
      return 'Invalid Email.';
    case internalErrorCodes.INVALID_PASSWORD:
      return 'Invalid Password.';
    case internalErrorCodes.PROVIDE_USERNAME:
      return 'Username is required.';
    case internalErrorCodes.PROVIDE_VERIFICATION_CODE:
      return 'Verification code is required.';
    case internalErrorCodes.NEED_AT_LEAST_ONE_CORRECT_ANSWER:
      return 'At least one answer should be correct';
    case serverErrorCodes.PASSWORD_TOO_SHORT:
      return 'Password must be at least 8 characters.';
    case serverErrorCodes.PASSWORD_REQUIRES_NON_ALPHANUMERIC:
      return 'Password needs at least one special character.';
    case serverErrorCodes.PASSWORD_REQUIRED_LOWER:
      return 'Password needs at least one lowercase letter.';
    case serverErrorCodes.PASSWORD_REQUIRES_UPPER:
      return 'Password needs at least one uppercase letter.';
    case serverErrorCodes.PASSWORD_REQUIRES_DIGIT:
      return 'Password needs at least one number.';
    case serverErrorCodes.EMAIL_FORMAT_NOT_VALID:
      return 'Invalid email format.';
    case serverErrorCodes.EMAIL_ALREADY_TAKEN:
      return 'Email is already taken.';
    case serverErrorCodes.USERNAME_ALREADY_TAKEN:
      return 'Username is already taken.';
    case serverErrorCodes.INVALID_VERIFICATION_CODE:
      return 'Invalid verification code.';
    case serverErrorCodes.INVALID_AUTH_CREDENTIALS:
      return 'Looks like something is wrong with your username or password! Please try again!';
    case serverErrorCodes.ORG_NAME_ALREADY_TAKEN:
      return 'Workspace name is already taken.';
    case serverErrorCodes.USER_ALREADY_ORG_OWNER:
      return 'User is already a Workspace owner.';
    case serverErrorCodes.ORG_ROLE_NAME_TAKEN:
      return 'Workspace role name is already in use.';
    case serverErrorCodes.INVITATION_ALREADY_EXISTS:
      return 'Invitation already exists for this user.';
    case serverErrorCodes.USER_ALREADY_IN_ORG:
      return 'User is already a member of the other Workspace.';
    case serverErrorCodes.USER_ALREADY_IN_THIS_ORG:
      return 'User is already a member of this Workspace.';
    case serverErrorCodes.USER_ALREADY_A_LEARNER:
      return 'User is already a learner in the Workspace.';
    case serverErrorCodes.MAX_AMOUNT_OF_EMAILS_SENT:
      return "You've reached the maximum number of retries. Please wait for an hour and try again.";
    case serverErrorCodes.COURSE_NAME_TAKEN:
      return 'Course name taken';
    case serverErrorCodes.CANT_DELETE_LAST_FOLDER:
      return 'Course should contain at least 1 ';
    case serverErrorCodes.FILE_TOO_LARGE:
      return 'File size exceeds 100MB. Please upload a smaller file.';
    case serverErrorCodes.INVALID_OPERATION:
      return 'Invalid Operation';
    case serverErrorCodes.CANT_REPUBLISH_COURSE_WITH_ENROLLMENTS:
      return 'This course has active enrollments, please contact your admin to republish the course';
    case serverErrorCodes.MAX_FILES_SIZE_EXCEEDED:
      return 'Maximum storage size exceeded';
    case serverErrorCodes.CANT_MAKE_LESSON_NON_GRADABLE:
      return "This lesson have assessable content and can't be non-assessable";
    case internalErrorCodes.CANT_GENERATE_QUESTIONS:
      return "We couldn't generate questions for your video";
    case serverErrorCodes.ALREADY_HAVE_A_SUB:
      return 'You already have an active subscription';
    case serverErrorCodes.ALREADY_USED_TRIAL:
      return 'You already used trial period';
    case serverErrorCodes.LTI_ISSUER_ALREADY_REGISTERED:
      return 'This LTI Issuer already registered in your workspace';
    case serverErrorCodes.MAX_AMOUNT_OF_USERS_REACHED:
      return 'You are trying to invite users over the users quota';
    case serverErrorCodes.INCORRECT_USERS_AMOUNT:
      return 'Incorrect users amount';
    case serverErrorCodes.CANT_CHANGE_TO_SAME_TIER:
      return 'You cant change to the same tier';
    case serverErrorCodes.LESS_USERS_IN_SUB_THAN_YOU_HAVE:
      return 'You are trying to get fewer seats than you have users in the workspace';
    case serverErrorCodes.MAX_AMOUNT_OF_GENERATIONS_REACHED:
      return 'You have crossed the threshold of AI videos you can generate';
    case serverErrorCodes.AI_SERVER_OVRLOADED:
      return "Oops! Couldn't generate the video due to high demand. Please try again later.";
    default:
      return 'An unexpected error occurred.';
  }
};

const serverErrorCodes = {
  PASSWORD_TOO_SHORT: 'PasswordTooShort',
  PASSWORD_REQUIRES_NON_ALPHANUMERIC: 'PasswordRequiresNonAlphanumeric',
  PASSWORD_REQUIRED_LOWER: 'PasswordRequiresLower',
  PASSWORD_REQUIRES_UPPER: 'PasswordRequiresUpper',
  PASSWORD_REQUIRES_DIGIT: 'PasswordRequiresDigit',
  EMAIL_FORMAT_NOT_VALID: 'email_format_not_valid',
  EMAIL_ALREADY_TAKEN: 'user_with_this_email_already_exists',
  USERNAME_ALREADY_TAKEN: 'DuplicateUserName',
  INVALID_VERIFICATION_CODE: 'InvalidToken',
  INVALID_AUTH_CREDENTIALS: 'invalid_auth_credentials',
  USER_ALREADY_ORG_OWNER: 'user_already_org_owner',
  ORG_NAME_ALREADY_TAKEN: 'org_name_already_taken',
  ORG_ROLE_NAME_TAKEN: 'org_role_name_already_taken',
  INVITATION_ALREADY_EXISTS: 'invitation_already_exists',
  USER_ALREADY_IN_ORG: 'user_already_a_part_of_the_workspace',
  USER_ALREADY_IN_THIS_ORG: 'user_already_a_part_of_this_workspace',
  USER_ALREADY_A_LEARNER: 'user_already_a_learner_in_the_workspace',
  MAX_AMOUNT_OF_EMAILS_SENT: 'max_amount_of_emails_sent',
  COURSE_NAME_TAKEN: 'course_name_taken',
  CANT_DELETE_LAST_FOLDER: 'cant_delete_last_folder',
  FILE_TOO_LARGE: 'file_too_large',
  INVALID_OPERATION: 'invalid_operation',
  MAX_FILES_SIZE_EXCEEDED: 'max_file_sizes_exceeded',
  CANT_REPUBLISH_COURSE_WITH_ENROLLMENTS: 'cant_republish_course_if_not_admin',
  CANT_MAKE_LESSON_NON_GRADABLE: 'cant_make_lesson_non_gradable',
  ALREADY_HAVE_A_SUB: 'already_has_active_subscription',
  ALREADY_USED_TRIAL: 'already_used_trial',
  LTI_ISSUER_ALREADY_REGISTERED: 'platform_issuer_already_registered',
  MAX_AMOUNT_OF_USERS_REACHED: 'maximum_users_qty_reached',
  INCORRECT_USERS_AMOUNT: 'wrong_user_qty',
  CANT_CHANGE_TO_SAME_TIER: 'same_tier_not_allowed',
  LESS_USERS_IN_SUB_THAN_YOU_HAVE: 'less_then_current_qty',
  MAX_AMOUNT_OF_GENERATIONS_REACHED: 'no_available_ai_video_generation',
  AI_SERVER_OVRLOADED: 'server_overloaded',
};

const internalErrorCodes = {
  INVALID_EMAIL: 'provide_email_error',
  INVALID_PASSWORD: 'provide_password_error',
  PROVIDE_USERNAME: 'provide_username_error',
  PROVIDE_VERIFICATION_CODE: 'provide_verification_code',
  SOMETHING_WENT_WRONG: 'something_went_wrong',
  NEED_AT_LEAST_ONE_CORRECT_ANSWER: 'need_at_least_one_correct_answer',
  CANT_GENERATE_QUESTIONS: 'cant_generate_questions',
};

export {internalErrorCodes, getErrorFromCode};

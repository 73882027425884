import {ApiResponse} from '../models/ApiResponse';
import {
  Course,
  CourseInstructor,
  CourseListItem,
} from '../models/Course/Course';
import {CoursePresentation} from '../models/Course/CoursePresentation';
import {LearnerCourseProgress} from '../models/Course/LearnerCourseProgress';
import {Subject} from '../models/Course/LearningStandards';
import {SubjectListItem} from '../models/Course/Subject';
import {SidebarOptions} from '../models/UI/SidebarOption';
import {apiClient, withAuth} from './baseApiClient';

class CoursesApi {
  public async createCourse(
    model: Course,
    organizationId: string,
  ): Promise<ApiResponse<Course>> {
    const headers = await withAuth({});
    return apiClient.post(`/course/${organizationId}/create`, model, {
      headers,
    });
  }

  public async getLearningStandarts(
    organizationId: string,
  ): Promise<ApiResponse<Subject[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/course/${organizationId}/learning-standards`, {
      headers,
    });
  }

  public async getOrganizationCourses(
    organizationId: string,
  ): Promise<ApiResponse<CourseListItem[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/course/${organizationId}/list`, {headers});
  }

  public async getPublishedOrganizationCourses(
    organizationId: string,
  ): Promise<ApiResponse<CourseListItem[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/course/${organizationId}/published/list`, {headers});
  }

  public async getCourseInstructor(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<CourseInstructor>> {
    const headers = await withAuth({});
    return apiClient.get(`/course/${organizationId}/${courseId}/instructor`, {
      headers,
    });
  }

  public async uploadCourseAvatar(
    image: File,
    courseId: string,
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    const form = new FormData();
    form.append('image', image);
    return apiClient.post(`/course/${organizationId}/image/${courseId}`, form, {
      headers,
    });
  }

  public async editCourse(
    organizationId: string,
    course: Course,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.put(`/course/${organizationId}/edit`, course, {
      headers,
    });
  }

  public async deleteCourse(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(`/course/${organizationId}/delete/${courseId}`, {
      headers,
    });
  }

  public async getCourse(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<Course>> {
    const headers = await withAuth({});
    return apiClient.get(`/course/${organizationId}?courseId=${courseId}`, {
      headers,
    });
  }

  public async getCoursesList(): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.get(`/course/my`, {
      headers,
    });
  }

  public async getPublicCoursesList(
    subjectId?: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/course/public?page=0&pageSize=100${
        subjectId ? '&subjectId=' + subjectId : ''
      }`,
      {
        headers,
      },
    );
  }

  public async getCoursePresentation(
    courseId: string,
  ): Promise<ApiResponse<CoursePresentation>> {
    const headers = await withAuth({});
    return apiClient.get(`/CoursePresentation/${courseId}/presentation`, {
      headers,
    });
  }

  public async getCoursePreview(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<CoursePresentation>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/CoursePresentation/${organizationId}/${courseId}/preview`,
      {
        headers,
      },
    );
  }

  public async getLearnerCourseProgress(
    courseId: string,
  ): Promise<ApiResponse<LearnerCourseProgress>> {
    const headers = await withAuth({});
    return apiClient.get(`/course/${courseId}/my_progress`, {
      headers,
    });
  }

  public async selfEnrollInTheCourse(courseId: string) {
    const headers = await withAuth({});
    return apiClient.post(
      `/course/${courseId}/public/enroll`,
      {},
      {
        headers,
      },
    );
  }

  public async exportAsScorm(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<any>> {
    return apiClient.post(
      `/course/${organizationId}/${courseId}/scorm`,
      {},
      {
        responseType: 'blob',
      },
    );
  }

  public async getCourseSubjects(): Promise<ApiResponse<SubjectListItem[]>> {
    const headers = await withAuth({});
    return apiClient.get(`/subject`, {
      headers,
    });
  }

  public async publishCourse(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/course/${organizationId}/${courseId}/publish`,
      {},
      {
        headers,
      },
    );
  }

  public async rePublishCourse(
    organizationId: string,
    courseId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/course/${organizationId}/${courseId}/republish`,
      {},
      {
        headers,
      },
    );
  }

  public async getSidebarOptions(
    organizationId: string,
    search: string,
  ): Promise<ApiResponse<SidebarOptions>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/course/${organizationId}/courses_legend_list?search=${search}`,
      {
        headers,
      },
    );
  }
}

export default CoursesApi;

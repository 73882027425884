import React, {useContext, useEffect, useState} from 'react';
import {BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath} from 'reactflow';

import './buttonedge.css';
import {edgeAddIc} from '../../../assets/images';
import {EditorContext} from '../container/LessonEditorContainer';
import {
  JUMP_TO_NODE,
  getBaseNodes,
} from '../../../data/models/LessonContent/LessonContentBase';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';
import {getIconByType, getTooltipByType} from '../config';
import EditorTooltipContent from '../../../uiToolkit/EditorTooltopButton/EditorTooltipContent';

export default function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  ...props
}: EdgeProps) {
  const context = useContext(EditorContext);

  const [showTooltip, setShowTooltip] = useState(false);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  useEffect(() => {
    document.body.addEventListener('click', onDocumentClick);
    return () => {
      removeEventListener('click', onDocumentClick);
    };
  }, []);

  const onDocumentClick = () => {
    setShowTooltip(false);
  };

  const getParent = () => {
    const lessonContent: LessonContentList = context.lessonContent;
    return lessonContent.contentList.items[props.source]?.data;
  };

  const getChild = () => {
    return context.lessonContent.contentList.items[props.target]?.data;
  };

  const getActionsByParent = () => {
    let availableActions = getBaseNodes(
      context.isGradable,
      getParent()?.internalId !== undefined,
    );
    if (getChild()) {
      availableActions = availableActions.filter(a => a !== JUMP_TO_NODE);
    }
    return availableActions.map(item => ({
      icon: getIconByType(item),
      action: () => context.addNewNode(getParent()?.internalId, item),
      tooltip: getTooltipByType(item),
    }));
  };

  const onPlusClick = (e: any) => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
  };

  const onAction = (action: () => void) => {
    setShowTooltip(false);
    action();
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <>
          {!context.isPublished ? (
            <div
              style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                fontSize: 12,
                // everything inside EdgeLabelRenderer has no pointer events by default
                // if you have an interactive element, set pointer-events: all
                pointerEvents: 'all',
                zIndex: 3,
              }}
              className="nodrag nopan">
              <button className="edgebutton" onClick={onPlusClick}>
                <img src={edgeAddIc} />
              </button>
              <div className="EdgeTooltipContainer">
                {showTooltip && (
                  <EditorTooltipContent
                    actions={getActionsByParent()}
                    onAction={onAction}
                  />
                )}
              </div>
            </div>
          ) : null}
        </>
      </EdgeLabelRenderer>
    </>
  );
}

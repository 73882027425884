import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../data/repositories/AuthRepository';
import UserRepository from '../../../data/repositories/UserRepository';

class UserMenuPopUpStore {
  private authRepo = new AuthRepository();
  private userRepo = new UserRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async logout() {
    return await this.authRepo.logout();
  }

  public async getUserName() {
    const result = await this.userRepo.getUserInfo();
    if (result.success && result.data) {
      return {name: result.data.name, email: result.data.email};
    }
    return {name: '', email: ''};
  }
}

export default UserMenuPopUpStore;

import {getRoleNameFromRole} from '../../../data/staticValues/defaultRoleNames';
import {getArticleForWord} from '../../../utils/getArticleForWord';

export const notificationsText = {
  youHaveOneInv: 'You have one invitation from ',
  invitationTitle: (workspace: string, role: string) =>
    `You're invited by ${workspace} to become ${getArticleForWord(
      getRoleNameFromRole(role),
    )} ${getRoleNameFromRole(role)}`,
  accept: 'Accept',
  deny: 'Ignore',
  notifications: 'Notifications',
  viewAll: 'View All Notifications',
  noNotifications: 'No New Notifications',
  viewCourse: 'View course',
  markAsRead: 'Delete',
  viewWorkspace: 'View Workspace',
  newNotifications: 'Recent notifications',
  seenNotifications: 'Seen notifications',
};

import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../data/repositories/AuthRepository';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class ForgotPasswordStore {
  private authRepo = new AuthRepository();
  private loadingModule = new LoadingModule();

  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async sendPasswordReset(email: string) {
    this.loadingModule.startLoading();
    const result = await this.authRepo.sendResetPassword(email);
    this.loadingModule.endLoading();
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.setErrors(result.errors);
    }
    return false;
  }

  public async changePasswordWithCode(
    email: string,
    password: string,
    code: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.authRepo.changePasswordWithCode(
      email,
      password,
      code,
    );
    this.loadingModule.endLoading();
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.setErrors(result.errors);
    }
    return false;
  }

  public clearErrors() {
    this.errors = [];
  }

  private setErrors(errors: string[]) {
    this.errors = errors;
  }
}

export default ForgotPasswordStore;

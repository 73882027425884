import React from 'react';
import {courseEditTabs} from '../navConfig';
import NavigationTabs from '../../../../uiToolkit/NavigationTabs';
import {Outlet, useParams} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';
import Breadcrumbs from '../../../../uiToolkit/Breadcrumbs';
import './styles.css';
import {courseEditText} from '../../../../pages/CourseDetails/courseEditText';

interface Props {
  courseLink: string;
}

const CourseEditRootUI = React.memo(({courseLink}: Props) => {
  const [courseName, setCourseName] = React.useState('');
  const {organizationId, courseId} = useParams();
  return (
    <div>
      <Row className="CourseEditRootContainer">
        <Col xs={10} lg={{span: 10}}>
          <Breadcrumbs
            steps={[
              {title: courseEditText.CourseEditRootCourse, link: courseLink},
              {title: courseName},
            ]}
          />
          <NavigationTabs tabs={courseEditTabs(organizationId, courseId)} />
          <Row className="CourseEditRootRow">
            <Col lg={12} xs={12}>
              <Outlet context={[courseName, setCourseName]} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
});

export default CourseEditRootUI;

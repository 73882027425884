export const coursesListText = {
  title: 'Courses',
  create: 'Create new course',
  actions: 'Actions',
  deleteConfirm: 'Are you sure you want to delete this course?',
  edit: 'Edit Course Info',
  open: 'Course Editor',
  learners: 'View Learners',
  gradebook: 'Results',
  addLearners: 'Enroll Learners',
  gradebookListDisabled: 'Publish course to view results',
  learnersAddDisabled: 'Publish course to add learners',
  instructorAddDisabled: 'Publish course to add an instructor',
  cantDelete: "You can't delete published course with active enrollments",
  addInstructor: 'Assign Instructor',
  delete: 'Delete Course',
  WorkspaceTitle: 'Workspace',
  published: 'Published',
  yes: 'Yes',
  unpublished: 'Unpublished',
  no: 'No',
  public: 'Public',
  private: 'Private',
};

import React, {useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addJumpToWizardText} from '../addJumpToWizardText';
import './styles.css';
import Button from '../../../../uiToolkit/Buttons/Button';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import {SelectOption} from '../../../../data/models/UI/SelectOption';

interface Props {
  onCancel: () => void;
  onCreate: (option: string) => void;
  isLoading?: boolean;
  options: SelectOption[];
}

const AddJumpToWizardUI = React.memo(
  ({onCancel, onCreate, isLoading, options}: Props) => {
    const [jumpToId, setJumpToId] = useState('');

    const onSave = () => {
      if (jumpToId.trim() !== '') {
        onCreate(jumpToId);
      }
    };

    return (
      <ModalContainer onClose={onCancel}>
        <div className="AddChoiceTextOptionWizardContainer">
          <p className="Header">{addJumpToWizardText.title}</p>
          <Dropdown
            options={options}
            heading={addJumpToWizardText.lessonNodes}
            setSelectedOption={setJumpToId}
            type="box"
            selectedOption={jumpToId}
          />
          <div className="Footer">
            <Button
              title={addJumpToWizardText.cancel}
              onClick={onCancel}
              uiType="text"
            />
            <Button
              title={addJumpToWizardText.accept}
              onClick={onSave}
              isLoading={isLoading}
              disabled={!jumpToId}
              uiType="action"
            />
          </div>
        </div>
      </ModalContainer>
    );
  },
);

export default AddJumpToWizardUI;

import React from 'react';
import {Link} from 'react-router-dom';
import Tooltip from '../../../../uiToolkit/Tooltip';

interface Props {
  icon: any;
  title: string;
  route: string;
  activeIcon: any;
  activeRoute: string;
  createOption: boolean;
  userPermissions: string[];
  permission?: string;
  createPermission?: string;
  createOptionTooltip?: string;
  overrideCreateLink?: string;
}

const NavListItem = React.memo(
  ({
    title,
    route,
    activeIcon,
    activeRoute,
    createOption,
    userPermissions,
    permission,
    createPermission,
    createOptionTooltip,
    overrideCreateLink,
  }: Props) => {
    const checkPermission = (permission: string) => {
      return userPermissions.find(up => up === permission);
    };

    const showCreateOption = () => {
      if (!createOption || !createOptionTooltip) {
        return false;
      }

      return createPermission ? checkPermission(createPermission) : true;
    };

    if (permission && !checkPermission(permission)) {
      return <div />;
    }

    return (
      <div
        className={`NavListItemContainer ${
          route === activeRoute ? 'activeRouteLink' : ''
        }`}>
        <Link to={route} className={`MainRouteLink`}>
          <span className={`icon-type${activeIcon}`}></span>
          <h4 className={route === activeRoute ? 'Active' : ''}>{title}</h4>
        </Link>
        {showCreateOption() && createOptionTooltip ? (
          <Tooltip tooltipText={createOptionTooltip}>
            <Link
              to={overrideCreateLink ? overrideCreateLink : `${route}/create`}>
              <span className={`icon-typePlus-icon`}></span>
            </Link>
          </Tooltip>
        ) : (
          <div />
        )}
      </div>
    );
  },
);

export default NavListItem;

import React, {useRef, useState} from 'react';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import SimplePlayerControlled from '../../../../uiToolkit/SimplePlayerControlled';
import {addFreeformChoiceText} from '../addFreeformChoiceText';
import './styles.css';
import FreeformImageOptions from '../components/FreeformImageOptions';
import {
  BRANCH_IMAGE_FREEFORM,
  BRANCH_SHAPE_FREEFORM,
  BRANCH_TEXT_FREEFORM,
  LessonContentBase,
  LessonContentFreeformImageChoice,
  LessonContentFreeformShapeChoice,
  LessonContentFreeformTextChoice,
} from '../../../../data/models/LessonContent/LessonContentBase';
import EditorSvgCanvas from '../../FreeformElements/SvgCanvas';
import EditorSvgPath from '../../FreeformElements/EditorSvgPath';
import EditorSvgImage from '../../FreeformElements/EditorSvgImage';
import EditorSvgText, {
  DEFAULT_SVG_FONT_SIZE,
} from '../../FreeformElements/EditorSvgText';
import {
  addPointToOverlay,
  generateShapePoints,
  removePointFromOverlay,
} from '../../FreeformElements/EditorSvgPath/utils';
import Button from '../../../../uiToolkit/Buttons/Button';
import {
  Point,
  SvgImage,
} from '../../../../data/models/LessonContent/ContentFreeform';
import FreeformTextOptions from '../components/FreeformTextOptions';
import FreeformShapeOptions from '../components/FreeformShapeOptions';
import {
  scaleImageOptionsForDisplay,
  scaleShapeOptionsForDisplay,
  scaleTextOptionsForDisplay,
} from '../utlis';

interface Props {
  onClose: () => void;
  videoSource?: string;
  videoEndTime?: number;
  currentNode?:
    | LessonContentFreeformImageChoice
    | LessonContentFreeformShapeChoice
    | LessonContentFreeformTextChoice;
  saveChanges: (node: LessonContentBase) => void;
  siblingNodes: LessonContentBase[];
}

const AddFreeformChoiceUI = React.memo(
  ({
    onClose,
    videoSource,
    videoEndTime,
    currentNode,
    saveChanges,
    siblingNodes,
  }: Props) => {
    const [width, setWidth] = useState(0);
    const [activeNode, setActiveNode] = useState<
      | LessonContentFreeformImageChoice
      | LessonContentFreeformShapeChoice
      | LessonContentFreeformTextChoice
    >();
    const containerRef = useRef<any>(null);

    React.useEffect(() => {
      function handleResize() {
        if (containerRef && containerRef.current) {
          setWidth(containerRef.current.offsetWidth);
        }
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    React.useLayoutEffect(() => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    }, []);

    React.useEffect(() => {
      if (currentNode && width) {
        if (
          currentNode.ivNodeType === BRANCH_SHAPE_FREEFORM &&
          !(currentNode as LessonContentFreeformShapeChoice)?.overlayCoordinates
            ?.length
        ) {
          setActiveNode({
            ...currentNode,
            overlayCoordinates: generateShapePoints(width),
            encodedAtWidth: width,
          });
        } else if (currentNode.encodedAtWidth !== 0) {
          if (currentNode.ivNodeType === BRANCH_IMAGE_FREEFORM) {
            const imageNode = currentNode as LessonContentFreeformImageChoice;
            setActiveNode({
              ...imageNode,
              encodedAtWidth: width,
              ...scaleImageOptionsForDisplay(
                imageNode.encodedAtWidth,
                width,
                imageNode,
              ),
            });
          }
          if (currentNode.ivNodeType === BRANCH_TEXT_FREEFORM) {
            const textNode = currentNode as LessonContentFreeformTextChoice;
            setActiveNode({
              ...textNode,
              encodedAtWidth: width,
              ...scaleTextOptionsForDisplay(textNode.encodedAtWidth, width, {
                x: textNode.x,
                y: textNode.y,
              }),
            });
          }
          if (currentNode.ivNodeType === BRANCH_SHAPE_FREEFORM) {
            const shapeNode = currentNode as LessonContentFreeformShapeChoice;
            setActiveNode({
              ...shapeNode,
              encodedAtWidth: width,
              ...scaleShapeOptionsForDisplay(
                shapeNode.encodedAtWidth,
                width,
                shapeNode.overlayCoordinates,
              ),
            });
          }
        } else {
          setActiveNode({...currentNode, encodedAtWidth: width});
        }
      }
    }, [currentNode, width]);

    const onSave = () => {
      if (activeNode) {
        if (
          activeNode.ivNodeType === BRANCH_TEXT_FREEFORM &&
          !(activeNode as LessonContentFreeformTextChoice).fontSize
        ) {
          saveChanges({
            ...activeNode,
            fontSize: DEFAULT_SVG_FONT_SIZE,
          } as LessonContentFreeformTextChoice);
        } else {
          saveChanges(activeNode);
        }
      }
    };

    const onChangeImageField = (field: string, value: string) => {
      if (activeNode && activeNode.ivNodeType === BRANCH_IMAGE_FREEFORM) {
        const updatedNode: LessonContentFreeformImageChoice = {
          ...(activeNode as LessonContentFreeformImageChoice),
          [field]: value,
        };
        setActiveNode(updatedNode);
      }
    };

    const onChangeTextField = (field: string, value: any) => {
      if (activeNode && activeNode.ivNodeType === BRANCH_TEXT_FREEFORM) {
        const updatedNode: LessonContentFreeformImageChoice = {
          ...(activeNode as LessonContentFreeformImageChoice),
          [field]: value,
        };
        setActiveNode(updatedNode);
      }
    };

    const onChangeShapeField = (field: string, value: any) => {
      if (activeNode && activeNode.ivNodeType === BRANCH_SHAPE_FREEFORM) {
        const updatedNode: LessonContentFreeformShapeChoice = {
          ...(activeNode as LessonContentFreeformShapeChoice),
          [field]: value,
        };
        setActiveNode(updatedNode);
      }
    };

    const updateImageSvg = (options: SvgImage) => {
      if (activeNode && activeNode.ivNodeType === BRANCH_IMAGE_FREEFORM) {
        const updatedNode: LessonContentFreeformImageChoice = {
          ...(activeNode as LessonContentFreeformImageChoice),
          ...options,
        };
        setActiveNode(updatedNode);
      }
    };

    const updateTextSvg = (position: Point) => {
      if (activeNode && activeNode.ivNodeType === BRANCH_TEXT_FREEFORM) {
        const updatedNode: LessonContentFreeformTextChoice = {
          ...(activeNode as LessonContentFreeformTextChoice),
          ...position,
        };
        setActiveNode(updatedNode);
      }
    };

    const updateShapeSvg = (points: Point[]) => {
      if (activeNode && activeNode.ivNodeType === BRANCH_SHAPE_FREEFORM) {
        const updatedNode: LessonContentFreeformShapeChoice = {
          ...(activeNode as LessonContentFreeformShapeChoice),
          overlayCoordinates: [...points],
        };
        setActiveNode(updatedNode);
      }
    };

    const addShapePoint = () => {
      if (activeNode && activeNode.ivNodeType === BRANCH_SHAPE_FREEFORM) {
        const newPoints = addPointToOverlay(
          (activeNode as LessonContentFreeformShapeChoice).overlayCoordinates,
        );
        const updatedShape = {
          ...activeNode,
          overlayCoordinates: newPoints,
        };
        setActiveNode(updatedShape);
      }
    };

    const removeShapePoint = () => {
      if (activeNode && activeNode.ivNodeType === BRANCH_SHAPE_FREEFORM) {
        const newPoints = removePointFromOverlay(
          (activeNode as LessonContentFreeformShapeChoice).overlayCoordinates,
        );
        const updatedShape = {
          ...activeNode,
          overlayCoordinates: newPoints,
        };
        setActiveNode(updatedShape);
      }
    };

    const getCurrentNodeRender = () => {
      if (!activeNode) return <g></g>;
      if (activeNode.ivNodeType === BRANCH_IMAGE_FREEFORM) {
        const currentImageNode = activeNode as LessonContentFreeformImageChoice;
        return (
          <EditorSvgImage
            image={currentImageNode}
            aspect={currentImageNode.aspect}
            source={currentImageNode.contentUrl || ''}
            updateImageOptions={updateImageSvg}
          />
        );
      }
      if (activeNode.ivNodeType === BRANCH_TEXT_FREEFORM) {
        const currentTextNode = activeNode as LessonContentFreeformTextChoice;
        return (
          <EditorSvgText
            text={currentTextNode.text}
            position={currentTextNode}
            fontSize={currentTextNode.fontSize}
            color={currentTextNode.color}
            updateTextOptions={updateTextSvg}
          />
        );
      }
      if (activeNode.ivNodeType === BRANCH_SHAPE_FREEFORM) {
        const currentShapeNode = activeNode as LessonContentFreeformShapeChoice;
        return (
          <EditorSvgPath
            shapePoints={currentShapeNode.overlayCoordinates}
            updateShapePoints={updateShapeSvg}
          />
        );
      }
      return <g></g>;
    };

    const getSiblingNodesRenders = (): React.ReactElement[] => {
      return siblingNodes.map((node, index) => {
        if (node.ivNodeType === BRANCH_IMAGE_FREEFORM) {
          const imageNode = node as LessonContentFreeformImageChoice;
          return (
            <EditorSvgImage
              image={scaleImageOptionsForDisplay(
                imageNode.encodedAtWidth,
                width,
                imageNode,
              )}
              key={index}
              source={imageNode.contentUrl || ''}
              aspect={imageNode.aspect}
            />
          );
        }
        if (node.ivNodeType === BRANCH_TEXT_FREEFORM) {
          const textNode = node as LessonContentFreeformTextChoice;
          return (
            <EditorSvgText
              position={scaleTextOptionsForDisplay(
                textNode.encodedAtWidth,
                width,
                {x: textNode.x, y: textNode.y},
              )}
              text={textNode.text}
              fontSize={textNode.fontSize}
              color={textNode.color}
              key={index}
            />
          );
        }
        if (node.ivNodeType === BRANCH_SHAPE_FREEFORM) {
          const shapeNode = node as LessonContentFreeformShapeChoice;
          return (
            <EditorSvgPath
              shapePoints={scaleShapeOptionsForDisplay(
                shapeNode.encodedAtWidth,
                width,
                shapeNode.overlayCoordinates,
              )}
              key={index}
            />
          );
        }
        return <g key={index}></g>;
      });
    };

    return (
      <ModalContainer onClose={onClose}>
        <div className="AddFreeformOverlay">
          <div>
            <p className="SectionHeader">
              {addFreeformChoiceText.lastVideoFrame}
            </p>
            <div className="PlayerContainer" ref={containerRef}>
              <SimplePlayerControlled
                width={width}
                source={videoSource}
                startTime={videoEndTime}
                endTime={videoEndTime}
                hideControls
              />
              <EditorSvgCanvas width={width}>
                <g>
                  {getSiblingNodesRenders()}
                  {getCurrentNodeRender()}
                </g>
              </EditorSvgCanvas>
            </div>
            <div className="AddFreeformFooter">
              <Button
                uiType="hollow"
                onClick={onClose}
                title={addFreeformChoiceText.cancel}
              />
              <Button
                uiType="action"
                onClick={onSave}
                title={addFreeformChoiceText.save}
              />
            </div>
          </div>
          {activeNode && activeNode.ivNodeType === BRANCH_IMAGE_FREEFORM && (
            <FreeformImageOptions
              imageNode={activeNode as LessonContentFreeformImageChoice}
              onChangeImageField={onChangeImageField}
            />
          )}
          {activeNode && activeNode.ivNodeType === BRANCH_TEXT_FREEFORM && (
            <FreeformTextOptions
              textNode={activeNode as LessonContentFreeformTextChoice}
              onChangeTextField={onChangeTextField}
            />
          )}
          {activeNode && activeNode.ivNodeType === BRANCH_SHAPE_FREEFORM && (
            <FreeformShapeOptions
              shapeNode={activeNode as LessonContentFreeformShapeChoice}
              onChangeShapeField={onChangeShapeField}
              onAddShapePoint={addShapePoint}
              onRemoveShapePoint={removeShapePoint}
            />
          )}
        </div>
      </ModalContainer>
    );
  },
);

export default AddFreeformChoiceUI;

import {makeAutoObservable} from 'mobx';
import LtiRepository from '../../../../data/repositories/LtiRepository';
import {LtiPlatform} from '../../../../data/models/Lti/LtiIntegration';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';

class LtiSettingsStore {
  private ltiRepo = new LtiRepository();
  private subscriptionRepo = new SubscriptionRepository();
  public isLoading = false;
  public intialLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getOrganizationAvailableFeatures(organizationId: string) {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async generateKeys(organizationId: string) {
    this.isLoading = true;
    await this.ltiRepo.generateKeys(organizationId);
    this.isLoading = false;
  }

  public async getToolData(organizationId: string) {
    this.intialLoading = true;
    const result = await this.ltiRepo.getToolData(organizationId);
    this.intialLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getPlatforms(organizationId: string) {
    this.isLoading = true;
    const result = await this.ltiRepo.getPlatforms(organizationId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    } else {
      return [];
    }
  }

  public async editPlatform(
    organizationId: string,
    platformId: string,
    platform: LtiPlatform,
  ) {
    this.isLoading = true;
    const result = await this.ltiRepo.editPlatform(
      organizationId,
      platformId,
      platform,
    );
    this.isLoading = false;
    if (!result.success && result.errors && result.errors.length) {
      toast(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async addPlatform(organizationId: string, platform: LtiPlatform) {
    this.isLoading = true;
    const result = await this.ltiRepo.addPlatform(organizationId, platform);
    this.isLoading = false;
    if (!result.success && result.errors && result.errors.length) {
      toast(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async deletePlatform(organizationId: string, platformId: string) {
    this.isLoading = true;
    const result = await this.ltiRepo.deletePlatform(
      organizationId,
      platformId,
    );
    this.isLoading = false;
    if (!result.success && result.errors && result.errors.length) {
      toast(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }
}

export default LtiSettingsStore;

import {makeAutoObservable} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import {toast} from 'react-toastify';
import {CourseEdit} from '../../../../data/models/Course/CourseEdit';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
class GeneralCourseEditStore {
  private coursesRepo = new CoursesRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getCourse(organizationId: string, courseId: string) {
    this.isLoading = true;
    const course = await this.coursesRepo.getCourse(organizationId, courseId);
    this.isLoading = false;
    return course.data;
  }

  public async getCourseInstructor(organizationId: string, courseId: string) {
    const result = await this.coursesRepo.getCourseInstructor(
      organizationId,
      courseId,
    );
    return result.data;
  }

  public async saveCourse(
    organizationId: string,
    courseId: string,
    course: any,
    courseEdit: CourseEdit,
  ) {
    this.isLoading = true;
    if (
      course.title !== courseEdit.title ||
      course.description !== courseEdit.description
    ) {
      course.title = courseEdit.title;
      course.description = courseEdit.description;
      const result = await this.coursesRepo.editCourse(organizationId, course);
      if (!result.success && result.errors && result.errors.length) {
        toast.error(getErrorFromCode(result.errors[0]), toastConfig);
        this.isLoading = false;
        return false;
      }
    }

    if (courseEdit.image) {
      const imageUpdate = await this.coursesRepo.editCoursePoster(
        organizationId,
        courseId,
        courseEdit.image,
      );
      if (
        !imageUpdate.success &&
        imageUpdate.errors &&
        imageUpdate.errors.length
      ) {
        toast.error(getErrorFromCode(imageUpdate.errors[0]), toastConfig);
        this.isLoading = false;
        return false;
      }
    }
    this.isLoading = false;
    return true;
  }
}

export default GeneralCourseEditStore;

import React, {useEffect} from 'react';
import SignupUI from '../view/SignupUI';
import {useNavigate} from 'react-router-dom';
import SignupStore from '../store/SignupStore';
import {inject, observer} from 'mobx-react';

interface Props {
  signupStore?: SignupStore;
}

const SignupContainer = inject('signupStore')(
  observer(({signupStore}: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
      return () => {
        signupStore!.clearErrors();
      };
    }, []);

    const onSignUp = async (email: string, password: string, name: string) => {
      const result = await signupStore?.registerUser(email, password, name);
      if (result) {
        signupStore!.sendEmailVerification().then(() => {
          navigate('/auth/confirm_email');
        });
      }
    };

    const clearErorrs = () => {
      signupStore!.clearErrors();
    };

    return (
      <SignupUI
        onSignUp={onSignUp}
        errors={signupStore!.errors}
        isLoading={signupStore!.isLoading}
        clearErorrs={clearErorrs}
      />
    );
  }),
);

export default SignupContainer;

import React, {useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import NavBar from '../../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import './styles.css';
import {helpConfig, navConfig, contactConfig} from '../navConfig';
import NavListItem from '../components/NavListItem';
import {Organization} from '../../../../data/models/Oganization/Organization';
import QuotaLimitUI from '../components/QuotaLimitUI';
import Tooltip from '../../../../uiToolkit/Tooltip';
import MobileRestriction from '../../../../modules/MobileRestriction';
import {SubscriptionPermissions} from '../../../../data/models/Subscription/SubscriptionPermission';

interface Props {
  permissions: string[];
  organization?: Organization;
  organizationId?: string;
  storageUsed: number;
  hasSubscription?: boolean;
  subscriptionPermissions?: SubscriptionPermissions;
  usersCount: number;
}

const DashboardRootUI = React.memo(
  ({
    permissions,
    organizationId,
    organization,
    storageUsed,
    hasSubscription,
    subscriptionPermissions,
    usersCount,
  }: Props) => {
    const location = useLocation();
    const [navHeight, setNavHeight] = useState(0);

    return (
      <div className="DashboardRoot">
        <NavBar onHeightCalculated={setNavHeight} />
        <Container fluid>
          <Row>
            <Col
              lg={2}
              className="DashboardRootSideNav d-none d-lg-block"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <div className="NavItemsList">
                {organization && (
                  <Tooltip tooltipText={organization.name}>
                    <div className="Header">
                      {organization.avatar && (
                        <img src={organization.avatar} alt={'avatar'} />
                      )}
                      <span>{organization.name}</span>
                    </div>
                  </Tooltip>
                )}
                {navConfig(organizationId).map(item =>
                  !item.reqireSubscription ||
                  (item.reqireSubscription && hasSubscription) ? (
                    <NavListItem
                      {...item}
                      activeRoute={location.pathname}
                      key={item.title}
                      userPermissions={permissions}
                    />
                  ) : null,
                )}
              </div>
              {hasSubscription && (
                <QuotaLimitUI
                  storageUsed={storageUsed}
                  storageAvailable={subscriptionPermissions?.storageLimit}
                />
              )}
              {hasSubscription && (
                <QuotaLimitUI
                  storageUsed={usersCount}
                  storageAvailable={subscriptionPermissions?.userQty}
                  isUsersLimit
                />
              )}
              <div className="Divider" />
              <NavListItem
                {...helpConfig}
                activeRoute={location.pathname}
                key={helpConfig.title}
                userPermissions={permissions}
              />
              <NavListItem
                {...contactConfig}
                activeRoute={location.pathname}
                key={contactConfig.title}
                userPermissions={permissions}
              />
            </Col>
            <Col
              lg={10}
              xs={12}
              className="DashboardRootContent d-none d-lg-block px-4"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <Outlet />
            </Col>
            <Col
              xs={12}
              className="DashboardRootContent d-lg-none d-block px-4"
              style={{height: `calc(100vh - ${navHeight}px)`}}>
              <MobileRestriction />
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default DashboardRootUI;

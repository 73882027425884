export const orgDataFormText = {
  nameTitle: 'Workspace Name',
  namePlaceholder: 'Name',
  avatarTitle: 'Workspace Logo',
  avatarPlaceholder: 'Upload your workspace logo',
  primaryColorTitle: 'Workspace Primary color',
  primaryColorPlaceholder: '#011E1E',
  secondaryColorTitle: 'Workspace Secondary color',
  secondaryColorPlaceholder: '#B8EF36',
  description: 'Description',
  desscriptionPlaceholder: 'Description for your Workspace',
  cancel: 'Cancel',
  type: 'Workspace Type',
  selectType: 'Select workspace type',
};

import {makeAutoObservable} from 'mobx';
import UserRepository from '../../../data/repositories/UserRepository';
import CoursesRepository from '../../../data/repositories/CoursesRepository';

class CourseHomeStore {
  private userRepo = new UserRepository();
  private courseRepository = new CoursesRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getUserInfo() {
    this.isLoading = true;
    const result = await this.userRepo.getUserInfo();
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async selfEnroll(courseId: string) {
    this.isLoading = true;
    await this.courseRepository.selfEnroll(courseId);
    this.isLoading = false;
  }

  public async getLearnerCourseProgress(courseId: string) {
    this.isLoading = true;
    const result =
      await this.courseRepository.getLearnerCourseProgress(courseId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getCoursePresentation(courseId: string) {
    this.isLoading = true;
    const result = await this.courseRepository.getCoursePresentation(courseId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }
}

export default CourseHomeStore;

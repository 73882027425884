import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import {LessonContentVideoBackgorund} from '../../../../../data/models/LessonContent/LessonContentBase';
import LessonEditorStore from '../../../store/LessonEditorStore';
import MyLibraryModalStore from '../../../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import {lessonEditorText} from '../../../lessonEditorText';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';
import VideoBackgorundOptionsUI from '../view/VideoBackgroundOptionsUI';

interface Props {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentVideoBackgorund;
  lessonEditorStore?: LessonEditorStore;
  onChangesMade: () => void;
  myLibraryModalStore?: MyLibraryModalStore;
  isPublished?: boolean;
}

const VideoBackgroundOptionsContainer = inject(
  'lessonEditorStore',
  'addFreeformChoiceStore',
)(
  observer(
    ({entityToEdit, lessonEditorStore, isPublished, onChangesMade}: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onEdit = (isCorrect?: boolean | null) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              isCorrect,
            } as LessonContentVideoBackgorund)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(success => {
              if (success) {
                onChangesMade();
              } else {
                toast.error(getErrorFromCode(''), toastConfig);
              }
            });
        }
      };

      return entityToEdit ? (
        <>
          <VideoBackgorundOptionsUI
            isLoading={lessonEditorStore!.isLoading}
            onDelete={() => setShowDelete(true)}
            isPublished={isPublished}
            onSave={onEdit}
            videoBackground={entityToEdit}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteChoiceTextOption}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      ) : null;
    },
  ),
);

export default VideoBackgroundOptionsContainer;

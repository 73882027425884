export const appText = {
  authInfoModalTitle: 'Interactive Video Learning.',
  autnInfoModelBody:
    'Catering specifically for education and CPD, tapybl is on a mission to uplift global learning outcomes through visually engaging and personalized interactive video learning.',
  passwordValidationLength: 'at least 8 characters',
  passwordValidationNumber: 'at least 1 number',
  passwordValidationCase: 'at least 1 uppercase and 1 lowercase letter',
  passwordValidationCharacter: 'at least 1 special character ( # ? ! $ & @ )',
  upload: 'Upload',
  browse: 'Browse',
  dashboardSettingsTitle: 'Settings',
  cancel: 'Cancel',
  accept: 'Accept',
  active: 'Active',
  invitationSent: 'Invitation Sent',
  inactive: 'Inactive',
  createCoursePrompt: 'Create your own course and share it with anyone',
  createCourseAction: 'Create a course',
  noCourses: 'You don’t have a course yet',
  explore: 'Explore',
  help: 'Help',
  filter: 'Filter',
  logoutConfirm: 'Are you sure you want to log out?',
  invitationDeclined: 'Invitation Declined',
  logout: 'Logout',
  search: 'Search',
  back: 'Back',
  addIcon: 'Add icon',
  replaceIcon: 'Replace icon',
  nextAttempt: (time: number) =>
    `Code not received? Request a new code in ${time} seconds`,
  passwordMustContain: 'Password must contain:',
  noCoursesTitle: 'There are no courses within this workspace yet',
  noCoursesSubtitlte: 'Create your own course and share it with anyone',
  delete: 'Delete',
  yes: 'Yes',
  no: 'No',
  upgrade: 'Upgrade now!',
  noLibraryTitle: 'Welcome to your library!',
  noLibrarySubtitle: "Let's get started by uploading your files.",
  addAnswer: 'Add answer',
  max4Answers: 'up to 4 answers',
  welcomeTitle: 'Welcome Aboard!',
  welcomeSubtitle: 'Your account is good to go!',
  minimumCharacters: (minCharactes: number) =>
    `*minumum of ${minCharactes} characters`,
  startTime: 'Start time',
  saveChanges: 'Save Changes',
  save: 'Save',
  apply: 'Apply',
  close: 'Close',
  publishCourse: 'Publish Course',
  publishedCourse: 'Published Course',
  clearSearch: 'Clear Search',
  learners: 'Learners',
  generating: 'Generating your results',
  lessonAlreadyPublished: 'Cannot delete items from published lesson',
  copiedToClipboard: 'Copied to clipboard',
  exportAsScorm: 'Export as SCORM',
  deleteThisNode: 'Delete Selected Node Only',
  deleteThisNodeAndChildren: 'Delete Selected Node and Children',
};

import {makeAutoObservable} from 'mobx';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';
import LessonsRepository from '../../../data/repositories/LessonsRepository';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';

class ScormStore {
  private loadingModule = new LoadingModule();
  private lessonsRepo = new LessonsRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getScormLessonData(
    lessonSecret: string,
    publishedLessonId: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.lessonsRepo.getScormLessonData(
      lessonSecret,
      publishedLessonId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getScormForSCORM(
    lessonSecret: string,
    publishedLessonId: string,
    progress: LearnerProgressModel,
  ) {
    this.loadingModule.startLoading();
    const result = await this.lessonsRepo.getScoreForSCORM(
      lessonSecret,
      publishedLessonId,
      progress,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }
}

export default ScormStore;

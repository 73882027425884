import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import ChoiceImageOptionOptionsUI from '../view/ChallengeImageOptionOptionsUI';
import {LessonContentChallengeBranchImageOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import LessonEditorStore from '../../../store/LessonEditorStore';
import {
  MEDIA_FILES_IMAGE,
  MediaFile,
} from '../../../../../data/models/LibraryFile/MediaFile';
import MyLibraryModalStore from '../../../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import {lessonEditorText} from '../../../lessonEditorText';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';

interface Props {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentChallengeBranchImageOption;
  lessonEditorStore?: LessonEditorStore;
  onCancel: () => void;
  onChangesMade: () => void;
  myLibraryModalStore?: MyLibraryModalStore;
  isPublished?: boolean;
}

const ChallengeImageOptionOptionsContainer = inject(
  'lessonEditorStore',
  'myLibraryModalStore',
)(
  observer(
    ({
      entityToEdit,
      lessonEditorStore,
      myLibraryModalStore,
      onCancel,
      isPublished,
      onChangesMade,
    }: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const [newFile, setNewFile] = useState<MediaFile>();
      const {organizationId, lessonId} = useParams();

      const onOpenImagePicker = () => {
        myLibraryModalStore?.showLibrary(MEDIA_FILES_IMAGE, setNewFile);
      };

      const onEdit = (isCorrect: boolean) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              contentId: newFile
                ? newFile.storageFileName
                : entityToEdit.contentId,
              isCorrect,
            } as LessonContentChallengeBranchImageOption)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(success => {
              if (success) {
                onChangesMade();
              } else {
                toast.error(getErrorFromCode(''), toastConfig);
              }
            });
        }
      };

      return entityToEdit ? (
        <>
          <ChoiceImageOptionOptionsUI
            entitiyToEdit={entityToEdit}
            openImagePicker={onOpenImagePicker}
            newImage={newFile}
            isLoading={lessonEditorStore!.isLoading}
            onCancel={onCancel}
            onSave={onEdit}
            onDelete={() => setShowDelete(true)}
            isPublished={isPublished}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteChallengeImageOption}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      ) : null;
    },
  ),
);

export default ChallengeImageOptionOptionsContainer;

import React from 'react';
import './styles.css';

interface Props {
  icon?: any;
  title: string;
  subtitle?: string;
}

const PageTitle = React.memo(({icon, title, subtitle}: Props) => {
  return (
    <div>
      <div className="PageTitleContainer">
        {icon && <img src={icon} alt="icon" />}
        <h1>{title}</h1>
      </div>
      {subtitle && (
        <h5
          className={
            icon ? 'PageTitleSubtitle' : 'PageTitleSubtitleWithoutIcon'
          }>
          {subtitle}
        </h5>
      )}
    </div>
  );
});

export default PageTitle;

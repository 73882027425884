export const createCourseText = {
  title: 'New Course',
  subtitle:
    "Let's kick things off by adding some key details about your course!",
  titleTextPlaceholder: 'Give your course a name',
  titleTextTitle: 'Course Name',
  subjectSelectTitle: 'Course Category',
  subjectSelectPlaceholder: 'Select a category for this course',
  standartsSelectPlaceholder: 'Choose a standard',
  standartsSelectTitle: 'Standards (Optional)',
  descriptionTextPlaceholder: 'Describe your course',
  descriptionTextTitle: 'Course Description',
  gradeSelectPlaceholder: 'Select a score for this course',
  gradeSelectTitle: 'Score',
  iconPlaceholder: 'Upload a course icon',
  saveAndExit: 'Save and close',
  iconHeading: 'Course Icon',
  type: 'Assessable Course',
  typePlaceholder: 'Select a type for this course',
  publicListing: 'Allow public listing with self-enrollment',
  structureSelectPlaceholder: 'Select course structure',
  structureSelectTitle: 'Structure course according to:',
  sizePlaceholder: 'Select number',
  unitsTitle: 'Total number of units',
  weeksTitle: 'Total number of weeks',
  modulesTitle: 'Total number of modules',
  topicsTitle: 'Total number of topics',
  foldersTitle: 'Total number of folders',
  amountOfFolders: 'Select total number',
  saveDraft: 'Save draft',
  nextStep: 'Next Step',
  cancel: 'Cancel',
  wantGradable: 'Want assessable courses?',
  passingScore: 'Passing score',
};

import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import EnrollLearnersWizardUI from '../view/EnrollLearnersWizardUI';
import EnrollLearnersWizardStore from '../store/EnrollLearnersWizardStore';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

interface Props {
  enrollLearnersWizardStore?: EnrollLearnersWizardStore;
}

export const ENROLLMENT_LIST_PAGE_SIZE = 10;

const EnrollLearnersWizardContainer = inject('enrollLearnersWizardStore')(
  observer(({enrollLearnersWizardStore}: Props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(0);
    const {organizationId} = useParams();
    const [learnerData, setLearnerData] = React.useState<any[]>([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
      if (organizationId) {
        enrollLearnersWizardStore!
          .getOrganizationLearners(
            organizationId,
            currentPage - 1,
            ENROLLMENT_LIST_PAGE_SIZE,
            search,
          )
          .then(res => {
            setLearnerData(res.data || []);
            setPages(res.pagesCount);
          });
      }
    }, [enrollLearnersWizardStore?.isVisible(), currentPage, search]);

    const onCancel = () => {
      enrollLearnersWizardStore!.cancelWizard();
      resetData();
    };

    const onSave = async (selectedLearners: string[], dueDate?: Date) => {
      if (organizationId) {
        const success = await enrollLearnersWizardStore!.saveLearners(
          selectedLearners,
          organizationId,
          dueDate,
        );
        if (success) {
          resetData();
        }
      }
    };

    const resetData = () => {
      setCurrentPage(1);
      setLearnerData([]);
    };

    const onPageChange = (page: number) => {
      setCurrentPage(page);
    };

    return enrollLearnersWizardStore!.isVisible() ? (
      <EnrollLearnersWizardUI
        learnerData={learnerData}
        onCancel={onCancel}
        onSave={onSave}
        pages={pages}
        onPageChange={onPageChange}
        search={search}
        onSearchChange={setSearch}
      />
    ) : null;
  }),
);

export default EnrollLearnersWizardContainer;

export const selectSubscriptionText = {
  selectTitle:
    "Plans for Schools and Academic Institutions. Transform your educational experience with tapybl's innovative interactive approach to education.",
  license: 'License',
  tailorTitle: 'Customize Your Plan',
  selectSeats: 'Select the quantity of seats you require',
  seatsSelected: 'Seats selected',
  classroomSeatsText: 'Min. for this plan 30 seats and up to 299',
  schoolSeatsText: 'Min. for this plan 300 seats',
  selectGradableTitle: 'Select your plan below:',
  yourPrice: 'Your subscription price',
  classroomPlan: 'Classroom Plan',
  schoolPlan: 'School Plan',
  seats: 'Seats',
  gradableLessons: 'Assessable lessons',
  nonGradableLessons: 'Non Assessable lessons',
  subscribeNow: 'Subscribe now',
  upgradeNow: 'Upgrade now',
  startTrial: 'Start Trial Now',
  changingTier: 'Changing Tier',
  changingPlan: 'Upgrading your Plan',
  currectGradedOption: (currentOption: string) =>
    `You are currently subscribed to ${currentOption} the option`,
  changedToGradedOption: (selectedOption: string) =>
    `You are changing to the ${selectedOption} option`,
  gradable: 'Assessment-Based',
  nonGradable: 'Non-Assessment Based',
  currentSeats: (seats: number) => `You currently have ${seats} seats`,
  changedSeats: (seats: number) => `You are increasing to ${seats} seats`,
  priceDifference: 'Price difference:',
  supportChangeGraded: (isGraded: boolean) =>
    `Change to ${
      isGraded ? 'Assessment-Based' : 'Non-Assessment Based'
    } option`,
  addSeats: (seats: number) => `Add ${seats} seats`,
  removeSeats: (seats: number) => `Remove ${seats} seats`,
  noTaxes: '*Price shown excludes taxes',
  currentTier: (tier: string) =>
    `You are currently subscribed to the ${tier} tier with 30 users`,
  upgradingToTier: (tier: string) =>
    `You are upgrading to the ${tier} tier with 300 users`,
  upgradeSupport: (newTier: string, setsDiff: number) =>
    `Upgrade to ${newTier} tier (${setsDiff > 0 ? '-' : '+'}${setsDiff} users)`,
  school: 'School',
  classroom: 'Classroom',
};

import React from 'react';
import InputMask from 'react-input-mask';
import './styles.css';

const mask = [/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

interface Props {
  isValid: boolean;
  label: string;
  value: string;
  onChange: (value: string) => void;
  labelTop?: boolean;
  className?: string;
}

const TimeInput = React.memo(
  ({isValid, label, value, onChange, labelTop, className}: Props) => {
    return (
      <div
        className={`TimeInputContainer ${!isValid ? 'Invalid' : ''} ${
          className ? className : ''
        }`}>
        {labelTop && <p className="LabelTop">{label}</p>}
        <InputMask
          mask={mask}
          maskPlaceholder={'00:00:00'}
          onChange={e => onChange(e.target.value)}
          value={value}
        />
        {!labelTop && <p>{label}</p>}
      </div>
    );
  },
);

export default TimeInput;

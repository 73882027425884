import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {enrollLearnersWizardText} from '../enrollLearnersWizardText';
import './styles.css';
import {getDay} from 'date-fns';

interface Props {
  dueDate: Date | null;
  setDueDate: (date: Date | null) => void;
  className?: string;
}

const DatePickerComponent = React.memo(
  ({dueDate, setDueDate, className}: Props) => {
    const isWeekday = (date: Date) => {
      const day = getDay(date);
      return day !== 0 && day !== 6;
    };

    return (
      <div className={`DatePickerContainer ${className ? className : ''}`}>
        <h3>{enrollLearnersWizardText.DateHeading}</h3>
        <DatePicker
          selected={dueDate}
          onChange={date => setDueDate(date)}
          placeholderText={enrollLearnersWizardText.DatePlaceholder}
          dateFormat="dd MMMM, yyyy"
          className="DueDateBox"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          fixedHeight
          filterDate={isWeekday}
          minDate={new Date(Date.now())}
        />
      </div>
    );
  },
);

export default DatePickerComponent;

export const addVideoSourceWizardText = {
  pickVideoTitle: 'My Library',
  allVideos: 'All Videos',
  vimeoVideos: 'Vimeo Videos',
  cropTitle: 'Add a start and end for video',
  startTime: 'Start',
  endTime: 'End',
  useFullVideo: 'Use the full video instead',
  cancel: 'Cancel',
  save: 'Save',
};

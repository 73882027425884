import React, {useState} from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import Button from '../../../../../uiToolkit/Buttons/Button';
import {appText} from '../../../../../assets/text/appText';
import Tooltip from '../../../../../uiToolkit/Tooltip';
import './styles.css';
import {LessonContentFreeformTextChoice} from '../../../../../data/models/LessonContent/LessonContentBase';
import YesNoOption from '../../../../../uiToolkit/Inputs/YesNoOption';

interface Props {
  choiceText: LessonContentFreeformTextChoice;
  isLoading: boolean;
  onDelete: () => void;
  isPublished?: boolean;
  openEditOverlay: () => void;
  onSave: (isCorrect?: boolean | null) => void;
}

const FreeformTextOptionsUI = React.memo(
  ({
    isLoading,
    onDelete,
    isPublished,
    openEditOverlay,
    choiceText,
    onSave,
  }: Props) => {
    const [isCorrect, setIsCorrect] = useState(
      (choiceText as LessonContentFreeformTextChoice).isCorrect,
    );

    return (
      <div className="TextOptions">
        {isCorrect !== null && (
          <YesNoOption
            value={isCorrect}
            onSelect={setIsCorrect}
            title={lessonEditorText.isCorrect}
            uiType="box"
            className="mb-3"
          />
        )}
        {isCorrect !== null && (
          <Button
            title={lessonEditorText.save}
            onClick={() => onSave(isCorrect)}
            uiType="box"
            disabled={isCorrect === choiceText.isCorrect}
          />
        )}
        <Button
          title={lessonEditorText.editOverlay}
          onClick={openEditOverlay}
          uiType="box"
        />
        {isPublished ? (
          <Tooltip tooltipText={appText.lessonAlreadyPublished} greenTooltip>
            <div>
              <Button
                title={lessonEditorText.delete}
                onClick={onDelete}
                uiType="text_danger"
                isLoading={isLoading}
                disabled={isPublished}
              />
            </div>
          </Tooltip>
        ) : (
          <Button
            title={appText.deleteThisNodeAndChildren}
            onClick={onDelete}
            uiType="text_danger"
            isLoading={isLoading}
            disabled={isPublished}
          />
        )}
      </div>
    );
  },
);

export default FreeformTextOptionsUI;

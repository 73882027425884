import React, {useEffect, useState} from 'react';
import UserProfileRootUI from '../view/UserProfileRootUI';
import {inject, observer} from 'mobx-react';
import NavigationStore from '../../../store/NavigationStore';
import {UserInfo} from '../../../../data/models/User/UserInfo';
import SettingsUserProfileStore from '../../../../pages/UserProfile/SettingsUserProfile/store/SettingsUserProfileStore';

interface Props {
  navigationStore?: NavigationStore;
  settingsUserProfileStore?: SettingsUserProfileStore;
}

const UserProfileRootContainer = inject(
  'navigationStore',
  'settingsUserProfileStore',
)(
  observer(({navigationStore, settingsUserProfileStore}: Props) => {
    const [profileData, setProfileData] = useState<UserInfo>();

    useEffect(() => {
      navigationStore!.getUserInfo().then(data => {
        if (data) {
          setProfileData(data);
        }
      });
    }, [navigationStore]);

    useEffect(() => {
      if (settingsUserProfileStore!.profileUpdated) {
        navigationStore!.getUserInfo().then(data => {
          if (data) {
            setProfileData(data);
          }
        });
        settingsUserProfileStore!.setProfileUpdated(false);
      }
    }, [settingsUserProfileStore!.profileUpdated]);

    return <UserProfileRootUI profileData={profileData} />;
  }),
);

export default UserProfileRootContainer;

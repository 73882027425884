import React from 'react';
import {createOrgText} from '../createOrgText';
import ErrorBox from '../../../uiToolkit/ErrorBox';
import OrgDataForm from '../../../forms/OrgDataForm';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import './styles.css';

interface Props {
  onCancel: () => void;
  onCreateOrganization: (
    name: string,
    description: string,
    primaryColor: string,
    secondaryColor: string,
    type: number,
    image?: File,
  ) => void;
  isLoading: boolean;
  errors: string[];
  clearErrors: () => void;
}

const CreateOrganizationUI = React.memo(
  ({onCancel, onCreateOrganization, isLoading, errors, clearErrors}: Props) => {
    return (
      <ModalContainer onClose={onCancel}>
        <div className="CreateOrganizationContainer">
          <div className="Header">
            <h3>{createOrgText.title}</h3>
          </div>
          <ErrorBox
            errorCodes={errors}
            className="ErrorBox"
            clearErrors={clearErrors}
          />
          <OrgDataForm
            onSubmit={onCreateOrganization}
            onCancel={onCancel}
            submitText={createOrgText.create}
            isLoading={isLoading}
            skipColors
          />
        </div>
      </ModalContainer>
    );
  },
);

export default CreateOrganizationUI;

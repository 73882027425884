import {SelectOption} from '../UI/SelectOption';
import {
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_QUESTION,
  CHOICE_TEXT_QUESTION,
  LessonContentBase,
  LessonContentChallengeBranchAI,
  LessonContentChallengeBranchQuestion,
  LessonContentChoiceQuestion,
  LessonContentMChoice,
  LessonContentVideo,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from './LessonContentBase';

export interface LessonContentList {
  contentList: {
    rootId: string;
    items: {
      [key: string]: LessonContentItem;
    };
  };
  id: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface LessonContentItem {
  data: LessonContentBase;
  parentsIds: string[];
  childIds: string[];
}

export const getListOfItemsFromContentList = (list?: LessonContentList) => {
  const result: SelectOption[] = [];
  if (!list) {
    return result;
  }
  Object.keys(list.contentList.items).map(key => {
    const item = list.contentList.items[key];
    const option = getLessonContentItemOption(item);
    if (option) {
      result.push(option);
    }
  });
  return result;
};

export const getLessonContentItemTitle = (
  itemId: string,
  lessonContent: LessonContentList,
) => {
  if (!lessonContent) return '';
  const item = lessonContent.contentList.items[itemId];
  const option = getLessonContentItemOption(item);
  if (option) {
    return option.value;
  }
  return '';
};

const getLessonContentItemOption = (item: LessonContentItem) => {
  if (!item || !item.data) return undefined;
  switch (item.data.ivNodeType) {
    case VIMEO_VIDEO_TYPE:
    case OVERLAY_VIDEO_TYPE:
    case OVERLAY_VIMEO_VIDEO_TYPE:
    case VIDEO_NODE_TYPE: {
      return {
        key: item.data.internalId,
        value: (item.data as LessonContentVideo).title,
      };
    }
    case CHOICE_TEXT_QUESTION: {
      return {
        key: item.data.internalId,
        value: (item.data as LessonContentChoiceQuestion).title,
      };
    }
    case MULTIPLE_CHOICE: {
      return {
        key: item.data.internalId,
        value: (item.data as LessonContentMChoice).question,
      };
    }
    case CHALLENGE_BRANCH_QUESTION: {
      return {
        key: item.data.internalId,
        value: (item.data as LessonContentChallengeBranchQuestion).question,
      };
    }
    case CHALLENGE_BRANCH_AI: {
      return {
        key: item.data.internalId,
        value: (item.data as LessonContentChallengeBranchAI).question,
      };
    }
  }
};

import {redirect} from 'react-router-dom';
import AuthRepository from '../data/repositories/AuthRepository';
import SubscriptionRepository from '../data/repositories/SubscriptionRepository';
import OrganizationRepository from '../data/repositories/OrganizationRepository';
import {APP_PERMISSIONS} from '../data/permissions';
import {ORGANIZATION_USER_LEARNER} from '../data/models/Oganization/Organization';

const landingLoader = async () => {
  const repo = new AuthRepository();
  const user = repo.getUser();
  if (user) {
    throw redirect('/home');
  }
  return {user};
};

const rootLoader = async () => {
  const repo = new AuthRepository();
  const user = repo.getUser();
  if (user) {
    throw redirect('/home');
  } else {
    throw redirect('/auth/login');
  }
  return {user};
};

const secureRouteLoader = () => {
  const repo = new AuthRepository();
  const user = repo.getUser();
  if (!user) {
    throw redirect('/auth/login');
  }
  if (!user.emailConfirmed) {
    throw redirect('/auth/confirm_email');
  }
  return {user};
};

const exploreRouteLoader = async () => {
  const repo = new AuthRepository();
  const orgRepo = new OrganizationRepository();
  const user = repo.getUser();
  if (!user) {
    throw redirect('/auth/login');
  }
  if (!user.emailConfirmed) {
    throw redirect('/auth/confirm_email');
  }
  const myOrg = await orgRepo.getMyOrganization();
  if (
    myOrg.success &&
    myOrg.data &&
    myOrg.data.userType === ORGANIZATION_USER_LEARNER
  ) {
    throw redirect('/home');
  }
  return {user};
};

const workspaceRouteLoader = () => async () => {
  const repo = new AuthRepository();
  const orgRepo = new OrganizationRepository();
  const user = repo.getUser();
  if (!user) {
    throw redirect('/auth/login');
  }
  if (!user.emailConfirmed) {
    throw redirect('/auth/confirm_email');
  }
  const organizationDetails = await orgRepo.getMyOrganization();
  if (!organizationDetails.success || !organizationDetails.data) {
    throw redirect('/home');
  }
  if (
    organizationDetails.data &&
    organizationDetails.data.userType === ORGANIZATION_USER_LEARNER
  ) {
    throw redirect('/home');
  }
  return {user};
};

const createSubscriptionLoader =
  () =>
  async ({params}: any) => {
    const repo = new AuthRepository();
    const permissionsRepo = new SubscriptionRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }
    const hasPermission = await repo.verifyUserPermission(
      APP_PERMISSIONS.ORG_MANAGE_SETTINGS,
      params.organizationId,
    );
    if (!hasPermission) {
      throw redirect(`/manage/${params.organizationId}/workspace`);
    }
    const hasSubscription = await permissionsRepo.getSubscriptionPermissions(
      params.organizationId,
    );
    if (hasSubscription.success) {
      throw redirect(`/manage/${params.organizationId}/workspace`);
    }
    return {user};
  };

const permissionRouteLoader =
  (permission: string) =>
  async ({params}: any) => {
    const repo = new AuthRepository();
    const permissionsRepo = new SubscriptionRepository();
    const user = repo.getUser();
    if (!user) {
      throw redirect('/auth/login');
    }
    if (!user.emailConfirmed) {
      throw redirect('/auth/confirm_email');
    }
    const hasPermission = await repo.verifyUserPermission(
      permission,
      params.organizationId,
    );
    if (!hasPermission) {
      throw redirect('/permission_error');
    }
    const hasSubscription = await permissionsRepo.getOrganizationSubscription(
      params.organizationId,
    );
    if (!hasSubscription.success) {
      throw redirect(`/manage/${params.organizationId}/workspace`);
    }
    return {user};
  };

const confirmEmailLoader = () => {
  const repo = new AuthRepository();
  const user = repo.getUser();
  if (!user) {
    throw redirect('/auth/login');
  } else if (user && user.emailConfirmed) {
    throw redirect('/home');
  }
  return {user};
};

export {
  landingLoader,
  secureRouteLoader,
  confirmEmailLoader,
  permissionRouteLoader,
  rootLoader,
  workspaceRouteLoader,
  createSubscriptionLoader,
  exploreRouteLoader,
};

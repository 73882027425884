import React, {useEffect, useState} from 'react';
import GradebookUI from '../view/GradebookUI';
import {inject, observer} from 'mobx-react';
import GradebookStore from '../store/GradebookStore';
import {useParams} from 'react-router-dom';
import {
  GradebookGrades,
  GradebookLearner,
  GradebookLesson,
} from '../../../data/models/Course/Gradebook';
import LearningJourneyStore from '../../../modules/LearningJourney/store/LearningJourneyStore';
import {LearningJourney} from '../../../modules/LearningJourney';

interface Props {
  gradebookStore?: GradebookStore;
  learningJourneyStore?: LearningJourneyStore;
}

const PAGE_SIZE = 9;

const GradebookContainer = inject(
  'gradebookStore',
  'learningJourneyStore',
)(
  observer(({gradebookStore, learningJourneyStore}: Props) => {
    const [search, setSearch] = useState('');
    const {courseId, organizationId} = useParams();
    const [learners, setLearners] = useState<GradebookLearner[]>([]);
    const [lessons, setLessons] = useState<GradebookLesson[]>([]);
    const [grades, setGrades] = useState<GradebookGrades>({});
    const [pagesCount, setPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
      if (organizationId && courseId) {
        gradebookStore!
          .getGradebook(
            organizationId,
            courseId,
            currentPage - 1,
            PAGE_SIZE,
            search,
          )
          .then(res => {
            setLearners(res.learners);
            setLessons(res.lessons);
            setGrades(res.grades);
            setPagesCount(res.pagesCount);
          });
      }
    }, [currentPage, search]);

    const onOpenLearningJourney = (
      learnerId: string,
      lessonId: string,
      courseId: string,
    ) => {
      learningJourneyStore!.openJourney(learnerId, lessonId, courseId);
    };

    return (
      <>
        <GradebookUI
          search={search}
          setSearch={setSearch}
          learners={learners}
          lessons={lessons}
          grades={grades}
          onChangePage={setCurrentPage}
          pagesCount={pagesCount}
          itemsPerPage={PAGE_SIZE}
          currentPage={currentPage}
          onOpenLearningJourney={onOpenLearningJourney}
        />
        <LearningJourney />
      </>
    );
  }),
);

export default GradebookContainer;

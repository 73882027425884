import React from 'react';
import {selectSubscriptionText} from '../selectSubscriptionText';
import Button from '../../../uiToolkit/Buttons/Button';

interface Props {
  title: string;
  description: string;
  features: string[];
  priceInfo: string;
  priceSubtitle: string;
  gradableInfo: string;
  buttonText: string;
  onClick: () => void;
  backgorund: string;
}

const SubscriptionPlan = React.memo(
  ({
    title,
    description,
    features,
    priceInfo,
    priceSubtitle,
    gradableInfo,
    buttonText,
    onClick,
    backgorund,
  }: Props) => {
    return (
      <div
        className="SubscriptionPlan"
        style={{backgroundImage: `url(${backgorund})`}}>
        <h2>{title}</h2>
        <p>{selectSubscriptionText.license}</p>
        <span>{description}</span>
        <ul className="Features">
          {features.map(item => (
            <li key={item}>
              <span className="icon-typeCheck"></span>
              {item}
            </li>
          ))}
        </ul>
        <h3>{priceInfo}</h3>
        <h4>{priceSubtitle}</h4>
        <h5>{gradableInfo}</h5>
        <Button
          title={buttonText}
          onClick={onClick}
          className="SelectSubButton"
        />
      </div>
    );
  },
);

export default SubscriptionPlan;

import React from 'react';
import {addFreeformChoiceText} from '../addFreeformChoiceText';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import {LessonContentFreeformImageChoice} from '../../../../data/models/LessonContent/LessonContentBase';

interface Props {
  imageNode: LessonContentFreeformImageChoice;
  onChangeImageField: (field: string, value: string) => void;
}

const FreeformImageOptions = React.memo(
  ({imageNode, onChangeImageField}: Props) => {
    return (
      <div className="OptionsContainer">
        <p className="SectionHeader">{addFreeformChoiceText.options}</p>
        <div className="OptionsContent">
          <TextInput
            value={imageNode.title}
            heading={addFreeformChoiceText.imageChoiceTitle}
            onChange={(value: string) => onChangeImageField('title', value)}
            placeholder={addFreeformChoiceText.title}
            uiType="box"
          />
        </div>
      </div>
    );
  },
);

export default FreeformImageOptions;

import {makeAutoObservable} from 'mobx';
import NotificationsRepository from '../../../data/repositories/NotificationsRepository';
import {Notification} from '../../../data/models/Notifications/Invitation';

class NotificationCentreStore {
  private notificationsRepository = new NotificationsRepository();

  constructor() {
    makeAutoObservable(this);
  }

  private notifications: Notification[] = [];

  public async getMyNotifications() {
    const result = await this.notificationsRepository.getMyNotifications();
    if (result.success && result.data) {
      this.notifications = result.data;
    } else {
      this.notifications = [];
    }
  }

  public async denyInvitation(invitaionId: string, notificationId: string) {
    const result =
      await this.notificationsRepository.denyInvitation(invitaionId);
    if (result.success) {
      await this.notificationsRepository.markAsSeen(notificationId);
    }
    return result.success;
  }

  public async markAsSeen(notificationId: string) {
    await this.notificationsRepository.markAsSeen(notificationId);
  }

  public getNotifications() {
    return this.notifications;
  }

  public async getSeenNotifications() {
    const result = await this.notificationsRepository.getSeenNotifications();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default NotificationCentreStore;

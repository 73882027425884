export const lessonsText = {
  save: 'Save',
  next: 'Next',
  optionsEmpty: 'Select a lesson to see options here',
  newLessonTitle: 'New Lesson',
  newLessonDescription: '',
  lessonTitle: 'Title',
  lessonTitlePlaceholder: 'Title for your lesson',
  description: 'Description',
  descriptionPlaceholder: 'Lesson Description',
  deleteLesson: 'Delete Lesson',
  delete: 'Delete',
  newLesson: 'Add New Lesson',
  discardChanges: 'Cancel',
  deleteConfirmation: 'Are you sure you want to delete this lesson?',
  discardChangesConfirmation:
    'You have unsaved changes do you want to proceed?',
  gradableTitle: 'Assessable lesson?',
  changesSaved: 'Changes Saved',
  operationFailed: 'Operation Failed',
  numberOfRetries: 'Number of retries',
  supplementalAlert: 'Notify instructors in the event of a lesson failure.',
  deleteAlert: 'Delete alert on fail',
  deleteAlertConfirmation:
    'Are you sure you want to remove the notification for lesson failures?',
  passingScore: 'Passing score',
  infoGradable: 'This lesson is assessable',
  infoPercent: 'Score needed to pass',
  retriesInfo: 'This lesson allows retries',
  supplementalArrowInfo: 'Supplemental lesson',
  alertInfo: 'Notify the instructor in case of lesson failure',
  lessonInfo: 'Show supplemental lesson',
  addSupplemental: 'Add supplemental path',
  alertInstructor: 'Alert instructor',
  watchSupplemental: 'Show supplemental lesson',
  cantChangeScoreOfPublishedLesson:
    "*Can't edit a grade settings of the published lesson",
};

export const navConfig = [
  // {
  //   title: 'Stats',
  //   route: `/profile/stats`,
  // },
  {
    title: 'Settings',
    route: `/profile/settings`,
  },
  {
    title: 'Notifications',
    route: `/profile/notifications`,
  },
];

import React, {useCallback, useEffect, useState} from 'react';
import AddFreeformChoiceUI from '../view/AddFreeformChoiceUI';
import AddFreeformChoiceStore from '../store/AddFreeformChoiceStore';
import {inject, observer} from 'mobx-react';
import {
  LessonContentItem,
  LessonContentList,
} from '../../../../data/models/LessonContent/LessonContentList';
import {getSiblingOptions, getVideoParent} from '../utlis';
import {
  LessonContentBase,
  LessonContentVideo,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {useParams} from 'react-router-dom';

interface Props {
  addFreeformChoiceStore?: AddFreeformChoiceStore;
  lessonContent: LessonContentList;
}

const AddFreeformChoiceContainer = inject('addFreeformChoiceStore')(
  observer(({addFreeformChoiceStore, lessonContent}: Props) => {
    const [currentVideoNode, setCurrentVideoNode] =
      useState<LessonContentItem>();

    const {organizationId, lessonId} = useParams();

    useEffect(() => {
      if (!addFreeformChoiceStore!.wizardShown) return;
      if (addFreeformChoiceStore!.parentNodeId) {
        const videoNode = getVideoParent(
          addFreeformChoiceStore!.parentNodeId,
          lessonContent,
        );
        setCurrentVideoNode(videoNode);
      }
      if (addFreeformChoiceStore!.inEditMode) {
        const videoNode = getVideoParent(
          addFreeformChoiceStore!.currentNode?.internalId || '',
          lessonContent,
        );
        setCurrentVideoNode(videoNode);
      }
    }, [
      addFreeformChoiceStore!.parentNodeId,
      addFreeformChoiceStore!.wizardShown,
    ]);

    const getVideoSource = () => {
      if (currentVideoNode) {
        return (currentVideoNode.data as LessonContentVideo).contentUrl;
      }
    };

    const getVideoSourceTimeEnd = () => {
      if (currentVideoNode) {
        return (currentVideoNode.data as LessonContentVideo).endInSeconds;
      }
    };

    const onSave = (node: LessonContentBase) => {
      if (organizationId && lessonId) {
        addFreeformChoiceStore!.saveNode(organizationId, lessonId, node);
      }
    };

    const getSiblingBranchOptions = useCallback(() => {
      if (
        addFreeformChoiceStore!.parentNodeId ||
        (addFreeformChoiceStore!.currentNode &&
          addFreeformChoiceStore!.currentNode.internalId)
      ) {
        return getSiblingOptions(
          addFreeformChoiceStore!.parentNodeId || '',
          lessonContent,
          addFreeformChoiceStore!.currentNode,
        );
      }
      return [];
    }, [
      lessonContent,
      addFreeformChoiceStore!.currentNode,
      addFreeformChoiceStore!.parentNodeId,
    ]);

    return addFreeformChoiceStore!.wizardShown ? (
      <AddFreeformChoiceUI
        onClose={() => addFreeformChoiceStore!.closeWizard()}
        videoSource={getVideoSource()}
        videoEndTime={getVideoSourceTimeEnd()}
        currentNode={addFreeformChoiceStore!.currentNode}
        saveChanges={onSave}
        siblingNodes={getSiblingBranchOptions()}
      />
    ) : null;
  }),
);

export default AddFreeformChoiceContainer;

import SubscriptionApi from '../api/SubscriptionApi';

class SubscriptionRepository {
  private subscriptionApi = new SubscriptionApi();

  public getSubscriptionPermissions(organizationId: string) {
    return this.subscriptionApi.getSubscriptionPermissions(organizationId);
  }

  public getSubscribeSession(
    organizationId: string,
    qty: number,
    tier: number,
  ) {
    return this.subscriptionApi.getSubscribeSession(organizationId, qty, tier);
  }

  public upgradeSubscription(
    organizationId: string,
    qty: number,
    tier: number,
  ) {
    return this.subscriptionApi.upgradeSubscription(organizationId, qty, tier);
  }

  public async getOrganizationSubscription(organizationId: string) {
    return this.subscriptionApi.getOrganizationSubscription(organizationId);
  }

  public async getPaymentMethodSession(organizationId: string) {
    return this.subscriptionApi.getPaymentMethodSession(organizationId);
  }

  public async getSubscribeTrialSession(organizationId: string, tier: number) {
    return this.subscriptionApi.getSubscribeTrialSession(organizationId, tier);
  }

  public cancelSubscription(organizationId: string) {
    return this.subscriptionApi.cancelSubscription(organizationId);
  }

  public getInvoicesList(organizationId: string) {
    return this.subscriptionApi.getInvoicesList(organizationId);
  }
}

export default SubscriptionRepository;

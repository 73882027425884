import React from 'react';
import DataTable from 'react-data-table-component';
import {VimeoMediaFile} from '../../../data/models/LibraryFile/MediaFile';
import TablePagination from '../../../uiToolkit/TablePagination';

const columns = [
  {
    name: 'Filename',
    selector: (row: VimeoMediaFile) => row.title,
    cell: (row: VimeoMediaFile) => (
      <div className="TableNameContainer" data-tag="allowRowEvents">
        <div className="TableNameContainerThumb">
          <img
            src={row.placeholderUrl}
            alt="placeholder"
            data-tag="allowRowEvents"
          />
        </div>

        <h5 className="TableText" data-tag="allowRowEvents">
          {row.title}
        </h5>
      </div>
    ),
    grow: 4,
  },
  {
    name: 'Duration',
    selector: (row: VimeoMediaFile) => row.duration,
    cell: (row: VimeoMediaFile) => (
      <h5 className="TableText" data-tag="allowRowEvents">
        {row.duration}
      </h5>
    ),
    id: 'size',
  },
];

interface Props {
  sources: VimeoMediaFile[];
  pages: number;
  onPageChange: (page: number) => void;
}

const VimeoFilesTable = React.memo(({sources, pages, onPageChange}: Props) => {
  return sources.length ? (
    <DataTable
      className="Table"
      columns={columns}
      data={sources}
      pointerOnHover
      highlightOnHover
      onChangePage={onPageChange}
      pagination
      paginationServer
      paginationPerPage={10}
      paginationComponent={TablePagination}
      paginationTotalRows={pages * 10}
      striped
      theme="custom-stripes"
    />
  ) : null;
});

export default VimeoFilesTable;

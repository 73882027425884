export const courseStructureOptions = [
  {
    key: '0',
    value: 'UNITS',
    amountSelectTitle: 'Number of units',
    valueLower: 'Unit',
  },
  {
    key: '1',
    value: 'WEEKS',
    amountSelectTitle: 'Number of weeks',
    valueLower: 'Week',
  },
  {
    key: '2',
    value: 'MODULES',
    amountSelectTitle: 'Number of modules',
    valueLower: 'Module',
  },
  {
    key: '3',
    value: 'TOPICS',
    amountSelectTitle: 'Number of topics',
    valueLower: 'Topic',
  },
  {
    key: '4',
    value: 'FOLDERS',
    amountSelectTitle: 'Number of folders',
    valueLower: 'Folder',
  },
];

export const getStructureFromKey = (key: string) => {
  let value = courseStructureOptions.find(item => item.key === key)?.value;
  if (value) {
    value = value?.toLowerCase();
    value = value.charAt(0).toUpperCase() + value.slice(1);
  }
  return value || '';
};

export const getStructureFromKeySingular = (key: string) => {
  let value = courseStructureOptions.find(item => item.key === key)?.value;
  if (value) {
    value = value?.toLowerCase();
    value = value.charAt(0).toUpperCase() + value.slice(1, value.length - 1);
  }
  return value || '';
};

export const structureSizeOptions = [
  {
    key: '1',
    value: '1',
  },
  {
    key: '2',
    value: '2',
  },
  {
    key: '3',
    value: '3',
  },
  {
    key: '4',
    value: '4',
  },
  {
    key: '5',
    value: '5',
  },
  {
    key: '6',
    value: '6',
  },
  {
    key: '7',
    value: '7',
  },
  {
    key: '8',
    value: '8',
  },
  {
    key: '9',
    value: '9',
  },
  {
    key: '10',
    value: '10',
  },
  {
    key: '11',
    value: '11',
  },
  {
    key: '12',
    value: '12',
  },
];

export const courseTypeOptions = [
  {
    key: '0',
    value: 'Assessable',
  },
  {
    key: '1',
    value: 'Non-Assesment Based',
  },
];

export const GRADABLE_COURSE_TYPE = '0';
export const PARTICIPATION_COURSE_TYPE = '1';

export const getCourseTypeFromKey = (key: string) => {
  return courseTypeOptions.find(item => item.key === key)?.value;
};

import React, {useEffect, useState} from 'react';
import StorageIntegrationSettingsUI from '../view/StorageIntegrationSettingsUI';
import StorageIntegrationStore from '../store/StorageIntegrationStore';
import {inject, observer} from 'mobx-react';
import {useParams} from 'react-router-dom';

interface Props {
  storageIntegrationStore?: StorageIntegrationStore;
}

const StorageIntegrationContainer = inject('storageIntegrationStore')(
  observer(({storageIntegrationStore}: Props) => {
    const [hasIntegration, setHasIntegration] = useState<boolean>();
    const {organizationId} = useParams();

    useEffect(() => {
      fetchIntegration();
    }, []);

    const fetchIntegration = () => {
      if (organizationId) {
        storageIntegrationStore!
          .getHaveVimeoIntegration(organizationId)
          .then(res => {
            setHasIntegration(res.vimeoConfigured);
          });
      }
    };

    const onSaveSecret = (secret: string) => {
      if (organizationId) {
        storageIntegrationStore!
          .addVimeoSecret(organizationId, secret)
          .then(fetchIntegration);
      }
    };

    return (
      <StorageIntegrationSettingsUI
        onSaveSecret={onSaveSecret}
        hasIntegration={hasIntegration}
      />
    );
  }),
);

export default StorageIntegrationContainer;

export const generateAIVideosText = {
  ModalHeader:
    'Generate Videos from Microsoft Powerpoint or Adobe PDF using our AI Assistant',
  description:
    'Generate AI Videos using the latest AI technology. Create videos from text, images, and more.',
  text: 'Generate AI Videos',
  save: 'Save',
  cancel: 'Cancel',
  slideIgnoreHeadingText: 'Slides to Ignore',
  slideIgnorePlaceholderText:
    'Enter slide numbers separated by commas like 1, 2, 4-6, 8',
  selectType: 'Choose Video Duration',
  type: 'Step 2. Tell us how long you would like the video ',
  durationSubtitle:
    'The duration of your video is based on how much narrator content you wish our AI to create according to each slide/page in your file. To keep it to a minimum, select Short. Selecting Long means the AI will enrich the narration content for each slide significantly.',
  checkboxText:
    'Step 3. Decide if you wish to generate images in addition to your content in the video',
  aiImageSubtitleText:
    'In addition to the narrator content, our AI can also generate images according to the content of your slides or pages. Selecting to include AI-generated images means we also need to know how long the images should display relative to the content slide/page whilst the narrator speaks.',
  sliderHeading: 'Content to Image Ratio',
  sliderSubtitle:
    'Our AI works by analysing each slide/page and generation narrator audio. If you also generate an image for each slide/page, you can set the ratio to define the amount of time the content slide/page will display, relative to the generated image, whist the audio for that content plays.',
  uploadPPTText: 'Step 1. Upload a pptx or pdf file',
  successMessage:
    'AI Video is being generated. You will be notified once it is ready.',
  uploadPPTSubtitle: 'File can have the following formats: .pptx .pdf',
  voiceGenderHeading: 'Select the Voice of the Narrator',
  languageDropdownHeading: 'Select the Language of the Narrator',
  dialectDropdownHeading: 'Select the Dialect of the Narrator',
  selectVoiceGender: 'Select Voice',
  loadingText:
    'AI is currently generating your video. This may take some time. The video will appear in your Library when ready.',
  noAvailableAiVideoGeneration:
    'You have crossed the threshold of AI videos you can generate',
  narrationCustomization: 'Step 4. Customize Voice Narration',
  narrationCustomizationSubtitle:
    'The AI will generate the narration for your video. You can customize the voice of the narrator by selecting the voice, language, and dialect from a variety of options available to you below.',
};

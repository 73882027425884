import React, {useState} from 'react';
import TextInput, {TextInputUITypes} from '../TextInput';
import {HexColorPicker} from 'react-colorful';
import './styles.css';

interface Props {
  value: string;
  onChange: (value: string) => void;
  heading: string;
  placeholder: string;
  uiType?: TextInputUITypes;
  className?: string;
}

const ColorInput = React.memo(
  ({value, onChange, heading, placeholder, uiType, className}: Props) => {
    const [inFocus, setInFocus] = useState(false);

    return (
      <div>
        <TextInput
          value={value}
          onChange={onChange}
          heading={heading}
          placeholder={placeholder}
          className={className}
          isMandatory={true}
          onFocusFunc={() => setInFocus(true)}
          uiType={uiType}
        />
        {inFocus && (
          <div className="ColorInputContainer">
            <span
              className="ColorInputClose icon-type-bolder_x"
              onClick={() => setInFocus(false)}></span>
            <HexColorPicker color={value} onChange={onChange} />
          </div>
        )}
      </div>
    );
  },
);

export default ColorInput;

import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import {LessonContentFreeformShapeChoice} from '../../../../../data/models/LessonContent/LessonContentBase';
import LessonEditorStore from '../../../store/LessonEditorStore';
import MyLibraryModalStore from '../../../../../modules/MyLibraryModal/store/MyLibraryModalStore';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import {lessonEditorText} from '../../../lessonEditorText';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';
import FreeformShapeOptionsUI from '../view/FreeformShapeOptionsUI';
import AddFreeformChoiceStore from '../../../../../modules/Editor/AddFreeformChoiceOption/store/AddFreeformChoiceStore';

interface Props {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentFreeformShapeChoice;
  lessonEditorStore?: LessonEditorStore;
  onCancel: () => void;
  onChangesMade: () => void;
  myLibraryModalStore?: MyLibraryModalStore;
  isPublished?: boolean;
  addFreeformChoiceStore?: AddFreeformChoiceStore;
}

const FreeformShapeOptionsContainer = inject(
  'lessonEditorStore',
  'addFreeformChoiceStore',
)(
  observer(
    ({
      entityToEdit,
      lessonEditorStore,
      onCancel,
      isPublished,
      onChangesMade,
      addFreeformChoiceStore,
    }: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onEdit = (title: string, isCorrect: boolean | null) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              title,
              isCorrect,
            } as LessonContentFreeformShapeChoice)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(success => {
              if (success) {
                onChangesMade();
              } else {
                toast.error(getErrorFromCode(''), toastConfig);
              }
            });
        }
      };

      const openEditOverlay = () => {
        if (entityToEdit) {
          addFreeformChoiceStore!.showWizardForEdit(
            entityToEdit,
            onChangesMade,
          );
        }
      };

      return entityToEdit ? (
        <>
          <FreeformShapeOptionsUI
            choiceShape={entityToEdit}
            openEditOverlay={openEditOverlay}
            isLoading={lessonEditorStore!.isLoading}
            onCancel={onCancel}
            onSave={onEdit}
            onDelete={() => setShowDelete(true)}
            isPublished={isPublished}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteChoiceShapeOption}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      ) : null;
    },
  ),
);

export default FreeformShapeOptionsContainer;

import React, {useRef, useState} from 'react';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {
  getSecondsFromTimeText,
  getTimeTextFromSeconds,
} from '../../../../utils/getTimeTextFromSeconds';
import SimplePlayerControlled from '../../../../uiToolkit/SimplePlayerControlled';
import './styles.css';
import TimeInput from '../../../../uiToolkit/Inputs/TimeInput/indes';
import Button from '../../../../uiToolkit/Buttons/Button';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addVideoOverlayWizardText} from '../addVideoOverlayWizardText';
import {
  OVERLAY_ACTION_SEEK,
  OVERLAY_NEXT_NODE_OPTION,
  OVERLAY_PREV_NODE_OPTION,
  overlayTapActionOptions,
} from '../../../../data/models/LessonContent/ContentFreeform';
import {
  OVERLAY_ACTION_URL,
  VideoOverlay,
} from '../../../../data/models/LessonContent/ContentFreeform';
import {Accordion} from 'react-bootstrap';
import {AccordionEventKey} from 'react-bootstrap/esm/AccordionContext';
import Tooltip from '../../../../uiToolkit/Tooltip';
import {
  LessonContentOverlayVideo,
  LessonContentVimeoOverlayVideo,
} from '../../../../data/models/LessonContent/LessonContentBase';
import {
  LessonContentList,
  getListOfItemsFromContentList,
} from '../../../../data/models/LessonContent/LessonContentList';
import {LessonShortModel} from '../../../../data/models/Course/Lesson';
import EditorSvgCanvas from '../../FreeformElements/SvgCanvas';
import EditorSvgPath from '../../FreeformElements/EditorSvgPath';
import OverlayOptions, {
  videoOverlayValidators,
} from '../components/OverlayOptions';
import {
  addPointToOverlay,
  generateShapePoints,
  removePointFromOverlay,
} from '../../FreeformElements/EditorSvgPath/utils';
import {scaleShapeOptionsForDisplay} from '../../AddFreeformChoiceOption/utlis';

interface Props {
  selectedFile: MediaFile | null;
  selectedVimeoFile: VimeoMediaFile | null;
  existingOverlayNode?:
    | LessonContentOverlayVideo
    | LessonContentVimeoOverlayVideo;
  onCancel: () => void;
  onSave: (start: number, end: number, overlays: VideoOverlay[]) => void;
  lessonContent?: LessonContentList;
  lessonsList: LessonShortModel[];
  isGradable: boolean;
}

const CropVideoStepUI = React.memo(
  ({
    selectedFile,
    onCancel,
    onSave,
    selectedVimeoFile,
    existingOverlayNode,
    lessonContent,
    lessonsList,
    isGradable,
  }: Props) => {
    const [width, setWidth] = useState(0);
    const [timeStart, setTimeStart] = useState('00:00:00');
    const [timeEnd, setTimeEnd] = useState('00:00:01');
    const [duration, setDuration] = useState(0);
    const [progress, setProgress] = useState(0);
    const [overlays, setOverlays] = useState<VideoOverlay[]>([]);
    const [activeOverlayIndex, setActiveOverlayIndex] = useState<number | null>(
      null,
    );
    const containerRef = useRef<any>(null);

    React.useEffect(() => {
      if (existingOverlayNode) {
        setTimeStart(
          getTimeTextFromSeconds(existingOverlayNode.startInSeconds),
        );
        setTimeEnd(getTimeTextFromSeconds(existingOverlayNode.endInSeconds));
        const newOverlays = existingOverlayNode.overlays.map(overlay => ({
          ...overlay,
          overlayCoordinates: overlay.overlayCoordinates.map(coord => ({
            ...coord,
            ...scaleShapeOptionsForDisplay(overlay.encodedAtWidth, width, [
              coord,
            ])[0],
          })),
        }));
        setOverlays([...newOverlays]);
        if (existingOverlayNode.overlays.length) {
          setActiveOverlayIndex(0);
        }
      }
    }, [existingOverlayNode, width]);

    React.useEffect(() => {
      function handleResize() {
        if (containerRef && containerRef.current) {
          setWidth(containerRef.current.offsetWidth);
        }
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    React.useEffect(() => {
      if (overlays.length === 1) {
        setActiveOverlayIndex(0);
      }
    }, [overlays]);

    React.useLayoutEffect(() => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    }, []);

    const saveNode = () => {
      if (startTimeIsValid() && endTimeIsValid() && allOverlaysAreValid()) {
        onSave(
          getSecondsFromTimeText(timeStart),
          getSecondsFromTimeText(timeEnd),
          overlays.map(overlayVM => ({
            ...overlayVM,
            encodedAtWidth: width,
          })),
        );
      }
    };

    const onAddNewOverlay = () => {
      const newOverlay: VideoOverlay = {
        overlayStartTime: 0,
        overlayEndTime: 1,
        overlayCoordinates: generateShapePoints(width),
        actionType: OVERLAY_ACTION_URL,
        encodedAtWidth: width,
        overlayVisible: false,
        overlayTitle: `Overlay ${overlays.length}`,
      };
      const newOverlays = [...overlays, newOverlay];
      setOverlays(newOverlays);
    };

    const onAddPoint = () => {
      if (activeOverlayIndex == null) return;
      const currentOverlay = overlays[activeOverlayIndex];
      if (currentOverlay) {
        const newPoints = addPointToOverlay(currentOverlay.overlayCoordinates);
        const updatedOverlay = {
          ...currentOverlay,
          overlayCoordinates: newPoints,
        };
        updateOverlayByIndex(activeOverlayIndex)(updatedOverlay);
      }
    };

    const onRemovePoint = () => {
      if (activeOverlayIndex == null) return;
      const currentOverlay = overlays[activeOverlayIndex];
      if (currentOverlay) {
        const newPoints = removePointFromOverlay(
          currentOverlay.overlayCoordinates,
        );
        const updatedOverlay = {
          ...currentOverlay,
          overlayCoordinates: newPoints,
        };
        updateOverlayByIndex(activeOverlayIndex)(updatedOverlay);
      }
    };

    const startTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(timeStart);
        const endSeconds = getSecondsFromTimeText(timeEnd);
        return seconds <= Math.ceil(duration) && seconds < endSeconds;
      }
      return true;
    };

    const endTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(timeEnd);
        return seconds <= Math.ceil(duration);
      }
      return true;
    };

    const onDuration = (dur: number) => {
      setDuration(dur);
      if (timeEnd === '00:00:01') {
        setTimeEnd(getTimeTextFromSeconds(Math.ceil(dur)));
      }
    };

    const updateActiveOverlayPoints = (value: any) => {
      if (activeOverlayIndex == null) return;
      const currentOverlay = overlays[activeOverlayIndex];
      if (currentOverlay) {
        const updatedOverlay: VideoOverlay = {
          ...currentOverlay,
          overlayCoordinates: value,
        };
        updateOverlayByIndex(activeOverlayIndex)(updatedOverlay);
      }
    };

    const updateOverlayByIndex = (index: number) => (overlay: VideoOverlay) => {
      if (index == null) return;
      const updatedOverlays = [...overlays];
      updatedOverlays[index] = overlay;
      setOverlays(updatedOverlays);
    };

    const getCurrentOverlay = () => {
      if (activeOverlayIndex == null) return;
      const currentOverlay = overlays[activeOverlayIndex];
      if (
        currentOverlay &&
        currentOverlay.overlayStartTime <= progress &&
        currentOverlay.overlayEndTime >= progress
      ) {
        return currentOverlay;
      }
    };

    const overlayIsValid = (index: number) => {
      const overlay = overlays[index];
      if (!overlay) return false;

      const validators = videoOverlayValidators(
        overlay,
        getSecondsFromTimeText(timeStart),
        getSecondsFromTimeText(timeEnd),
      );

      if (!validators.overlayStartTimeIsValid()) {
        return false;
      }

      if (!validators.overlayEndTimeIsValid()) {
        return false;
      }

      if (overlay.actionType === OVERLAY_ACTION_URL && !overlay.payload) {
        return false;
      }

      if (
        overlay.actionType === OVERLAY_ACTION_SEEK &&
        !validators.overlaySeekTimeIsValid()
      ) {
        return false;
      }

      return true;
    };

    const onSelectOverlay = (item: AccordionEventKey) => {
      if (item && !Array.isArray(item)) {
        setActiveOverlayIndex(parseInt(item, 10));
      }
    };

    const allOverlaysAreValid = () => {
      let allValid = true;
      overlays.forEach((overlay, index) => {
        if (!overlayIsValid(index)) {
          allValid = false;
        }
      });
      return allValid;
    };

    const getSource = () => {
      if (selectedFile) {
        return selectedFile.fileUrl;
      }
      if (selectedVimeoFile) {
        return selectedVimeoFile.url;
      }
      if (existingOverlayNode) {
        return existingOverlayNode.contentUrl;
      }
    };

    return (
      <ModalContainer onClose={onCancel}>
        <div className="d-flex flex-row">
          <div className="AddVideoOverlaySourceStepContent CropPlayerContent">
            <p className="CropHeader">{addVideoOverlayWizardText.cropTitle}</p>
            <div className="CropPlayerContainer" ref={containerRef}>
              <SimplePlayerControlled
                width={width}
                onDuration={onDuration}
                onVideoProgress={setProgress}
                source={getSource()}
                startTime={
                  startTimeIsValid()
                    ? getSecondsFromTimeText(timeStart)
                    : undefined
                }
                endTime={
                  endTimeIsValid() ? getSecondsFromTimeText(timeEnd) : undefined
                }
              />
              {getCurrentOverlay() && (
                <EditorSvgCanvas width={width}>
                  <EditorSvgPath
                    shapePoints={getCurrentOverlay()!.overlayCoordinates}
                    updateShapePoints={updateActiveOverlayPoints}
                  />
                </EditorSvgCanvas>
              )}
            </div>
            <div className="TimePickersContainer">
              <TimeInput
                value={timeStart}
                onChange={setTimeStart}
                label={addVideoOverlayWizardText.startTime}
                isValid={startTimeIsValid()}
                labelTop
              />
              <TimeInput
                value={timeEnd}
                onChange={setTimeEnd}
                label={addVideoOverlayWizardText.endTime}
                isValid={endTimeIsValid()}
                labelTop
              />
            </div>
            <div className="Footer">
              <Button
                title={addVideoOverlayWizardText.cancel}
                onClick={onCancel}
                uiType="text"
              />
              <Button
                title={addVideoOverlayWizardText.save}
                onClick={saveNode}
                disabled={
                  !startTimeIsValid() ||
                  !endTimeIsValid() ||
                  !allOverlaysAreValid()
                }
                uiType="action"
              />
            </div>
          </div>
          <div className="OverlaySettings">
            <p className="Title">{addVideoOverlayWizardText.overlayTitle}</p>
            <Button
              title={addVideoOverlayWizardText.addOverlay}
              onClick={onAddNewOverlay}
              uiType="box"
              className="AddOverlayButton"
            />
            <div className="OverlaysList">
              <Accordion defaultActiveKey={'0'} onSelect={onSelectOverlay}>
                {overlays.map((item, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>
                      <div className="OverlayHeader">
                        {item.overlayTitle}
                        {!overlayIsValid(index) && (
                          <Tooltip
                            tooltipText={
                              addVideoOverlayWizardText.overlayError
                            }>
                            <span className="icon-typeWarning OverlayWarning"></span>
                          </Tooltip>
                        )}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <OverlayOptions
                        overlay={item}
                        updateOverlay={updateOverlayByIndex(index)}
                        videoTimeStart={getSecondsFromTimeText(timeStart)}
                        videoTimeEnd={getSecondsFromTimeText(timeEnd)}
                        onAddPoint={onAddPoint}
                        onRemovePoint={onRemovePoint}
                        actionOptions={overlayTapActionOptions(isGradable)}
                        lessonContentOptions={[
                          OVERLAY_PREV_NODE_OPTION,
                          ...getListOfItemsFromContentList(lessonContent),
                          OVERLAY_NEXT_NODE_OPTION,
                        ]}
                        courseLessonsOptions={lessonsList.map(item => ({
                          key: item.lessonId,
                          value: item.lessonName,
                        }))}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  },
);

export default CropVideoStepUI;

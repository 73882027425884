import React, {useCallback} from 'react';
import './styles.css';

interface Props {
  rowsPerPage: number;
  rowCount: number;
  onChangePage: (page: number, totalRows: number) => void;
  currentPage: number;
}

const TablePagination = React.memo(
  ({rowsPerPage, rowCount, onChangePage, currentPage}: Props) => {
    const getAmountOfPages = useCallback(() => {
      return Math.ceil(rowCount / rowsPerPage);
    }, [rowsPerPage, rowCount]);

    const getPagesArray = useCallback(() => {
      const pages = [];
      if (currentPage < 5 && currentPage + 4 <= getAmountOfPages()) {
        for (let i = 0; i < currentPage + 4; i++) {
          pages.push(i + 1);
        }
      } else if (currentPage < 5 && currentPage + 4 > getAmountOfPages()) {
        for (let i = 0; i < getAmountOfPages(); i++) {
          pages.push(i + 1);
        }
      } else if (currentPage + 4 > getAmountOfPages()) {
        for (let i = currentPage - 5; i < getAmountOfPages(); i++) {
          pages.push(i + 1);
        }
      } else {
        for (let i = currentPage - 5; i < currentPage + 4; i++) {
          pages.push(i + 1);
        }
      }
      return pages;
    }, [getAmountOfPages, currentPage]);

    const canGoPrev = useCallback(() => {
      return currentPage > 1;
    }, [currentPage]);

    const canGoNext = useCallback(() => {
      return currentPage < getAmountOfPages();
    }, [currentPage, getAmountOfPages]);

    return (
      <div className="TablePaginationContainer">
        <button
          className={`SideButton ${canGoPrev() ? 'Active' : ''}`}
          disabled={!canGoPrev()}
          onClick={() => onChangePage(currentPage - 1, rowCount)}>
          Previous page
        </button>
        {getAmountOfPages() !== 0 && !getPagesArray().includes(1) && (
          <button
            className={`NumberButton ${currentPage === 1 ? 'Active' : ''}`}
            onClick={() => onChangePage(1, rowCount)}
            key={1}>
            1...
          </button>
        )}
        {getPagesArray().map(item => (
          <button
            className={`NumberButton ${currentPage === item ? 'Active' : ''}`}
            onClick={() => onChangePage(item, rowCount)}
            key={item}>
            {item}
          </button>
        ))}
        {getAmountOfPages() !== 0 &&
          !getPagesArray().includes(getAmountOfPages()) && (
            <button
              className={`NumberButton ${
                currentPage === getAmountOfPages() ? 'Active' : ''
              }`}
              onClick={() => onChangePage(getAmountOfPages(), rowCount)}
              key={getAmountOfPages()}>
              ...{getAmountOfPages()}
            </button>
          )}
        <button
          className={`SideButton ${canGoNext() ? 'Active' : ''}`}
          disabled={!canGoNext()}
          onClick={() => onChangePage(currentPage + 1, rowCount)}>
          Next page
        </button>
      </div>
    );
  },
);

export default TablePagination;

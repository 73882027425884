import {makeAutoObservable} from 'mobx';
import SubscriptionRepository from '../../../data/repositories/SubscriptionRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';

class SelectSubscriptionStore {
  private subscriptionRepo = new SubscriptionRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getSubscriptionSession(
    organizationId: string,
    qty: number,
    tier: number,
  ) {
    this.isLoading = true;
    const result = await this.subscriptionRepo.getSubscribeSession(
      organizationId,
      qty,
      tier,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    } else if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }

  public async updateSubscription(
    organizationId: string,
    qty: number,
    tier: number,
  ) {
    this.isLoading = true;
    const result = await this.subscriptionRepo.upgradeSubscription(
      organizationId,
      qty,
      tier,
    );
    this.isLoading = false;
    if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async getCurrentSubscription(organizationId: string) {
    this.isLoading = true;
    const result =
      await this.subscriptionRepo.getOrganizationSubscription(organizationId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getTrialSubscriptionSession(
    organizationId: string,
    tier: number,
  ) {
    this.isLoading = true;
    const result = await this.subscriptionRepo.getSubscribeTrialSession(
      organizationId,
      tier,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    } else if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }
}

export default SelectSubscriptionStore;

import React, {useEffect, useState} from 'react';
import StaffEditUI from '../view/StaffEditUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {Role} from '../../../../data/models/Oganization/Role';
import OrganizationStaffStore from '../../store/OrganizationStaffStore';
import {StaffRecord} from '../../../../data/models/Oganization/StaffRecord';

interface Props {
  organizationStaffStore?: OrganizationStaffStore;
}

const StaffEditContainer = inject('organizationStaffStore')(
  observer(({organizationStaffStore}: Props) => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [member, setMember] = useState<StaffRecord>();
    const {organizationId, memberId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId && memberId) {
        organizationStaffStore
          ?.getOrganizationRoles(organizationId)
          .then(setRoles);
        organizationStaffStore!
          .getStaffMember(organizationId, memberId)
          .then(setMember);
      }
    }, []);

    const onCancel = () => {
      navigate(`/manage/${organizationId}/settings/users`);
    };

    const onEditMember = async (roleId: string) => {
      if (member && member.staffMemberId && organizationId) {
        const success = await organizationStaffStore!.editStaffMemeber(
          organizationId,
          member.staffMemberId,
          roleId,
        );
        if (success) {
          onCancel();
        }
      }
    };

    return member ? (
      <StaffEditUI
        onCancel={onCancel}
        roles={roles}
        isLoading={organizationStaffStore!.isLoading}
        member={member}
        onSubmit={onEditMember}
      />
    ) : null;
  }),
);

export default StaffEditContainer;

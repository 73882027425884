import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import ChoiceTextOptionOptionsUI from '../view/ChoiceTextOptionOptionsUI';
import {LessonContentChoiceTextOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import LessonEditorStore from '../../../store/LessonEditorStore';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import {lessonEditorText} from '../../../lessonEditorText';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';

interface Props {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentChoiceTextOption;
  lessonEditorStore?: LessonEditorStore;
  onCancel: () => void;
  onChangesMade: () => void;
  isPublished?: boolean;
}

const ChoiceTextOptionOptionsContainer = inject('lessonEditorStore')(
  observer(
    ({
      entityToEdit,
      lessonEditorStore,
      onCancel,
      onChangesMade,
      isPublished,
    }: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onEdit = (question: string) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              title: question,
            } as LessonContentChoiceTextOption)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(success => {
              if (success) {
                onChangesMade();
              } else {
                toast.error(getErrorFromCode(''), toastConfig);
              }
            });
        }
      };
      return (
        <>
          <ChoiceTextOptionOptionsUI
            entityToEdit={entityToEdit}
            onCancel={onCancel}
            onEdit={onEdit}
            isLoading={lessonEditorStore!.isLoading}
            onDelete={() => setShowDelete(true)}
            isPublished={isPublished}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteChoiceTextOption}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      );
    },
  ),
);

export default ChoiceTextOptionOptionsContainer;

import React from 'react';
import {dashboardText} from '../dashboardText';
import PageTitle from '../../../uiToolkit/PageTitle';
import './styles.css';
import {OrganizationSubscription} from '../../../data/models/Subscription/Subscription';
import {CoursesList} from '../../Courses';
import Button from '../../../uiToolkit/Buttons/Button';
import {homeActive, subscriptionExpiredImage} from '../../../assets/images';

interface Props {
  subscription?: OrganizationSubscription;
  usedTrial: boolean;
  onStartTrial: () => void;
  isLoading: boolean;
  onSubscribe: () => void;
  loadingSubscription: boolean;
}

const WorkspaceUI = React.memo(
  ({
    subscription,
    usedTrial,
    onStartTrial,
    isLoading,
    onSubscribe,
    loadingSubscription,
  }: Props) => {
    const getContent = () => {
      if (loadingSubscription) {
        return null;
      }
      if (subscription === undefined && !usedTrial) {
        return (
          <div>
            <PageTitle title={dashboardText.title} icon={homeActive} />
            <div className="SubscriptionStartContainer">
              <img src={subscriptionExpiredImage} />
              <h1>{dashboardText.startTrial}</h1>
              <p>{dashboardText.startTrialInfo}</p>
              <p>{dashboardText.startTrialInfo2}</p>
              <p>{dashboardText.startTrialInfo3}</p>
              <Button
                title={dashboardText.startTrialButton}
                onClick={onStartTrial}
                isLoading={isLoading}
                className="StartTrialButton"
              />
            </div>
          </div>
        );
      }
      if (subscription === undefined && usedTrial) {
        return (
          <div>
            <PageTitle title={dashboardText.title} icon={homeActive} />
            <div className="SubscriptionStartContainer">
              <img src={subscriptionExpiredImage} />
              <h1>{dashboardText.subcriptionEnded}</h1>
              <p>{dashboardText.subscriptionEndedInfo}</p>
              <p>{dashboardText.subscriptionEndedInfo2}</p>
              <span className="ClickToUpgrade">{dashboardText.clickToSub}</span>
              <Button
                title={dashboardText.upgradeNow}
                onClick={onSubscribe}
                isLoading={isLoading}
                className="StartTrialButton"
              />
            </div>
          </div>
        );
      }
      return <CoursesList isWorkspace />;
    };

    return <div className="WorkspaceContainer">{getContent()}</div>;
  },
);

export default WorkspaceUI;

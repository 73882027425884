import {makeAutoObservable} from 'mobx';
import AnalyticsRepository from '../../../data/repositories/AnalyticsRepository';
import AuthRepository from '../../../data/repositories/AuthRepository';

class AdminPanelStore {
  private analyticsRepo = new AnalyticsRepository();
  private authRepo = new AuthRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public getAnalytics() {
    return this.analyticsRepo.getAdminAnalytics();
  }

  public async getLastAccessData(page: number, pageSize: number) {
    const res = await this.analyticsRepo.getLastAccessData(page, pageSize);
    if (res.success && res.data) {
      return res.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }

  public logout() {
    return this.authRepo.logout();
  }
}

export default AdminPanelStore;

import React, {useState} from 'react';
import './styles.css';
import SearchInput from '../Inputs/SearchInput';
import {EditorText} from './EditorText';
import {bookIc, folderIcEmpty, lessonsIcon} from '../../assets/images';
import {Link, useParams} from 'react-router-dom';
import {isUndefined} from 'lodash';
import {ActiveTabs} from '../../data/models/UI/ActiveTabs';
import Tooltip from '../Tooltip';

interface Props {
  sidebarOptions: any;
  onSearchKeyChange: (search: string) => void;
  activeTabs: ActiveTabs;
}
const EditorSidebarNavigation = React.memo(
  ({sidebarOptions, onSearchKeyChange, activeTabs}: Props) => {
    const [search, setSearch] = useState('');
    const {organizationId} = useParams();
    const [expandedItems, setExpandedItems] = React.useState<
      Record<string, boolean>
    >({
      [activeTabs.courseId]: true,
      [String(activeTabs.folderId)]: isUndefined(activeTabs.folderId),
      [String(activeTabs.lessonId)]: isUndefined(activeTabs.lessonId),
    });

    React.useEffect(() => {
      const newExpandedItems: Record<string, boolean> = {
        [activeTabs.courseId]: true,
      };

      if (activeTabs.folderId) {
        newExpandedItems[activeTabs.folderId] = true;
      }

      if (activeTabs.lessonId) {
        newExpandedItems[activeTabs.lessonId] = true;
      }

      setExpandedItems(newExpandedItems);
    }, [activeTabs.courseId, activeTabs.folderId, activeTabs.lessonId]);

    const handleSearchChange = (text: string) => {
      setSearch(text);
      onSearchKeyChange(text);
    };

    const toggleExpanded = (id: string) => {
      setExpandedItems(prevState => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };

    return (
      <div className="EditorSidebarNavigationContainer">
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          className="SmallSearchBox"
        />
        <h6>{EditorText.sidebarCourseName}</h6>
        <div className="SidebarOptionsContainer">
          <ul className="SidebarOptionsCourseName">
            {sidebarOptions.map(
              (course: {id: string; name: string; folders: any}) => (
                <li key={course.id}>
                  <div className="FolderNameContainer">
                    <button
                      className="SidebarExpandButton"
                      onClick={() => toggleExpanded(course.id)}>
                      {expandedItems[course.id] ? '-' : '+'}
                    </button>
                    <Link
                      to={`/editor/${organizationId}/courses/${course.id}/folders`}
                      className="SidebarLink">
                      <img
                        src={bookIc}
                        alt="course icon"
                        className="SidebarIcon"
                      />
                      <Tooltip
                        tooltipText={course.name}
                        className="SidebarTooltip">
                        <span
                          className={
                            activeTabs.courseId === course.id ? 'Active' : ''
                          }>
                          {course.name}{' '}
                        </span>
                      </Tooltip>
                    </Link>
                  </div>
                  {expandedItems[course.id] && (
                    <ul style={{paddingLeft: '10px'}}>
                      {course.folders.map(
                        (folder: {id: string; name: string; lessons: any}) => (
                          <li key={folder.id}>
                            <div className="FolderNameContainer">
                              <button
                                className="SidebarExpandButton"
                                onClick={() => toggleExpanded(folder.id)}>
                                {expandedItems[folder.id] ? '-' : '+'}
                              </button>
                              <Link
                                to={`/editor/${organizationId}/courses/${course.id}/folders/${folder.id}/lessons`}
                                className="SidebarLink">
                                <img
                                  src={folderIcEmpty}
                                  alt="folder icon"
                                  className="SidebarIcon"
                                />
                                <Tooltip
                                  tooltipText={folder.name}
                                  className="SidebarTooltip">
                                  <span
                                    className={
                                      activeTabs.folderId === folder.id
                                        ? 'Active'
                                        : ''
                                    }>
                                    {folder.name}{' '}
                                  </span>
                                </Tooltip>
                              </Link>
                            </div>
                            {expandedItems[folder.id] && (
                              <ul style={{paddingLeft: '25px'}}>
                                {folder.lessons.map(
                                  (lesson: {id: string; name: string}) => (
                                    <li key={lesson.id}>
                                      <Link
                                        to={`/editor/${organizationId}/courses/${course.id}/folders/${folder.id}/lessons/${lesson.id}/edit`}
                                        className="SidebarLink">
                                        <img
                                          src={lessonsIcon}
                                          alt="lesson icon"
                                          className="SidebarIconSmall"
                                        />
                                        <Tooltip
                                          tooltipText={lesson.name}
                                          className="SidebarTooltip">
                                          <span
                                            className={
                                              activeTabs.lessonId === lesson.id
                                                ? 'Active'
                                                : ''
                                            }>
                                            {lesson.name}{' '}
                                          </span>
                                        </Tooltip>
                                      </Link>
                                    </li>
                                  ),
                                )}
                              </ul>
                            )}
                          </li>
                        ),
                      )}
                    </ul>
                  )}
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    );
  },
);
export default EditorSidebarNavigation;

import React from 'react';
import {lessonEditorText} from '../../../lessonEditorText';
import {LessonContentChoiceImageOption} from '../../../../../data/models/LessonContent/LessonContentBase';
import {MediaFile} from '../../../../../data/models/LibraryFile/MediaFile';
import BoxImagePreview from '../../../../../uiToolkit/BoxImagePreview';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';

interface Props {
  choiceImage: LessonContentChoiceImageOption;
  newImage?: MediaFile;
  openImagePicker: () => void;
  onCancel: () => void;
  onSave: () => void;
  isLoading: boolean;
  onDelete: () => void;
  isPublished?: boolean;
}

const ChoiceImageOptionOptionsUI = React.memo(
  ({
    choiceImage,
    openImagePicker,
    newImage,
    onSave,
    isLoading,
    onDelete,
    isPublished,
  }: Props) => {
    return (
      <div>
        <BoxImagePreview
          heading={lessonEditorText.source}
          source={newImage ? newImage.fileUrl : choiceImage.contentUrl}
          actionTitle={lessonEditorText.editSource}
          action={openImagePicker}
        />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={newImage !== undefined}
          onDelete={onDelete}
          deleteTitle={lessonEditorText.delete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChoiceImageOptionOptionsUI;

import React, {useEffect, useState} from 'react';
import RoleDetailsUI from '../view/RoleDetailsUI';
import {useNavigate, useParams} from 'react-router-dom';
import SettingsStore from '../../store/SettingsStore';
import {inject, observer} from 'mobx-react';
import {Role} from '../../../../data/models/Oganization/Role';

interface Props {
  settingsStore?: SettingsStore;
}

const RoleEditContainer = inject('settingsStore')(
  observer(({settingsStore}: Props) => {
    const [role, setRole] = useState<Role>();
    const navigate = useNavigate();
    const {organizationId, roleId} = useParams();

    useEffect(() => {
      if (roleId && organizationId) {
        settingsStore!.getRoleDetailsById(roleId, organizationId).then(setRole);
      }
    }, []);

    const onClose = () => {
      navigate(`/manage/${organizationId}/settings/roles`, {
        replace: true,
      });
    };

    const onEdit = async (name: string, permissions: string[]) => {
      if (organizationId && role) {
        const success = await settingsStore!.editOrganizationRole(
          organizationId,
          role.id,
          name,
          permissions,
        );
        if (success) {
          onClose();
        }
      }
    };

    const clearErrors = () => {
      settingsStore!.clearRoleSettingsErrors();
    };

    return (
      <RoleDetailsUI
        onSave={onEdit}
        onCancel={onClose}
        errors={settingsStore!.roleSettingsErrors}
        isLoading={settingsStore!.isLoading}
        editMode
        clearErrors={clearErrors}
        roleToEdit={role}
      />
    );
  }),
);

export default RoleEditContainer;

import {inject, observer} from 'mobx-react';
import React, {useState} from 'react';
import MChoiceOptionsUI from '../view/MChoiceOptionsUI';
import {
  LessonContentMChoice,
  LessonContentMChoiceAnswer,
} from '../../../../../data/models/LessonContent/LessonContentBase';
import LessonEditorStore from '../../../store/LessonEditorStore';
import {useParams} from 'react-router-dom';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';
import {lessonEditorText} from '../../../lessonEditorText';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';

interface Props {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentMChoice;
  lessonEditorStore?: LessonEditorStore;
  onCancel: () => void;
  onChangesMade: () => void;
  isPublished?: boolean;
  rootId: string;
}

const MCHoiceOptionsContainer = inject('lessonEditorStore')(
  observer(
    ({
      entityToEdit,
      lessonEditorStore,
      onCancel,
      onChangesMade,
      isPublished,
      rootId,
    }: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const [showSoftDelete, setShowSoftDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onEdit = (
        question: string,
        answers: LessonContentMChoiceAnswer[],
        gradeWeight: number,
      ) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              question,
              answers,
              gradeWeight,
            } as LessonContentMChoice)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const onSoftDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.softDeleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const processDeleteResult = (success: boolean) => {
        if (success) {
          onChangesMade();
        } else {
          toast.error(getErrorFromCode(''), toastConfig);
        }
      };

      return entityToEdit ? (
        <>
          <MChoiceOptionsUI
            mChoice={entityToEdit}
            saveChanges={onEdit}
            isLoading={lessonEditorStore!.isLoading}
            onCancel={onCancel}
            onDelete={() => setShowDelete(true)}
            onSoftDelete={
              entityToEdit?.internalId === rootId
                ? undefined
                : () => setShowSoftDelete(true)
            }
            isPublished={isPublished}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteMChoice}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteMChoiceSoft}
            onAccept={onSoftDelete}
            onCancel={() => setShowDelete(false)}
            shown={showSoftDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      ) : null;
    },
  ),
);

export default MCHoiceOptionsContainer;

import React, {useContext, useEffect, useState} from 'react';
import {NodeData} from '../models';
import EditorTooltipContent from '../../../uiToolkit/EditorTooltopButton/EditorTooltipContent';
import {
  CHALLENGE_BRANCH_HEADER,
  CHOICE_HEADER_NODE_TYPE,
  JUMP_TO_NODE,
  getNextStepsForNodeByType,
} from '../../../data/models/LessonContent/LessonContentBase';
import {EditorContext} from '../container/LessonEditorContainer';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';
import {getIconByType, getTooltipByType} from '../config';
import {replaceIc} from '../../../assets/images';
import Tooltip from '../../../uiToolkit/Tooltip';
import {lessonEditorText} from '../lessonEditorText';

interface Props {
  currentNode: NodeData;
}

const NodeReplace = React.memo(({currentNode}: Props) => {
  const context = useContext(EditorContext);

  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    document.body.addEventListener('click', onDocumentClick);
    return () => {
      removeEventListener('click', onDocumentClick);
    };
  }, []);

  const onDocumentClick = () => {
    setShowActions(false);
    const currentSelectedNode = document.querySelector(
      `[data-testid='rf__node-${currentNode.payload.internalId}']`,
    );
    if (currentSelectedNode) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      currentSelectedNode.style.zIndex = 0;
    }
  };

  const getCurrentNodeParent = () => {
    const lessonContent: LessonContentList = context.lessonContent;
    if (!lessonContent.contentList || !lessonContent.contentList.items) return;
    const parents =
      lessonContent.contentList.items[currentNode.payload.internalId]
        ?.parentsIds;
    if (!parents || !parents.length) {
      return undefined;
    }
    return lessonContent.contentList.items[parents[0]]?.data;
  };

  const getCurrentNodeChild = () => {
    const lessonContent: LessonContentList = context.lessonContent;
    if (!lessonContent.contentList || !lessonContent.contentList.items) return;
    const children =
      lessonContent.contentList.items[currentNode.payload.internalId]?.childIds;
    if (!children || !children.length) {
      return undefined;
    }
    return lessonContent.contentList.items[children[0]]?.data;
  };

  const getActionsByParent = () => {
    const parent = getCurrentNodeParent();
    let availableActions = getNextStepsForNodeByType(
      parent?.ivNodeType,
      context.isGradable,
      parent?.internalId !== undefined,
    );
    availableActions = availableActions.filter(
      a => a !== CHALLENGE_BRANCH_HEADER && a !== CHOICE_HEADER_NODE_TYPE,
    );
    const child = getCurrentNodeChild();
    if (child) {
      availableActions = availableActions.filter(a => a !== JUMP_TO_NODE);
    }
    return availableActions.map(item => ({
      icon: getIconByType(item),
      action: () =>
        context.addNewNode(parent?.internalId, item, currentNode.payload),
      tooltip: getTooltipByType(item),
    }));
  };

  const onAction = (action: () => void) => {
    setShowActions(false);
    action();
  };

  const onToggleTooltip = (e: any) => {
    e.stopPropagation();
    const currentSelectedNode = document.querySelector(
      `[data-testid='rf__node-${currentNode.payload.internalId}']`,
    );
    if (currentSelectedNode) {
      if (!showActions) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        currentSelectedNode.style.zIndex = 2;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        currentSelectedNode.style.zIndex = 0;
      }
    }
    setShowActions(!showActions);
  };

  return !context.isPublished ? (
    <>
      <button className="ReplaceButton" onClick={onToggleTooltip}>
        <Tooltip tooltipText={lessonEditorText.replace} greenTooltip>
          <img src={replaceIc} />
        </Tooltip>
      </button>
      <div className="ReplaceActionsContainer">
        {showActions && (
          <EditorTooltipContent
            actions={getActionsByParent()}
            onAction={onAction}
          />
        )}
      </div>
    </>
  ) : null;
});

export default NodeReplace;

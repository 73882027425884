export const userProfileText = {
  nameTitle: 'Full Name',
  namePlaceholder: 'Please Enter Your Full Name',
  imageTitle: 'Profile Picture',
  imageSubtitle: 'Please Upload Your Profile Picture',
  emailTitle: 'Email',
  emailPlaceholder: 'Please Enter Your Email',
  currentPasswordTitle: 'Current Password',
  currentPasswordPlaceholder: 'Please Enter Your Current Password',
  newPasswordTitle: 'Password',
  newPasswordPlaceholder: 'Please Enter Your New Password',
  confirmPasswordTitle: 'Confirm Password',
  confirmPasswordPlaceholder: 'Please Enter Your New Password Again',
  nameUpdateFailed: 'Failed to Update Name',
  nameUpdateSuccessful: 'Successfully Updated Name',
  profilePictureUpdateFailed: 'Failed to Update Profile Picture',
  profilePictureUpdateSuccessful: 'Successfully Updated Profile Picture',
  passwordUpdateFailed: 'Failed to Update Password',
  passwordUpdateSuccessful: 'Successfully Updated Password',
  nameShortLength: 'Please provide your Name',
  passwordAndConfirmPasswordMismatch:
    'Please make sure your new password and confirm password match',
  passwordAndConfirmPasswordEmpty: 'Please provide your new password',
  currentPasswordEmpty: 'Please provide your current password',
};

import {Edge, Node} from 'reactflow';
import {
  LessonContentItem,
  LessonContentList,
} from '../../../data/models/LessonContent/LessonContentList';
import {
  ACKNOWLEDGMENT_NODE,
  BRANCH_BACKGROUND_FREEFORM,
  BRANCH_IMAGE_FREEFORM,
  BRANCH_SHAPE_FREEFORM,
  BRANCH_TEXT_FREEFORM,
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_AI_CORRECT,
  CHALLENGE_BRANCH_AI_INCORRECT,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_IMAGE_OPTION,
  CHALLENGE_BRANCH_QUESTION,
  CHALLENGE_BRANCH_TEXT_OPTION,
  CHOICE_HEADER_NODE_TYPE,
  CHOICE_IMAGE_OPTION,
  CHOICE_TEXT_OPTION,
  CHOICE_TEXT_QUESTION,
  JUMP_TO_NODE,
  LessonContentBase,
  MULTIPLE_CHOICE,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';
import {
  defaultEdgeOptions,
  defaultNodeOptions,
} from '../../../modules/EditorSettings/settings';

const ENTRY_NODE_ID = 'entry';
const CREATE_NEW_NODE_ID = 'create';

const MAXIMUM_CHILDREN_PER_BRANCH = 4;

const EntryNode: LessonContentItem = {
  data: {
    internalId: ENTRY_NODE_ID,
    ivNodeType: -1,
  },
  parentsIds: [],
  childIds: [],
};

export const buildNodeTree = (
  lessonTitle: string,
  lessonContent: LessonContentList,
  skipFinishers?: boolean,
) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  const {contentList} = lessonContent;

  nodes.push(getEntryNode(lessonTitle));

  if (!contentList.rootId) {
    const {node, edge} = getFinisherNodeEdgePair(EntryNode.data);
    nodes.push(node);
    edges.push(edge);
    return {nodes, edges};
  }

  if (contentList.rootId) {
    const firstNode = contentList.items[contentList.rootId];
    const {nodes: nodesFilled, edges: edgesFilled} = getNodeTreeRecursive(
      firstNode,
      lessonContent,
      skipFinishers,
    );
    return {
      nodes: [...nodes, ...nodesFilled],
      edges: [...edges, ...edgesFilled],
    };
  }

  return {
    nodes: [...nodes],
    edges: [...edges],
  };
};

const getNodeTreeRecursive = (
  currentNode: LessonContentItem,
  lessonContent: LessonContentList,
  skipFinishers?: boolean,
) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  let currentNodeParent = EntryNode;
  if (currentNode?.parentsIds?.length) {
    currentNodeParent =
      lessonContent.contentList.items[currentNode.parentsIds[0]];
  }

  nodes.push(getSourceNode(currentNode.data, currentNodeParent.data));
  edges.push(
    getEdge(
      currentNode.data.internalId,
      currentNodeParent.data.internalId,
      currentNodeParent.data.ivNodeType,
    ),
  );

  if (currentNode.childIds) {
    currentNode.childIds.forEach(childId => {
      const {nodes: childNodes, edges: childEdges} = getNodeTreeRecursive(
        lessonContent.contentList.items[childId],
        lessonContent,
        skipFinishers,
      );
      nodes.push(...childNodes);
      edges.push(...childEdges);
    });
  }

  if (!skipFinishers) {
    const finishers = tryAddFinisherNode(currentNode);
    if (finishers) {
      nodes.push(...finishers.nodes);
      edges.push(...finishers.edges);
    }
  }

  return {nodes, edges};
};

const tryAddFinisherNode = (currentNode: LessonContentItem) => {
  if (currentNode.data.ivNodeType === JUMP_TO_NODE) {
    return null;
  }
  if (
    isNodeBranching(currentNode.data.ivNodeType) &&
    currentNode.childIds.length < MAXIMUM_CHILDREN_PER_BRANCH &&
    currentNode.childIds.length > 0
  ) {
    const {node, edge} = getFinisherNodeEdgePair(currentNode.data);
    return {nodes: [node], edges: [edge]};
  } else if (
    isNodeBranching(currentNode.data.ivNodeType) &&
    currentNode.childIds.length === 0
  ) {
    const finisherNodes = [];
    const finisherEdges = [];
    finisherNodes.push(
      getFinisherNode(
        currentNode.data,
        `${currentNode.data.internalId}-branch-first`,
      ),
    );
    finisherEdges.push(
      getEdge(
        `${currentNode.data.internalId}-branch-first-${currentNode.data.internalId}`,
        currentNode.data.internalId,
        currentNode.data.ivNodeType,
      ),
    );
    finisherNodes.push(
      getFinisherNode(
        currentNode.data,
        `${currentNode.data.internalId}-branch-second`,
      ),
    );
    finisherEdges.push(
      getEdge(
        `${currentNode.data.internalId}-branch-second-${currentNode.data.internalId}`,
        currentNode.data.internalId,
        currentNode.data.ivNodeType,
      ),
    );
    return {nodes: finisherNodes, edges: finisherEdges};
  } else if (!currentNode.childIds.length) {
    const {node, edge} = getFinisherNodeEdgePair(currentNode.data);
    return {nodes: [node], edges: [edge]};
  }

  return null;
};

const getEntryNode = (lessonTitle: string) => ({
  id: ENTRY_NODE_ID,
  data: {lessonTitle},
  type: 'entryNode',
  ...defaultNodeOptions,
});

const getEdge = (
  target: string,
  source: string,
  parentType?: number,
  finisherEdge?: boolean,
) => ({
  id: `e-${target}-${source}`,
  source: source,
  target: target,
  ...defaultEdgeOptions(parentType, false, finisherEdge),
});

const getSourceNode = (node: LessonContentBase, parent: LessonContentBase) => ({
  id: node.internalId,
  data: {
    payload: node,
    type: node.ivNodeType,
    parent,
  },
  type: getElementTypeFromNode(node.ivNodeType),
  ...defaultNodeOptions,
});

const getFinisherNodeEdgePair = (source: LessonContentBase) => {
  return {
    node: getFinisherNode(source),
    edge: getEdge(
      `${CREATE_NEW_NODE_ID}-${source.internalId}`,
      source.internalId,
      source.ivNodeType,
      true,
    ),
  };
};

const getFinisherNode = (parent: LessonContentBase, overrideId?: string) => ({
  id: `${overrideId ? overrideId : CREATE_NEW_NODE_ID}-${parent.internalId}`,
  data: {
    parent: parent.internalId !== ENTRY_NODE_ID ? parent : undefined,
  },
  type: 'addNewSourceNode',
  ...defaultNodeOptions,
});

const getElementTypeFromNode = (nodeType: number) => {
  switch (nodeType) {
    case VIDEO_NODE_TYPE:
    case VIMEO_VIDEO_TYPE:
      return 'elementCard';
    case CHOICE_HEADER_NODE_TYPE:
      return 'choiceHeader';
    case CHOICE_TEXT_QUESTION:
      return 'choiceQuestion';
    case CHOICE_TEXT_OPTION:
      return 'choiceTextOption';
    case CHALLENGE_BRANCH_IMAGE_OPTION:
      return 'challengeBranchImageNode';
    case CHOICE_IMAGE_OPTION:
      return 'choiceImageNode';
    case MULTIPLE_CHOICE:
      return 'mChoiceNode';
    case CHALLENGE_BRANCH_HEADER:
      return 'challangeBranchHeader';
    case CHALLENGE_BRANCH_QUESTION:
      return 'challengeBranchQuestionNode';
    case CHALLENGE_BRANCH_TEXT_OPTION:
      return 'challengeBranchTextNode';
    case CHALLENGE_BRANCH_AI:
      return 'challengeBranchAINode';
    case CHALLENGE_BRANCH_AI_CORRECT:
      return 'challengeBranchAICorrectNode';
    case CHALLENGE_BRANCH_AI_INCORRECT:
      return 'challengeBranchAIInorrectNode';
    case BRANCH_IMAGE_FREEFORM:
      return 'choiceFreeformImage';
    case BRANCH_TEXT_FREEFORM:
      return 'choiceFreeformText';
    case BRANCH_SHAPE_FREEFORM:
      return 'choiceFreeformShape';
    case JUMP_TO_NODE:
      return 'jumpToNode';
    case BRANCH_BACKGROUND_FREEFORM:
      return 'choiceVideoBackground';
    case ACKNOWLEDGMENT_NODE:
      return 'acknowledgmentNode';
    default:
      return 'elementCard';
  }
};

const isNodeBranching = (type: number) => {
  switch (type) {
    case CHALLENGE_BRANCH_QUESTION:
    case CHOICE_TEXT_QUESTION:
      return true;
    default:
      return false;
  }
};

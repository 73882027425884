import AnalyticsApi from '../api/AnalyticsAPI';

class AnalyticsRepository {
  private analyticsApi = new AnalyticsApi();

  public getAdminAnalytics() {
    return this.analyticsApi.getAdminAnalytics();
  }

  public getLastAccessData(page: number, pageSize: number) {
    return this.analyticsApi.getLastAccessData(page, pageSize);
  }
}

export default AnalyticsRepository;

import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import CourseAccessGateUI from '../view/CourseAccessGateUI';
import CourseAccessGateStore from '../store/CourseAccessGateStore';
import {useParams} from 'react-router-dom';

interface Props {
  children: any;
  courseAccessGateStore?: CourseAccessGateStore;
  publishedCourse?: boolean;
}

const CourseAccessGateContainer = inject('courseAccessGateStore')(
  observer(({children, courseAccessGateStore, publishedCourse}: Props) => {
    const [missingFeature, setMissingFeature] = useState<string>();
    const {organizationId, courseId} = useParams();

    useEffect(() => {
      if (courseId) {
        if (publishedCourse) {
          courseAccessGateStore!
            .checkPublishedCourseAccess(courseId)
            .then(setMissingFeature);
        } else if (organizationId) {
          courseAccessGateStore!
            .checkCourseAccess(organizationId, courseId)
            .then(setMissingFeature);
        }
      }
    }, []);

    return !missingFeature ? (
      children
    ) : (
      <CourseAccessGateUI missingFeature={missingFeature} />
    );
  }),
);

export default CourseAccessGateContainer;

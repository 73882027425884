import React, {useEffect} from 'react';
import {
  logoTextWhite,
  pauseControlsIcon,
  playControlsIcon,
} from '../../assets/images';
import {intervalToDuration} from 'date-fns';
import './styles.css';

const CONTROLS_FADE_TIMEOUT = 3000;

interface Props {
  playing: boolean;
  togglePlaying: () => void;
  currentTime: number;
  seekTo: (time: number) => void;
  duration: number;
  setPlaying: (playing: boolean) => void;
  dontHide?: boolean;
  noLogo?: boolean;
  slim?: boolean;
}

const VideoPlayerControls = React.memo(
  ({
    playing,
    togglePlaying,
    currentTime,
    seekTo,
    duration,
    setPlaying,
    dontHide,
    noLogo,
    slim,
  }: Props) => {
    const [seekWidth, setSeekWidth] = React.useState(0);
    const [controlsPosition, setControlsPosition] = React.useState({
      active: false,
    });
    const [showControls, setShowControls] = React.useState(true);

    const seekLineRef = React.useRef<any>(null);

    useEffect(() => {
      if (showControls && !dontHide) {
        setTimeout(() => {
          setShowControls(false);
        }, CONTROLS_FADE_TIMEOUT);
      }
    }, [showControls]);

    useEffect(() => {
      setTimeout(() => {
        setBaseLineWidth();
      }, 1000);

      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('resize', setBaseLineWidth);
      return () => {
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('resize', setBaseLineWidth);
      };
    }, []);

    const handleMouseUp = () => {
      if (controlsPosition.active) {
        setPlaying(true);
        setControlsPosition({
          active: false,
        });
      }
    };

    const setBaseLineWidth = () => {
      if (seekLineRef && seekLineRef.current) {
        setSeekWidth(seekLineRef.current.offsetWidth);
      }
    };

    React.useLayoutEffect(() => {
      if (seekLineRef && seekLineRef.current) {
        setSeekWidth(seekLineRef.current.offsetWidth);
      }
    }, []);

    const getCurrentTimeText = React.useCallback(
      (seconds: number) => {
        const durationInterval = intervalToDuration({
          start: 0,
          end: Math.ceil(seconds) * 1000,
        });
        const zeroPad = (num: any) => String(num).padStart(2, '0');
        if (duration > 3600) {
          return [
            durationInterval.hours,
            durationInterval.minutes,
            durationInterval.seconds,
          ]
            .map(zeroPad)
            .join(':');
        }
        return [durationInterval.minutes, durationInterval.seconds]
          .map(zeroPad)
          .join(':');
      },
      [currentTime, duration],
    );

    const onContainerPointerMove = () => {
      if (!showControls) {
        setShowControls(true);
      }
    };

    const onPointerMove = (e: any) => {
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      if (controlsPosition.active) {
        if (x < 0) {
          seekTo(0);
        }
        if (x > seekWidth) {
          seekTo(duration);
        } else {
          seekTo((duration * x) / seekWidth);
        }
      }
    };

    const onPointerDown = () => {
      setPlaying(false);
      setControlsPosition({
        active: true,
      });
    };

    const onPointerUp = () => {
      setPlaying(true);
      setControlsPosition({
        active: false,
      });
    };

    const onSeekClick = (e: any) => {
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      if (x < 0) {
        seekTo(0);
      }
      if (x > seekWidth) {
        seekTo(duration);
      } else {
        seekTo((duration * x) / seekWidth);
      }
    };

    const calculateProgressWidth = React.useCallback(() => {
      const result = (Math.ceil(currentTime) * seekWidth) / Math.ceil(duration);
      if (result > seekWidth) {
        return seekWidth;
      }
      return result;
    }, [currentTime]);

    return (
      <div
        className={`VideoPlayerControls ${
          !showControls ? 'VideoPlayerControlsHidden' : ''
        } ${slim && 'VideoPlayerControlsSlim'}`}
        onPointerMove={onContainerPointerMove}>
        {!noLogo && (
          <img src={logoTextWhite} alt="logo-text-white" className="Logo" />
        )}
        <button className="PlayButton" onClick={togglePlaying}>
          <img
            src={playing ? pauseControlsIcon : playControlsIcon}
            alt="play"
            className="Icon"
          />
        </button>
        <h6 className="TimeText">{getCurrentTimeText(currentTime)}</h6>
        <div className="SeekLineContainer">
          <div
            className="SeekLineOuter"
            onPointerMove={onPointerMove}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            ref={seekLineRef}
            onClick={onSeekClick}>
            <div
              className="SeekLineInner"
              style={{width: `${calculateProgressWidth()}px`}}
            />
          </div>
        </div>
        <h6 className="TimeText">{getCurrentTimeText(duration)}</h6>
      </div>
    );
  },
);

export default VideoPlayerControls;

export const homeText = {
  welcome: 'Welcome',
  yourCourses: 'Your courses',
  viewAll: 'View all',
  noCourses: 'Take the first step!',
  explore: 'Explore our extensive public library of interactive tapybl courses',
  noLearnerCourses: 'Looks like you have not been enrolled in any courses yet!',
  create: 'Become a tapybl instructor with your own workspace and course',
  exploreAction: 'Explore Courses',
  createAction: 'Create Workspace',
  createCourseAction: 'Create a course',
  progress: 'Progress',
  teachingTitle: 'My Teaching Courses',
  learningTitle: 'My Learning Courses',
};

import React from 'react';
import './styles.css';
import {openWorkspaceText} from '../openWorkspaceText';

interface Props {
  onOpenDashboard: () => void;
}

const OpenDashboardUI = React.memo(({onOpenDashboard}: Props) => {
  return (
    <button className="OpenDashboardButton" onClick={onOpenDashboard}>
      {openWorkspaceText.workspace}
    </button>
  );
});

export default OpenDashboardUI;

import React, {useLayoutEffect, useRef} from 'react';
import './styles.css';

type TextAreaUITypes = 'standart' | 'box';

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  heading?: string;
  className?: string;
  fieldError?: boolean;
  isMandatory?: boolean;
  uiType?: TextAreaUITypes;
}

const TextAreaInput = React.memo(
  ({
    value,
    onChange,
    placeholder,
    heading,
    className,
    fieldError,
    isMandatory,
    uiType,
  }: Props) => {
    const textbox = useRef<any>(null);

    const adjustHeight = () => {
      if (textbox && textbox.current) {
        textbox.current.style.height = 'inherit';
        textbox.current.style.height = `${textbox.current.scrollHeight}px`;
      }
    };

    useLayoutEffect(adjustHeight, []);

    const handleKeyDown = (e: any) => {
      onChange(e.target.value);
      adjustHeight();
    };

    const getBaseClass = () => {
      switch (uiType) {
        case 'box':
          return 'TextAreaBoxInput';
        default:
          return 'TextAreaInput';
      }
    };

    return (
      <div className={className}>
        <p className="TextAreaHeading">
          {heading}
          {isMandatory ? <p className="MandatoryIndicator">*</p> : ''}
        </p>
        <div className="TextAreaContainer">
          <textarea
            value={value}
            ref={textbox}
            onChange={handleKeyDown}
            placeholder={placeholder}
            className={`${getBaseClass()} ${fieldError ? 'FieldError' : ''}`}
          />
        </div>
      </div>
    );
  },
);

export default TextAreaInput;

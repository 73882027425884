import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../../data/repositories/OrganizationRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {settingsText} from '../../settingsText';

class StorageIntegrationStore {
  private organizationRepo = new OrganizationRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async addVimeoSecret(organizationId: string, secret: string) {
    this.isLoading = true;
    const res = await this.organizationRepo.addVimeoSecret(
      organizationId,
      secret,
    );
    this.isLoading = false;
    if (!res.success && res.errors && res.errors.length) {
      toast.error(getErrorFromCode(res.errors[0]), toastConfig);
    } else if (res.success) {
      toast.success(settingsText.vimeoAdded, toastConfig);
    }
    return res.success;
  }

  public async getHaveVimeoIntegration(organizationId: string) {
    this.isLoading = true;
    const res =
      await this.organizationRepo.getHaveVimeoIntegration(organizationId);
    this.isLoading = false;
    if (res.success && res.data) {
      return res.data;
    }
    return res.success;
  }
}

export default StorageIntegrationStore;

import React from 'react';
import {settingsText} from '../../settingsText';
import {Col, Row} from 'react-bootstrap';
import './styles.css';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import {formatDateTimeTextFormat} from '../../../../utils/notificationTimeTextConvertor';
import {OrganizationSubscription} from '../../../../data/models/Subscription/Subscription';
import InvoicesTable from '../components/InvoicesTable';
import ActionsSection from '../components/ActionsSection';

interface Props {
  // onUpgradeSubscription: () => void;
  subscription: OrganizationSubscription;
  onCancelSubscription: () => void;
  onChangePaymentMethod: () => void;
  invoices: any[];
  isLoading: boolean;
}

const SubscriptionSettingsUI = React.memo(
  ({
    subscription,
    onCancelSubscription,
    onChangePaymentMethod,
    // onUpgradeSubscription,
    invoices,
    isLoading,
  }: Props) => {
    return (
      <div className="SubscriptionSettingsContainer">
        <Row>
          <Col xs={12} lg={6}>
            {/* <FieldButtonInput
              heading={
                subscription.trialEnd
                  ? settingsText.trialSubscription
                  : subscription.subscription.name
              }
              value={
                subscription.trialEnd
                  ? settingsText.trialSubscription
                  : subscription.subscription.name
              }
              buttonText={settingsText.upgradeNow}
              onClick={onUpgradeSubscription}
            /> */}
            <TextInput
              value={
                subscription.trialEnd
                  ? settingsText.trialSubscription
                  : subscription.subscription.name
              }
              heading={
                subscription.trialEnd
                  ? settingsText.trialSubscription
                  : subscription.subscription.name
              }
              onChange={() => {
                //nothing
              }}
              placeholder=""
              className="SubscriptionField"
              noEdit
            />
            <TextInput
              value={formatDateTimeTextFormat(
                subscription.currentPeriodEndDate,
              )}
              heading={settingsText.expirationDate}
              onChange={() => {
                //nothing
              }}
              placeholder=""
              className="SubscriptionField"
              noEdit
            />
            {subscription?.last4 && (
              <TextInput
                value={`****_****_****_${subscription.last4}`}
                heading={settingsText.cardDetails}
                onChange={() => {
                  //nothing
                }}
                placeholder=""
                className="SubscriptionField"
                noEdit
              />
            )}
          </Col>
          <Col xs={12}>
            <InvoicesTable invoices={invoices} isLoading={isLoading} />
            <ActionsSection
              onCancelSubscription={onCancelSubscription}
              onChangePaymentMethod={onChangePaymentMethod}
            />
          </Col>
        </Row>
      </div>
    );
  },
);

export default SubscriptionSettingsUI;

import React from 'react';
import {CourseAccessGate} from '../../modules/CourseAccessGate';
import LTIPlayerContainer from './container/LTIPlayerContainer';

const LTIPlayer = () => {
  return (
    <CourseAccessGate>
      <LTIPlayerContainer />
    </CourseAccessGate>
  );
};

export {LTIPlayer};

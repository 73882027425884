import React from 'react';
import GradebookTable from '../components/GradebookTable';
import SearchInput from '../../../uiToolkit/Inputs/SearchInput';
import {
  GradebookGrades,
  GradebookLearner,
  GradebookLesson,
} from '../../../data/models/Course/Gradebook';
import PageTitle from '../../../uiToolkit/PageTitle';
import {gradebookText} from '../gradebookText';
import './styles.css';

interface Props {
  search: string;
  setSearch: (search: string) => void;
  lessons: GradebookLesson[];
  learners: GradebookLearner[];
  grades: GradebookGrades;
  pagesCount: number;
  itemsPerPage: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onOpenLearningJourney: (
    lessonId: string,
    learnerId: string,
    courseId: string,
  ) => void;
}

const GradebookUI = React.memo(
  ({
    search,
    setSearch,
    learners,
    lessons,
    grades,
    pagesCount,
    itemsPerPage,
    currentPage,
    onChangePage,
    onOpenLearningJourney,
  }: Props) => {
    return (
      <div className="GradebookContainer">
        <PageTitle title={gradebookText.title} />
        <div className="Header">
          <SearchInput value={search} onChange={setSearch} />
        </div>
        {learners.length && lessons.length ? (
          <GradebookTable
            learners={learners}
            lessons={lessons.sort((x, y) => (x.order > y.order ? 1 : -1))}
            grades={grades}
            pagesCount={pagesCount}
            onChangePage={onChangePage}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onOpenLearningJourney={onOpenLearningJourney}
          />
        ) : (
          <p className="NoLearners">No records</p>
        )}
      </div>
    );
  },
);

export default GradebookUI;

import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import UserAvatar from '../../../uiToolkit/UserAvatar';
import {UserInfo} from '../../../data/models/User/UserInfo';
import NavigationUserAvatarStore from '../store/NavigationUserAvatarStore';

interface Props {
  navigationUserAvatarStore?: NavigationUserAvatarStore;
}

const NavigationUserAvtarContainer = inject('navigationUserAvatarStore')(
  observer(({navigationUserAvatarStore}: Props) => {
    const [user, setUser] = useState<UserInfo>();

    useEffect(() => {
      navigationUserAvatarStore!.getUserInfo().then(setUser);
    }, []);

    return <UserAvatar username={user?.name} avatarUrl={user?.avatarUrl} />;
  }),
);

export default NavigationUserAvtarContainer;

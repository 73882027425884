import React, {useEffect, useState} from 'react';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {lessonEditorText} from '../../../lessonEditorText';
import {LessonContentChallengeBranchAI} from '../../../../../data/models/LessonContent/LessonContentBase';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import {GradeWeights} from '../../../../../data/models/LessonContent/GradeWeights';
import Dropdown from '../../../../../uiToolkit/Inputs/Dropdown';

interface Props {
  entityToEdit?: LessonContentChallengeBranchAI;
  isPublished: boolean;
  onDelete: () => void;
  isLoading: boolean;
  onEdit: (question: string, gradeWeight: number) => void;
}

const ChallengeBranchAIOptionsUI = React.memo(
  ({entityToEdit, isPublished, onDelete, isLoading, onEdit}: Props) => {
    const [question, setQuestion] = useState('');
    const [gradeWeight, setGradeWeight] = useState('1');

    useEffect(() => {
      if (entityToEdit) {
        setQuestion(entityToEdit.question);
        setGradeWeight(entityToEdit.gradeWeight.toString());
      }
    }, [entityToEdit]);

    const onSave = () => {
      onEdit(question, parseInt(gradeWeight, 10));
    };

    const changesWasMade = () =>
      entityToEdit?.question !== question ||
      entityToEdit?.gradeWeight.toString() !== gradeWeight;

    return (
      <div className="ChallengeBranchOptionsContainer">
        <TextInput
          heading={lessonEditorText.question}
          value={question}
          onChange={setQuestion}
          placeholder={lessonEditorText.question}
          fieldError={question.trim() === ''}
          maxCharacters={250}
          isMandatory={true}
          uiType="box"
        />
        <Dropdown
          options={GradeWeights}
          setSelectedOption={setGradeWeight}
          selectedOption={gradeWeight}
          heading={lessonEditorText.gradeWeight}
          type="box"
        />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && question.trim() !== ''}
          onDelete={onDelete}
          deleteTitle={lessonEditorText.delete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default ChallengeBranchAIOptionsUI;

import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import AddVideoOverlayUI from '../view/AddVideoOverlayUI';
import {VideoOverlay} from '../../../../data/models/LessonContent/ContentFreeform';
import {useParams} from 'react-router-dom';
import {LessonShortModel} from '../../../../data/models/Course/Lesson';
import EditVideoOverlayStore from '../store/EditVideoOverlayStore';

interface Props {
  editVideoOverlayStore?: EditVideoOverlayStore;
}

const EditVideoOverlayContainer = inject('editVideoOverlayStore')(
  observer(({editVideoOverlayStore}: Props) => {
    const [lessonsList, setLessonsList] = useState<LessonShortModel[]>([]);
    const {organizationId, lessonId, courseId} = useParams();

    useEffect(() => {
      if (organizationId && courseId) {
        editVideoOverlayStore!
          .getLessonsList(organizationId, courseId)
          .then(lessonsResult =>
            setLessonsList(lessonsResult.filter(x => x.lessonId !== lessonId)),
          );
      }
    }, []);

    const onClose = () => {
      editVideoOverlayStore!.closeWizard();
    };

    const onSave = (start: number, end: number, overlays: VideoOverlay[]) => {
      if (organizationId && lessonId) {
        editVideoOverlayStore!.editOverlayNode(
          organizationId,
          lessonId,
          start,
          end,
          overlays,
        );
      }
    };

    return editVideoOverlayStore!.shouldShowWizard() ? (
      <AddVideoOverlayUI
        onCancel={onClose}
        selectedFile={editVideoOverlayStore!.getVideoSource()}
        selectedVimeoFile={editVideoOverlayStore!.getVimeoSource()}
        onSave={onSave}
        lessonContent={editVideoOverlayStore!.getLessonContent()}
        lessonsList={lessonsList}
        isGradable={editVideoOverlayStore!.getIsGradable()}
      />
    ) : null;
  }),
);

export default EditVideoOverlayContainer;

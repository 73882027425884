import React from 'react';
import {editorAddItemIc, lessonArrowDown} from '../../assets/images';
import Tooltip from '../Tooltip';

interface Props {
  onClick: () => void;
  tooltip?: string;
}

const EditorCreateFirstEntry = React.memo(({onClick, tooltip}: Props) => {
  return (
    <div className="EditorCreateFirstEntry">
      <img src={lessonArrowDown} className="Arrow" />
      {tooltip ? (
        <Tooltip tooltipText={tooltip} greenTooltip>
          <button onClick={onClick}>
            <img src={editorAddItemIc} />
          </button>
        </Tooltip>
      ) : (
        <button onClick={onClick}>
          <img src={editorAddItemIc} />
        </button>
      )}
    </div>
  );
});

export default EditorCreateFirstEntry;

export interface MediaFile {
  id: string;
  fileName: string;
  fileType: string;
  fileUrl?: string;
  placeholderUrl?: string;
  dateCreated: Date;
  storageFileName: string;
  fileSize: number;
  isUsed: boolean;
  origin?: number;
  status?: number;
  fileStatus?: number;
}

export interface VimeoMediaFile {
  url: string;
  placeholderUrl: string;
  duration: number;
  title: string;
}

export const MEDIA_FILES_ALL = 0;
export const MEDIA_FILES_VIDEO = 1;
export const MEDIA_FILES_IMAGE = 2;

export const FILE_ORIGIN_ALL = 0;
export const FILE_ORIGIN_UPLOADED = 1;
export const FILE_ORIGIN_GENERATED = 2;

export const FILE_SOURCE_TAPYBL = 0;
export const FILE_SOURCE_VIMEO = 1;

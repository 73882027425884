import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import './styles.css';

interface Props {
  tabs: {
    title: string;
    route: string;
  }[];
}
const NavigationTabs = React.memo(({tabs}: Props) => {
  const location = useLocation();

  return (
    <div className="NavigationTabsContainer">
      {tabs.map(tab => (
        <Link
          key={tab.title}
          className={location.pathname === tab.route ? 'ActiveButton' : ''}
          to={tab.route}>
          {tab.title}
        </Link>
      ))}
      <div className="FillBorder" />
    </div>
  );
});

export default NavigationTabs;

import React from 'react';
import {featureDisabledIc} from '../../../assets/images';
import {courseAccessGateText} from '../courseAccessGateText';
import {getSubscriptionFeatureName} from '../../../data/permissions';
import './styles.css';
import {Link} from 'react-router-dom';

interface Props {
  missingFeature: string;
}

const CourseAccessGateUI = React.memo(({missingFeature}: Props) => {
  return (
    <div className="CourseAccessGateContainer">
      <img src={featureDisabledIc} />
      <h1>{courseAccessGateText.title}</h1>
      <h3>{courseAccessGateText.subtitle}</h3>
      <p>{courseAccessGateText.accessNotAllowed}</p>
      <p className="Feature">{getSubscriptionFeatureName(missingFeature)}</p>
      <p>{courseAccessGateText.contactAdmin}</p>
      <Link to="/home">{courseAccessGateText.home}</Link>
    </div>
  );
});

export default CourseAccessGateUI;

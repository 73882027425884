import {makeAutoObservable} from 'mobx';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';

class SubscriptionSettingsStore {
  private subscriptionRepo = new SubscriptionRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getOrganizationSubscription(organizationId: string) {
    this.isLoading = true;
    const result =
      await this.subscriptionRepo.getOrganizationSubscription(organizationId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getPaymentMethodSession(organizationId: string) {
    this.isLoading = true;
    const result =
      await this.subscriptionRepo.getPaymentMethodSession(organizationId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    } else if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }

  public async cancelSubscription(organizationId: string) {
    this.isLoading = true;
    const result =
      await this.subscriptionRepo.cancelSubscription(organizationId);
    this.isLoading = false;
    return result.success;
  }

  public async getInvoicesList(organizationId: string) {
    this.isLoading = true;
    const result = await this.subscriptionRepo.getInvoicesList(organizationId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data.data;
    }
    return [];
  }
}

export default SubscriptionSettingsStore;

import {
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../data/models/LessonContent/LessonContentBase';
import {LessonContentItem} from '../../data/models/LessonContent/LessonContentList';

export const nodeIsASourceNode = (node?: LessonContentItem) => {
  return (
    node &&
    (node.data.ivNodeType === VIDEO_NODE_TYPE ||
      node.data.ivNodeType === OVERLAY_VIDEO_TYPE ||
      node.data.ivNodeType === OVERLAY_VIMEO_VIDEO_TYPE ||
      node.data.ivNodeType === VIMEO_VIDEO_TYPE)
  );
};

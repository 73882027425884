import {makeAutoObservable} from 'mobx';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import {
  JUMP_TO_NODE,
  LessonContentJumpTo,
} from '../../../../data/models/LessonContent/LessonContentBase';

class AddJumpToWizardStore {
  private lessonContentRepo = new LessonContentRepository();
  private showWizard = false;
  private parentId?: string;
  private onCreateCallback?: () => void;

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public startWizard(parentId: string, onCreateCallback: () => void) {
    this.showWizard = true;
    this.parentId = parentId;
    this.onCreateCallback = onCreateCallback;
  }

  public isVisible() {
    return this.showWizard;
  }

  public cancelWizard() {
    this.showWizard = false;
    this.parentId = undefined;
    this.onCreateCallback = undefined;
  }

  public async createOption(
    organizationId: string,
    lessonId: string,
    jumpToId: string,
  ) {
    if (this.parentId) {
      this.isLoading = true;
      const result = await this.lessonContentRepo.addLessonContent(
        organizationId,
        lessonId,
        {
          data: {
            ivNodeType: JUMP_TO_NODE,
            jumpToNodeId: jumpToId,
          } as LessonContentJumpTo,
          parentsIds: [this.parentId],
        },
      );
      this.isLoading = false;
      if (result.success && this.onCreateCallback) {
        this.onCreateCallback();
        this.cancelWizard();
      }
    }
  }
}

export default AddJumpToWizardStore;

import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';

class ExploreButtonStore {
  private orgRepo = new OrganizationRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }
  public async getLearnerOrganization() {
    this.isLoading = true;
    const result = await this.orgRepo.getMyOrganization();
    this.isLoading = false;
    return result.data;
  }
}

export default ExploreButtonStore;

import React, {useState} from 'react';
import ErrorBox from '../../../uiToolkit/ErrorBox';
import AuthHeader from '../../../uiToolkit/AuthHeader';
import {confirmEmailText} from '../confirmEmailText';
import {internalErrorCodes} from '../../../data/errorCodes';
import './styles.css';
import RetryButton from '../../../uiToolkit/Buttons/RetryButton';
import TextInput from '../../../uiToolkit/Inputs/TextInput';
import Button from '../../../uiToolkit/Buttons/Button';

interface Props {
  errors: string[];
  onConfirmEmail: (code: string) => void;
  onRetry: () => void;
  isLoading: boolean;
  onBack: () => void;
  clearErorrs: () => void;
}

const ConfirmEmailUI = React.memo(
  ({
    errors,
    onConfirmEmail,
    onRetry,
    isLoading,
    onBack,
    clearErorrs,
  }: Props) => {
    const [code, setCode] = useState('');
    const [internalErrors, setInternalErrors] = useState<string[]>([]);

    const onSubmit = () => {
      if (code !== '') {
        onConfirmEmail(code);
      } else {
        setInternalErrors([internalErrorCodes.PROVIDE_VERIFICATION_CODE]);
      }
    };

    const onClearErrors = () => {
      setInternalErrors([]);
      clearErorrs();
    };

    return (
      <>
        <div className="ConfirmEmailErrorContainer">
          <ErrorBox
            errorCodes={[...errors, ...internalErrors]}
            clearErrors={onClearErrors}
          />
        </div>
        <div className="ConfirmEmailFormContainer">
          <button className="EmailBackButton" onClick={onBack}>
            <span className="icon-typeArrow-down"></span>
            {confirmEmailText.goBack}
          </button>
          <AuthHeader
            title={confirmEmailText.title}
            subtitle={confirmEmailText.subtitle}
          />
          <div className="ConfirmEmailInputContainer">
            <TextInput
              type="text"
              value={code}
              onChange={setCode}
              placeholder={confirmEmailText.code}
              uiType="pill"
            />
          </div>
          <div className="ConfirmEmailSubmitButtonContainer">
            <Button
              title={confirmEmailText.submit}
              onClick={onSubmit}
              disabled={code.trim() === ''}
              isLoading={isLoading}
              uiType="auth_action"
              fullWidth
            />
          </div>
          <RetryButton
            onRetry={onRetry}
            title={confirmEmailText.didntGetACode}
          />
        </div>
      </>
    );
  },
);

export default ConfirmEmailUI;

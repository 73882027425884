import React from 'react';
import {Outlet} from 'react-router-dom';
import MobileRestriction from '../../../modules/MobileRestriction';

const SubscriptionRoot = React.memo(() => {
  return (
    <>
      <div className="d-none d-lg-block">
        <Outlet />
      </div>
      <div className="d-lg-none d-block">
        <MobileRestriction />
      </div>
    </>
  );
});

export default SubscriptionRoot;

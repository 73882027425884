import React from 'react';
import NavBar from '../../../../uiToolkit/NavBar';
import {CoursePresentation} from '../../../../data/models/Course/CoursePresentation';
import Breadcrumbs from '../../../../uiToolkit/Breadcrumbs';
import {coursePreviewText} from '../coursePreviewText';
import './styles.css';
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import OrganizationBadge from '../../../../uiToolkit/OrganizationBadge';
import IconButton from '../../../../uiToolkit/Buttons/IconButton';
import {
  courseActionsLessonsIc,
  dividerWhiteIc,
  lessonListPlayButtonIc,
} from '../../../../assets/images';
import {
  getMinutesFromSeconds,
  getTimeTextFromSeconds,
} from '../../../../utils/getTimeTextFromSeconds';
import Tooltip from '../../../../uiToolkit/Tooltip';
import {Folder} from '../../../../data/models/Course/Folder';
import Button from '../../../../uiToolkit/Buttons/Button';
import {formatDateTime} from '../../../../utils/notificationTimeTextConvertor';

interface Props {
  coursePresentation?: CoursePresentation;
  courseLink: string;
  onEditFolders: () => void;
  onLessonClick: (lessonId: string) => void;
  onPublishCourse: () => void;
  isLoading: boolean;
}

const CoursePreviewUI = React.memo(
  ({
    coursePresentation,
    courseLink,
    onEditFolders,
    onLessonClick,
    onPublishCourse,
    isLoading,
  }: Props) => {
    const getBreadcrumbs = () => {
      return [
        {title: coursePreviewText.courses, link: courseLink},
        {title: coursePresentation?.course.title || ''},
      ];
    };

    const getLessonDuration = (lessonId: string) => {
      return (
        coursePresentation?.lessonDurations.find(
          item => item.lessonId == lessonId,
        )?.duration || 0
      );
    };

    const calculateFolderDuration = (folderId: string) => {
      const lessons =
        coursePresentation?.folders.find(item => item.id == folderId)
          ?.lessons || [];
      let duration = 0;
      lessons.forEach(lesson => {
        duration += getLessonDuration(lesson.id);
      });
      return duration;
    };

    const folderIsValid = (folder: Folder) => {
      return (
        folder.lessons &&
        folder.lessons.length &&
        calculateFolderDuration(folder.id) !== 0
      );
    };

    const calculateLessonsAmount = () => {
      const lessons = [];
      coursePresentation?.folders.forEach(item => {
        if (item.lessons) {
          item.lessons
            .filter(x => !x.isSupplementalLesson)
            .forEach(lesson => {
              lessons.push(lesson);
            });
        }
      });
      return lessons.length;
    };

    return (
      <div>
        <NavBar />
        <Container>
          <Row>
            <Col lg={9} xs={12}>
              <div className="CoursePreviewContainer">
                <Breadcrumbs steps={getBreadcrumbs()} />
                <h2 className="Title">{coursePresentation?.course.title}</h2>
                <OrganizationBadge
                  avatar={coursePresentation?.organization.avatar}
                  name={coursePresentation?.organization.name}
                />
                <p className="Description">
                  {coursePresentation?.course.description}
                </p>
                <div className="d-flex flex-row align-items-center">
                  <p className="FoldersHeading">
                    {coursePreviewText.inThisCourse}
                  </p>
                  <IconButton
                    onClick={onEditFolders}
                    icon="icon-typeEdit-with-square"
                  />
                </div>
                {coursePresentation?.folders
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((item, index) => (
                    <Accordion
                      defaultActiveKey={
                        folderIsValid(item) ? index.toString() : ''
                      }
                      key={index.toString()}>
                      <Accordion.Item eventKey={index.toString()}>
                        <Accordion.Header>
                          <span className="accordion-title">{item.name}</span>
                          <img src={dividerWhiteIc} />
                          {
                            item.lessons?.filter(x => !x.isSupplementalLesson)
                              .length
                          }{' '}
                          {coursePreviewText.lessons}
                          <img src={dividerWhiteIc} />
                          {getMinutesFromSeconds(
                            calculateFolderDuration(item.id),
                          )}{' '}
                          {coursePreviewText.minApprox}
                          {!folderIsValid(item) && (
                            <Tooltip
                              tooltipText={coursePreviewText.invalidFolder}>
                              <span className="icon-typeWarning FolderWarning"></span>
                            </Tooltip>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          {(item.lessons || [])
                            .sort((a, b) => (a.order > b.order ? 1 : -1))
                            .filter(x => !x.isSupplementalLesson)
                            .map(lesson => (
                              <div
                                key={lesson.id}
                                onClick={() => {
                                  onLessonClick(lesson.id);
                                }}
                                className={'LessonContainer'}>
                                <div className="d-flex flex-row w-100">
                                  <img src={lessonListPlayButtonIc} />
                                  <p>{lesson.title}</p>
                                  {getLessonDuration(lesson.id) === 0 ? (
                                    <Tooltip
                                      tooltipText={
                                        coursePreviewText.invalidLesson
                                      }>
                                      <span className="icon-typeWarning LessonWarning"></span>
                                    </Tooltip>
                                  ) : null}
                                </div>
                                <p>
                                  {getTimeTextFromSeconds(
                                    getLessonDuration(lesson.id),
                                  )}
                                </p>
                              </div>
                            ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
              </div>
            </Col>
            <Col xs={12} lg={3} className="mt-4">
              <div className="CoursePublishCard">
                <img
                  className="Poster"
                  src={coursePresentation?.course.image}
                  alt=""
                />
                <p className="Title">{coursePreviewText.coursePreview}</p>
                <div className="CourseActionInfo">
                  <div>
                    <img src={courseActionsLessonsIc} />
                    <span className="Info">
                      {calculateLessonsAmount()}{' '}
                      {coursePreviewText.lessonsAmount(
                        calculateLessonsAmount(),
                      )}
                    </span>
                  </div>
                </div>
                <Button
                  title={
                    coursePresentation?.course.isPublished
                      ? coursePreviewText.republishCourse
                      : coursePreviewText.publishCourse
                  }
                  onClick={onPublishCourse}
                  className="PublishButton"
                  uiType="action"
                  isLoading={isLoading}
                />
                {coursePresentation?.course?.dateLastPublished && (
                  <p className="PublishDate">
                    {coursePreviewText.datePublished}{' '}
                    {formatDateTime(
                      coursePresentation?.course?.dateLastPublished,
                    )}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default CoursePreviewUI;

export interface Organization {
  id: string;
  name: string;
  description: string;
  primaryColor: string;
  secondaryColor: string;
  avatar?: string;
  avatarName?: string;
  totalLibraryMediaFileSizesInMb: number;
  type: number;
  trialSubscriptionUsed?: boolean;
}

export interface OrganizationDetails {
  id: string;
  name: string;
  description: string;
  primaryColor?: string;
  secondaryColor?: string;
  avatar?: string;
  userType: number;
}

export const ORGANIZATION_USER_LEARNER = 0;
export const ORGANIZATION_USER_STAFF = 1;

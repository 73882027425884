import * as React from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {homeText} from '../homeText';
import {UserInfo} from '../../../data/models/User/UserInfo';
import {
  LearningCourseModel,
  TeachingCourseModel,
} from '../../../data/models/Course/CourseList';
import UserEmptyState from '../components/UserEmptyState';
import OrganizationEmptyState from '../components/OrganizationEmptyState';
import CoursesCard from '../components/CoursesCard';
import './styles.css';
import {
  ORGANIZATION_USER_LEARNER,
  ORGANIZATION_USER_STAFF,
  OrganizationDetails,
} from '../../../data/models/Oganization/Organization';
import LearnerEmptyState from '../components/LearnerEmptyState';

interface Props {
  userInfo?: UserInfo;
  userOrganizationDetails?: OrganizationDetails;
  isLoading: boolean;
  onExplore: () => void;
  onCreateOrg: () => void;
  onCreateCourse: () => void;
  teachingCourses: TeachingCourseModel[];
  learningCourses: LearningCourseModel[];
  onOpenCourse: (courseId: string, teaching?: boolean) => void;
  dataFetched?: boolean;
}

const HomeUI = React.memo(
  ({
    userInfo,
    isLoading,
    onCreateOrg,
    onExplore,
    onCreateCourse,
    teachingCourses,
    learningCourses,
    onOpenCourse,
    userOrganizationDetails,
    dataFetched,
  }: Props) => {
    return (
      <div>
        <NavBar />
        <Container fluid>
          <Row className="HomeStatisticsContainer">
            <Col xs={12}>
              <div className="StatisticsRow">
                <h2 className="HomeWelcomeText">
                  {homeText.welcome} {userInfo?.name}!
                </h2>
                {userOrganizationDetails &&
                userOrganizationDetails?.userType ===
                  ORGANIZATION_USER_LEARNER ? (
                  <div className="LearnerOrgDetails">
                    {userOrganizationDetails.avatar && (
                      <img src={userOrganizationDetails.avatar} />
                    )}
                    <h2>{userOrganizationDetails.name}</h2>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="HomeCoursesContainer">
          {teachingCourses?.length ? (
            <Row className="HomeCoursesContent">
              <h2 className="HomeCourseTitle">{homeText.teachingTitle}</h2>
              {teachingCourses.map((course, index) => (
                <React.Fragment key={index}>
                  <CoursesCard
                    course={course}
                    onOpenCourse={() => onOpenCourse(course.courseId, true)}
                  />
                </React.Fragment>
              ))}
            </Row>
          ) : null}

          {learningCourses?.length ? (
            <Row className="HomeCoursesContent">
              <h2 className="HomeCourseTitle">{homeText.learningTitle}</h2>
              {learningCourses.map((course, index) => (
                <React.Fragment key={index}>
                  <CoursesCard
                    course={course}
                    onOpenCourse={() => onOpenCourse(course.id)}
                  />
                </React.Fragment>
              ))}
            </Row>
          ) : null}

          {!teachingCourses.length &&
            !learningCourses.length &&
            (isLoading || !dataFetched ? (
              <div />
            ) : userOrganizationDetails?.userType ===
              ORGANIZATION_USER_STAFF ? (
              <OrganizationEmptyState onCreate={onCreateCourse} />
            ) : userOrganizationDetails?.userType ===
              ORGANIZATION_USER_LEARNER ? (
              <LearnerEmptyState />
            ) : (
              <UserEmptyState onExplore={onExplore} onCreate={onCreateOrg} />
            ))}
        </Container>
      </div>
    );
  },
);

export default HomeUI;

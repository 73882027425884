export const signupText = {
  title: 'Sign Up',
  subtitle: 'It’s quick and easy.',
  email: 'Email',
  password: 'Password',
  name: 'Full Name',
  acceptTerms: 'I agree to the',
  termsAndPrivacy: 'terms & conditions.',
  submit: 'Sign Up',
  haveAnAccount: 'Already have an account?',
  signin: 'Sign In',
};

import React from 'react';
import './styles.css';
import {closeModalIcon} from '../../assets/images';

interface Props {
  children: React.ReactElement;
  onClose: () => void;
}

const ModalContainer = React.memo(({children, onClose}: Props) => {
  return (
    <div className="ModalContainer">
      <div className="ModalContent">
        <button className="CloseButton" onClick={onClose}>
          <img src={closeModalIcon} />
        </button>
        {children}
      </div>
    </div>
  );
});

export default ModalContainer;

import React from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import DataTable from 'react-data-table-component';
import {GradeResult} from '../models';

interface Props {
  results: GradeResult;
  onCancel: () => void;
}

const columns = [
  {
    name: 'Question',
    selector: (row: {question: string; correct: boolean}) => row.question,
    cell: (row: {question: string; correct: boolean}) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.question}
      </h5>
    ),
    grow: 2.5,
  },
  {
    name: 'Result',
    selector: (row: {question: string; correct: boolean}) => row.correct,
    cell: (row: {question: string; correct: boolean}) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.correct ? 'Correct' : 'Incorrect'}
      </h5>
    ),
  },
];

const ResultsModal = React.memo(({results, onCancel}: Props) => {
  return (
    <ModalContainer onClose={onCancel}>
      <div className="ResultsModalContainer">
        <DataTable data={results.answers} columns={columns} />
      </div>
    </ModalContainer>
  );
});

export default ResultsModal;

import React, {useEffect, useState} from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import {selectSubscriptionText} from '../selectSubscriptionText';
import NumbersInput from '../../../uiToolkit/Inputs/NumbersInput';
import {
  GRADABLE_SUBSCRIPTION,
  SUBSCRIPTION_CLASSROOM,
  SUBSCRIPTION_SCHOOL,
  gradablePlans,
} from '../subscriptionPlans';
import GradableCard from '../components/GradableCard';
import Button from '../../../uiToolkit/Buttons/Button';
import {
  CLASSROOM_GRADABLE,
  CLASSROOM_NOT_GRADABLE,
  OrganizationSubscription,
  SCHOOL_GRADABLE,
  SCHOOL_NOT_GRADABLE,
} from '../../../data/models/Subscription/Subscription';
import PlanDiffSection from '../components/PlanDiffSection';

interface Props {
  selectedPlanId: number;
  onCreateSubscription: (qty: number, tier: number) => void;
  isLoading: boolean;
  upgrade?: boolean;
  trial?: boolean;
  currentSubscription?: OrganizationSubscription;
}

const CLASS_GRADABLE_PRICE = 9;
const CLASS_NOGRADABLE_PRICE = 6;
const SCHOOL_GRADABLE_PRICE = 8;
const SCHOOL_NOGRADABLE_PRICE = 5;

const TailorSubscriptionUI = React.memo(
  ({
    selectedPlanId,
    onCreateSubscription,
    isLoading,
    upgrade,
    trial,
    currentSubscription,
  }: Props) => {
    const [seats, setSeats] = useState<number | undefined>(30);
    const [gradableId, setGradableId] = useState(GRADABLE_SUBSCRIPTION);

    const getSetsTextByPlan = () => {
      if (selectedPlanId === SUBSCRIPTION_CLASSROOM) {
        return selectSubscriptionText.classroomSeatsText;
      }
      if (selectedPlanId === SUBSCRIPTION_SCHOOL) {
        return selectSubscriptionText.schoolSeatsText;
      }
    };

    useEffect(() => {
      if (selectedPlanId === SUBSCRIPTION_CLASSROOM) {
        setSeats(30);
      }
      if (selectedPlanId === SUBSCRIPTION_SCHOOL) {
        setSeats(300);
      }
    }, [selectedPlanId]);

    const getMinSeatsByPlan = () => {
      if (selectedPlanId === SUBSCRIPTION_CLASSROOM) {
        return 30;
      }
      if (selectedPlanId === SUBSCRIPTION_SCHOOL) {
        return 300;
      }
      return 30;
    };

    const getFieldError = () => {
      if (
        selectedPlanId === SUBSCRIPTION_CLASSROOM &&
        ((seats || 0) < 30 || (seats || 0) > 299)
      ) {
        return true;
      }
      if (selectedPlanId === SUBSCRIPTION_SCHOOL && (seats || 0) < 300) {
        return true;
      }
      return false;
    };

    const calculatePrice = () => {
      let price = 0;
      if (selectedPlanId === SUBSCRIPTION_CLASSROOM) {
        if (gradableId === GRADABLE_SUBSCRIPTION) {
          price = CLASS_GRADABLE_PRICE;
        } else {
          price = CLASS_NOGRADABLE_PRICE;
        }
      } else {
        if (gradableId === GRADABLE_SUBSCRIPTION) {
          price = SCHOOL_GRADABLE_PRICE;
        } else {
          price = SCHOOL_NOGRADABLE_PRICE;
        }
      }
      return (seats || 0) * price;
    };

    const onSubscribe = () => {
      if (!getFieldError()) {
        let tier = CLASSROOM_NOT_GRADABLE;
        if (selectedPlanId === SUBSCRIPTION_CLASSROOM) {
          if (gradableId === GRADABLE_SUBSCRIPTION) {
            tier = CLASSROOM_GRADABLE;
          } else {
            tier = CLASSROOM_NOT_GRADABLE;
          }
        } else {
          if (gradableId === GRADABLE_SUBSCRIPTION) {
            tier = SCHOOL_GRADABLE;
          } else {
            tier = SCHOOL_NOT_GRADABLE;
          }
        }
        onCreateSubscription(seats || getMinSeatsByPlan(), tier);
      }
    };

    return (
      <div>
        <NavBar />
        <div className="TailorSubscriptionContainer">
          <Container fluid>
            <Row>
              <Col lg={9} xs={12} style={{backgroundColor: 'white'}}>
                <div className="SubscriptionTailorTools">
                  <div className="Header">
                    <h2>{selectSubscriptionText.tailorTitle}</h2>
                    {!trial && (
                      <>
                        <p>{selectSubscriptionText.selectSeats}</p>
                        <div className="SelectSeatsContainer">
                          <NumbersInput
                            value={seats}
                            setValue={setSeats}
                            uiType="box"
                            className="Input"
                            maxValue={99999}
                            fieldError={getFieldError()}
                          />
                          <p>{selectSubscriptionText.seatsSelected}</p>
                        </div>
                        <span className="SeatsAmountDisclaimer">
                          {getSetsTextByPlan()}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <p className="GradableSelectTitle">
                  {selectSubscriptionText.selectGradableTitle}
                </p>
                <div className="GradableSelectContainer">
                  {gradablePlans.map(plan => (
                    <GradableCard
                      {...plan}
                      key={plan.id}
                      onSelect={() => setGradableId(plan.id)}
                      isSelected={gradableId === plan.id}
                    />
                  ))}
                </div>
              </Col>
              <Col lg={3} xs={12} className="SubscriptionResultContainer">
                <div className="SubscriptionResult">
                  {upgrade && currentSubscription && (
                    <PlanDiffSection
                      selectedTier={selectedPlanId}
                      currentSubscription={currentSubscription}
                      selectedIsGradable={gradableId === GRADABLE_SUBSCRIPTION}
                      selectedSeats={seats || 0}
                      isLoading={isLoading}
                      hasError={getFieldError()}
                      onSubscribe={onSubscribe}
                      currentPrice={calculatePrice()}
                    />
                  )}
                  {!upgrade && (
                    <div>
                      <div>
                        <h3>{selectSubscriptionText.yourPrice}</h3>
                        <p>
                          {selectedPlanId === SUBSCRIPTION_CLASSROOM
                            ? selectSubscriptionText.classroomPlan
                            : selectSubscriptionText.schoolPlan}
                        </p>
                        {!trial && (
                          <p>
                            {seats} {selectSubscriptionText.seats}
                          </p>
                        )}
                        <p>
                          {gradableId === GRADABLE_SUBSCRIPTION
                            ? selectSubscriptionText.gradableLessons
                            : selectSubscriptionText.nonGradableLessons}
                        </p>
                      </div>
                      <div>
                        <h2>$ {calculatePrice()}.00 / Yr</h2>
                        <Button
                          title={
                            upgrade
                              ? selectSubscriptionText.upgradeNow
                              : trial
                              ? selectSubscriptionText.startTrial
                              : selectSubscriptionText.subscribeNow
                          }
                          onClick={onSubscribe}
                          disabled={getFieldError()}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  },
);

export default TailorSubscriptionUI;

import React from 'react';
import {Col} from 'react-bootstrap';
import './styles.css';
import {lessonsIcon, logoTextWhite} from '../../../../assets/images';
import {homeText} from '../../homeText';

interface Props {
  course: any;
  onOpenCourse: () => void;
}

const CoursesCard = (props: Props) => {
  const {course} = props;

  return (
    <>
      <Col lg={{span: 3}} xs={12} md={6} className="courses-card_container">
        <div className="courses-card" onClick={props.onOpenCourse}>
          <div className="courses-header">
            <div
              className="courses-thumb"
              style={{
                backgroundImage: course.coursePosterUrl
                  ? `url(${course.coursePosterUrl})`
                  : `url(${logoTextWhite})`,
                backgroundColor: course.coursePosterUrl
                  ? ''
                  : 'var(--workspace-primary-color-1)',
                backgroundSize: course.coursePosterUrl ? '' : 'auto',
              }}></div>
            <div className="courses-tag_container">
              <span className="courses-tag tag-math">
                {course.subjectTitle}
              </span>
            </div>
          </div>
          <h2 className="courses-title">{course.title}</h2>
          {course.progress != null ? (
            <div className="courses-progress_container">
              <p>
                {course.progress === 100
                  ? 'Completed'
                  : `${homeText.progress} ${course.progress}%`}
              </p>
            </div>
          ) : null}

          <p className="courses-organization_name">{course.organizationName}</p>
          <div className="courses-lessons_container">
            <img src={lessonsIcon} alt="" />
            <p>
              <span>{course.amountOfLessons}</span>{' '}
              {course.amountOfLessons === 1 ? 'Lesson' : 'Lessons'}
            </p>
          </div>
        </div>
      </Col>
    </>
  );
};

export default CoursesCard;

import React, {useContext} from 'react';
import NodeHandles from './NodeHandles';
import {NodeData} from '../models';
import {EditorContext} from '../container/LessonEditorContainer';
import {lessonEditorText} from '../lessonEditorText';
import {LessonContentJumpTo} from '../../../data/models/LessonContent/LessonContentBase';
import {getLessonContentItemTitle} from '../../../data/models/LessonContent/LessonContentList';
import Tooltip from '../../../uiToolkit/Tooltip';
import NodeReplace from './NodeReplace';

interface Props {
  data: NodeData;
}

const JumpToNode = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  const getNode = () => {
    return data.payload as LessonContentJumpTo;
  };

  return (
    <NodeHandles>
      <div className="EditorNodeContainer">
        <NodeReplace currentNode={data} />
        <div
          onClick={onClick}
          className={`EditorCard ${
            context.selectedNode?.internalId === data.payload.internalId
              ? 'SelectedEditorCard'
              : ''
          } ForceWidthCard`}>
          <p className="ChoiceQuestionNode">
            {lessonEditorText.jumpToNodeTooltip}
          </p>
          <Tooltip
            tooltipText={getLessonContentItemTitle(
              getNode().jumpToNodeId,
              context.lessonContent,
            )}
            greenTooltip>
            <p className="ChoiceQuestionNode">
              {getLessonContentItemTitle(
                getNode().jumpToNodeId,
                context.lessonContent,
              )}
            </p>
          </Tooltip>
        </div>
      </div>
    </NodeHandles>
  );
});

export default JumpToNode;

import React from 'react';
import {createOrgText} from '../createOrgText';

interface Props {
  onOpen: () => void;
}

const CreateOrganizationOpenerUI = React.memo(({onOpen}: Props) => {
  return (
    <li className="createOrganizationButtonContainer">
      <a href="#" onClick={onOpen}>
        <span className="icon-typeOrganization"></span>
        {createOrgText.create}
      </a>
    </li>
  );
});

export default CreateOrganizationOpenerUI;

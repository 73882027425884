import React, {useEffect, useState} from 'react';
import SubscriptionSettingsUI from '../view/SubscriptionSettingsUI';
import {inject, observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import SubscriptionSettingsStore from '../store/SubscriptionSettingsStore';
import {OrganizationSubscription} from '../../../../data/models/Subscription/Subscription';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {settingsText} from '../../settingsText';
import {InvoiceListItem} from '../../../../data/models/Subscription/Invoice';

interface Props {
  subscriptionSettingsStore?: SubscriptionSettingsStore;
}

const SubscriptionSettingsContainer = inject('subscriptionSettingsStore')(
  observer(({subscriptionSettingsStore}: Props) => {
    const [subscription, setSubscription] =
      useState<OrganizationSubscription>();
    const {organizationId} = useParams();
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const [invoices, setInvoices] = useState<InvoiceListItem[]>([]);

    useEffect(() => {
      if (organizationId) {
        subscriptionSettingsStore!
          .getOrganizationSubscription(organizationId)
          .then(setSubscription);
        subscriptionSettingsStore!
          .getInvoicesList(organizationId)
          .then(setInvoices);
      }
    }, []);

    // const onUpgradeSubscription = () => {
    //   navigate(`/subscription/${organizationId}/upgrade`);
    // };

    const onChangePaymentMethod = () => {
      if (organizationId) {
        subscriptionSettingsStore!
          .getPaymentMethodSession(organizationId)
          .then(res => {
            if (res) {
              const win = window.open(res, '_blank');
              if (win != null) {
                win.focus();
              }
            }
          });
      }
    };

    const onCancelSubscription = () => {
      if (organizationId) {
        subscriptionSettingsStore
          ?.cancelSubscription(organizationId)
          .then(success => {
            if (success) {
              window.location.reload();
            }
          });
      }
    };

    return subscription ? (
      <>
        <SubscriptionSettingsUI
          // onUpgradeSubscription={onUpgradeSubscription}
          subscription={subscription}
          onCancelSubscription={() => setShowConfirmCancel(true)}
          onChangePaymentMethod={onChangePaymentMethod}
          invoices={invoices}
          isLoading={subscriptionSettingsStore!.isLoading}
        />
        <ConfirmationModal
          title={settingsText.areYouSureCancelSubscription}
          onAccept={onCancelSubscription}
          onCancel={() => setShowConfirmCancel(false)}
          shown={showConfirmCancel}
        />
      </>
    ) : null;
  }),
);

export default SubscriptionSettingsContainer;

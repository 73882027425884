export const addMChoiceWizardText = {
  title: 'Multiple Choice Q&A.',
  questionTitle: 'Question',
  questionPlaceholder: 'Enter your question',
  answerTitle: 'Answer',
  answerPlaceholder: 'Add multiple choice answer',
  isCorrectTitle: 'Correct Answer?',
  addAnswer: 'Add answer',
  cancel: 'Cancel',
  accept: 'Save',
  generateWithAI: 'Generate with AI Assistant',
  assistant: 'AI Assistant',
  topicHeading: 'Specify the question topic for suggestions.',
  topicPlaceholder: 'Questions topic',
  continue: 'Continue',
  select: 'Select',
  Disclamer: '*Maximum 4, with at least 1 answer correct. No duplicate answers',
  chooseQuestion: 'Choose the question you want to proceed with.',
  gradeWeight: 'Score Weight',
  openAIAssistant: 'Open Tapybl AI Assistant',
  cantGenerate:
    'AI generation is only available for videos uploaded directly to Tapybl.',
};

import {makeAutoObservable} from 'mobx';
import LearnerRepository from '../../../data/repositories/LearnerRepository';
import CoursesRepository from '../../../data/repositories/CoursesRepository';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {learnersText} from '../learnersText';
import {
  LEARNER_INVITATION_FAIL,
  LEARNER_INVITATION_PARTIAL_SUCCESS,
  LEARNER_INVITATION_SUCCESS,
} from '../../../data/models/Learners/Learner';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class LearnersStore {
  private learnerRepo = new LearnerRepository();
  private coursesRepo = new CoursesRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getMyLearners(
    page: number,
    organizationId: string,
    pageSize: number,
    courseIds?: string[],
    search?: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.learnerRepo.getMyLearners(
      page,
      organizationId,
      pageSize,
      courseIds,
      search,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }

  public async deleteLearnerInvitation(
    organizationId: string,
    invitationId: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.learnerRepo.deleteLearnerInvitation(
      organizationId,
      invitationId,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async getLearnersInvitations(
    organizationId: string,
    page: number,
    pageSize: number,
    orderBy: string,
    search?: string,
    orderAsc?: boolean,
  ) {
    this.loadingModule.startLoading();
    const result = await this.learnerRepo.getLearnersInvitations(
      organizationId,
      page,
      pageSize,
      orderBy,
      search,
      orderAsc,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return {
      data: [],
      pagesCount: 0,
    };
  }

  public async addLearners(organizationId: string, learnersEmails: string[]) {
    this.loadingModule.startLoading();
    const result = await this.learnerRepo.addLearners(
      organizationId,
      learnersEmails,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      if (result.data.result === LEARNER_INVITATION_FAIL) {
        toast.error(learnersText.learnerInviteFailMsg, toastConfig);
      }
      if (result.data.result === LEARNER_INVITATION_PARTIAL_SUCCESS) {
        toast.success(learnersText.learnerInvitePartialSuccessMsg, toastConfig);
      }
      if (result.data.result === LEARNER_INVITATION_SUCCESS) {
        toast.success(learnersText.learnerInviteSuccessMsg, toastConfig);
      }
    } else if (result.errors) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async getOrganizationCourses(organizationId: string) {
    const result =
      await this.coursesRepo.getPublishedOrganizationCourses(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async deleteLearner(organizationId: string, learnerId: string) {
    this.loadingModule.startLoading();
    const result = await this.learnerRepo.deleteLearner(
      organizationId,
      learnerId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      toast.success(learnersText.learnerDeleteSuccessMsg, toastConfig);
      return result.data;
    } else if (result.errors) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }

  public async activateLearner(organizationId: string, learnerId: string) {
    this.loadingModule.startLoading();
    const result = await this.learnerRepo.activateLearner(
      organizationId,
      learnerId,
    );
    this.loadingModule.endLoading();
    if (result.success) {
      toast.success(learnersText.learnerActivated, toastConfig);
    }
    return result.success;
  }

  public async removeLearnerFromTheCourse(
    organizationId: string,
    learnerId: string,
    courseId: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.learnerRepo.removeLearnerFromTheCourse(
      organizationId,
      learnerId,
      courseId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      toast.success(learnersText.learnerRemoveFromCourseSuccess, toastConfig);
    } else if (result.errors) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    return result.success;
  }

  public async reEnrollLearner(
    organizationId: string,
    courseId: string,
    learnerId: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.learnerRepo.reEnrollLearnerInTheCourse(
      organizationId,
      courseId,
      learnerId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      toast.success(learnersText.learnerReEnrollSuccess, toastConfig);
    }
    return result.success;
  }
}

export default LearnersStore;

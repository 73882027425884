export const courseEditTabs = (organizationId?: string, courseId?: string) => [
  {
    title: 'General',
    route: `/manage/${organizationId}/course/${courseId}/general`,
  },
  // {
  //   title: 'Syllabus',
  //   route: `/manage/${organizationId}/course/${courseId}/syllabus`,
  // },
];

import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import JumpToOptionsUI from '../view/JumpToOptionsUI';
import {LessonContentJumpTo} from '../../../../../data/models/LessonContent/LessonContentBase';
import LessonEditorStore from '../../../store/LessonEditorStore';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../../data/errorCodes';
import {toastConfig} from '../../../../../uiToolkit/Toast/toastConfig';
import {lessonEditorText} from '../../../lessonEditorText';
import ConfirmationModal from '../../../../../uiToolkit/ConfirmationModal';
import {
  LessonContentList,
  getListOfItemsFromContentList,
} from '../../../../../data/models/LessonContent/LessonContentList';

interface Props {
  contentForTab: string;
  optionsForType: string;
  entityToEdit?: LessonContentJumpTo;
  lessonEditorStore?: LessonEditorStore;
  onCancel: () => void;
  onChangesMade: () => void;
  isPublished?: boolean;
  lessonContent?: LessonContentList;
  rootId: string;
}

const JumpToOptionsContainer = inject('lessonEditorStore')(
  observer(
    ({
      entityToEdit,
      lessonEditorStore,
      onCancel,
      onChangesMade,
      isPublished,
      lessonContent,
      rootId,
    }: Props) => {
      const [showDelete, setShowDelete] = useState(false);
      const [showSoftDelete, setShowSoftDelete] = useState(false);
      const {organizationId, lessonId} = useParams();

      const onEdit = (jumpToId: string) => {
        if (organizationId && lessonId && entityToEdit) {
          lessonEditorStore
            ?.editContentNode(organizationId, lessonId, {
              ...entityToEdit,
              jumpToNodeId: jumpToId,
            } as LessonContentJumpTo)
            .then(onChangesMade);
        }
      };

      const onDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.deleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const onSoftDelete = () => {
        if (organizationId && lessonId && entityToEdit) {
          setShowDelete(false);
          lessonEditorStore
            ?.softDeleteContentNode(
              organizationId,
              lessonId,
              entityToEdit.internalId,
            )
            .then(processDeleteResult);
        }
      };

      const processDeleteResult = (success: boolean) => {
        if (success) {
          onChangesMade();
        } else {
          toast.error(getErrorFromCode(''), toastConfig);
        }
      };

      return (
        <>
          <JumpToOptionsUI
            entityToEdit={entityToEdit}
            onCancel={onCancel}
            onEdit={onEdit}
            isLoading={lessonEditorStore!.isLoading}
            onDelete={() => setShowDelete(true)}
            onSoftDelete={
              entityToEdit?.internalId === rootId
                ? undefined
                : () => setShowSoftDelete(true)
            }
            isPublished={isPublished}
            options={getListOfItemsFromContentList(lessonContent)}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteJumpToOption}
            onAccept={onDelete}
            onCancel={() => setShowDelete(false)}
            shown={showDelete}
            acceptText={lessonEditorText.delete}
          />
          <ConfirmationModal
            title={lessonEditorText.areYouSureDeleteJumpToOptionSoft}
            onAccept={onSoftDelete}
            onCancel={() => setShowSoftDelete(false)}
            shown={showSoftDelete}
            acceptText={lessonEditorText.delete}
          />
        </>
      );
    },
  ),
);

export default JumpToOptionsContainer;

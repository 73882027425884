import React from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import DataTable from 'react-data-table-component';
import TablePagination from '../../../uiToolkit/TablePagination';
import {getFirstCapitals} from '../../../utils/getFirstCapitals';
import {addInstructorWizardText} from '../addInstructorWizardText';
import Checkbox from '../../../uiToolkit/Inputs/Checkbox';
import Button from '../../../uiToolkit/Buttons/Button';
import {CourseInstructor} from '../../../data/models/Course/Course';
import '../../../uiToolkit/DataTable/styles.css';
import './styles.css';

interface Props {
  instructorData: any[];
  onCancel: () => void;
  onSave: (selectedInstructors: string) => void;
  currentInstructor?: CourseInstructor;
  isLoading: boolean;
}

const columns = (
  checkedInstructorId: string,
  setCheckedInstructorId: React.Dispatch<React.SetStateAction<string>>,
) => [
  {
    name: '',
    selector: (row: any) => row.staffMemberId,
    cell: (row: any) => (
      <Checkbox
        checked={checkedInstructorId === row.staffMemberId}
        className="Checkbox"
        onCheck={() =>
          handleCheckboxChange(
            row.staffMemberId,
            checkedInstructorId,
            setCheckedInstructorId,
          )
        }
        radio
      />
    ),
    id: 'InstructorCheckboxColumn',
  },
  {
    name: 'Name',
    selector: (row: any) => row.name,
    cell: (row: any) => (
      <div className="InstructorNameColumn">
        {row.avatarUrl ? (
          <img
            src={row.avatarUrl}
            alt="User Profile Picture"
            className="InstructorModalColumn"
          />
        ) : (
          <span className="Initials">{getFirstCapitals(row.name || '')}</span>
        )}
        <p className="DataTableText">{row.name}</p>
      </div>
    ),
    grow: 7,
    id: 'NameColumn',
  },
  {
    name: 'Email',
    selector: (row: any) => row.email,
    cell: (row: any) => <p className="DataTableText">{row.email}</p>,
    grow: 6,
    id: 'EmailColumn',
  },
  {
    name: 'Role',
    selector: (row: any) => row.roleName,
    cell: (row: any) => <p className="DataTableText">{row.roleName}</p>,
    grow: 6,
    id: 'RoleColumn',
  },
];

const handleCheckboxChange = (
  instructorId: string,
  checkedInstructorId: string,
  setCheckedInstructorId: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (instructorId === checkedInstructorId) {
    setCheckedInstructorId('');
  } else {
    setCheckedInstructorId(instructorId);
  }
};

const AddInstructorWizardUI = React.memo(
  ({onCancel, onSave, instructorData, currentInstructor, isLoading}: Props) => {
    const [checkedInstructorId, setCheckedInstructorId] = React.useState('');

    return (
      <div>
        <ModalContainer onClose={onCancel}>
          <div className="AddInstructorWizardContainer">
            <div className="Header">
              <h1>{addInstructorWizardText.ModalTitle}</h1>
            </div>
            {currentInstructor && (
              <div className="CurrentInstructorContainer">
                <span>
                  {addInstructorWizardText.currentInstructor}:{' '}
                  <span>{currentInstructor?.instructor.userName}</span>
                </span>
              </div>
            )}
            <div className="dataTableContainer">
              <DataTable
                data={instructorData}
                columns={columns(checkedInstructorId, setCheckedInstructorId)}
                className="AddInstructorTable"
                pagination
                striped
                theme="custom-stripes"
                paginationPerPage={10}
                paginationComponent={TablePagination}
              />
            </div>

            <div className="Footer">
              <Button title="Cancel" onClick={onCancel} uiType="text" />
              <Button
                title="Save"
                onClick={() => onSave(checkedInstructorId)}
                uiType="action"
                disabled={!checkedInstructorId}
                isLoading={isLoading}
              />
            </div>
          </div>
        </ModalContainer>
      </div>
    );
  },
);

export default AddInstructorWizardUI;

import React, {useEffect, useState} from 'react';
import CourseHomeUI from '../view/CourseHomeUI';
import {inject, observer} from 'mobx-react';
import CourseHomeStore from '../store/CourseHomeStore';
import {UserInfo} from '../../../data/models/User/UserInfo';
import {CoursePresentation} from '../../../data/models/Course/CoursePresentation';
import {useNavigate, useParams} from 'react-router-dom';
import {LearnerCourseProgress} from '../../../data/models/Course/LearnerCourseProgress';

interface Props {
  courseHomeStore?: CourseHomeStore;
}

const CourseHomeContainer = inject('courseHomeStore')(
  observer(({courseHomeStore}: Props) => {
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [coursePresentation, setCoursePresentation] =
      useState<CoursePresentation>();
    const [courseProgress, setCourseProgress] =
      useState<LearnerCourseProgress>();

    const navigate = useNavigate();
    const {courseId} = useParams();

    useEffect(() => {
      courseHomeStore!.getUserInfo().then(setUserInfo);
      fetchPresentation();
      fetchProgress();
    }, []);

    const fetchProgress = () => {
      if (courseId) {
        courseHomeStore!
          .getLearnerCourseProgress(courseId)
          .then(setCourseProgress);
      }
    };

    const onLessonClick = (lessonId: string) => {
      if (courseId) {
        navigate(`/course/${courseId}/lesson/${lessonId}`);
      }
    };

    const fetchPresentation = () => {
      if (courseId) {
        courseHomeStore!
          .getCoursePresentation(courseId)
          .then(setCoursePresentation);
      }
    };

    const getCourseLink = () => {
      return `/home`;
    };

    const onContinue = (lessonId: string) => {
      navigate(`/course/${courseId}/lesson/${lessonId}`);
    };

    const onSelfEnroll = () => {
      if (courseId) {
        courseHomeStore!.selfEnroll(courseId).then(fetchProgress);
      }
    };

    return (
      <>
        <CourseHomeUI
          userInfo={userInfo}
          coursePresentation={coursePresentation}
          courseLink={getCourseLink()}
          onLessonClick={onLessonClick}
          courseProgress={courseProgress}
          onContinue={onContinue}
          onSelfEnroll={onSelfEnroll}
        />
      </>
    );
  }),
);

export default CourseHomeContainer;

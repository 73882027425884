export const dashboardText = {
  title: 'Workspace',
  startTrial: 'Start Your Free Trial',
  startTrialInfo:
    "Get started today with a 7-day free trial to explore tapybl's features and create courses at your own pace.",
  startTrialInfo2:
    'Enjoy the flexibility to upgrade or cancel anytime during your trial.',
  startTrialInfo3: 'Ready to elevate your teaching experience?',
  startTrialButton: 'Start Trial',
  subcriptionEnded: 'Your Subscription Has Expired',
  subscriptionEndedInfo:
    'Ensure uninterrupted access to your workspace and unlock the complete suite of benefits',
  subscriptionEndedInfo2: 'and features tapybl provides by renewing your plan.',
  clickToSub: 'Simply click on "Renew Now!" to get started.',
  upgradeNow: 'Renew Now!',
};

import React from 'react';
import {notFoundIcon} from '../../assets/images';
import './styles.css';
import {notFoundText} from './notFoundText';
import {Link} from 'react-router-dom';

const NotFound = React.memo(() => {
  return (
    <div className="NotFoundContainer">
      <img src={notFoundIcon} />
      <h3>{notFoundText.title}</h3>
      <span>{notFoundText.info}</span>
      <div className="Links">
        <Link to="/home">{notFoundText.home}</Link>
        <Link to="/explore">{notFoundText.explore}</Link>
        <Link
          to="https://anitechsolutions.atlassian.net/wiki/spaces/THC/overview"
          target="_blank">
          {notFoundText.help}
        </Link>
      </div>
    </div>
  );
});

export default NotFound;

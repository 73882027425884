import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import ExploreButtonStore from './store/ExploreButtonStore';
import {
  ORGANIZATION_USER_LEARNER,
  OrganizationDetails,
} from '../../data/models/Oganization/Organization';
import {Link} from 'react-router-dom';
import {appText} from '../../assets/text/appText';

interface Props {
  exploreButtonStore?: ExploreButtonStore;
}

const ExploreButton = inject('exploreButtonStore')(
  observer(({exploreButtonStore}: Props) => {
    const [userOrg, setUserOrg] = useState<OrganizationDetails>();

    useEffect(() => {
      exploreButtonStore!.getLearnerOrganization().then(setUserOrg);
    }, []);

    return !exploreButtonStore?.isLoading &&
      (!userOrg || userOrg?.userType !== ORGANIZATION_USER_LEARNER) ? (
      <Link className="NavBarRegularButton" to="/explore">
        {appText.explore}
      </Link>
    ) : null;
  }),
);

export default ExploreButton;

import React from 'react';
import CourseEditRootUI from '../view/CourseEditRootUI';
import {useParams} from 'react-router-dom';

const CourseEditRootContainer = React.memo(() => {
  const {organizationId} = useParams();

  const getCourseLink = () => {
    if (organizationId) {
      return `/manage/${organizationId}/courses`;
    } else {
      return `/home`;
    }
  };

  return (
    <div>
      <CourseEditRootUI courseLink={getCourseLink()} />
    </div>
  );
});

export default CourseEditRootContainer;

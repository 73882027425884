import React from 'react';
import {settingsText} from '../../settingsText';
import DataTable from 'react-data-table-component';
import {InvoiceListItem} from '../../../../data/models/Subscription/Invoice';
import {formatDateTimeTextFormat} from '../../../../utils/notificationTimeTextConvertor';
import '../../../../uiToolkit/DataTable/styles.css';
import TablePagination from '../../../../uiToolkit/TablePagination';

interface Props {
  invoices: InvoiceListItem[];
  isLoading: boolean;
}

const columns = [
  {
    name: 'Invoice Id',
    selector: (row: InvoiceListItem) => row.number,
    sortable: true,
    cell: (row: InvoiceListItem) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.number}
      </h5>
    ),
  },
  {
    name: 'Payed at',
    selector: (row: InvoiceListItem) => row.id,
    sortable: true,
    cell: (row: InvoiceListItem) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {formatDateTimeTextFormat(new Date(row.created * 1000).toISOString())}
      </h5>
    ),
  },
  {
    name: 'Actions',
    selector: (row: InvoiceListItem) => row.invoice_pdf,
    sortable: true,
    cell: (row: InvoiceListItem) => (
      <div>
        <a
          className="DataTableText DataTableActionEdit"
          href={row.invoice_pdf}
          rel="noopener noreferrer"
          target="_blank">
          {settingsText.viewPdf}
        </a>
      </div>
    ),
  },
];

const InvoicesTable = React.memo(({invoices, isLoading}: Props) => {
  return (
    <div className="InvoicesSectionContainer">
      <h3>{settingsText.invoices}</h3>
      {!isLoading && (
        <DataTable
          data={invoices}
          columns={columns}
          className="DataTable"
          pagination
          paginationPerPage={5}
          paginationComponent={TablePagination}
        />
      )}
    </div>
  );
});

export default InvoicesTable;

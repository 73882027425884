import {makeAutoObservable} from 'mobx';
import CoursesListRepository from '../../../data/repositories/CoursesRepository';

class ExploreStore {
  private coursesListRepository = new CoursesListRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getPublicCourse(subjectId?: string) {
    const result =
      await this.coursesListRepository.getPublicCourseList(subjectId);
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async getSubjects() {
    const result = await this.coursesListRepository.getSubjects();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default ExploreStore;

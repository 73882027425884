export const subscriptionSuccessText = {
  title: 'Congratulations',
  subtitle: 'Your one-year subscription is now active',
  trialActive: 'Your 7 days trial is now active',
  updateSuccess: 'Your subscription was successfully updated',
  updateInfo: 'You can access transaction details through your invoices list.',
  whatsNext: 'What’s next?',
  cardText1: 'Start adding users to your workspace',
  cardText2: 'Create some courses and share them with your students',
  goToWorkspace: 'Go to workspace',
  paymentSubtitle: 'Your payment method has been successfully updated',
  paymentInfo:
    'Enter your workspace to create your next course and share it with your learners!',
};

import React from 'react';
import {Point} from '../../../../data/models/LessonContent/ContentFreeform';
import '../styles.css';

interface Props {
  position: Point;
  text: string;
  fontSize: number;
  color: string;
  updateTextOptions?: (position: Point) => void;
  onClick?: () => void;
}

export const DEFAULT_SVG_FONT_SIZE = 16;

const EditorSvgText = React.memo(
  ({position, text, fontSize, color, updateTextOptions, onClick}: Props) => {
    const [textPosition, setTextPosition] = React.useState<Point>({x: 0, y: 0});
    const [resizeData, setResizeData] = React.useState({
      active: false,
      offset: {x: 0, y: 0},
    });

    React.useEffect(() => {
      setTextPosition({...position});
    }, [position]);

    const handlePointerDown = (e: any) => {
      if (onClick) {
        onClick();
        e.stopPropagation();
      }
      if (!updateTextOptions) return;
      const el = e.target;
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      el.setPointerCapture(e.pointerId);
      setResizeData({
        ...resizeData,
        active: true,
        offset: {
          x,
          y,
        },
      });
    };

    const handlePointerMove = (e: any) => {
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      if (resizeData.active) {
        setTextPosition({
          x: textPosition.x - (resizeData.offset.x - x),
          y: textPosition.y - (resizeData.offset.y - y),
        });
      }
    };

    const handlePointerUp = () => {
      setResizeData({
        ...resizeData,
        active: false,
      });
      if (updateTextOptions) {
        updateTextOptions(textPosition);
      }
    };

    return (
      <g>
        <text
          x={textPosition.x}
          y={textPosition.y}
          cursor={updateTextOptions ? 'move' : onClick ? 'pointer' : ''}
          className="SvgText"
          fill={color}
          fontSize={fontSize || DEFAULT_SVG_FONT_SIZE}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}>
          {text.split('\n').map((textPart, index) => (
            <tspan
              key={index}
              x={textPosition.x}
              dy={(fontSize || DEFAULT_SVG_FONT_SIZE) + 2}>
              {textPart}
            </tspan>
          ))}
        </text>
      </g>
    );
  },
);

export default EditorSvgText;

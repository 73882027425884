import React from 'react';
import {Link} from 'react-router-dom';

const Landing = React.memo(() => {
  return (
    <div>
      <Link to="/auth/login">Login</Link>
      <Link to="/auth/signup">Signup</Link>
    </div>
  );
});

export {Landing};

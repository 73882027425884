import React from 'react';
import {
  numberPickerArrowDownIc,
  numberPickerArrowUpIc,
} from '../../../assets/images';
import './styles.css';

type NumbersInputUITypes = 'standart' | 'box';

interface Props {
  value?: number;
  setValue: (amount?: number) => void;
  disabled?: boolean;
  fieldError?: boolean;
  uiType?: NumbersInputUITypes;
  heading?: string;
  isMandatory?: boolean;
  className?: string;
  maxValue?: number;
}

const NumbersInput = React.memo(
  ({
    value,
    setValue,
    disabled,
    fieldError,
    uiType,
    heading,
    isMandatory,
    className,
    maxValue,
  }: Props) => {
    const onArrowUp = () => {
      let result = 0;
      const max = maxValue ? maxValue : 100;
      if (value !== undefined) {
        result = value + 1;
      }
      if (result <= max) {
        setValue(result);
      }
    };

    const onArrowDown = () => {
      let result = 0;
      if (value !== undefined) {
        result = value - 1;
      }
      if (result >= 0) {
        setValue(result);
      }
    };

    const onInputChange = (e: any) => {
      if (e.target.value !== '') {
        const max = maxValue ? maxValue : 100;
        if (
          parseInt(e.target.value, 10) <= max &&
          parseInt(e.target.value, 10) > 0
        ) {
          setValue(parseInt(e.target.value, 10));
        }
        if (parseInt(e.target.value, 10) >= max) {
          setValue(max);
        }
        if (parseInt(e.target.value, 10) <= 0) {
          setValue(0);
        }
      } else {
        setValue(undefined);
      }
    };

    const getBaseClass = () => {
      switch (uiType) {
        case 'box':
          return 'NumbersInputBoxContainer';
        default:
          return 'NumbersInputStandart';
      }
    };

    return (
      <div className={`${className} ${disabled ? 'NumbersInputDisabled' : ''}`}>
        <p className="NumbersInputTitle">
          {heading}
          {isMandatory ? <p className="MandatoryIndicator">*</p> : ''}
        </p>
        <div
          className={`NumbersInputContainer ${getBaseClass()} ${
            fieldError ? 'NumbersInputFieldError' : ''
          }`}>
          <input
            type="number"
            value={value}
            disabled={disabled}
            onChange={onInputChange}
          />
          <div className="ButtonsContainer">
            <button onClick={onArrowUp}>
              <img src={numberPickerArrowUpIc} />
            </button>
            <button onClick={onArrowDown}>
              <img src={numberPickerArrowDownIc} />
            </button>
          </div>
        </div>
      </div>
    );
  },
);

export default NumbersInput;
